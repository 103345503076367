import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Badge,
  Navbar,
  Offcanvas,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";
import { ReactComponent as HeaderLogo } from "../../assets/images/mvs-logo.svg";
import {
  attorney_LandingPage,
  client_Changepassword,
  client_EditComponent,
  client_LandingPage,
  client_uploadDocuments,
  firmsuperadmin_LandingPage,
  login,
  client_new_folder_upload_documents,
  client_view_bank_details,
  client_add_bank_details,
  client_request_bankList_details,
} from "../../constants/routes";
import { ChevronDown, X } from "react-bootstrap-icons";
import { ReactComponent as PasswordIcon } from "../../assets/images/change_pwd_icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout_icon.svg";
import { ReactComponent as ChangeAttorneyIcon } from "../../assets/images/UserGear.svg";
import { ReactComponent as UserProfile } from "../../assets/images/user-profile.svg";
import { ReactComponent as EditProfile } from "../../assets/images/edit-profile.svg";
import { ReactComponent as Notification } from "../../assets/images/BellSimple.svg";
import { ReactComponent as Notify } from "../../assets/images/notify-icon.svg";
import { ReactComponent as NotifyCross } from "../../assets/images/notify-cross-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  myProfile,
  changePassword,
  logout,
  editProfile,
  notification,
  changeAttorney,
} from "../../constants/labels";
import { useSignoutsuperuserMutation } from "../../slices/loginAuthManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { signOutApiCall } from "../../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { ROLE } from "../../constants/role";
import ClientOffCanvasEditProfile from "../editProfileOffCanvas/ClientOffCanvasEditProfile";
import ClientNotificationCanvas from "../editProfileOffCanvas/NotificationCanvas";
import {
  useLazyGetNotificationStatusQuery,
  useUpdateNotificationStatusMutation,
} from "../../slices/userAuthManagementSlice";
import {
  useLazyGetAllDocsByRootIdQuery,
  useUpdateWillRetainflagMutation,
} from "../../slices/documentApiManagement";
import ClientChangeAttorney from "../editProfileOffCanvas/ClientChangeAttorneyCanvas";
import ModalPopup from "../../components/modal/ModalPopup";
import SuccessModalContent from "../../components/widgets/SuccessModalContent";
import { firmAttorneylabel } from "../../constants/firm/labels";
import ReactGA from "react-ga4";
import {
  useDeleteNotificationsMutation,
  useGetNotificationsQuery,
  usePutNotificationsMutation,
} from "../../slices/notificationApiManagementSlice";
import { useLazyGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import { useLazyNotificationCheckQuery } from "../../slices/folderApiManagementSlice";
import ClientOffCanvasEditComponent from "../editProfileOffCanvas/ClientOffCanvasEditComponent";
import FamilyOffCanvasEditProfile from "../editProfileOffCanvas/FamilyOffCanvasEditProfile";
import FamilyOffCanvasAddProfile from "../editProfileOffCanvas/FamilyOffCanvasAddProfile";
import DocumentPreview from "../modal/DocumentPreview";
import dayjs from "dayjs";
import { documentApiManagement } from "../../slices/documentApiManagement";
import {
  useLazyGetAllBankDetailsQuery,
  useLazyGetShareBankDetailsQuery,
} from "../../slices/wireTransferApiManagementSlice";
export default function HeaderProfileClient() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [signoutsuperuser, { isLoading }] = useSignoutsuperuserMutation();
  const [getDocumentDetails, { isLoading: isLoadingGetDocDetails }] =
    useLazyGetAllDocsByRootIdQuery();
  const { userInfo } = useSelector((state) => state.auth);
  const [collapse, setCollapese] = useState(false);
  const [notificationCollapse, setNotificationCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const notificationDropdownRef = useRef(null);
  const [visible, showVisible] = useState(false);
  const handleClose = () => showVisible(false);
  const [showNotificationCanvas, setNotificationCanvas] = useState(false);
  const [showChangeAttorney, setChangeAttorney] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(true);
  const [isLoadingDoc, setLoading] = useState(false);
  const [progressMap, setProgressMap] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [viewDocType, setViewDocType] = useState("");
  const [selectedDocs, setSelectedDocs] = useState("");
  const [isSubmitted, setSubmitClicked] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [fileName, setFileName] = useState("");
  const [docId, setDocId] = useState("");
  const [rootDocTypeId, setRootDocTypeId] = useState("");
  const [notificationId, setNotificationId] = useState("");
  const [isReadDoc, setReadDoc] = useState(false);
  const handleNotificationClose = () => setNotificationCanvas(false);
  const handleChangeAttorneyClose = () => setChangeAttorney(false);
  const [editClient, setEditClient] = useState(false);
  const [editFamily, setEditFamily] = useState(false);
  const [addFamily, setAddFamily] = useState(false);
  const [getShareData, { isFetching: checkGetShareDataLoading }] =
    useLazyGetShareBankDetailsQuery();
  const [getAllBankDetails, , { isFetching: checkGetAllBankDetailsLoading }] =
    useLazyGetAllBankDetailsQuery();

  let media = "lg";
  const ssoResponse =
    sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
  const isSSOLogin = ssoResponse
    ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
    : false;

  const [trigger] = useLazyGetNotificationStatusQuery();
  const [updateNotification, { isLoading: updateNotificationLoading }] =
    useUpdateNotificationStatusMutation();
  const { data: notifications } = useGetNotificationsQuery(userInfo?.userId, {
    pollingInterval: 60000,
  });
  // [ checkDeleted ] = useCheckFolderDeleteQuery();
  const [updateWillRetain, { isLoading: updateWillRetainLoading }] =
    useUpdateWillRetainflagMutation();
  const [updateNotifications, { isLoading: isUpdateNotificationsLoading }] =
    usePutNotificationsMutation();
  const [deleteNotifications, { isLoading: isDeleteNotificationsLoading }] =
    useDeleteNotificationsMutation();
  const [getClientData] = useLazyGetClientQuery();
  const [checkNotification, { isLoading: checkNotificationLoading }] =
    useLazyNotificationCheckQuery();
  useEffect(() => {
    getClientData(userInfo?.userId);
  }, [addFamily, editFamily, editClient, getClientData, userInfo]);

  const getNotification = useCallback(async () => {
    if (userInfo?.userId && userInfo?.userId !== undefined) {
      try {
        const res = await trigger(userInfo?.userId).unwrap();
        if (res?.isSuccessful) {
          setNotificationStatus(res?.bell);
        } else {
          toast.error(res?.message);
        }
      } catch (error) {
        toast.error(error?.errorMessage);
      }
    }
  }, [userInfo?.userId, trigger]);

  useEffect(() => {
    if (userInfo?.userId) {
      getNotification();
    }
  }, [userInfo?.userId, getNotification]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  });
  const updateRead = async (id) => {
    try {
      await updateNotifications(id).unwrap();
    } catch (err) {
      toast.error(err?.errorMessage || err?.message);
    }
  };
  useEffect(() => {
    if (isSubmitted) {
      if (rootDocTypeId === "DT_001") {
        const data = {
          docId,
          userId: userInfo?.userId,
          orgDocsFlag: radioValue === "Yes" ? true : false,
        };
        submitWillUpdateNotification(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSubmitted,
    location?.state?.doc?.rootDocId,
    location?.state?.doc?.userId,
    radioValue,
    rootDocTypeId,
    userInfo?.userId,
  ]);
  const submitWillUpdateNotification = async (data) => {
    setSubmitClicked(false);
    try {
      const res = await updateWillRetain(data).unwrap();
      if (res?.isSuccessful) {
        setShowModal(false);
        toast.success(res?.message);
        updateRead(notificationId);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.errorMessage || err?.data?.message);
    }
  };
  const handleClickListener = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCollapese(false);
    }
    if (
      notificationDropdownRef &&
      !notificationDropdownRef.current.contains(event.target)
    ) {
      setNotificationCollapse(false);
    }
  };
  const handleLogout = async () => {
    setCollapese(true);
    try {
      const res = await signoutsuperuser(userInfo?.accessToken).unwrap();
      dispatch(signOutApiCall());
      toast.success(res?.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleChangePassword = () => {
    const firmType = userInfo?.firmTypeId;
    setCollapese((collapse) => !collapse);
    navigate(client_Changepassword);
    firmType === 1 &&
      ReactGA.event({
        category: `${ROLE.client} - 3 Level`,
        action: `Change Password - ${ROLE.client} - 3 Level`,
        label: `Change Password - ${ROLE.client} - 3 Level`,
      });
    firmType === 2 &&
      ReactGA.event({
        category: `${ROLE.client} - 2 Level`,
        action: `Change Password - ${ROLE.client} - 2 Level`,
        label: `Change Password - ${ROLE.client} - 2 Level`,
      });
  };
  const handleEditProfile = (notification) => {
    const firmType = userInfo?.firmTypeId;
    firmType === 1 &&
      ReactGA.event({
        category: `${ROLE.client} - 3 Level`,
        action: `Edit Profile - ${ROLE.client} - 3 Level`,
        label: `Edit Profile - ${ROLE.client} - 3 Level`,
      });

    firmType === 2 &&
      ReactGA.event({
        category: `${ROLE.client} - 2 Level`,
        action: `Edit Profile - ${ROLE.client} - 2 Level`,
        label: `Edit Profile - ${ROLE.client} - 2 Level`,
      });
    if (notification) {
      setCollapese(false);
    }
    if (window.innerWidth >= 768) {
      showVisible(true);
    } else {
      navigate(client_EditComponent, {
        state: {
          page: "clientPage",
          id: userInfo?.userId,
          pathname: location?.pathname,
          state: location?.state,
        },
      });
    }
  };

  const handleShareBankDetailsNoti = async (notification) => {
    if (notification) {
      setCollapese(false);
    }
    const apiUrl = notification[0]?.apiUrl;
    const urlObj = new URL(apiUrl);
    const queryParameters = new URLSearchParams(urlObj.search);
    const transactionId = queryParameters.get("transactionId");
    const identifierId = queryParameters.get("identifier");
    try {
      const result = await getShareData({
        transactionId: transactionId,
        userId: userInfo?.userId,
      }).unwrap();
      if (result?.data !== null) {
        navigate(client_view_bank_details, {
          state: {
            page: "viewBankDetailNoti",
            id: {
              transactionId: transactionId,
              identifier: identifierId,
              securityKey: result?.key
            },
            userId: userInfo.userId,
          },
        });
      } else {
        toast.error(result?.message);
      }
    } catch (error) {
      toast.error(error?.errorMessage || error?.data?.message);
    }
  };

  const handleLogoNavigation = () => {
    const user = sessionStorage.getItem("userInfo");
    const userInfo = JSON.parse(user);
    if (userInfo?.role === ROLE.firmsuperadmin) {
      navigate(firmsuperadmin_LandingPage);
    } else if (userInfo?.role === ROLE.attorney) {
      navigate(attorney_LandingPage);
    } else if (userInfo?.role === ROLE.client) {
      navigate(client_LandingPage);
    } else {
      navigate(login);
    }
  };
  const handleNotification = () => {
    const firmType = userInfo?.firmTypeId;
    setCollapese((collapse) => !collapse);
    setNotificationCanvas(true);
    firmType === 1 &&
      ReactGA.event({
        category: `${ROLE.client} - 3 Level`,
        action: `Change Notification - ${ROLE.client} - 3 Level`,
        label: `Change Notification - ${ROLE.client} - 3 Level`,
      });
    firmType === 2 &&
      ReactGA.event({
        category: `${ROLE.client} - 2 Level`,
        action: `Change Notification - ${ROLE.client} - 2 Level`,
        label: `Change Notification - ${ROLE.client} - 2 Level`,
      });
  };
  const handleNotificationStatus = async (status) => {
    const transformData = {
      userId: userInfo?.userId,
      bell: status,
    };
    const data = JSON.stringify(transformData);
    try {
      if (notification !== status) {
        const res = await updateNotification(data).unwrap();
        if (res?.isSuccessful) {
          toast.success(
            status ? "Notification turned on" : "Notification turned off"
          );
          setNotificationCanvas(false);
          setNotificationStatus(status);
        } else {
          toast.error(res?.message);
        }
      }
    } catch (error) {
      toast.error(error?.errorMessage || error?.data?.message);
    }
  };
  const handleChangeAttorney = () => {
    setCollapese((collapse) => !collapse);
    setChangeAttorney(true);
    ReactGA.event({
      category: `${ROLE.client} - 3 Level`,
      action: `Change Attorney - ${ROLE.client} - 3 Level`,
      label: `Change Attorney - ${ROLE.client} - 3 Level`,
    });
  };
  const handlePrevPage = () => {
    setSuccessModal(false);
  };
  const imageProfile = (
    <div
      className="custom-navbar-profile"
      data-testid="profile-image"
      onClick={() => setCollapese(!collapse)}
    >
      <UserProfile className="custom-navbar-img" />
    </div>
  );

  const handleNotificationClick = async (id) => {
    let notification = notifications.filter(
      (notification) => notification.notificationId === id
    );
    let type = notification[0].notificationType;
    let isRead = notification[0].seen;
    let rootDocTypeId = notification[0].rootDocTypeId;
    let docId = notification[0].docId;

    setNotificationCollapse(false);
    if (type === "WILL_UPLOAD" || type === "DELETE_DOCUMENT") {
      setReadDoc(isRead);
      try {
        if (rootDocTypeId === "DT_001" && !isRead) {
          setLoading(true);
          let filteredData;
          const result = await getDocumentDetails({
            rootDocId: "RD_001",
            userId: userInfo?.userId,
          });
          if (result?.data && result?.data[0]) {
            filteredData = result.data.filter((item) => item.docId === docId);
            let docName = filteredData[0].docName;
            handleDocumentPreview({
              docId,
              docName,
              rootDocTypeId,
              isRead,
              id,
            });
            return;
          }
        }
        const result = await getClientData(userInfo?.userId).unwrap();
        if (result?.clientId) {
          let rootDocName = notification[0].rootDocName;
          let navigatePage = notification[0].rootDocId.startsWith("ARD")
            ? client_new_folder_upload_documents
            : client_uploadDocuments;
          if (notification[0].rootDocId.startsWith("ARD")) {
            try {
              const checkResult = await checkNotification({
                userId: userInfo?.userId,
                rootDocId: notification[0].rootDocId,
              }).unwrap();
              if (!checkResult?.isSuccessful) {
                toast.error(checkResult?.message);
                !isRead && updateRead(id);
                return;
              }
              if (checkResult?.isSuccessful) {
                dispatch(
                  documentApiManagement.util.invalidateTags(["getfoldersByid"])
                );
              }
            } catch (e) {
              console.log("api error", e);
              if (e?.status && e?.status === 500) {
                toast.error(e?.data?.message);
                !isRead && updateRead(id);
                return;
              }
              if (e?.status && e?.status === 404) {
                toast.error("Sorry! you have already deleted the folder");
                !isRead && updateRead(id);
                return;
              }
            }
          }
          navigate(navigatePage, {
            state: {
              doc: {
                userId: userInfo?.userId,
                rootDocId: notification[0].rootDocId,
                rootDocName: rootDocName,
              },
              user: result,
              docType: notification[0].notification_message,
            },
          });
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    } else if (
      type === "CLIENT_ONBOARD_BY_ATTORNEY" ||
      type === "CLIENT_EDIT_BY_ATTORNEY" ||
      type === "CLIENT_EDIT_BY_FIRM_ADMIN" ||
      type === "CLIENT_ONBOARD_BY_ADMIN"
    ) {
      try {
        const result = await getClientData(userInfo?.userId).unwrap();
        if (result?.clientId) {
          handleEditProfile("notification");
        } else {
          toast.error(result?.message);
        }
      } catch (err) {
        toast.error(err?.message || err?.errorMessage);
      }
    } else if (
      type === "BANK_ACCOUNT_SHARE_BELL" ||
      type === "RECEIVE_BANK_DETAILS_MAIL_ACK_BELL"
    ) {
      handleShareBankDetailsNoti(notification);
    } else if (type === "RECEIVE_BANK_DETAILS_BELL") {
      const apiUrl = notification[0]?.apiUrl;
      const urlObj = new URL(apiUrl);
      const queryParameters = new URLSearchParams(urlObj.search);
      const transactionId = queryParameters.get("transactionId");
      try {
        const shareResult = await getShareData({
          transactionId: transactionId,
          userId: userInfo?.userId,
        }).unwrap();
        if (shareResult?.data === null) {
          try {
            const result = await getAllBankDetails(userInfo?.userId).unwrap();
            if (Array.isArray(result?.data) && result.data.length > 0) {
              navigate(client_request_bankList_details, {
                state: {
                  id: notification,
                  page: "requestBankDetails",
                },
              });
            } else {
              navigate(client_add_bank_details, {
                state: {
                  id: notification,
                  page: "requestBankDetails",
                },
              });
            }
          } catch (error) {
            toast.error(error?.errorMessage || error?.data?.message);
          }
        } else {
          toast.error("Bank details already submitted.");
          navigate(client_add_bank_details, {
            state: {
              id: notification,
              page: "requestBankDetailsAddNoti",
              data: shareResult,
            },
          });
        }
      } catch (error) {
        toast.error(error?.errorMessage || error?.data?.message);
      }
    }
    if (!isRead) {
      try {
        await updateNotifications(id).unwrap();
      } catch (err) {
        toast.error(err?.errorMessage || err?.message);
      }
    }
  };
  const blobToFile = (blob, fileName) => {
    const file = new File([blob], fileName, { type: "application/pdf" });
    return file;
  };
  const handleDocumentPreview = async ({
    docId,
    docName,
    rootDocTypeId,
    id,
    isRead,
  }) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents/${docId}`;
    setDocId(docId);
    const token = userInfo?.accessToken;
    try {
      const response = await axios({
        url: baseUrl,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: "Bearer " + token },
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;

          if (totalLength !== null) {
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );

            // Update progress for this specific file
            setProgressMap((prevProgressMap) => ({
              ...prevProgressMap,
              [docId]: { progress, docName },
            }));
          }
        },
      });
      if (response.status === 200) {
        setLoading(false);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const file = blobToFile(blob, docName);
        setTimeout(() => {
          setProgressMap((prevProgressMap) => {
            const updatedProgressMap = { ...prevProgressMap };
            delete updatedProgressMap[docId];
            return updatedProgressMap;
          });
        }, 2000);
        setShowModal(true);
        setViewDocType("will");
        setSelectedDocs(file);
        setFileName(docName);
        setRootDocTypeId(rootDocTypeId);
        setNotificationId(id);
        //setIsRead()
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error(`Document not found due to token expiration!`);
      }
      setLoading(false);
      console.error("Error downloading file:", err);
      toast.error(`${docName} downloading failed. Try again!`);
      setProgressMap((prevProgressMap) => {
        const updatedProgressMap = { ...prevProgressMap };
        delete updatedProgressMap[docId];
        return updatedProgressMap;
      });
    }
  };
  const handleClearAll = async () => {
    let notificationids = [];
    notificationids = notifications.map(
      (notification) => notification.notificationId
    );
    setNotificationCollapse(false);
    try {
      const res = await deleteNotifications(notificationids).unwrap();
      if (res?.isSuccessful) {
        toast.success("Notifications have been cleared");
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.errorMessage || err?.message);
    }
  };
  const activeNotifications =
    notifications?.length > 0 &&
    notifications.filter((notification) => notification.active);
  const unreadNotifications =
    notifications?.length > 0 &&
    notifications.filter(
      (notification) => !notification.seen && notification.active
    );

  return (
    <>
      <Navbar
        key={media}
        expand={media}
        data-bs-theme="dark"
        className={`custom-navbar profile align-items-center`}
        data-testid="header-profile"
      >
        <div className={"container-fluid px-lg-4"}>
          <div className="d-flex align-items-center">
            <Navbar.Toggle
              onClick={() => setShow(true)}
              aria-controls={`offcanvasNavbar-expand-${media}`}
              className="navbar-toggle-bg-icon p-0 me-2"
              data-testid="nav-bar-toggle"
            />
            <div
              onClick={() => handleLogoNavigation()}
              data-testid="header-profile-logo"
              className="cursor-point"
            >
              <HeaderLogo />
            </div>
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${media}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${media}`}
            placement="start"
            className="sidebar-mb"
            show={show}
            data-testid="nav-bar-offcanvas"
            onHide={() => setShow(false)}
          >
            <Offcanvas.Header className="header-line position-relative">
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${media}`}
                className="d-flex"
              >
                <HeaderLogo />
                <span
                  data-testid="offcanvas-close-icon"
                  onClick={() => setShow(false)}
                >
                  <X
                    size={28}
                    color="#fff"
                    className="position-absolute close-icon"
                  />
                </span>
              </Offcanvas.Title>
              <span className="offcanvas-header-pos"></span>
            </Offcanvas.Header>
            <Offcanvas.Body
              className="bg-color offcanvas-body-flex"
              data-testid="offcanvas-body-attorney"
            ></Offcanvas.Body>
          </Navbar.Offcanvas>

          <div className="d-flex justify-content-center align-items-center">
            <div
              className="dropdown"
              data-testid="notification-ref"
              ref={notificationDropdownRef}
            >
              {notificationStatus ? (
                <OverlayTrigger
                  key="light"
                  placement="bottom"
                  overlay={
                    <Tooltip
                      className="tooltip custom-tooltip-notification"
                      id="tooltip-bottom"
                    >
                      Check your notifications
                    </Tooltip>
                  }
                >
                  <div
                    className="pe-4 position-relative notification-container"
                    onClick={() =>
                      setNotificationCollapse(() => !notificationCollapse)
                    }
                    data-testid="notfication-openclose"
                  >
                    <Notify className="bell-icon" />
                    <Badge
                      className="position-absolute custom-badge"
                      pill
                      bg="success badge-success-position"
                    >
                      {unreadNotifications?.length !== 0
                        ? unreadNotifications?.length > 9
                          ? `9+`
                          : unreadNotifications.length
                        : null}
                    </Badge>
                  </div>
                </OverlayTrigger>
              ) : (
                <div className="pe-4 position-relative notification-container">
                  <NotifyCross />
                </div>
              )}

              {/* <div className="dropdown" ref={notificationDropdownRef}> */}
              <div
                className={`dropdown-content custom-content dropdown-scroll`}
              >
                <ul
                  className={`${
                    !notificationCollapse ? "ul-hidden" : "ul-show"
                  }`}
                >
                  <li className="title-container sticky-header">
                    <div className="d-flex justify-content-between mx-2">
                      <span className="title">
                        Notifications{" "}
                        {unreadNotifications?.length > 0 &&
                          `(${unreadNotifications.length})`}
                      </span>
                      <span
                        className="clear-all"
                        onClick={handleClearAll}
                        hidden={!activeNotifications}
                        data-testid="clear-all"
                      >
                        Clear All
                      </span>
                    </div>
                  </li>
                  {activeNotifications && activeNotifications?.length > 0 ? (
                    activeNotifications.map((notification, i) => (
                      <li
                        className="pd"
                        key={notification.notificationId}
                        onClick={() =>
                          handleNotificationClick(notification.notificationId)
                        }
                        data-testid={`notification-${i}`}
                      >
                        <div className="document-details d-flex flex-column mx-2">
                          <span
                            className={`document-type ${
                              notification.seen ? "" : "notification-unread"
                            }`}
                          >
                            {notification.notification_message}
                          </span>
                          <span
                            className={`updated-time ${
                              notification.seen ? "" : "notification-unread"
                            }`}
                          >
                            on{" "}
                            {dayjs(notification.createdAt).format(
                              "MMM D, YYYY h:mm A"
                            )}
                          </span>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="no-notifications">
                      No notifications available
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <div
              className="dropdown"
              data-testid="profile-ref"
              ref={dropdownRef}
            >
              <div>
                <span></span>
                <span></span>
              </div>
              <span className="cursor-point">
                {imageProfile}
                <ChevronDown
                  size={13}
                  className="dropbtn"
                  color="#fff"
                  data-testid="chevronDown-icon"
                  onClick={() => setCollapese(() => !collapse)}
                />
              </span>
              {
                <div className={`dropdown-content`}>
                  <ul className={`${!collapse ? "ul-hidden" : "ul-show"}`}>
                    <li className="disabled">
                      <span className="mx-2">{myProfile}</span>
                    </li>
                    {!isSSOLogin && (
                      <li
                        onClick={handleEditProfile}
                        data-testid="edit-profile"
                      >
                        <EditProfile />
                        <span className="mx-2">{editProfile}</span>
                      </li>
                    )}
                    {!isSSOLogin && (
                      <li
                        onClick={handleChangePassword}
                        data-testid="change-password"
                      >
                        <PasswordIcon />
                        <span className="mx-2">{changePassword}</span>
                      </li>
                    )}
                    {userInfo?.firmTypeId && userInfo?.firmTypeId === 1 && (
                      <li
                        data-testid="change-attorney"
                        onClick={handleChangeAttorney}
                      >
                        <ChangeAttorneyIcon />
                        <span className="mx-2">{changeAttorney}</span>
                      </li>
                    )}
                    <li data-testid="notification" onClick={handleNotification}>
                      <Notification />
                      <span className="mx-2">{notification}</span>
                    </li>
                    <li onClick={handleLogout} data-testid="logout">
                      <LogoutIcon />
                      <span className="mx-2">{logout}</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
      </Navbar>
      {(isLoading ||
        isLoadingDoc ||
        isLoadingGetDocDetails ||
        updateWillRetainLoading ||
        checkNotificationLoading) && <Loader />}
      {visible && (
        <ClientOffCanvasEditComponent
          visible={visible}
          onClose={handleClose}
          state={{
            page: "attorneyPage",
            id: userInfo?.userId,
          }}
          handleEditClient={() => setEditClient(true)}
          handleEditFamily={() => setEditFamily(true)}
          handleAddFamily={() => setAddFamily(true)}
        />
      )}

      {editClient && (
        <ClientOffCanvasEditProfile
          visible={editClient}
          onClose={() => setEditClient(false)}
        />
      )}
      {editFamily && (
        <FamilyOffCanvasEditProfile
          visible={editFamily}
          onClose={() => setEditFamily(false)}
        />
      )}
      {addFamily && (
        <FamilyOffCanvasAddProfile
          visible={addFamily}
          onClose={() => setAddFamily(false)}
        />
      )}
      <div id="client-notification-container">
        {showNotificationCanvas && (
          <ClientNotificationCanvas
            visible={showNotificationCanvas}
            onClose={handleNotificationClose}
            notification={notificationStatus}
            setUserNotification={handleNotificationStatus}
          />
        )}
      </div>
      <div id="client-change-attorney-container">
        {showChangeAttorney && (
          <ClientChangeAttorney
            visible={showChangeAttorney}
            onClose={handleChangeAttorneyClose}
            setSuccessModal={() => {
              setChangeAttorney(false);
              setSuccessModal(true);
            }}
          />
        )}
      </div>
      <ModalPopup
        show={showSuccessModal}
        setShow={() => setSuccessModal(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        modalClass="modal-container success-alert"
        titleClass="header-title"
        headerClass="no-border"
        modalContentElement={
          <SuccessModalContent text={firmAttorneylabel.changeRequest} />
        }
        hasFooter={false}
        footerClass="no-border pt-0"
        footerButtonText="Add"
        footerButtonClass="custom-button btn-standard btn-add-modal"
        onClickCloseNavigation={handlePrevPage}
        animation={false}
      />
      {(updateNotificationLoading ||
        isUpdateNotificationsLoading ||
        isDeleteNotificationsLoading ||
        checkGetAllBankDetailsLoading ||
        checkGetShareDataLoading) && <Loader />}
      <DocumentPreview
        showModal={showModal}
        setShowCallback={() => setShowModal(false)}
        fileContent={fileName}
        fileName={fileName}
        setSubmitCallback={() => setSubmitClicked(true)}
        setRadioCallback={(val) => setRadioValue(val)}
        selectedDropdown={isReadDoc ? "" : rootDocTypeId}
        selectedDocs={selectedDocs}
        docType={viewDocType}
        docId={docId}
        notificationMessage="Please review the file that your Professional has uploaded"
      />
      {Object.entries(progressMap).length > 0 && (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(([docId, { progress, docName }]) => (
            <div key={docId} className="progress-bar-document">
              <div className="d-flex flex-column">
                <div>{docName}</div>
                <div className="d-flex align-items-center">
                  <progress value={progress} max={100} />{" "}
                  <div className="ps-2">{progress}%</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

function VeteranUploadReplaceFile({
  showModal,
  setShowCallback,
  setVetYesCallBack,
  documentName,
}) {
  const handleClose = () => {
    setShowCallback(false);
  };

  const handleClickYes = () => {
    setVetYesCallBack();
  };
  return (
    <>
      <Modal
        id="modal-popup-container-search"
        className="register-modal register-modal-vet-login"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={() => {
          setShowCallback(false);
        }}
        data-testid="modal-popup-registered"
      >
        <Modal.Body>
          <>
            <div className="modal-content-container text-center pt-3">
              <div className="modal-content-register-fail mt-3 mb-2">
                <b>Replace previous {`${documentName}`}?</b>
              </div>
            </div>
            <h6 className="mb-2 mt-1 result-subtext-register-login">
              The previous {`${documentName}`} file will be replaced. Are you
              sure you want to proceed?
            </h6>
            <div className="modal-footer border-0 d-flex justify-content-center mb-3">
              <div className="modal-button-section d-flex justify-content-center">
                <Button
                  className="custom-button btn-standard btn-yes me-3"
                  type="button"
                  onClick={handleClickYes}
                >
                  Yes
                </Button>
                <Button
                  className="custom-button btn-standard btn-no"
                  type="button"
                  onClick={handleClose}
                >
                  No
                </Button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}

VeteranUploadReplaceFile.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
  setVetYesCallBack: PropTypes.func,
  documentName: PropTypes.string,
};

export default VeteranUploadReplaceFile;

import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Info } from "../../assets/images/Info-Personal-Use.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/SuccessModalIcon.svg";

function RegisterSuccessMessage({
  showModal,
  setShowCallback,
  modalContentElement,
  isApiSuccess,
  message,
}) {
  const navigate = useNavigate();
  const handleLogin = () => {
    setShowCallback(false);
    navigate("/Login");
  };

  const handleClose = () => {
    setShowCallback(false);
  };
  return (
    <>
      <Modal
        id="modal-popup-container-search"
        className="register-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={() => {
          setShowCallback(false);
        }}
        data-testid="modal-popup-registered"
      >
        {/* <Modal.Header className="modal-custom-header">
          <Modal.Title className="modal-custom-title">
            {headerTitle}
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {isApiSuccess ? (
            <>
              <div className="modal-content-container text-center pt-1">
                <div className="modal-content-register mt-4">
                  <SuccessIcon />
                </div>
              </div>
              <h6 className="mb-3 mt-3 result-subtext-register">
                Hello, Your account has been successfully created! We have sent
                your login credentials to your email address.
              </h6>

              <div className="modal-footer border-0 d-flex justify-content-center mb-3">
                <div>
                  <Button
                    className="custom-button btn-standard next-button"
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="modal-content-container text-center pt-3">
                <div className="modal-content-register-fail mt-3 mb-2">
                  <Info />
                </div>
              </div>
              <h6 className="mb-3 mt-1 result-subtext-register-fail">
                {message}
              </h6>
              <div className="modal-footer border-0 d-flex justify-content-center mb-2">
                <div>
                  <Button
                    className="custom-button btn-standard ok-button"
                    onClick={handleClose}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

RegisterSuccessMessage.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
  isApiSuccess: PropTypes.bool,
  message: PropTypes.string,
};

export default RegisterSuccessMessage;

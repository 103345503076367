// website URL
export const famVault_wesbite = "https://stage.famvault.com"
export const view_bank_details="/viewbankdetails";
export const request_bank_details  = "/requestbankdetails";
export const existing_request_bank_details = "/alreadyrequestedbankdetails";
export const bank_details_otp = "/bankdetailsotppage"
export const adminWillDownload =  "/admin/download"
//open routes
export const defaultRoute = "/";
export const sso_login = "/";
export const login = "/login";
export const signup = "/signup";
export const signupProfessional = "/signup/professional";
export const signupMember = "/signup/member";
export const otpverification = "/otpverification";
export const forgotPasswordEmailPage="/forgotpasswordemail";
export const forgotpasswordPage="/forgotpasswordpage";
export const forgotPasswordSuccessPage="/forgotpasswordsuccess";
export const resettemporarypassword = "/resettemporarypassword"
export const mvssuperadmin_landingpage = "/MVS_S_ADMIN/";
export const mvssuperadmin_firmViewDetails = "/MVS_S_ADMIN/firmviewdetails";
export const mvssuperadmin_firmEditDetails = "/MVS_S_ADMIN/firmeditdetails";
export const mvssuperadmin_firmOnboardingForm = "/MVS_S_ADMIN/onboardingform";
export const mvssuperadmin_firmreports = "/MVS_S_ADMIN/reports";

// admin
export const firmsuperadmin_LandingPage = "/FIRM_ADMIN";
export const firmsuperadmin_ReportsPage = "/FIRM_ADMIN/reports";
export const firmsuperadmin_BulkUpload = "/FIRM_ADMIN/bulkupload";
export const firmsuperadmin_AddEmployeePage="/FIRM_ADMIN/addemployee";
export const firmsuperadmin_ViewEmployeePage="/FIRM_ADMIN/viewemployee";
export const firmsuperadmin_EditEmployeePage="/FIRM_ADMIN/editemployee";
export const firmsuperadmin_Changepassword = "/FIRM_ADMIN/changepassword";
export const nafirmsuperadmin_Addclient="/FIRM_ADMIN/addclient";
export const nafirmsuperadmin_Editclient="/FIRM_ADMIN/editclient";
export const nafirmsuperadmin_Viewclient="/FIRM_ADMIN/viewclient";
export const nafirmsuperadmin_BulkUpload="/FIRM_ADMIN/bulkuploadclient";
export const firmsuperadmin_additional_prof_landing_page = "/FIRM_ADMIN/additionproflanding";
export const firmsuperadmin_additional_prof_upload_documents = "/FIRM_ADMIN/additionprofuploaddocuments";
export const firmsuperadmin_family_member_landing_page="/FIRM_ADMIN/reporterlanding";
export const firmsuperadmin_additional_prof_new_folder_upload_documents="/FIRM_ADMIN/addprofnewfolderuploaddocuments";

//ATT
export const attorney_LandingPage = "/ATT";
export const attorney_AddClient = "/ATT/addclient";
export const attorney_EditClient = "/ATT/editclient";
export const attorney_ViewClient = "/ATT/viewclient";
export const attorney_Changepassword = "/ATT/changepassword";
export const attoney_foldersPage="/ATT/folders";
export const attorney_uploadClientDocuments="/ATT/uploadclientdocuments";
export const attoeney_client_addPeople="/ATT/addPeople";
export const client_viewPeoplePage = "/ATT/viewinformation";
export const client_editPeoplePage = "/ATT/editinformation";
export const attorney_EditProfile = "/ATT/editprofile";
export const attorney_client_addSuccussor="/ATT/addsuccessor";
export const attorney_client_addExecutor ="/ATT/addExecutor";
export const attorney_client_addWitness ="/ATT/addWitness";
export const attorney_client_addFamily = "/ATT/addfamily";
export const attorney_client_viewFamily = "/ATT/viewfamily";
export const attorney_client_editFamily = "/ATT/editfamily";
export const attorney_additional_prof_landing_page = "/ATT/additionproflanding";
export const attorney_additional_prof_upload_documents = "/ATT/additionprofuploaddocuments";
export const attorney_family_member_landing_page="/ATT/reporterlanding";
export const attorney_bulk_upload="/ATT/bulkupload";
export const attorney_request_bank_details = "/ATT/requestbankdetails";
export const attorney_new_folder_upload_documents= "/ATT/newfolderuploaddocuments";
export const attonrey_additional_prof_new_folder_upload_documents="/ATT/addprofnewfolderuploaddocuments";
export const attorney_view_share_bank_details = "/ATT/viewsharedbankdetails";
export const attorney_ai_recommendations = "/ATT/folders/recommended_folders";

//client
export const client_LandingPage = "/CLI"
export const client_Changepassword = "/CLI/changepassword";
export const client_uploadDocuments="/CLI/uploaddocuments";
export const client_EditProfile = "/CLI/editprofile";
export const client_EditComponent = "/CLI/editcomponent";
export const client_editFamily = "/CLI/editfamily";
export const client_addFamily = "/CLI/addfamily"
export const client_addPeople="/CLI/addPeople";
export const client_addSuccussor="/CLI/addsuccessor";
export const viewPeoplePage="/CLI/viewinformation";
export const editPeoplePage="/CLI/editinformation";
export const client_addExecutor= "/CLI/addExecutor";
export const client_addWitness= "/CLI/addWitness";
export const client_share_bank_details = "/CLI/sharebankdetails";
export const client_upload_docs_external_user = "/CLI/uploaddocumentexternaluser";
export const client_family_member_landing_page="/CLI/reporterlanding";
export const client_ai_recommendations = "/CLI/recommended_folders";

export const client_receivefiles= "/CLI/receivefiles";
export const client_personaluse = "/CLI/personaluse"
export const client_additional_prof_landing_page = "/CLI/additionproflanding";
export const client_additional_prof_upload_documents = "/CLI/additionprofuploaddocuments";
export const client_new_folder_upload_documents= "/CLI/newfolderuploaddocuments";
export const client_additional_prof_new_folder_upload_documents="/CLI/addprofnewfolderuploaddocuments";
export const client_bulk_file_share =  "/CLI/bulkfiletransfer";
export const client_personal_bank_details="/CLI/personalbankdetails";
export const client_add_bank_details="/CLI/addbankdetails";
export const client_view_bank_details="/CLI/viewbankdetails";
export const client_edit_bank_details="/CLI/editbankdetails";
export const client_bankinformation ="/CLI/bankinformation";
export const client_request_bank_details = "/CLI/requestbankdetails";
export const client_request_bankList_details = "/CLI/requestbanklistdetails";

//veteran
export const veteran_LandingPage = "/IND_CLI_VET"
export const veteran_Changepassword = "/IND_CLI_VET/changepassword";
export const veteran_uploadDocuments="/IND_CLI_VET/uploaddocuments";
export const veteran_EditProfile = "/IND_CLI_VET/editprofile";
export const veteran_EditComponent = "/IND_CLI_VET/editcomponent";
export const veteran_editFamily = "/IND_CLI_VET/editfamily";
export const veteran_addFamily = "/IND_CLI_VET/addfamily"
export const veteran_addPeople="/IND_CLI_VET/addPeople";
export const veteran_addExecutor= "/IND_CLI_VET/addExecutor";
export const veteran_addWitness= "/IND_CLI_VET/addWitness";
export const veteran_addSuccussor="/IND_CLI_VET/addsuccessor";
export const veteran_viewPeoplePage = "/IND_CLI_VET/viewinformation";
export const veteran_editPeoplePage = "/IND_CLI_VET/editinformation";
export const veteran_additional_prof_landing_page = "/IND_CLI_VET/additionproflanding";
export const veteran_additional_prof_upload_documents = "/IND_CLI_VET/additionprofuploaddocuments";
export const veteran_new_folder_upload_documents= "/IND_CLI_VET/newfolderuploaddocuments";
export const veteran_additional_prof_new_folder_upload_documents="/IND_CLI_VET/addprofnewfolderuploaddocuments";

//executor
export const executor_LandingPage="/EXE";
export const executor_Changepassword = "/EXE/changepassword";
export const executor_clientdocumentsDetails="/EXE/clientdocumentdetails";
export const executor_clientProfile = "/EXE/clientprofile"
export const executor_viewPeoplePage="/EXE/executorviewinformation";
export const executor_EditProfile = "/EXE/editprofile";
export const executor_additional_prof_landing_page = "/EXE/additionproflanding";
export const executor_additional_prof_upload_documents = "/EXE/additionprofuploaddocuments";
export const executor_family_member_landing_page="/EXE/reporterlanding";
export const executor_additional_prof_new_folder_upload_documents="/EXE/addprofnewfolderuploaddocuments";
export const executor_bulk_file_share = "/EXE/bulkfileshare"

export const familymember_landingpage = "/REP"
export const familymember_additional_prof_landing_page = "/REP/additionproflanding";
export const familymember_additional_prof_upload_documents = "/REP/additionprofuploaddocuments";
export const familymember_additional_prof_new_folder_upload_documents="/REP/addprofnewfolderuploaddocuments";
//Medical Professional

export const medical_professional_details = "/medicalprofessional";
export const client_details = "/medicalprofessional/clientdetails"
export const medical_professional_landing_page = "/medicalprofessional/homepage"
export const medical_professional_family_member_landing_page="/medicalprofessional/reporterlanding";

export const external_user_share_landing_page = "/EXTER";
export const external_user_additional_prof_landing_page = "/EXTER/additionproflanding";
export const external_user_additional_prof_upload_documents = "/EXTER/additionprofuploaddocuments";
export const external_family_member_landing_page="/EXTER/reporterlanding";
export const external_additional_prof_new_folder_upload_documents="/EXTER/addprofnewfolderuploaddocuments";

export const additional_prof_landingpage = "/ADD_PROF";
export const additional_prof_uploadDocuments = "/ADD_PROF/additionprofuploaddocuments";
export const additional_profl_family_member_landing_page="/ADD_PROF/reporterlanding";
export const additional_prof_new_folder_upload_documents="/ADD_PROF/addprofnewfolderuploaddocuments";

export const addprof_other_role_landing="additionproflanding";
export const addprof_other_role_uploaddocs= "additionprofuploaddocuments";

//deathclaim
export const deathclaim_landingpage = "/deathclaim"
export const admin_verify = "/admin-verify";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import LoaderAnalyser from "../Loader/LoaderAnalyser";

function AnalysingFile({ show, modalSize, setShowAnalyse, text }) {
  return (
    <>
      <Modal centered size={modalSize} show={show}>
        <div className="mt-3 text-center">
          <div className="mt-5">{text}</div>
          <LoaderAnalyser style={{ position: "relative", top: "-27px" }} />
        </div>
      </Modal>
    </>
  );
}

export default AnalysingFile;

AnalysingFile.propTypes = {
  show: PropTypes.bool.isRequired,
  modalSize: PropTypes.string,
};

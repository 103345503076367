import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import UploadCertificateAi from "../bulkUpload/UploadCertificateAi";

function DragandDropFile({
  show,
  setShow,
  modalSize,
  role,
  type,
  onFileUploaded,
  onUploadSuccess,
  userId,
  clientId,
  onAiUploadCompleted,
}) {
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal centered size={modalSize} show={show} onHide={handleClose}>
        <UploadCertificateAi
          role={role}
          type={type}
          onFileUploaded={onFileUploaded}
          onUploadSuccess={onUploadSuccess}
          clientId = {clientId}
          userId = {userId}
          onAiUploadCompleted = {onAiUploadCompleted}
        />
      </Modal>
    </>
  );
}

export default DragandDropFile;

DragandDropFile.propTypes = {
  show: PropTypes.bool.isRequired,
  modalSize: PropTypes.string,
  type: PropTypes.string,
  onFileUploaded: PropTypes.func,
};

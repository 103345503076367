import React, { useState, useEffect, useCallback } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Stepper, Step, StepButton } from "@mui/material";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import { addPeopleTitle } from "../../constants/attorney/lables";
import {
  veteran_LandingPage,
  veteran_uploadDocuments,
  veteran_addPeople,
} from "../../constants/routes";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { useLazyGetStakeHolderDetailsQuery } from "../../slices/stackHolderApiManagmentSlice";
import {
  setStackHolderDetails,
  setButtonEvent,
  setExecutorlen,
  setWitnessorlen,
} from "../../slices/stackHolderSlice";
import WitnessInformation from "../../components/attorney/people/WitnessInformation";
import ExecutorInformation from "../../components/attorney/people/ExecutorInformation";
import SuccessorInformation from "../../components/attorney/people/SuccessorInformation";
import { ReactComponent as SkippedIcon } from "../../assets/images/skippedIcon.svg";
import { ReactComponent as LineIcon } from "../../assets/images/line.svg";
import { stepStyle, stepSkipStyle } from "../../constants/stepperStyles";
import { clientTitle } from "../../constants/client/labels";
export default function VeteranAddPeople() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: veteran_LandingPage,
      content: "--",
      active: false,
      state: { user: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: veteran_uploadDocuments,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-3",
      link: veteran_addPeople,
      content: "--",
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [pageLoader, setPageLoader] = useState(false);
  const [skipAcivity, setSkipActivity] = useState(new Set());
  const [skipped, setSkipped] = useState(false);
  const [getAPICall, setGetAPICall] = useState(false);
  const { stackHolders } = useSelector((state) => state.stackholder);
  const [trigger, { isLoading: getStackHolderLoading }] =
    useLazyGetStakeHolderDetailsQuery();
  const steps = [
    {
      component: (
        <ExecutorInformation
          onNext={() => nextStep()}
          getAPICall={getAPICall}
        />
      ),
      label: "Executor Information",
      title: "Add Executor Information",
    },
    {
      component: (
        <SuccessorInformation
          onPrevious={() => prevStep()}
          onNext={() => nextStep()}
          onSkip={() => skipStep()}
        />
      ),
      label: "Successor Information",
      title: "Add Successor Information",
    },
    {
      component: <WitnessInformation onPrevious={() => prevStep()} />,
      label: "Witness Information",
      title: "Add Witness Information",
    },
    // Add more steps as needed
  ];
  const getStackHolderDetails = useCallback(
    async (stackHoldersData) => {
      if (stackHoldersData && stackHoldersData !== null) {
        const successor =
          stackHoldersData.extracted_information.successor_information.length >
          0
            ? stackHoldersData.extracted_information.successor_information.filter(
                (e, i) => i < 1
              )[0]
            : {};
        dispatch(
          dispatch(
            setStackHolderDetails({
              ...stackHoldersData,
              executorList: [
                ...stackHoldersData.extracted_information.executor_details
                  .filter((e, i) => i < 2)
                  .map((e, i) => {
                    return {
                      firstName: e.firstName,
                      lastName: e.lastName,
                      email: e.emailId,
                      phoneNumber: e.phoneNumber,
                      dob: e.dateOfBirth,
                      drivingLicense: e?.drivingLicense
                        ? e?.drivingLicense
                        : "",
                      address: {
                        addressLine1: e?.address?.addressLine1,
                        addressLine2: e?.address?.addressLine2,
                        zip: e?.address?.zip,
                        city: e?.address?.city,
                        state: e?.address?.state,
                        coutry: e?.address?.country,
                      },
                    };
                  }),
              ],
              successor:
                Object.keys(successor).length > 0
                  ? {
                      firstName: successor?.firstName,
                      lastName: successor?.lastName,
                      email: successor?.emailId,
                      phoneNumber: successor?.phoneNumber,
                      dob: successor?.dateOfBirth,
                      drivingLicense: successor?.drivingLicense
                        ? successor?.drivingLicense
                        : "",
                      address: {
                        addressLine1: successor?.address?.addressLine1,
                        addressLine2: successor?.address?.addressLine2,
                        zip: successor?.address?.zip,
                        city: successor?.address?.city,
                        state: successor?.address?.state,
                        country: successor?.address?.country,
                      },
                    }
                  : {},
              witness: [
                ...stackHoldersData.extracted_information.witness_information
                  .filter((e, i) => i < 2)
                  .map((e, i) => {
                    return {
                      firstName: e.firstName,
                      lastName: e.lastName,
                      email: e.emailId,
                      phoneNumber: e.phoneNumber,
                      dob: e.dateOfBirth,
                      drivingLicense: e?.drivingLicense
                        ? e?.drivingLicense
                        : "",
                      address: {
                        addressLine1: e?.address?.addressLine1,
                        addressLine2: e?.address?.addressLine2,
                        zip: e?.address?.zip,
                        city: e?.address?.city,
                        state: e?.address?.state,
                        coutry: e?.address?.country,
                      },
                    };
                  }),
              ],
            })
          )
        );
      }
    },
    [dispatch]
  );
  const getStackHolderDetailsAPI = useCallback(async () => {
    setPageLoader(true);
    try {
      const result = await trigger(location?.state?.user?.clientId).unwrap();
      setActiveStep(0);
      setGetAPICall(true);
      dispatch(
        setStackHolderDetails({
          ...stackHolders,
          executorList: [...result?.executorList.filter((e, i) => i < 2)],
          witness: [...result?.witness.filter((e, i) => i < 2)],
          successor: { ...result?.successor },
          aiDataFlag: result?.aiDataFlag,
        })
      );
      dispatch(
        setExecutorlen(
          result?.executorList?.length > 2 ? 2 : result?.executorList?.length
        )
      );
      dispatch(
        setWitnessorlen(
          result?.witness?.length > 2 ? 2 : result?.witness?.length
        )
      );
      if (result && result?.stepsCompleted > 0) {
        let tempResult = result?.stepsCompleted;
        if (location?.state?.step && location?.state?.step === "addsuccesor") {
          tempResult = 1;
        }
        setActiveStep(tempResult);
        setTimeout(() => {
          setPageLoader(false);
        }, 5000);
        setGetAPICall(true);
        //(result);
      } else {
        setPageLoader(false);
        setGetAPICall(true);
        toast.error(result?.message);
      }
    } catch (error) {
      setPageLoader(false);
      setGetAPICall(true);
      toast.error(error);
    }
  }, [trigger, location, dispatch, stackHolders]);
  useEffect(() => {
    if (location?.state?.user) {
      setItems([
        {
          id: "item-1",
          link: veteran_LandingPage,
          content: clientTitle,
          active: false,
          state: { user: location?.state?.user },
        },
        {
          id: "item-2",
          link: veteran_uploadDocuments,
          content: location?.state?.doc?.rootDocName,
          active: false,
          state: { doc: location?.state?.doc, user: location?.state?.user },
        },
        {
          id: "item-3",
          link: veteran_addPeople,
          content: addPeopleTitle[activeStep],
          active: true,
          state: { doc: location?.state?.doc, user: location?.state?.user },
        },
      ]);
    }
  }, [
    activeStep,
    location?.state?.doc,
    location?.state?.user,
    stackHolders.familyMember,
  ]);

  useEffect(() => {
    if (location?.state?.user?.clientId) {
      const stackHoldersData = JSON.parse(
        sessionStorage.getItem("aistackholders")
      );
      if (stackHoldersData && stackHoldersData !== null) {
        getStackHolderDetails(stackHoldersData);
      } else {
        getStackHolderDetailsAPI();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.user?.clientId]);
  const prevStep = () => {
    if (activeStep === 0) return;
    dispatch(setButtonEvent(true));
    setActiveStep((prevState) => prevState - 1);
    setSkipActivity(new Set());
    setSkipped(false);
  };
  const nextStep = () => {
    if (activeStep === 2) return;
    dispatch(setButtonEvent(true));
    setActiveStep((prevState) => prevState + 1);
  };
  const skipStep = () => {
    if (activeStep < 2) setActiveStep((prevStep) => prevStep + 1);
    setSkipActivity((prevSkip) => {
      const newSkipSet = new Set(prevSkip.values());
      newSkipSet.add(activeStep);
      return newSkipSet;
    });
    setSkipped(true);
  };
  const handleStep = (i) => {
    setActiveStep(i);
  };
  return (
    <>
      <div
        className="attorney-client-title-page-container px-1  px-lg-4 px-xl-4"
        data-testid="attonery-client-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={addPeopleTitle[activeStep]}
              isAddPeople={true}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container add-people-container  px-1 px-lg-3 px-xl-3 px-md-4">
            <div className="stepper-container m-auto pt-3">
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={skipped ? stepSkipStyle : stepStyle}
              >
                {steps.map((step, index) => {
                  let stepProps = {};
                  let skipped = false;
                  if (skipAcivity.has(index)) {
                    stepProps.completed = false;
                    skipped = true;
                  }
                  return (
                    <Step key={index} {...stepProps}>
                      {skipped ? (
                        <StepButton
                          onClick={() => handleStep(index)}
                          icon={
                            <div className="skip-icon-container">
                              <span className="circle-icon">
                                <SkippedIcon />
                              </span>
                              <span className="line-icon">
                                <LineIcon height={10} />
                              </span>
                            </div>
                          }
                        >
                          {step.label}
                        </StepButton>
                      ) : (
                        <StepButton onClick={() => handleStep(index)}>
                          {step.label}
                        </StepButton>
                      )}
                    </Step>
                  );
                })}
              </Stepper>
            </div>
            <div className="add-people-form pt-3 px-0">
              {steps[activeStep]?.component}
            </div>
          </div>
        </Container>
      </div>
      {(getStackHolderLoading || pageLoader) && <Loader />}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Container, Alert, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import {
  attorney_LandingPage,
  attoney_foldersPage,
  attorney_uploadClientDocuments,
  attorney_new_folder_upload_documents,
  attorney_ai_recommendations,
} from "../../constants/routes";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
// import { ReactComponent as DotsBig } from "../../assets/images/DotsBig.svg";
// import { ReactComponent as DotsSmall } from "../../assets/images/DotsSmall.svg";
import { dateDMY } from "../../utils/dateFormat";
import { useGetfoldersByidQuery } from "../../slices/documentApiManagement";
import Loader from "../../components/Loader/Loader";
//import { toast } from "react-toastify";
import { useGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import { setStackHolderEmpty } from "../../slices/stackHolderSlice";
import { cliManagement } from "../../constants/attorney/lables";
import NewFolderModal from "../../components/modal/NewFolderModal";
import DragandDropFile from "../../components/modal/DragandDropFile";
import AnalysingFile from "../../components/modal/AnalysingFile";
import { ROLE } from "../../constants/role";
import { toast } from "react-toastify";
export default function AttorneyClientFolders() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNewFolderModal, setNewFolder] = useState(false);
  const userInfo = useSelector((state) => state.auth.userInfo);
  // console.log(userInfo);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showModalAnalyser, setShowModalAnalyser] = useState(false);
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: attorney_LandingPage,
      content: cliManagement,
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: attoney_foldersPage,
      content: "--",
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);
  const [foldersData, setFolderData] = useState([]);
  const {
    data,
    error: getApiError,
    isLoading: isGetLoading,
  } = useGetClientQuery(location?.state?.user?.clientId, {
    skip: location?.state?.user?.clientId === undefined,
  });
  //const [trigger, { isLoading, error }] = useLazyGetfoldersByidQuery();
  const {
    data: refetchData,
    error,
    isLoading,
    refetch,
  } = useGetfoldersByidQuery(data?.clientId, {
    skip: data?.clientId === undefined,
  });
  useEffect(() => {
    if (data?.clientId && refetchData && refetchData.length > 0) {
      setFolderData(refetchData);
    }
  }, [data?.clientId, refetchData]);
  useEffect(() => {
    if (location?.state?.refetch && data?.clientId) {
      refetch();
    }
    sessionStorage.removeItem("FromClientPage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const getDocumentTypes = useCallback(async () => {
  //   if (data?.clientId) {
  //     const result = await trigger(data?.clientId).unwrap();
  //     if (result && result.length > 0) {
  //       setFolderData(result);
  //     } else {
  //       toast.error(result?.message);
  //     }
  //   }
  // }, [data?.clientId, trigger]);

  // useEffect(() => {
  //   if (data?.fullName) {
  //     getDocumentTypes();
  //   }
  // }, [data?.fullName, getDocumentTypes]);

  useEffect(() => {
    if (foldersData && foldersData.length > 0) {
      setItems([
        {
          id: "item-1",
          link: attorney_LandingPage,
          content: cliManagement,
          active: false,
          state: { id: "", activeTab: "" },
        },
        {
          id: "item-2",
          link: attoney_foldersPage,
          content: data?.fullName,
          active: true,
          state: { id: "", activeTab: "" },
        },
      ]);
    }
  }, [data?.fullName, foldersData]);
  useEffect(() => {
    dispatch(setStackHolderEmpty());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const storeFileInSession = (file) => {     
    const reader = new FileReader();

      reader.onload = (e) => {
        const blob = new Blob([new Uint8Array(e.target.result)]);
        const blobUrl = URL.createObjectURL(blob);
        sessionStorage.setItem("uploadedFile", blobUrl);
      };

      reader.readAsArrayBuffer(file);    
    }

  const handleFileUploaded = async (file) => {
    setShowUploadModal((show) => !show);
    setShowModalAnalyser(true);
    storeFileInSession(file)
  };

  const handleUploadSuccess = (response) => {
    // console.log(response);
    setShowModalAnalyser(false);
    if (response.status === 200) {
      try {
        navigate(attorney_ai_recommendations, {
          state: {
            role: ROLE.attorney,
            response: response.data.aiRecommendationResponse,
            clientId: data?.clientId,
            userId: userInfo?.userId,
            fullName: data?.fullName,
          },
        });
        sessionStorage.setItem("FromClientPage", "Yes");
      } catch (err) {
        toast.error(
          err?.data?.message ||
            err?.errorMessage ||
            err?.data?.errorMessage ||
            err.error
        );
      }
    } else {
      toast.error(
        response?.data?.message ||
          response?.errorMessage ||
          response?.data?.errorMessage ||
          response.error
      );
    }
  };

  if (error || getApiError) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error.status}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      <div
        className="attorney-client-title-page-container px-lg-4 px-xl-4"
        data-testid="attonery-client-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-3 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={
                data?.fullName
                  ? `Document Details | ${data?.fullName}`
                  : "Document Details"
              }
              hasNewFolderBtn={true}
              createFolderCallback={() => setNewFolder(true)}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container attorney-folders ps-3 px-lg-4 px-md-4 px-xl-4">
            <div className="noof-document-uploaded pt-3">
              Total number of Documents uploaded:{" "}
              <span className="no-uploaded">
                {foldersData && foldersData.length > 0
                  ? foldersData[foldersData.length - 1].totalDocsByUserId
                  : 0}
              </span>
            </div>
            <div className="mt-3 folder-container-new d-flex flex-wrap justify-content-lg-start justify-content-md-center">
              <div className="d-none d-lg-block d-md-block">
                <div className="w-100 d-flex flex-lg-row flex-md-column align-items-lg-start align-items-md-center">
                  {foldersData && foldersData.length > 0 && (
                    <div key={`dt-0`}>
                      <LinkContainer
                        to={attorney_uploadClientDocuments}
                        state={{
                          doc: foldersData[0],
                          user: data,
                        }}
                      >
                        <div
                          className={`big-folder position-relative ${foldersData[0]?.folderColor}`}
                        >
                          <div className="three-dots position-absolute">
                            {/* <DotsBig /> */}
                          </div>
                          <div className="folder-name ps-3 pt-5">
                            {foldersData[0].rootDocName}
                          </div>
                          <div className="document-count text-center">
                            {foldersData[0].totalDocsByFolder}
                          </div>
                          <div className="edited-date ps-3 position-absolute">
                            {foldersData[0]?.lastModifiedAt !== null && (
                              <div>
                                Edited: {dateDMY(foldersData[0].lastModifiedAt)}
                              </div>
                            )}
                          </div>
                        </div>
                      </LinkContainer>
                    </div>
                  )}
                  <div className="other-common-folder-new ms-xl-5 ms-lg-5 d-flex flex-wrap align-items-lg-start align-items-md-center justify-content-lg-start justify-content-md-center mt-lg-0 mt-md-5">
                    {foldersData &&
                      foldersData.length > 0 &&
                      foldersData.map((folder, index) => {
                        if (index === 0) return null;
                        // let pdt =
                        //   folder.rootDocName.length > 20 ? "longstring" : "";
                        return (
                          <LinkContainer
                            to={
                              !folder?.isCustomFolder
                                ? attorney_uploadClientDocuments
                                : attorney_new_folder_upload_documents
                            }
                            key={`dt-${index}`}
                            state={{
                              doc: folder,
                              user: data,
                            }}
                          >
                            <div
                              className={`common-folders-new ${folder?.folderColor} me-4 mb-4 position-relative`}
                            >
                              <div className="three-dots position-absolute">
                                {/* <DotsSmall /> */}
                              </div>
                              <div className="folder-name ps-3">
                                {folder.rootDocName}
                              </div>
                              <div className="document-count text-center">
                                {folder.totalDocsByFolder}
                              </div>
                              <div className="edited-date ps-3 position-absolute">
                                {folder?.lastModifiedAt !== null && (
                                  <div>
                                    Edited: {dateDMY(folder.lastModifiedAt)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </LinkContainer>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none d-lg-none d-xl-none d-xxl-none w-100">
                <Row className="w-100">
                  <Col xs={12} sm={12}>
                    {foldersData && foldersData.length > 0 && (
                      <div key={`dt-0`}>
                        <LinkContainer
                          to={attorney_uploadClientDocuments}
                          state={{
                            doc: foldersData[0],
                            user: data,
                          }}
                        >
                          <div
                            className={`big-folder-small-devices ${foldersData[0]?.folderColor} position-relative`}
                          >
                            <div className="three-dots position-absolute">
                              {/* <DotsBig /> */}
                            </div>
                            <div className="folder-name ps-3 pt-5">
                              {foldersData[0].rootDocName}
                            </div>
                            <div className="document-count text-center">
                              {foldersData[0].totalDocsByFolder}
                            </div>
                            <div className="edited-date ps-3 position-absolute">
                              {foldersData[0]?.lastModifiedAt !== null && (
                                <div>
                                  Edited:{" "}
                                  {dateDMY(foldersData[0].lastModifiedAt)}
                                </div>
                              )}
                            </div>
                          </div>
                        </LinkContainer>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="w-100 mt-3">
                  <Row className="w-100 other-small-folders">
                    {foldersData &&
                      foldersData.length > 0 &&
                      foldersData.map((folder, index) => {
                        if (index === 0) return null;
                        // let pdt =
                        //   folder.rootDocName.length > 20 ? "longstring" : "";
                        return (
                          <LinkContainer
                            to={
                              !folder?.isCustomFolder
                                ? attorney_uploadClientDocuments
                                : attorney_new_folder_upload_documents
                            }
                            key={`dt-${index}`}
                            state={{
                              doc: folder,
                              user: data,
                            }}
                          >
                            <Col xs={6} sm={6} className="mb-3">
                              <div
                                className={`common-folders-new-small-devices ${folder?.folderColor} position-relative`}
                              >
                                <div className="three-dots position-absolute">
                                  {/* <DotsSmall /> */}
                                </div>
                                <div className="folder-name ps-3">
                                  {folder.rootDocName}
                                </div>

                                <div className="edited-date ps-3 position-absolute">
                                  <div className="document-count">
                                    {folder.totalDocsByFolder} Files
                                  </div>
                                  {folder?.lastModifiedAt !== null && (
                                    <div>
                                      Edited: {dateDMY(folder.lastModifiedAt)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </LinkContainer>
                        );
                      })}
                  </Row>
                </div>
              </div>
            </div>
            <div className="py-2">
              <p>
                Unable to find the right folder or document type? Try using our
                <span>
                  <button
                    className="button-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowUploadModal(true);
                    }}
                    data-testid="load-more-link"
                  >
                    {" "}
                    Suggested Upload{" "}
                  </button>
                </span>
                process.
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className="background-mvs-image type-1"></div>
      {(isLoading || isGetLoading) && <Loader data-testid="loader" />}
      <NewFolderModal
        showModal={showNewFolderModal}
        setShowCallback={(callbackData) => {
          setNewFolder(false);
          refetch();
        }}
        folderData={null}
        clientId={data?.clientId}
      />
      <DragandDropFile
        show={showUploadModal}
        setShow={() => setShowUploadModal(false)}
        modalSize="md"
        type="ai-doc-upload"
        onFileUploaded={(file) => {handleFileUploaded(file)}}
        onAiUploadCompleted={handleUploadSuccess}
        role="attorney"
        clientId={data?.clientId}
        userId={userInfo?.userId}
      />
      <AnalysingFile
        show={showModalAnalyser}
        size="md"
        setShowAnalyse={() => setShowModalAnalyser(false)}
        text="Analyzing the file, please wait!"
      />
    </>
  );
}

import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function AiDocUploadConfirmationModal({
  showModal,
  setShowCallback,
  setSuccessCallback,
  docName,
}) {
  const handleClose = () => {
    setShowCallback(false);
  };

  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="ai-doc-modal"
        data-testid="upload-confirmation-modal"
      >
        <Modal.Header
          className="share-doc-modal-header receive-file-header"
          closeButton
        ></Modal.Header>
        <Modal.Body className="share-doc-modal-body">
          <div className="d-flex justify-content-center align-items-center">
            <div className="px-2 pb-3 text-center">
              <div>
                {`Please confirm this document should be stored in your '${docName}' folder.`}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="share-doc-modal-footer justify-content-center pt-0 pb-5">
          <Button
            style={{ width: "auto" }}
            className="custom-button btn-standard doc-share-submit-btn"
            type="button"
            onClick={setSuccessCallback}
            data-testid="ai-doc-submit-btn"
          >
            Confirm
          </Button>
          <Button
            style={{ width: "auto" }}
            className="custom-button doc-share-submit-btn-nobg ms-3"
            type="button"
            onClick={handleClose}
            data-testid="ai-doc-no-btn"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

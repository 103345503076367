import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";

function ShortCertificateModal({
  show,
  modalSize,
  setShowAISuccess,
  message,
  Icon,
  btnText,
  showDragandDrop,
}) {
  const handleClose = () => {
    setShowAISuccess(false);
  };

  const handleReupload = () => {
    setShowAISuccess(false);
    showDragandDrop(true);
  };
  return (
    <>
      <Modal
        centered
        size={modalSize}
        show={show}
        id="Short-certificate-modal"
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header
          className="modal-custom-header"
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className="modal-content-container text-center p-lg-5 p-md-5 p-sm-5 p-2">
            <div>{Icon}</div>
            <div className="pt-4 font-16">{message}</div>
            {btnText === "Re-Upload" ? (
              <Button
                onClick={handleReupload}
                className="custom-button btn-standard mt-4 mb-4"
              >
                Re-Upload
              </Button>
            ) : (
              <Button
                onClick={handleClose}
                className="custom-button btn-standard mt-4 mb-4"
              >
                Ok
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShortCertificateModal;

ShortCertificateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  modalSize: PropTypes.string,
  setShowAISuccess: PropTypes.func,
  message: PropTypes.string,
  Icon: PropTypes.element,
  btnText: PropTypes.string,
};

import "./styles/global.scss";
import { Route, Routes } from "react-router-dom";
import "react-bootstrap";
import PrivateRoute from "./components/routes/PrivateRoute";
import {
  login,
  otpverification,
  resettemporarypassword,
  forgotpasswordPage,
  forgotPasswordEmailPage,
  forgotPasswordSuccessPage,
  defaultRoute,
  deathclaim_landingpage,
  admin_verify,
  bank_details_otp,
  signup,
  adminWillDownload,
  // signupProfessional,
  // signupMember,
} from "./constants/routes";
import { ROLE } from "./constants/role";
import FirmLandingPage from "./pages/mvssuperadmin/FirmLandingPage";
import FirmOnboardingForm from "./pages/mvssuperadmin/FirmOnboardingForm";
import FirmDetailsViewPage from "./pages/mvssuperadmin/FirmDetailsViewPage";
import FirmDetailsEditPage from "./pages/mvssuperadmin/FirmDetailsEditPage";
import FirmReportsPage from "./pages/mvssuperadmin/FirmReportsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SSOLogin from "./pages/SSOLogin";
import Login from "./pages/Login";
import SuperAdminRenderPage from "./pages/SuperAdminRenderPage";
import OtpVerification from "./pages/OtpVerification";
import FirmAdminReportsPage from "./pages/firmadmin/FirmAdminReportsPage";
import NotFound from "./components/NotFound/NotFound";
import FirmAdminBulkUpload from "./pages/firmadmin/FirmAdminBulkUpload";
import HeaderProfile from "./components/headers/HeaderProfile";
import HeaderProfileFirmAdmin from "./components/headers/HeaderProfileFirmAdmin";
import FirmAdminAddEmployeePage from "./pages/firmadmin/FirmAdminAddEmployeePage";
import FirmAdminViewEmployeePage from "./pages/firmadmin/FirmAdminViewEmployeePage";
import FirmAdminEditEmployeePage from "./pages/firmadmin/FirmAdminEditEmployeePage";
import ChangePassword from "./pages/ChangePassword";
import HeaderProfileAttorney from "./components/headers/HeaderProfileAttorney";
import AttorneyLandingPage from "./pages/attorney/AttorneyLandingPage";
import AttorneyAddClientPage from "./pages/attorney/AttorneyAddClientPage";
import AttorneyViewClientPage from "./pages/attorney/AttorneyViewClientPage";
import AttorneyEditCLientPage from "./pages/attorney/AttorneyEditClientPage";
import AttorneyClientFolders from "./pages/attorney/AttorneyClientFolders";
import ResetTemporaryPassword from "./pages/ResetTemporaryPassword";
import AttorneyUploadClientDocuments from "./pages/attorney/AttorneyUploadClientDocuments";
import AttorneyClientAddPeople from "./pages/attorney/AttorneyClientAddPeople";
import ViewPeopleDetailsPage from "./pages/attorney/ViewPeopleDetailsPage";
import EditPeopleDetailsPage from "./pages/attorney/EditPeopleDetailsPage";
import AttorneyEditProfile from "./pages/attorney/AttorneyEditProfile";
import AttorneyClientAddSuccessorPage from "./pages/attorney/AttorneyClientAddSuccessorPage";
import HeaderProfileClient from "./components/headers/HeaderProfileClient";
import ClientLandingPage from "./pages/client/ClientLandingPage";
import ForgotPasswordEmail from "./pages/ForgotPasswordEmail";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordSuccessPage from "./pages/ForgotPasswordSuccessPage";
import UploadDocuments from "./pages/client/UploadDocuments";
import IdleTimerComponent from "./components/idleTimer/IdleTimerComponent";
import ClientEditProfile from "./pages/client/ClientEditProfile";
import NAFirmAdminAddClientPage from "./pages/nonAttorneyFirmSuperAdmin/NAFirmAdminAddClientPage";
import NAFirmAdminEditClientPage from "./pages/nonAttorneyFirmSuperAdmin/NAFirmAdminEditClientPage";
import NAFirmAdminViewClientPage from "./pages/nonAttorneyFirmSuperAdmin/NAFirmAdminViewClientPage";
import ClientAddPeople from "./pages/client/ClientAddPeople";
import ClientAddSuccessor from "./pages/client/ClientAddSuccessorPage";
import ClientViewPeopleDetailsPage from "./pages/client/ClientViewPeopleDetailsPage";
import NAFirmAdminBulkUpload from "./pages/nonAttorneyFirmSuperAdmin/NAFirmAdminBulkUpload";
import ClientEditPeopleDetailsPage from "./pages/client/ClientEditPeopleDetailsPage";
import ExecutorLandingPage from "./pages/executor/ExecutorLandingPage";
import ClientDocumentDetails from "./pages/executor/ClientDocumentDetails";
import ExecutorViewPeopleDetails from "./pages/executor/ExecutorViewPeopleDetails";
import HeaderProfileExecutor from "./components/headers/HeaderProfileExecutor";
import FamilyMemberLandingPage from "./pages/familymember/FamilyMemberLandingPage";
import HeaderProfileFamilyMember from "./components/headers/HeaderProfileFamilyMember";
import ClientAddExecutorpage from "./pages/client/ClientAddExecutorpage";
import AttorneyClientAddExecutor from "./pages/attorney/AttorneyClientAddExecutor";
import ClientAddWitnessPage from "./pages/client/ClientAddWitnessPage";
import AttorneyClientAddWitness from "./pages/attorney/AttorneyClientAddWitness";
import ClientEditComponent from "./pages/client/ClientEditComponent";
import FamilyInformation from "./components/attorney/people/FamilyInformation";
import EditFamilyDetails from "./components/attorney/editPeopleInformation/EditFamilyDetails";
import AttorneyClientAddFamily from "./pages/attorney/AttorneyClientAddFamily";
import AttorneyViewFamilyMemberPage from "./pages/attorney/AttorneyViewFamilyMemberPage";
import AttorneyEditFamilyMemberPage from "./pages/attorney/AttorneyEditFamilyMemberPage";
import AdditionalProfLandingPage from "./pages/additionalprofessional/AdditionalProfLandingPage";
import MedicalProfessionalDetails from "./pages/medicalprofessional/MedicalProfessionalDetails";
import ClientDetails from "./pages/medicalprofessional/ClientDetails";
import MedicalProfessionalLandingPage from "./pages/medicalprofessional/MedicalProfessionalLandingPage";
import ClientReceiveFiles from "./pages/client/ClientReceiveFiles";
import ClientShareBankDetails from "./pages/client/ClientShareBankDetails";
import ClientBankDetailsUpload from "./pages/client/ClientBankDetailsUpload";
import { setDetails } from "./slices/shareReceiveBankDetailsSlice";
import { useDispatch } from "react-redux";
import HeaderProfileExternalUser from "./components/headers/HeaderProfileExternalUser";
import ExternalUserLandingPage from "./pages/externalUser/ExternalUserLandingPage";
import ClientPersonalUse from "./pages/client/ClientPersonalUse";
import AdditionalProfUploadDocuments from "./pages/additionalprofessional/AdditionalProfUploadDocuments";
import NoClaim from "./pages/deathclaim/noclaim";
import Header from "./components/headers/Header";
import DeathClaimPage from "./pages/deathclaim/DeathClaimPage";
import AttorneyBulkUpload from "./pages/attorney/AttorneyBulkUpload";
import ShareOtpVerification from "./pages/ShareOtpVerification";
import NewFolderUploadDocuments from "./pages/client/NewFolderUploadDocuments";
import ClientNewFolderUploadDocuments from "./pages/attorney/ClientNewFolderUploadDocuments";
import AdditionalProfNewFolderUploadDocuments from "./pages/additionalprofessional/AdditionalProfNewFolderUploadDocuments";
import ClientBulkFileTransfer from "./pages/client/ClientBulkFileTransfer";
import PersonalBankDetails from "./pages/client/PersonalBankDetails";
import AddBankDetails from "./components/WireTransfer/AddBankDetails";
import ViewBankDetails from "./components/WireTransfer/ViewBankDetails";
import ClientBankInformation from "./pages/client/ClientBankInformation";
import ClientRequestBankDetails from "./pages/client/ClientRequestBankDetails";
import ClientRequestBankDetailsTablePage from "./pages/client/ClientRequestBankDetailsTablePage";
import AiRecommendations from "./pages/AiRecommendations";
import ClientTwoLevelEmailScenarioPage from "./pages/client/ClientTwoLevelEmailScenarioPage";
import AdminVerifyPage from "./pages/deathclaim/AdminVerifyPage";
import BankDetailsOtpVerification from "./pages/BankDetailsOtpVerification";
// import Signup from "./pages/Signup";
import AdminLinkPage from "./pages/ai/AdminLinkPage";
// import SignupProfessional from "./pages/SignupProfessional";
import SignupMember from "./pages/SignupMember";
import VeteranLandingPage from "./pages/veteran/VeteranLandingPage";
import HeaderProfileVeteran from "./components/headers/HeaderProfileVeteran";
import VeteranUploadDocuments from "./pages/veteran/VeteranUploadDocuments";
import VeteranAddPeople from "./pages/veteran/VeteranAddPeople";
import VeteranAddExecutorpage from "./pages/veteran/VeteranAddExecutorpage";
import VeteranAddWitnessPage from "./pages/veteran/VeteranAddWitnessPage";
import VeteranAddSuccessorPage from "./pages/veteran/VeteranAddSuccessorPage";
import VeteranViewPeopleDetailsPage from "./pages/veteran/VeteranViewPeopleDetailsPage";
import VeteranEditPeopleDetailsPage from "./pages/veteran/VeteranEditPeopleDetailsPage";
import VeteranEditComponent from "./pages/veteran/VeteranEditComponent";
import VeteranEditProfile from "./pages/veteran/VeteranEditProfile";
import VeteranEditFamilyDetails from "./components/veteran/VeteranEditFamilyDetails";
import VeteranFamilyInformation from "./components/veteran/VeteranFamilyInformation";

function App() {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const isreporterpage = window.location.pathname.includes("reporterlanding");
  const isaddprofpage = window.location.pathname.includes(
    "additionproflanding"
  );
  const identifierID = queryParameters.get("identifier");
  const requestType = queryParameters.get("requestType");
  const urlType = queryParameters.get("type");
  const transactionId = queryParameters.get("transactionId");
  const isAdminLink = window.location.pathname.includes("admin/download");
  if (isAdminLink && identifierID) {
    dispatch(setDetails({ identifierID, requestType: "Admin" }));
  }
  if (
    identifierID &&
    urlType &&
    transactionId &&
    (urlType === "SEND" || urlType === "RECEIVE")
  ) {
    dispatch(setDetails({ identifierID, requestType: urlType, transactionId }));
    sessionStorage.setItem("fromWireTransfer", "Yes");
  }
  if (identifierID && requestType) {
    dispatch(setDetails({ identifierID, requestType }));
  }
  if (
    identifierID &&
    urlType &&
    (urlType === "addProf" ||
      urlType === "deathClaim" ||
      urlType === "SHARE" ||
      urlType === "BULK_SHARE")
  ) {
    dispatch(setDetails({ identifierID, requestType: urlType }));
  }

  const checkNonsso = identifierID || isreporterpage || isaddprofpage;
  const checkSSO =
    JSON.parse(sessionStorage.getItem("ssoIdentifier"))?.identifierID &&
    JSON.parse(sessionStorage.getItem("ssoIdentifier"))?.requestType;
  const checkShare =
    identifierID &&
    urlType &&
    (urlType === "SHARE" || urlType === "BULK_SHARE");
  const checkBank =
    transactionId &&
    identifierID &&
    urlType &&
    (urlType === "SEND" || urlType === "RECEIVE");
  return (
    <>
      <IdleTimerComponent />

      <Routes>
        {/* <Route
          path={defaultRoute}
          element={
            <>
              <HeaderProfileDeathClaim />
              <DealthClaimLandingPage />
            </>
          }
        /> */}
        {/* Public Routes*/}
        <Route path={adminWillDownload} element={<AdminLinkPage />} />
        <Route
          path={deathclaim_landingpage}
          element={
            <>
              <Header />
              <DeathClaimPage />
            </>
          }
        />
        <Route
          path={admin_verify}
          element={
            <>
              <Header />
              <AdminVerifyPage />
            </>
          }
        />
        <Route path="/viewbankdetails" element={<ViewBankDetails />} />
        <Route
          path="/requestbankdetails"
          element={<ClientTwoLevelEmailScenarioPage />}
        />
        <Route
          path="/alreadyrequestedbankdetails"
          element={<AddBankDetails />}
        />
        <Route
          path="/medicalprofessional"
          element={
            <>
              <Header />
              <MedicalProfessionalDetails />
            </>
          }
        />
        <Route
          path="/medicalprofessional/clientdetails"
          element={
            <>
              <Header />
              <ClientDetails />
            </>
          }
        />
        <Route
          path="/medicalprofessional/homepage"
          element={
            <>
              <Header />
              <MedicalProfessionalLandingPage />
            </>
          }
        />
        <Route
          path="/no-claim"
          element={
            <>
              <Header />
              <NoClaim />
            </>
          }
        />
        <Route
          path={defaultRoute}
          element={
            checkShare ? (
              <ShareOtpVerification />
            ) : checkBank ? (
              <BankDetailsOtpVerification />
            ) : (
              <SSOLogin />
            )
          }
        />

        <Route path={signup} element={<SignupMember />} />
        {/* <Route path={signupProfessional} element={<SignupProfessional />} />
        <Route path={signupMember} element={<SignupMember />} /> */}
        <Route
          path={bank_details_otp}
          element={<BankDetailsOtpVerification />}
        />
        <Route path={login} element={<Login />} />
        <Route
          path={forgotPasswordEmailPage}
          element={<ForgotPasswordEmail />}
        />
        <Route path={forgotpasswordPage} element={<ForgotPassword />} />
        <Route
          path={forgotPasswordSuccessPage}
          element={<ForgotPasswordSuccessPage />}
        />
        {/* Private Route for otp*/}

        <Route
          path={otpverification}
          element={
            <PrivateRoute
              roles={[
                ROLE.firmsuperadmin,
                ROLE.attorney,
                ROLE.client,
                ROLE.familymember,
                ROLE.executor,
                ROLE.externaluser,
                ROLE.additionalProf,
                ROLE.individialProf,
                ROLE.individualClient,
              ]}
            >
              <OtpVerification />
            </PrivateRoute>
          }
        />

        <Route
          path={resettemporarypassword}
          element={
            <PrivateRoute
              roles={[
                ROLE.firmsuperadmin,
                ROLE.attorney,
                ROLE.client,
                ROLE.familymember,
                ROLE.executor,
                ROLE.externaluser,
                ROLE.additionalProf,
                ROLE.individialProf,
                ROLE.individualClient,
              ]}
            >
              <ResetTemporaryPassword />
            </PrivateRoute>
          }
        />
        {/* Private Route for mvs super admin*/}
        <Route
          path={`/${ROLE.mvssuperadmin}/*`}
          element={
            <PrivateRoute roles={[ROLE.mvssuperadmin]}>
              <HeaderProfile />
              <Routes>
                <Route index={true} path="/" element={<FirmLandingPage />} />
                <Route path="onboardingform" element={<FirmOnboardingForm />} />
                <Route
                  path="firmviewdetails"
                  element={<FirmDetailsViewPage />}
                />
                <Route
                  path="firmeditdetails"
                  element={<FirmDetailsEditPage />}
                />
                <Route path="reports" element={<FirmReportsPage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </PrivateRoute>
          }
        />

        {/* Private Route for firm super admin */}
        <Route
          path={`/${ROLE.firmsuperadmin}/*`}
          element={
            <PrivateRoute roles={[ROLE.firmsuperadmin]}>
              {checkNonsso || checkSSO ? (
                <HeaderProfileExternalUser />
              ) : (
                <HeaderProfileFirmAdmin />
              )}
              <Routes>
                <Route
                  index={true}
                  path="/"
                  element={<SuperAdminRenderPage />}
                />
                <Route path="reports" element={<FirmAdminReportsPage />} />
                <Route
                  path="addemployee"
                  element={<FirmAdminAddEmployeePage />}
                />
                <Route
                  path="editEmployee"
                  element={<FirmAdminEditEmployeePage />}
                />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route
                  path="viewemployee"
                  element={<FirmAdminViewEmployeePage />}
                />
                <Route path="bulkupload" element={<FirmAdminBulkUpload />} />
                <Route
                  path="addclient"
                  element={<NAFirmAdminAddClientPage />}
                />
                <Route
                  path="viewclient"
                  element={<NAFirmAdminViewClientPage />}
                />
                <Route
                  path="editclient"
                  element={<NAFirmAdminEditClientPage />}
                />
                <Route
                  path="bulkuploadclient"
                  element={<NAFirmAdminBulkUpload />}
                />
                <Route
                  path="additionproflanding"
                  element={<AdditionalProfLandingPage />}
                />
                <Route
                  path="additionprofuploaddocuments"
                  element={<AdditionalProfUploadDocuments />}
                />
                <Route
                  path="reporterlanding"
                  element={<FamilyMemberLandingPage />}
                />
                <Route
                  path="addprofnewfolderuploaddocuments"
                  element={<AdditionalProfNewFolderUploadDocuments />}
                />
                <Route
                  path="personalbankdetails"
                  element={<PersonalBankDetails />}
                />
                <Route path="addbankdetails" element={<AddBankDetails />} />
                <Route path="viewbankdetails" element={<ViewBankDetails />} />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </PrivateRoute>
          }
        />

        {/* Private Route for attorney */}
        <Route
          path={`/${ROLE.attorney}/*`}
          element={
            <PrivateRoute roles={[ROLE.attorney]}>
              {checkNonsso || checkSSO ? (
                <HeaderProfileExternalUser />
              ) : (
                <HeaderProfileAttorney />
              )}
              <Routes>
                <Route
                  index={true}
                  path="/"
                  element={<AttorneyLandingPage />}
                />
                <Route path="addclient" element={<AttorneyAddClientPage />} />
                <Route path="folders" element={<AttorneyClientFolders />} />
                <Route path="viewclient" element={<AttorneyViewClientPage />} />
                <Route
                  path="uploadclientdocuments"
                  element={<AttorneyUploadClientDocuments />}
                />
                <Route path="editclient" element={<AttorneyEditCLientPage />} />
                <Route
                  path="viewinformation"
                  element={<ViewPeopleDetailsPage />}
                />
                <Route
                  path="editinformation"
                  element={<EditPeopleDetailsPage />}
                />
                <Route path="addPeople" element={<AttorneyClientAddPeople />} />
                <Route
                  path="addsuccessor"
                  element={<AttorneyClientAddSuccessorPage />}
                />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route path="editprofile" element={<AttorneyEditProfile />} />
                <Route
                  path="addExecutor"
                  element={<AttorneyClientAddExecutor />}
                />
                <Route
                  path="addWitness"
                  element={<AttorneyClientAddWitness />}
                />
                <Route path="addfamily" element={<AttorneyClientAddFamily />} />
                <Route
                  path="viewfamily"
                  element={<AttorneyViewFamilyMemberPage />}
                />
                <Route
                  path="editfamily"
                  element={<AttorneyEditFamilyMemberPage />}
                />
                <Route
                  path="additionproflanding"
                  element={<AdditionalProfLandingPage />}
                />
                <Route
                  path="additionprofuploaddocuments"
                  element={<AdditionalProfUploadDocuments />}
                />
                <Route
                  path="reporterlanding"
                  element={<FamilyMemberLandingPage />}
                />
                <Route path="bulkupload" element={<AttorneyBulkUpload />} />
                <Route
                  path="newfolderuploaddocuments"
                  element={<ClientNewFolderUploadDocuments />}
                />
                <Route
                  path="addprofnewfolderuploaddocuments"
                  element={<AdditionalProfNewFolderUploadDocuments />}
                />
                <Route
                  path="viewsharedbankdetails"
                  element={<ViewBankDetails />}
                />
                <Route path="requestbankdetails" element={<AddBankDetails />} />
                <Route
                  path="folders/recommended_folders"
                  element={<AiRecommendations />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </PrivateRoute>
          }
        />
        {/* Private Route for Client */}
        <Route
          path={`/${ROLE.client}/*`}
          element={
            <PrivateRoute roles={[ROLE.client]}>
              {checkNonsso || checkSSO ? (
                <HeaderProfileExternalUser />
              ) : (
                <HeaderProfileClient />
              )}
              {/* <HeaderProfileExecutor /> */}
              <Routes>
                <Route index={true} path="/" element={<ClientLandingPage />} />
                <Route
                  index={true}
                  path="uploaddocuments"
                  element={<UploadDocuments />}
                />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route path="editcomponent" element={<ClientEditComponent />} />
                <Route path="editprofile" element={<ClientEditProfile />} />
                <Route path="addfamily" element={<FamilyInformation />} />
                <Route path="editfamily" element={<EditFamilyDetails />} />
                <Route path="addPeople" element={<ClientAddPeople />} />
                <Route path="addsuccessor" element={<ClientAddSuccessor />} />
                <Route
                  path="viewinformation"
                  element={<ClientViewPeopleDetailsPage />}
                />
                <Route
                  path="editinformation"
                  element={<ClientEditPeopleDetailsPage />}
                />
                <Route path="addExecutor" element={<ClientAddExecutorpage />} />
                <Route path="addWitness" element={<ClientAddWitnessPage />} />
                <Route path="receivefiles" element={<ClientReceiveFiles />} />
                <Route
                  path="sharebankdetails"
                  element={<ClientShareBankDetails />}
                />
                <Route
                  path="bankinformation"
                  element={<ClientBankInformation />}
                />
                <Route
                  index={true}
                  path="uploaddocumentexternaluser"
                  element={<ClientBankDetailsUpload />}
                />
                <Route path="personaluse" element={<ClientPersonalUse />} />
                <Route
                  path="bulkfiletransfer"
                  element={<ClientBulkFileTransfer />}
                />
                <Route
                  path="additionproflanding"
                  element={<AdditionalProfLandingPage />}
                />
                <Route
                  path="additionprofuploaddocuments"
                  element={<AdditionalProfUploadDocuments />}
                />
                <Route
                  path="reporterlanding"
                  element={<FamilyMemberLandingPage />}
                />
                <Route
                  path="newfolderuploaddocuments"
                  element={<NewFolderUploadDocuments />}
                />
                <Route
                  path="addprofnewfolderuploaddocuments"
                  element={<AdditionalProfNewFolderUploadDocuments />}
                />
                <Route
                  path="personalbankdetails"
                  element={<PersonalBankDetails />}
                />
                <Route path="addbankdetails" element={<AddBankDetails />} />
                <Route path="viewbankdetails" element={<ViewBankDetails />} />
                <Route
                  path="requestbankdetails"
                  element={<ClientRequestBankDetails />}
                />
                <Route
                  path="requestbanklistdetails"
                  element={<ClientRequestBankDetailsTablePage />}
                />
                <Route
                  path="recommended_folders"
                  element={<AiRecommendations />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </PrivateRoute>
          }
        />

        {/* Private Route for Veteran */}
        <Route
          path={`/${ROLE.individualClient}_VET/*`}
          element={
            <PrivateRoute roles={[ROLE.individualClient]}>
              {checkNonsso || checkSSO ? (
                <HeaderProfileExternalUser />
              ) : (
                <HeaderProfileVeteran />
              )}
              <Routes>
                <Route index={true} path="/" element={<VeteranLandingPage />} />
                <Route
                  index={true}
                  path="uploaddocuments"
                  element={<VeteranUploadDocuments />}
                />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route
                  path="editcomponent"
                  element={<VeteranEditComponent />}
                />
                <Route path="editprofile" element={<VeteranEditProfile />} />
                <Route path="addPeople" element={<VeteranAddPeople />} />

                <Route
                  path="addfamily"
                  element={<VeteranFamilyInformation />}
                />
                <Route
                  path="editfamily"
                  element={<VeteranEditFamilyDetails />}
                />

                <Route
                  path="viewinformation"
                  element={<VeteranViewPeopleDetailsPage />}
                />
                <Route
                  path="editinformation"
                  element={<VeteranEditPeopleDetailsPage />}
                />
                <Route
                  path="addExecutor"
                  element={<VeteranAddExecutorpage />}
                />
                <Route path="addWitness" element={<VeteranAddWitnessPage />} />
                <Route
                  path="addsuccessor"
                  element={<VeteranAddSuccessorPage />}
                />

                <Route
                  path="additionproflanding"
                  element={<AdditionalProfLandingPage />}
                />
                <Route
                  path="additionprofuploaddocuments"
                  element={<AdditionalProfUploadDocuments />}
                />

                <Route
                  path="addprofnewfolderuploaddocuments"
                  element={<AdditionalProfNewFolderUploadDocuments />}
                />

                <Route
                  path="reporterlanding"
                  element={<FamilyMemberLandingPage />}
                />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </PrivateRoute>
          }
        />

        <Route
          path={`/${ROLE.externaluser}/*`}
          element={
            <PrivateRoute roles={[ROLE.externaluser]}>
              <HeaderProfileExternalUser />
              <Routes>
                <Route
                  index={true}
                  path="wire-transfer"
                  element={<ExternalUserLandingPage />}
                />
                <Route path="personaluse" element={<ClientPersonalUse />} />
                <Route
                  path="additionproflanding"
                  element={<AdditionalProfLandingPage />}
                />
                <Route
                  path="additionprofuploaddocuments"
                  element={<AdditionalProfUploadDocuments />}
                />
                <Route
                  path="reporterlanding"
                  element={<FamilyMemberLandingPage />}
                />
                <Route
                  path="addprofnewfolderuploaddocuments"
                  element={<AdditionalProfNewFolderUploadDocuments />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </PrivateRoute>
          }
        />
        <Route
          path={`/${ROLE.familymember}/*`}
          element={
            <PrivateRoute roles={[ROLE.familymember]}>
              {checkNonsso || checkSSO ? (
                <HeaderProfileExternalUser />
              ) : (
                <HeaderProfileFamilyMember />
              )}
              <Routes>
                <Route
                  path="additionproflanding"
                  element={<AdditionalProfLandingPage />}
                />
                <Route
                  path="additionprofuploaddocuments"
                  element={<AdditionalProfUploadDocuments />}
                />
                <Route
                  path="reporterlanding"
                  element={<FamilyMemberLandingPage />}
                />
                <Route
                  path="addprofnewfolderuploaddocuments"
                  element={<AdditionalProfNewFolderUploadDocuments />}
                />
                <Route path="/" element={<FamilyMemberLandingPage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </PrivateRoute>
          }
        />
        {/* Private Route for Executor */}
        <Route
          path={`/${ROLE.executor}/*`}
          element={
            <PrivateRoute roles={[ROLE.executor]}>
              {checkNonsso || checkSSO ? (
                <HeaderProfileExternalUser />
              ) : (
                <HeaderProfileExecutor />
              )}
              <Routes>
                <Route
                  index={true}
                  path="/"
                  element={<ExecutorLandingPage />}
                />
                <Route
                  path="clientdocumentdetails"
                  element={<ClientDocumentDetails />}
                />
                <Route
                  path="bulkfileshare"
                  element={<ClientBulkFileTransfer />}
                />
                <Route
                  path="executorviewinformation"
                  element={<ExecutorViewPeopleDetails />}
                />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route path="clientprofile" element={<ClientEditComponent />} />
                <Route
                  path="additionproflanding"
                  element={<AdditionalProfLandingPage />}
                />
                <Route
                  path="additionprofuploaddocuments"
                  element={<AdditionalProfUploadDocuments />}
                />
                <Route
                  path="reporterlanding"
                  element={<FamilyMemberLandingPage />}
                />
                <Route
                  path="addprofnewfolderuploaddocuments"
                  element={<AdditionalProfNewFolderUploadDocuments />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </PrivateRoute>
          }
        />
        <Route
          path={`/${ROLE.additionalProf}/*`}
          element={
            <PrivateRoute roles={[ROLE.additionalProf]}>
              <HeaderProfileExternalUser />
              <Routes>
                <Route
                  index={true}
                  path="/"
                  element={<AdditionalProfLandingPage />}
                />
                <Route
                  index={true}
                  path="additionprofuploaddocuments"
                  element={<AdditionalProfUploadDocuments />}
                />
                <Route
                  path="reporterlanding"
                  element={<FamilyMemberLandingPage />}
                />
                <Route
                  path="addprofnewfolderuploaddocuments"
                  element={<AdditionalProfNewFolderUploadDocuments />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-center"
        toastClassName="custom-toast"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;

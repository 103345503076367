import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Row, Col, Alert, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Loader from "../Loader/Loader";
import {
  additional_prof_landingpage,
  client_additional_prof_landing_page,
  firmsuperadmin_additional_prof_landing_page,
  attorney_additional_prof_landing_page,
  executor_additional_prof_landing_page,
  familymember_additional_prof_landing_page,
  external_user_additional_prof_landing_page,
  veteran_additional_prof_landing_page,
} from "../../constants/routes";
import {
  defaultDocType,
  videosigning,
  wills,
} from "../../constants/documentFileType";
import {
  useLazyGetAdditionalProfDocTypesQuery,
  useGetAdditionalProfAllRootDocsByIdentifierQuery,
  additionalProfApiManagementSlice,
} from "../../slices/additionalProfApiManagementSlice";
import { useUploadotherdocumentMutation } from "../../slices/documentApiManagement";
import EmployeeTitleBar from "../firmadmin/EmployeeTitleBar";
//import ModalPopup from "../modal/ModalPopup";
//import SuccessModalContent from "../widgets/SuccessModalContent";
import AdditionalProfDocumentDetails from "../Documents/AdditionalProfDocumentDetails";
export default function UploadDocuments() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedDropdown, setDropdown] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [acceptedFiles, setAcceptedFiles] = useState({});
  const [maxUploadFiles, setMaxUploadFiles] = useState("");
  const [isMultiple, setMultiple] = useState(true);
  //const [showSuccessModal, setSuccessModal] = useState(false);
  const [progressMap, setProgressMap] = useState({});
  const [uploadLoading, setLoading] = useState(false);

  const { userInfo } = useSelector((state) => state.auth);
  const [
    trigger,
    { isLoading: documentTypeLoading, error: documentTypeError },
  ] = useLazyGetAdditionalProfDocTypesQuery();
  const { data: allDocumentsData, isLoading: getAllDocsLoading } =
    useGetAdditionalProfAllRootDocsByIdentifierQuery(
      {
        identifierId: location?.state?.doc?.identifier,
        rootDocId: location?.state?.doc?.rootDocId,
      },
      {
        skip:
          location?.state?.doc?.identifier === undefined &&
          location?.state?.doc?.rootDocId === undefined,
        refetchOnMountOrArgChange: true,
      }
    );
  const [uploadotherdocument, { isLoading: uploadOthersLoading }] =
    useUploadotherdocumentMutation();
  const onDropAccepted = async (files) => {
    const data = {
      rootDocId: location?.state?.doc?.rootDocId,
      docTypeId: selectedDropdown,
      clientId: userInfo?.userId,
      userId: userInfo?.userId,
      identifier: location?.state?.doc?.identifier,
    };
    const fileData = new FormData();
    for (const file of files) {
      fileData.append("documents", file);
    }
    uploaddocumentotherFiles({ fileData, data });
  };
  const uploaddocumentotherFiles = useCallback(
    async ({ fileData, data }) => {
      const fileNames = fileData.getAll("documents").map((file) => file.name);
      try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/professional/?rootDocId=${data.rootDocId}&docTypeId=${data.docTypeId}&clientId=${data.clientId}&userId=${data.userId}&identifier=${data.identifier}`;
        const token = userInfo?.accessToken;
        const response = await axios({
          url: baseUrl,
          method: "POST",
          responseType: "json",
          headers: { Authorization: "Bearer " + token },
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.total;
            if (totalLength !== null) {
              setLoading(true);
              const progress = Math.round(
                (progressEvent.loaded * 100) / totalLength
              );
              // Update progress for this specific file
              setProgressMap((prevProgressMap) => ({
                ...prevProgressMap,
                [data.docTypeId]: { progress, fileNames },
              }));
            }
          },
          data: fileData,
        });

        if (response.status === 200) {
          setLoading(false);
          dispatch(
            additionalProfApiManagementSlice.util.invalidateTags([
              "getAdditionalProfAllDocsByIdentifier",
            ])
          );
          toast.success(response?.data?.message);
          setTimeout(() => {
            setProgressMap((prevProgressMap) => {
              const updatedProgressMap = { ...prevProgressMap };
              delete updatedProgressMap[data.docTypeId];
              return updatedProgressMap;
            });
          }, 2000);
        }
      } catch (err) {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || `File uploading failed. Try again!`
        );
        setProgressMap((prevProgressMap) => {
          const updatedProgressMap = { ...prevProgressMap };
          delete updatedProgressMap[data.docTypeId];
          return updatedProgressMap;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadotherdocument]
  );
  const onDropRejected = (params) => {
    if (params[0]?.errors[0]?.code === "file-invalid-type") {
      toast.error(
        "This format is not valid! Please upload PDF and DOCX format only"
      );
    }

    if (params[0]?.errors[0]?.code === "too-many-files") {
      toast.error("Maximum 10 files are allowed");
    }
  };
  const onFileDialogOpen = () => {
    //setFileContent(null);
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: acceptedFiles,
    noDrag: true,
    noClick: true,
    maxFiles: maxUploadFiles,
    noKeyboard: true,
    multiple: isMultiple,
    onDropAccepted,
    onDropRejected,
    onFileDialogOpen,
  });
  const getDocumentTypes = useCallback(async () => {
    if (location?.state?.doc?.rootDocId && location?.state?.doc?.identifier) {
      const result = await trigger({
        identifierId: location?.state?.doc?.identifier,
        rootDocId: location?.state?.doc?.rootDocId,
      }).unwrap();
      if (result && result.length > 0) {
        setDocumentTypes(result);
      } else {
        toast.error(result?.message);
      }
    }
  }, [location?.state, trigger]);
  useEffect(() => {
    if (selectedDropdown === "DT_001") {
      setAcceptedFiles(wills);
      setMultiple(false);
      setMaxUploadFiles(1);
    } else if (selectedDropdown === "DT_002") {
      setAcceptedFiles(videosigning);
      setMultiple(false);
      setMaxUploadFiles(1);
    } else {
      setAcceptedFiles(defaultDocType);
      setMultiple(true);
      setMaxUploadFiles(10);
    }
  }, [selectedDropdown]);
  useEffect(() => {
    if (location?.state?.doc?.rootDocId && location?.state?.doc?.identifier) {
      getDocumentTypes();
      // setUserDetails(location?.state?.user);
    }
  }, [location?.state, getDocumentTypes]);
  const navigateToBack = () => {
    let pathName = additional_prof_landingpage;
    switch (userInfo?.role) {
      case "FIRM_ADMIN":
        pathName = firmsuperadmin_additional_prof_landing_page;
        break;
      case "ATT":
        pathName = attorney_additional_prof_landing_page;
        break;
      case "CLI":
        pathName = client_additional_prof_landing_page;
        break;
      case "IND_CLI":
        if (userInfo?.veteran) {
          pathName = veteran_additional_prof_landing_page;
        }
        break;
      case "EXE":
        pathName = executor_additional_prof_landing_page;
        break;
      case "REP":
        pathName = familymember_additional_prof_landing_page;
        break;
      case "EXTER":
        pathName = external_user_additional_prof_landing_page;
        break;
      default:
        pathName = additional_prof_landingpage;
        break;
    }
    navigate(
      `${pathName}?identifier=${location?.state?.doc?.identifier}&requestType=${location?.state?.doc?.requestType}`
    );
  };
  const handleUploadDocumentSelect = (selValue) => {
    setDropdown(selValue);
  };
  if (documentTypeError) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status -{" "}
          {documentTypeError?.status}. {documentTypeError?.data}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      <div
        className="attorney-client-title-page-container  px-lg-4 px-xl-4"
        data-testid="attonery-client-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={false}
              hasButton={false}
              title={`${location?.state?.doc?.rootDocName}`}
              hasBackButton={true}
              navigateToPrev={navigateToBack}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container upload-client-container px-1 px-lg-4 px-xl-4">
            <div className="document-type-container px-2 px-lg-4 px-md-4 px-xl-1 pb-2">
              <Form className="custom-form employee-form type-2 pt-3">
                <Row className="">
                  <Col lg={8} md={8} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="select-documentType"
                        className="required mb-0"
                      >
                        Select document type
                      </Form.Label>
                      <Form.Select
                        name="documentType"
                        className="form-input input-border-radius-unset select-placeholder"
                        data-testid="select-documentType"
                        id="select-documentType"
                        onChange={(e) => {
                          handleUploadDocumentSelect(e.target.value);
                        }}
                      >
                        <option value="" hidden></option>
                        {documentTypes &&
                          documentTypes.length > 0 &&
                          documentTypes.map((group, index) => (
                            <option
                              value={group.rootDocTypeId}
                              key={"docType" + index}
                            >
                              {group.rootDocTypeName}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={12} className="browse-upload-button">
                    <div {...getRootProps()} data-testid="upload-file">
                      <input {...getInputProps()} data-testid="dropzone" />
                      <Button
                        className={`custom-button btn-standard custom-file-upload ${
                          selectedDropdown === "" && "btn-disable"
                        } 
                        mt-0 mt-lg-4 mt-md-4`}
                        type="button"
                        onClick={open}
                      >
                        Browse & Upload
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="document-list-container px-2 px-lg-4 px-md-4 px-xl-1 pb-2">
              <AdditionalProfDocumentDetails
                allDocumentsData={allDocumentsData}
                //userDetails={userDetails}
                locationState={{
                  doc: location?.state?.doc,
                }}
              />
            </div>
          </div>
        </Container>
      </div>
      {(documentTypeLoading ||
        getAllDocsLoading ||
        uploadOthersLoading ||
        uploadLoading) && <Loader data-testid="loader" />}
      {Object.entries(progressMap).length > 0 && (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(
            ([docId, { progress, fileNames }]) => (
              <div key={docId} className="progress-bar-document">
                <div className="d-flex flex-column">
                  <div>
                    <div>
                      <b>
                        {fileNames.length === 1
                          ? `${fileNames.length} document is uploading`
                          : `${fileNames.length} documents are uploading`}
                      </b>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <progress value={progress} max={100} />{" "}
                    <div className="ps-2">{progress}%</div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { addPeopleCompleteMessage } from "../../constants/client/labels";
import ModalPopupType7 from "../modal/ModalPopupType7";
function AddPeopleBreadCrumb({ items }) {
  const [showConfirmModal, setConfirmModal] = useState(false);
  const onClickHandler = (item) => {
    setConfirmModal(true);
  };
  return (
    <>
      <nav className="custom-breadcrumb">
        <ol className="breadcrumb">
          {items.map((item, index) => (
            <span onClick={() => onClickHandler(item)} key={item.id}>
              <li
                className={`breadcrumb-item ${
                  index === 0 ? "custom-breadcrumb-item" : "span-item"
                } ${item.active ? "active" : ""}`}
                key={item.id}
              >
                {item.content}
              </li>
            </span>
          ))}
        </ol>
      </nav>
      <ModalPopupType7
        setShowCallback={() => setConfirmModal(false)}
        content={addPeopleCompleteMessage}
        showModal={showConfirmModal}
        headerTitle=""
      />
    </>
  );
}
AddPeopleBreadCrumb.propTypes = {
  items: PropTypes.array.isRequired,
};
export default AddPeopleBreadCrumb;

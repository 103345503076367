import React from "react";
import Loader from "../../components/Loader/Loader";
import ClientOffCanvasViewProfile from "../../components/editProfileOffCanvas/ClientOffCanvasViewProfile";
import { useSelector } from "react-redux";
import { useGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import { Alert, CloseButton, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { client_Changepassword, client_EditProfile, client_LandingPage, client_addFamily, client_addPeople, client_addSuccussor, client_editFamily, client_uploadDocuments, editPeoplePage, executor_Changepassword, executor_LandingPage, executor_clientdocumentsDetails, executor_viewPeoplePage, viewPeoplePage } from "../../constants/routes";
import { ROLE } from "../../constants/role";

export default function ClientEditComponent() {
  const { userInfo } = useSelector((state) => state.auth);
  const id = userInfo?.role === ROLE.executor ? userInfo.clientId : userInfo.userId
  const { data, error, isLoading } = useGetClientQuery(id, {
    skip: userInfo?.userId === undefined,
  });

  console.log(data)
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab =  location?.state && location?.state?.activeTab ? location?.state?.activeTab : "profile"
  const identifierID = useSelector(
    (state) => state?.shareReceiveBankDetails?.identifierID && state.shareReceiveBankDetails.identifierID
  )
  const urlType = useSelector(
    (state) => state?.shareReceiveBankDetails?.requestType && state.shareReceiveBankDetails.requestType
  )
  const handleEditFamily = () => {
    navigate(client_editFamily, {
        state: {
          page: "clientPage",
          id: userInfo?.userId,
          pathname: location?.pathname,
          state: location?.state,
          header: "My Information | Edit Emergency Contact Profile"
        },
      });
}
  const handleEditClient = () => {
    navigate(client_EditProfile, {
        state: {
          page: "clientPage",
          id: userInfo?.userId,
          pathname: location?.pathname,
          state: location?.state,
          header: "My Information | Edit My Profile"
        },
      });
}
  const handleAddFamily = () => {
    navigate(client_addFamily, {
        state: {
          page: "clientPage",
          id: userInfo?.userId,
          pathname: location?.pathname,
          state: location?.state,
          header: "My Information | Add Emergency Contact Profile"
        },
      });
}

  const handleViewPageNavigation = () => {
    if (location?.state?.pathname === client_LandingPage) {
      navigate(client_LandingPage, {
        state: {
          page: "editProfile",
          id: location?.state?.id,
        },
      });
    } else if (location?.state?.pathname === client_uploadDocuments) {
      navigate(client_uploadDocuments, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === viewPeoplePage) {
      navigate(viewPeoplePage, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === editPeoplePage) {
      navigate(editPeoplePage, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === client_addPeople) {
      navigate(client_addPeople, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === client_addSuccussor) {
      navigate(client_addSuccussor, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === client_Changepassword) {
      navigate(client_Changepassword, {
        state: {
          page: "editProfile",
        },
      });
    }
    else if (location?.state?.pathname === executor_LandingPage) {
        navigate(executor_LandingPage, {
          state: {
            page: "editProfile",
            id: location?.state?.id,
          },
        });
      
      } else if (location?.state?.pathname === executor_clientdocumentsDetails) {
        navigate(executor_clientdocumentsDetails, {
          state: location?.state?.state,
        });
      } 
      else if (location?.state?.pathname === executor_viewPeoplePage) {
        navigate(executor_viewPeoplePage, {
          state: location?.state?.state,
        });
      }
      else if (location?.state?.pathname === executor_Changepassword) {
          navigate(executor_Changepassword, {
            state: {
              page: "editProfile",
            },
          });
        }
        else if (location?.state?.pathname === "/CLI/reporterlanding") {
          navigate(`/CLI/reporterlanding?identifier=${identifierID}&type=${urlType}`, {
            state: {
              page: "editProfile",
            },
          });
        }
          else if (location?.state?.pathname === "/EXE/reporterlanding") {
            navigate(`/EXE/reporterlanding?identifier=${identifierID}&type=${urlType}`, {
              state: {
                page: "editProfile",
              },
            });
          }
    else{
      if(identifierID && (urlType === 'deathClaim')){
        navigate(`/CLI/reporterlanding?identifier=${identifierID}&type=${urlType}`, {
          state: {
            page: "editProfile",
          },
        });
      }
      else{
        navigate(client_LandingPage, {
          state: {
            page: "editProfile",
            id: location?.state?.id,
          },
        });
      }
    }
  };
  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <div data-testid="client-edit-profile" >
            <CloseButton
              className="close-button-clientedit position-absolute"
              variant="black"
              onClick= {handleViewPageNavigation}
            />
            <div className="death-claim-title-page-container">
                <h3 className="my-2 ms-3"><b>My Information </b></h3>
                <div className="px-3 mb-2">
                    <div className="divider-firm"></div>
                </div>
            </div>
            <ClientOffCanvasViewProfile data={data} handleEditClient={handleEditClient} handleEditFamily={handleEditFamily} handleAddFamily={handleAddFamily} activeKey = {activeTab}/>
      </div>
      {(isLoading) && <Loader />}
    </>
  );
}

export const ROLE = {
    mvssuperadmin: "MVS_S_ADMIN",
    firmsuperadmin: "FIRM_ADMIN",
    attorney: "ATT",
    client: "CLI",
    executor: "EXE",
    familymember: "REP",
    externaluser: "EXTER",
    additionalProf:"ADD_PROF",
    individualClient: "IND_CLI",
    individialProf: "IND_ATT"
}
import { ThreeDots } from "react-loader-spinner";

const LoaderAnalyser = ({style}) => {
  return (
    <div>
      <ThreeDots
        visible={true}
        width="100"
        height="100"
        ariaLabel="three-dots-loading"
        radius="10"
        wrapperClass="d-flex justify-content-center mt-3"
        wrapperStyle={style}
        color="#188711"
        data-testid = "loading-progress"
      />
    </div>
  );
};

export default LoaderAnalyser;

import React, { useCallback, useEffect, useState } from "react";
import { Container, Alert, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { ReactComponent as InfoIcon } from "../../assets/images/VectorInfo-blue.svg";
import {
  additionalProfTitle,
  additionalProfAccessMsg,
} from "../../constants/labels";
//import { ReactComponent as UploadIconSmall } from "../../assets/images/UploadIconSmall.svg";
import EmployeeTitleBar from "../firmadmin/EmployeeTitleBar";
import {
  additional_prof_uploadDocuments,
  client_additional_prof_upload_documents,
  firmsuperadmin_additional_prof_upload_documents,
  attorney_additional_prof_upload_documents,
  executor_additional_prof_upload_documents,
  familymember_additional_prof_upload_documents,
  external_user_additional_prof_upload_documents,
  additional_prof_new_folder_upload_documents,
  firmsuperadmin_additional_prof_new_folder_upload_documents,
  attonrey_additional_prof_new_folder_upload_documents,
  client_additional_prof_new_folder_upload_documents,
  executor_additional_prof_new_folder_upload_documents,
  familymember_additional_prof_new_folder_upload_documents,
  external_additional_prof_new_folder_upload_documents,
  veteran_additional_prof_upload_documents,
  veteran_additional_prof_new_folder_upload_documents,
} from "../../constants/routes";
import { useLazyGetAdditionalProfRootDocsQuery } from "../../slices/additionalProfApiManagementSlice";
export default function LandingPage() {
  const [foldersData, setFolderData] = useState([]);
  const [idExpired, setIdExpired] = useState(null);
  const [uploadDocPath, setUploadDocPath] = useState(
    additional_prof_uploadDocuments
  );
  const [uploadcustomFolderDocPath, setUploadCustomFolderDocPath] = useState(
    additional_prof_new_folder_upload_documents
  );
  const { userInfo } = useSelector((state) => state.auth);
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );
  const requestType = useSelector(
    (state) => state.shareReceiveBankDetails.requestType
  );
  const ssoResponse =
    sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
  const isSSOLogin = ssoResponse
    ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
    : false;
  let identifierVal = null;
  let requestTypeVal = null;
  if (isSSOLogin) {
    if (
      sessionStorage.ssoIdentifier &&
      JSON.parse(sessionStorage.ssoIdentifier) &&
      JSON.parse(sessionStorage.ssoIdentifier)?.identifierID &&
      JSON.parse(sessionStorage.ssoIdentifier)?.requestType
    ) {
      identifierVal = JSON.parse(sessionStorage.ssoIdentifier)?.identifierID;
      requestTypeVal = JSON.parse(sessionStorage.ssoIdentifier)?.requestType;
    }
  } else {
    identifierVal = identifierID;
    requestTypeVal = requestType;
  }
  const [trigger, { isLoading, error }] =
    useLazyGetAdditionalProfRootDocsQuery();
  const getDocumentTypes = useCallback(async () => {
    if (identifierVal && identifierVal !== null) {
      try {
        const result = await trigger(identifierVal).unwrap();
        if (result && result.length > 0) {
          setFolderData(result);
          setIdExpired(null);
        } else {
          setIdExpired(null);
          toast.error(result?.message);
        }
      } catch (e) {
        if (e?.status && e?.status === 500) {
          if (
            e?.data &&
            e?.data?.errorCode &&
            e?.data?.errorCode === "ADDITIONAL_PROFESSIONAL_ISSUE-001"
          ) {
            setIdExpired(e?.data?.errorMessage);
          }
        }
      }
    }
  }, [trigger, identifierVal]);
  useEffect(() => {
    if (userInfo?.role) {
      let pathName = additional_prof_uploadDocuments;
      let customerfolderPath = additional_prof_new_folder_upload_documents;
      switch (userInfo?.role) {
        case "FIRM_ADMIN":
          pathName = firmsuperadmin_additional_prof_upload_documents;
          customerfolderPath =
            firmsuperadmin_additional_prof_new_folder_upload_documents;
          break;
        case "ATT":
          pathName = attorney_additional_prof_upload_documents;
          customerfolderPath =
            attonrey_additional_prof_new_folder_upload_documents;
          break;
        case "CLI":
          pathName = client_additional_prof_upload_documents;
          customerfolderPath =
            client_additional_prof_new_folder_upload_documents;
          break;
        case "IND_CLI":
          if (userInfo?.veteran) {
            pathName = veteran_additional_prof_upload_documents;
            customerfolderPath =
              veteran_additional_prof_new_folder_upload_documents;
          }
          break;
        case "EXE":
          pathName = executor_additional_prof_upload_documents;
          customerfolderPath =
            executor_additional_prof_new_folder_upload_documents;
          break;
        case "REP":
          pathName = familymember_additional_prof_upload_documents;
          customerfolderPath =
            familymember_additional_prof_new_folder_upload_documents;
          break;
        case "EXTER":
          pathName = external_user_additional_prof_upload_documents;
          customerfolderPath =
            external_additional_prof_new_folder_upload_documents;
          break;
        default:
          pathName = additional_prof_uploadDocuments;
          customerfolderPath = additional_prof_new_folder_upload_documents;
          break;
      }
      setUploadDocPath(pathName);
      setUploadCustomFolderDocPath(customerfolderPath);
    }
  }, [userInfo?.role, userInfo?.veteran]);
  useEffect(() => {
    if (identifierVal && identifierVal !== null) {
      getDocumentTypes();
    }
  }, [getDocumentTypes, identifierVal]);

  if (error && idExpired === null) {
    return (
      <div className="attorney-client-title-page-container px-lg-4 px-xl-4">
        <Container data-testid="error-check-view" className="pt-5">
          <Alert variant={"danger"}>
            Something Happened in the Server and Server status -{" "}
            {error?.status && error?.status}
          </Alert>
        </Container>
      </div>
    );
  }
  return (
    <>
      <div
        className="additional-professional-title-page-container px-lg-4 px-xl-4"
        data-testid="additional-professional-title"
      >
        <Container fluid>
          <div className="additional-professional-title px-3 pt-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={false}
              hasButton={false}
              title={additionalProfTitle}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          {(!identifierVal || idExpired !== null) && (
            <div className="firm-page-container px-3 px-lg-4 px-md-4 px-xl-4 py-4">
              <div className="deathclaim-message-container px-lg-2 px-md-2 px-sm-2 py-4">
                <span className="info-blue mx-3">
                  <InfoIcon />
                </span>
                <div
                  className="question-container"
                  data-testid="deathclaim-view-page"
                >
                  {idExpired !== null ? idExpired : additionalProfAccessMsg}
                </div>
              </div>
            </div>
          )}

          {identifierVal && identifierVal !== null && (
            <div className="firm-page-container attorney-folders mb-ps pt-1">
              <div className="mt-5 folder-container-new d-flex flex-wrap justify-content-lg-start justify-content-md-center">
                <div className="d-none d-lg-block d-md-block">
                  <div className="other-common-folder-new ms-xl-4 ms-lg-4 d-flex flex-wrap align-items-lg-start align-items-md-center justify-content-lg-start justify-content-md-center mt-lg-0 mt-md-5">
                    {foldersData &&
                      foldersData.length > 0 &&
                      foldersData.map((folder, index) => {
                        return (
                          <LinkContainer
                            to={{
                              pathname: folder?.isCustomFolder
                                ? uploadcustomFolderDocPath
                                : uploadDocPath,
                              search: `identifier=${identifierVal}&requestType=${requestTypeVal}`,
                            }}
                            key={`dt-${index}`}
                            state={{
                              doc: {
                                ...folder,
                                identifier: identifierVal,
                                requestType: requestTypeVal,
                              },
                            }}
                          >
                            <div
                              className={`common-folders-new ${folder.folderColor} me-4 mb-4 position-relative pt-4`}
                            >
                              <div className="folder-name-additional ps-3 pt-2">
                                {folder.rootDocName}
                              </div>
                              <div className="document-count text-center">
                                {folder.totalDocsByFolder}
                              </div>
                            </div>
                          </LinkContainer>
                        );
                      })}
                  </div>
                </div>
                <div className="d-block d-md-none d-lg-none d-xl-none d-xxl-none w-100">
                  <div className="w-100 mt-3">
                    <Row className="w-100 other-small-folders">
                      {foldersData &&
                        foldersData.length > 0 &&
                        foldersData.map((folder, index) => {
                          return (
                            <LinkContainer
                              to={{
                                pathname: folder?.isCustomFolder
                                  ? uploadcustomFolderDocPath
                                  : uploadDocPath,
                                search: `identifier=${identifierVal}&requestType=${requestTypeVal}`,
                              }}
                              key={`dt-${index}`}
                              state={{
                                doc: {
                                  ...folder,
                                  identifier: identifierVal,
                                  requestType: requestTypeVal,
                                },
                                // user: data,
                              }}
                            >
                              <Col xs={6} sm={6} className="mb-3">
                                <div
                                  className={`common-folders-new-small-devices ${folder.folderColor} position-relative pt-4`}
                                >
                                  <div className="folder-name px-3 pt-2">
                                    {folder.rootDocName}
                                  </div>

                                  <div className="edited-date ps-3 position-absolute">
                                    <div className="document-count">
                                      {folder.totalDocsByFolder} Files
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </LinkContainer>
                          );
                        })}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Container>
      </div>
      {isLoading && <Loader data-testid="loader" />}
    </>
  );
}

import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTitle from "../components/form/FormTitle";
import { emailRules } from "../constants/regExp";
import {
  splogintitle,
  sploginEmailLabel,
  sploginButton,
  sploginEmailReqValidation,
  sploginEmailValidation,
} from "../constants/labels";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/headers/Header";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader/Loader";
import { ReactComponent as WebLogo } from "../assets/images/MVS-logo-web-svg.svg";
import {
  useLazySsousersigninQuery,
  useLazySsoauthorizeQuery,
} from "../slices/ssoManagementSlice";
import { useLazySigninwithssoQuery } from "../slices/loginAuthManagementSlice";
import { ROLE } from "../constants/role";
import { setCredentials } from "../slices/authSlice";
import {
  firmsuperadmin_LandingPage,
  attorney_LandingPage,
  mvssuperadmin_landingpage,
  login,
  client_LandingPage,
  external_user_share_landing_page,
  familymember_landingpage,
  executor_LandingPage,
} from "../constants/routes";
export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const identifierIDState = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );
  const requestTypeState = useSelector(
    (state) => state.shareReceiveBankDetails.requestType
  );
  const [trigger] = useLazySigninwithssoQuery();
  const [ssoauthorize, { isLoading: ssoAuthorizeLoading }] =
    useLazySsoauthorizeQuery();
  const [signinwithsso, { isLoading: signInLoading }] =
    useLazySsousersigninQuery();
  const queryParams = new URLSearchParams(location.search);
  const codeValue = queryParams.get("code");
  const schema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(`${sploginEmailReqValidation}`)
      .email(`${sploginEmailValidation}`)
      .matches(emailRules, `${sploginEmailValidation}`),
  });
  useEffect(() => {
    if (codeValue != null) {
      const fetchData = async () => {
        let response = await signinwithsso(codeValue);
        if (response?.isSuccess) {
          const identifierID = JSON.parse(
            sessionStorage.getItem("ssoIdentifier")
          )?.identifierID;
          const requestType = JSON.parse(
            sessionStorage.getItem("ssoIdentifier")
          )?.requestType;
          dispatch(
            setCredentials({
              ...response.data,
              firmId: JSON.parse(sessionStorage.getItem("ssoResponse"))?.firmId,
            })
          );
          if (ROLE.firmsuperadmin === response.data["role"]) {
            if (
              identifierID &&
              requestType &&
              requestType !== "addProf" &&
              requestType !== "deathClaim"
            ) {
              navigate(
                `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
              );
            } else if (
              identifierID &&
              requestType &&
              requestType === "addProf"
            ) {
              navigate(
                `${firmsuperadmin_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
              );
            } else if (
              identifierID &&
              requestType &&
              requestType === "deathClaim"
            ) {
              navigate(
                `${firmsuperadmin_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
              );
            } else {
              navigate(firmsuperadmin_LandingPage, {
                state: {
                  id: response?.firmTypeId,
                },
              });
            }
          } else if (ROLE.attorney === response.data["role"]) {
            if (
              identifierID &&
              requestType &&
              requestType !== "addProf" &&
              requestType !== "deathClaim"
            ) {
              navigate(
                `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
              );
            } else if (
              identifierID &&
              requestType &&
              requestType === "addProf"
            ) {
              navigate(
                `${attorney_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
              );
            } else if (
              identifierID &&
              requestType &&
              requestType === "deathClaim"
            ) {
              navigate(
                `${attorney_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
              );
            } else {
              navigate(attorney_LandingPage);
            }
          } else if (ROLE.client === response.data["role"]) {
            if (
              identifierID &&
              requestType &&
              requestType !== "addProf" &&
              requestType !== "deathClaim"
            ) {
              navigate(
                `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
              );
            } else if (
              identifierID &&
              requestType &&
              requestType === "addProf"
            ) {
              navigate(
                `${client_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
              );
            } else if (
              identifierID &&
              requestType &&
              requestType === "deathClaim"
            ) {
              navigate(
                `${client_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
              );
            } else {
              navigate(client_LandingPage);
            }
          } else if (ROLE.executor === response.data["role"]) {
            if (
              identifierID &&
              requestType &&
              requestType !== "addProf" &&
              requestType !== "deathClaim"
            ) {
              navigate(
                `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
              );
            } else if (
              identifierID &&
              requestType &&
              requestType === "addProf"
            ) {
              navigate(
                `${executor_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
              );
            } else if (
              identifierID &&
              requestType &&
              requestType === "deathClaim"
            ) {
              navigate(
                `${executor_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
              );
            } else {
              navigate(executor_LandingPage);
            }
            // TODO navigate();
          } else if (ROLE.familymember === response.data["role"]) {
            if (
              identifierID &&
              requestType &&
              requestType !== "addProf" &&
              requestType !== "deathClaim"
            ) {
              navigate(
                `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
              );
            } else if (
              identifierID &&
              requestType &&
              requestType === "addProf"
            ) {
              navigate(
                `${familymember_landingpage}/additionproflanding?identifier=${identifierID}&type==${requestType}`
              );
            } else if (
              identifierID &&
              requestType &&
              requestType === "deathClaim"
            ) {
              navigate(
                `${familymember_landingpage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
              );
            } else {
              navigate(familymember_landingpage);
            }
            // TODO navigate();
          } else {
            navigate(mvssuperadmin_landingpage);
          }
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeValue]);

  useEffect(() => {
    sessionStorage.removeItem("fromOtpPage");
    sessionStorage.removeItem("fromWireTransfer")
    sessionStorage.removeItem("userInfo")
    sessionStorage.removeItem("isMaidenChecked")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const onSubmit = async (formData) => {
    try {
      const res = await trigger(formData.email).unwrap();
      if (res?.isSuccessful) {
        sessionStorage.setItem("ssoResponse", JSON.stringify(res));
        sessionStorage.setItem("fromSSO", "true");
        sessionStorage.setItem(
          "ssoIdentifier",
          JSON.stringify({
            identifierID: identifierIDState,
            requestType: requestTypeState,
          })
        );
        if (res?.isSSOEnabled === true) {
          const authResponse = await ssoauthorize(res?.domainName);
          window.location.replace(authResponse.error.data);
        } else {
          navigate(login, { state: { email: formData.email } });
        }
      } else if (res === null) {
        toast.error("User does not exist");
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error || err?.data?.errorMessage);
    }
  };

  if (signInLoading || ssoAuthorizeLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container
        className="login-container"
        data-testid="login-test-container"
        fluid
        style={{ padding: "0px" }}
      >
        <div>
          <div className="w-100 d-lg-none">
            <Header />
          </div>
          <Row>
            <Col lg={7} className="d-none d-lg-block p-0">
              <div className="login-web-image">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <WebLogo />
                </div>
              </div>
            </Col>
            <Col
              lg={5}
              xs={12}
              className="d-flex align-items-center justify-content-center p-0"
            >
              <div className="form-container">
                <Form
                  className="custom-form w-100 px-lg-2"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormTitle title={splogintitle} />
                  <Form.Group className="mb-3">
                    <Form.Label className="required" htmlFor={`email`}>
                      {sploginEmailLabel}
                    </Form.Label>
                    <Form.Control
                      className="form-input"
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="off"
                      {...register("email")}
                      isInvalid={!!errors.email}
                      onBlur={(e) => (e.target.value = e.target.value.trim())}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="w-100 pt-3">
                    <Button
                      className="custom-button btn-standard btn-login"
                      type="submit"
                      data-testid="submit-button"
                    >
                      {sploginButton}
                    </Button>
                  </div>
                </Form>
                {/* <div className="signup-container text-center mt-4">
                  Don’t have an account?{" "}
                  <span className="signup-btn ps-1" onClick={() => navigate(signup)}>
                    Sign Up
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
          <div className="tick-login"></div>
        </div>
      </Container>
    </>
  );
}

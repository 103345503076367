import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import InfoIcon from "../../assets/images/Info.png";
import PropTypes from "prop-types";

export default function TooltipPopoverInfo({ content, placement }) {
  const popover = (
    <Popover id="tooltip-ai">
      <Popover.Body>
        <div className="tooltip-ai">{content}</div>
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger={["click", "focus", "hover"]}
      placement={placement}
      overlay={popover}
    >
      <img src={InfoIcon} className="tooltip-img" alt="Info" />
    </OverlayTrigger>
  );
}

TooltipPopoverInfo.propTypes = {
  content: PropTypes.object.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

function VeteranLoginPopup({ showModal, setShowCallback }) {
  const handleClose = () => {
    setShowCallback(false);
  };
  return (
    <>
      <Modal
        id="modal-popup-container-search"
        className="register-modal register-modal-vet-login"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={() => {
          setShowCallback(false);
        }}
        data-testid="modal-popup-registered"
      >
        <Modal.Body>
          <>
            <div className="modal-content-container text-center pt-3">
              <div className="modal-content-register-fail mt-3 mb-2">
                <b>You now have an Essential plan</b>
              </div>
            </div>
            <h6 className="mb-2 mt-1 result-subtext-register-login">
              This gives you access to the Wills & Estate and Healthcare folder.
              We are bringing exciting features as an upgrade very soon.
            </h6>
            <div className="modal-footer border-0 d-flex justify-content-center mb-3">
              <div>
                <Button
                  className="custom-button btn-standard ok-button"
                  onClick={handleClose}
                >
                  Let’s Get Started
                </Button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}

VeteranLoginPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
};

export default VeteranLoginPopup;

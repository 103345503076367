import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, CloseButton } from "react-bootstrap";
import { clientPlaceholder } from "../../constants/attorney/lables";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import PhoneInput from "react-phone-input-2";
import dayjs from "dayjs";
import { setStackHolderDetails } from "../../slices/stackHolderSlice";
import { useAddPeopleMutation } from "../../slices/stackHolderApiManagmentSlice";
import { validation } from "../../constants/attorney/validationMessages";
import {
  phoneNumberRules,
  emailRules,
  alphabetRules,
  usphonNumberRules,
  indphonNumberRules,
} from "../../constants/regExp";
import {
  clientRelationship,
  formType,
} from "../../constants/attorney/mockData";
import ModalPopupType2 from "../modal/ModalPopupType2";
import utc from "dayjs/plugin/utc";
import { countryCodeList } from "../../constants/labels";
import {
  veteran_addPeople,
  veteran_addSuccussor,
  veteran_Changepassword,
  veteran_EditComponent,
  veteran_editPeoplePage,
  veteran_LandingPage,
  veteran_uploadDocuments,
  veteran_viewPeoplePage,
} from "../../constants/routes";

dayjs.extend(utc);

function VeteranFamilyInformation({ onClose }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = dayjs();
  const yesterday = dayjs()
    .year(today.year() - 100)
    .startOf("year");
  const [selectedDate, setSelectedDate] = useState(null);
  const { stackHolders } = useSelector((state) => state.stackholder);
  const { userInfo } = useSelector((state) => state.auth);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showModal, setShowModal] = useState(false);

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string()
      .trim()
      .required(`${validation.lastName.required}`)
      .matches(alphabetRules, `${validation.lastName.rule}`),
    email: yup
      .string()
      .trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
    dob: yup
      .string()
      .required(`${validation.dob.required}`)
      .test("valid-date", "Invalid date of birth", (value) => {
        if (!value) return true;
        return !isNaN(Date.parse(value));
      })
      .test("max-date", "Date of birth cannot be in the future", (value) => {
        if (!value) return true;
        const newtoday = dayjs().utc();
        const dob = dayjs(value).utc();
        return dob <= newtoday;
        //  let newtoday = new Intl.DateTimeFormat('en-US').format(today);
        //   // console.log(today.toLocaleDateString())
        //   newtoday = dayjs(newtoday).utc()
        //   console.log(dayjs(newtoday).utc())

        // let newdob = new Intl.DateTimeFormat('en-US').format(dob);
        // newdob = dayjs(newdob).utc()
      })
      .test(
        "min-date",
        "Date of birth should be after January 1, 1924",
        (value) => {
          if (!value) return true;
          // const today = dayjs().utc();
          const minDate = dayjs().year(1924).startOf("year");
          const dob = dayjs(value).utc();
          return dob >= minDate;
        }
      ),
    relationship: yup
      .string()
      .notOneOf([""], `${validation.relationship.required}`)
      .required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    //watch,
    control,
    setValue,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });
  const [addPeople] = useAddPeopleMutation();

  useEffect(() => {
    if (stackHolders?.familyMember) {
      if (
        stackHolders?.familyMember &&
        stackHolders?.familyMember?.phoneNumber
      ) {
        if (stackHolders?.familyMember?.phoneNumber?.length === 10) {
          setCountryCode("+1");
          setPhoneNumber(`1${stackHolders?.familyMember?.phoneNumber}`);
        } else {
          const firstDigit = stackHolders?.familyMember?.phoneNumber.charAt(1);
          const lasttendigit =
            stackHolders?.familyMember?.phoneNumber.slice(-10);
          if (firstDigit === "1") {
            setCountryCode("+1");
            setPhoneNumber(`1${lasttendigit}`);
          } else {
            setCountryCode("+91");
            setPhoneNumber(`91${lasttendigit}`);
          }
        }
      }
    }
  }, [stackHolders]);

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  useEffect(() => {
    if (stackHolders?.familyMember) {
      setValue("firstName", stackHolders?.familyMember?.firstName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("lastName", stackHolders?.familyMember?.lastName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("email", stackHolders?.familyMember?.emailId, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("relationship", stackHolders?.familyMember?.relationship);
      setSelectedDate(dayjs(stackHolders?.familyMember?.dateOfBirth));
      setValue("dob", dayjs(stackHolders?.familyMember?.dateOfBirth));
    }
  }, [stackHolders?.familyMember, setValue]);
  const handleDateChange = (date) => {
    // const adjustedDate = new Date(date);
    // adjustedDate.setHours(0, 0, 0, 0);
    // const onSelectDate = adjustedDate.toDateString();
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    setValue("dob", formattedDate, { shouldValidate: true });
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };
  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
  };
  const onSubmit = async (formData) => {
    const transformData = {
      clientId: userInfo?.userId,
      userId: userInfo?.userId,
      type: formType.family,
      familyMember: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        name: stackHolders?.familyMember?.name,
        emailId: formData.email,
        phoneNumber: `+${formData.phone}`,
        dateOfBirth: selectedDate,
        relationship: parseInt(formData.relationship),
      },
    };
    const data = JSON.stringify(transformData);
    const submitData = JSON.stringify({
      ...transformData.familyMember,
      dateOfBirth: dayjs(selectedDate).format("YYYY-MM-DD"),
    });
    const existData = JSON.stringify({
      ...stackHolders?.familyMember,
      dateOfBirth: dayjs(stackHolders?.familyMember?.dateOfBirth).format(
        "YYYY-MM-DD"
      ),
    });
    let edited = submitData !== existData ? true : false;
    if (edited === true) {
      try {
        const res = await addPeople(data).unwrap();
        if (res.isSuccessful) {
          toast.success(res?.message);
          dispatch(
            setStackHolderDetails({
              ...stackHolders,
              familyMember: {
                ...transformData.familyMember,
              },
            })
          );
          handleViewPageNavigation();
          onClose && onClose();
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.data?.errorMessage || err.errorMessage);
      }
    }
  };
  const handleViewPageNavigation = () => {
    if (location?.state?.pathname === veteran_LandingPage) {
      navigate(veteran_LandingPage, {
        state: {
          page: "editProfile",
          id: location?.state?.id,
        },
      });
    } else if (location?.state?.pathname === veteran_uploadDocuments) {
      navigate(veteran_uploadDocuments, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === veteran_viewPeoplePage) {
      navigate(veteran_viewPeoplePage, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === veteran_editPeoplePage) {
      navigate(veteran_editPeoplePage, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === veteran_addPeople) {
      navigate(veteran_addPeople, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === veteran_addSuccussor) {
      navigate(veteran_addSuccussor, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === veteran_Changepassword) {
      navigate(veteran_Changepassword, {
        state: {
          page: "editProfile",
        },
      });
    } else if (location?.state?.pathname === veteran_EditComponent) {
      navigate(veteran_EditComponent, {
        state: {
          activeTab: "family-info",
        },
      });
    }
  };
  return (
    <>
      {location?.state?.header && (
        <div className="death-claim-title-page-container border-0">
          <CloseButton
            className="close-button-clientedit position-absolute"
            variant="black"
            onClick={() => {
              setShowModal(true);
            }}
            data-testid="close-button"
          />
          <h5 className="my-2 ms-3">
            <b>{location?.state?.header} </b>
          </h5>
          <div className="px-3 mb-2">
            <div className="divider-firm-tab"></div>
          </div>
        </div>
      )}
      <Form
        className="custom-form employee-form px-2 pb-3 type-2 mx-4 pt-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col lg={12} md={12} className="mt-3">
            <Row>
              <Col lg={6} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor="family-firstname"
                    className="required mb-0"
                  >
                    {clientPlaceholder.firstName}
                  </Form.Label>
                  <Form.Control
                    className="form-input"
                    type="text"
                    name="firstName"
                    autoComplete="off"
                    maxLength={30}
                    {...register("firstName")}
                    isInvalid={!!errors.firstName}
                    data-testid="family-firstname"
                    id="family-firstname"
                  />
                  <Form.Control.Feedback
                    data-testid="invalid-feedback"
                    type="invalid"
                  >
                    {errors.firstName?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor="family-lastname"
                    className="required mb-0"
                  >
                    {clientPlaceholder.lastName}
                  </Form.Label>
                  <Form.Control
                    className="form-input input-border-radius-unset"
                    type="text"
                    name="lastName"
                    maxLength={30}
                    autoComplete="off"
                    {...register("lastName")}
                    isInvalid={!!errors.lastName}
                    data-testid="family-lastname"
                    id="family-lastname"
                  />
                  <Form.Control.Feedback
                    data-testid="invalid-feedback"
                    type="invalid"
                  >
                    {errors.lastName?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="" className="required mb-0">
                {clientPlaceholder.dob}
              </Form.Label>
              <Controller
                name="dob"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div
                      className={
                        errors.dob
                          ? "mui-date-picker-invalid-container"
                          : "mui-date-picker-container"
                      }
                    >
                      <DatePicker
                        name="dob"
                        type="date"
                        value={
                          dayjs(selectedDate).isValid()
                            ? dayjs(selectedDate)
                            : null
                        }
                        onChange={handleDateChange}
                        maxDate={today}
                        minDate={yesterday}
                        data-test="family-dob"
                      />
                    </div>
                  </LocalizationProvider>
                )}
              />
              <Form.Control
                type="hidden"
                value={selectedDate || ""}
                autoComplete="off"
                {...register("dob", {
                  required: true,
                })}
                isInvalid={!!errors.dob}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dob?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6} md={6}>
            <Form.Group className="mb-3">
              <Form.Label
                htmlFor="family-relationship"
                className="required mb-0"
              >
                {clientPlaceholder.relationship}
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input input-border-radius-unset select-placeholder"
                name="city"
                isInvalid={!!errors.relationship}
                {...register("relationship")}
                id="family-relationship"
                data-testid="family-relationship"
              >
                <option value="" hidden></option>
                {clientRelationship &&
                  clientRelationship.length > 0 &&
                  clientRelationship.map((relation, index) => {
                    return (
                      <option key={"relation" + index} value={relation.id}>
                        {relation.label}
                      </option>
                    );
                  })}
              </Form.Select>
              <Form.Control.Feedback
                data-testid="invalid-feedback"
                type="invalid"
              >
                {errors.relationship?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="family-email" className="required mb-0">
                {clientPlaceholder.email}
              </Form.Label>
              <Form.Control
                className="form-input input-border-radius-unset"
                type="email"
                name="email"
                autoComplete="off"
                {...register("email")}
                isInvalid={!!errors.email}
                onBlur={(e) => (e.target.value = e.target.value.trim())}
                data-testid="family-email"
                id="family-email"
              />
              <Form.Control.Feedback
                data-testid="invalid-feedback"
                type="invalid"
              >
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6} md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="family-phone" className="required mb-0">
                {clientPlaceholder.phone}
              </Form.Label>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <div
                    className={
                      errors.phone ? "phone-input-invalid-container" : ""
                    }
                  >
                    <PhoneInput
                      {...field}
                      defaultCountry="us"
                      onlyCountries={countryCodeList}
                      placeholder=""
                      title=""
                      country={"us"}
                      inputProps={{
                        required: true,
                        autoFocus: false,
                        title: "",
                      }}
                      value={phoneNumber}
                      countryCodeEditable={false}
                      onChangeCapture={handleMaxNumber}
                      onChange={(value, phone) => {
                        setPhoneNumber(value);
                        if (
                          phone.dialCode === "1" &&
                          phone.countryCode === "us"
                        ) {
                          setCountryCode("+1");
                        }
                        if (
                          phone.dialCode === "91" &&
                          phone.countryCode === "in"
                        ) {
                          setCountryCode("+91");
                        }
                      }}
                    />
                  </div>
                )}
              />
              <Form.Control
                className="form-input input-border-radius-unset input_number"
                type="hidden"
                name="phone"
                autoComplete="off"
                onKeyDown={handleKeyPress}
                onChangeCapture={handleMaxNumber}
                {...register("phone")}
                isInvalid={!!errors.phone}
                data-testid="family-phone"
                id="family-phone"
                value={phoneNumber}
              />
              <Form.Control.Feedback
                data-testid="invalid-feedback"
                type="invalid"
              >
                {errors.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-lg-2">
          <Button
            type="button"
            className="custom-button btn-nobg btn-update mx-2"
            data-testid="next-family-canel-button"
            onClick={() => setShowModal(true)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="custom-button btn-standard btn-update mx-2"
            data-testid="next-family-Submit-button"
          >
            Submit
          </Button>
        </div>
      </Form>
      <ModalPopupType2
        setShowCallback={() => setShowModal(false)}
        setYesCallback={onClose ? onClose : handleViewPageNavigation}
        content={`Are you sure you want to cancel the changes?`}
        showModal={showModal}
      />
    </>
  );
}

export default VeteranFamilyInformation;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotFound from "../../components/NotFound/NotFound";
import EditFamilyDetails from "../../components/attorney/editPeopleInformation/EditFamilyDetails";
import EditSuccessorDetails from "../../components/attorney/editPeopleInformation/EditSuccessorDetails";
import EditWitnessDetails from "../../components/attorney/editPeopleInformation/EditWitnessDetails";
import EditExecutorsDetails from "../../components/attorney/editPeopleInformation/EditExecutorsDetails";
import { useGetStakeHolderDetailsQuery } from "../../slices/stackHolderApiManagmentSlice";
import {
  veteran_editPeoplePage,
  veteran_LandingPage,
  veteran_uploadDocuments,
  veteran_viewPeoplePage,
} from "../../constants/routes";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import { Container } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import { clientTitle } from "../../constants/client/labels";

export default function VeteranEditPeopleDetailsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [titleFullName, setTitleFullName] = useState("");
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: veteran_LandingPage,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: veteran_uploadDocuments,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-3",
      link: veteran_viewPeoplePage,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-4",
      link: veteran_editPeoplePage,
      content: "--",
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);
  const { data, isLoading } = useGetStakeHolderDetailsQuery(
    location?.state?.id?.id?.doc?.userId,
    {
      refetchOnMountOrArgChange: true,
      skip: location?.state?.id === undefined,
    }
  );

  useEffect(() => {
    if (data) {
      let fullName = "";
      switch (location?.state?.id?.viewType) {
        case "family member":
          fullName = data?.familyMember?.name;
          break;
        case "witness":
          fullName = data?.witness[location?.state?.id?.index]?.name;
          break;
        case "executor":
          fullName = data?.executorList[location?.state?.id?.index]?.name;
          break;
        case "successor":
          fullName = data?.successor?.name;
          break;
        default:
          fullName = "";
          break;
      }
      setTitleFullName(fullName);
      setItems([
        {
          id: "item-1",
          link: veteran_LandingPage,
          content: clientTitle,
          active: false,
          state: { id: "", activeTab: "" },
        },
        {
          id: "item-2",
          link: veteran_uploadDocuments,
          content: location?.state?.id?.id?.doc?.rootDocName,
          active: false,
          state: {
            doc: location?.state?.id?.id?.doc,
            user: location?.state?.id?.id?.user,
          },
        },
        {
          id: "item-3",
          link: veteran_viewPeoplePage,
          content: `View ${fullName}`,
          active: false,
          state: {
            id: location?.state?.id?.id,
            file: location?.state?.id?.file,
            page: location?.state?.id?.page,
            index: location?.state?.id?.index,
            viewType: location?.state?.id?.viewType,
          },
        },
        {
          id: "item-4",
          link: veteran_editPeoplePage,
          content: fullName,
          active: true,
          state: {
            id: location?.state?.id,
          },
        },
      ]);
    }
  }, [data, location]);

  let renderComponet;
  switch (location?.state?.id?.viewType) {
    case "family member":
      renderComponet = <EditFamilyDetails />;
      break;
    case "executor":
      renderComponet = <EditExecutorsDetails />;
      break;
    case "successor":
      renderComponet = <EditSuccessorDetails />;
      break;
    case "witness":
      renderComponet = <EditWitnessDetails />;
      break;
    default:
      renderComponet = <NotFound />;
      break;
  }

  const handlePrevPage = () => {
    navigate(
      veteran_viewPeoplePage,
      {
        state: {
          id: location?.state?.id?.id,
          file: location?.state?.id?.file,
          page: location?.state?.id?.page,
          index: location?.state?.id?.index,
          viewType: location?.state?.id?.viewType,
        },
      }
    );
  };

  return (
    <>
      <div
        className="superadmin-container view-people-info-container"
        data-testid="client-edit-people-details-page"
      >
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-3 px-lg-4"
            data-testid="sa-addemployee-page"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={titleFullName}
              hasBackButton={true}
              navigateToPrev={handlePrevPage}
            />
          </div>
        </Container>
      </div>
      <div>{renderComponet}</div>
      {isLoading && <Loader />}
    </>
  );
}

import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import FormTitle from "../components/form/FormTitle";
import {
  attorney_LandingPage,
  client_LandingPage,
  familymember_landingpage,
  firmsuperadmin_LandingPage,
  executor_LandingPage,
  external_user_share_landing_page,
  additional_prof_landingpage,
  veteran_LandingPage,
} from "../constants/routes";
import Header from "../components/headers/Header";
import { inputFocus } from "../utils/inputMethod";
import {
  useSigninmvsMutation,
  useSigninwithmfaMutation,
} from "../slices/loginAuthManagementSlice";
import { toast } from "react-toastify";
import { ROLE } from "../constants/role";
import Loader from "../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials, signOutApiCall } from "../slices/authSlice";
import { useCallback, useEffect, useRef, useState } from "react";

export default function OtpVerification() {
  const otpTime = 30;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(otpTime); // 3 minutes in seconds
  const [timerRunning, setTimerRunning] = useState(true);
  const [sessionId, setSessionId] = useState(location?.state?.sessionId);
  const hasRun = useRef(false);
  const { userInfo } = useSelector((state) => state.auth);

  const handleLogout = useCallback(async () => {
    try {
      dispatch(signOutApiCall());
      const url = `${process.env.REACT_APP_BASE_URL_USERSERVICE}/api/v1/login/signOut?accesstoken=${userInfo?.accessToken}`;
      navigator.sendBeacon(url);
      toast.success("Signed out successfully...");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;

      const fromLogin = sessionStorage.getItem("fromLogin");

      if (fromLogin !== "true") {
        userInfo?.accessToken && handleLogout();
      }
    }
  }, [handleLogout, userInfo?.accessToken]);

  const [signinmvs] = useSigninmvsMutation();
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );
  const requestType = useSelector(
    (state) => state.shareReceiveBankDetails.requestType
  );
  useEffect(() => {
    let timer;
    if (timerRunning) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        } else {
          clearInterval(timer);
          setTimerRunning(false);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [seconds, timerRunning]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format the timer display
  const timerDisplay = `${minutes}:${
    remainingSeconds < 10 ? "0" : ""
  }${remainingSeconds}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const handleDigitChange = (event, value) => {
    if (event.target.value.length > 1) {
      setValue(value, event.target.value[event.target.value.length - 1]);
    } else {
      const numericValue = event.target.value.replace(/[^0-9]/g, "");
      setValue(value, numericValue);
    }
  };

  const [signinwithmfa, { isLoading }] = useSigninwithmfaMutation();

  const onSubmit = async (formData) => {
    const otp = Object.values(formData).toString().replaceAll(",", "");

    const transformData = {
      userName: location?.state?.userName,
      otp: otp,
      sessionId: sessionId,
    };

    const data = JSON.stringify(transformData);

    try {
      const res = await signinwithmfa(data).unwrap();
      dispatch(setCredentials({ ...res }));
      if (res?.successful) {
        if (res?.role === ROLE.firmsuperadmin) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${firmsuperadmin_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${firmsuperadmin_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(firmsuperadmin_LandingPage, {
              state: {
                id: res?.firmTypeId,
              },
            });
          }
        }
        if (res?.role === ROLE.attorney) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${attorney_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${attorney_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(attorney_LandingPage);
          }
        }
        if (res?.role === ROLE.client) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${client_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${client_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(client_LandingPage);
          }
        }
        if (res?.role === ROLE.individualClient && res.veteran) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${veteran_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${veteran_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(veteran_LandingPage);
            sessionStorage.setItem("showRippon", "true");
            sessionStorage.setItem("essentialPlan", "true");
          }
        }
        if (res?.role === ROLE.familymember) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${familymember_landingpage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${familymember_landingpage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(familymember_landingpage);
          }
        }
        if (res?.role === ROLE.executor) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${executor_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${executor_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(executor_LandingPage);
          }
        }
        if (res?.role === ROLE.externaluser) {
          if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${external_user_share_landing_page}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          }
        }
        if (res?.role === ROLE.additionalProf) {
          if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${additional_prof_landingpage}?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${additional_prof_landingpage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(additional_prof_landingpage);
          }
        }
        sessionStorage.removeItem("fromLogin");
        sessionStorage.removeItem("fromSSO");
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleResendOTP = async () => {
    setSeconds(otpTime);
    setTimerRunning(true);
    const transformData = {
      userName: location?.state?.userName,
      password: location?.state?.password,
    };

    const data = JSON.stringify(transformData);

    try {
      const res = await signinmvs(data).unwrap();
      setSessionId(res?.session);
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <>
      <Header />
      <div className="otp-verification-container">
        <Container fluid>
          <Row>
            <Col xs={12} lg={7} className="d-none d-lg-block p-0">
              <div className="login-web-image"></div>
            </Col>
            <Col md={12} sm={12} lg={5} xs={12}>
              <div className="d-flex justify-contant-lg-center justify-content-md-center justify-content-sm-center justify-align-container">
                <div className="inner-otp-container">
                  <Form
                    className="custom-form w-100 type-2"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <FormTitle
                      title="Enter Code to Verify"
                      variant="variant-2"
                    />
                    <Form.Group>
                      <Form.Label
                        htmlFor="photp1"
                        className="custom-form-label custom-label-otp"
                      >
                        Enter the <span className="f-w-700">OTP</span> sent to
                        your <span className="f-w-700">Mobile</span>
                      </Form.Label>
                      <div className="ms-0 d-flex justify-content-between">
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp1"
                          name="photp1"
                          data-testid="photp1-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          autoFocus={true}
                          min={0}
                          max={9}
                          {...register("photp1", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp1")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="1"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp2"
                          name="photp2"
                          data-testid="photp2-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp2", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp2")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="2"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp3"
                          name="photp3"
                          data-testid="photp3-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp3", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp3")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="3"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp4"
                          name="photp4"
                          data-testid="photp4-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp4", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp4")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          //isInvalid={!!errors.otp4}
                          tabIndex="4"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp5"
                          name="photp5"
                          data-testid="photp5-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp5", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp5")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="5"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp6"
                          name="photp6"
                          data-testid="photp6-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp6", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp6")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="6"
                        />
                      </div>
                      {errors &&
                        (errors?.photp1 ||
                          errors?.photp2 ||
                          errors?.photp3 ||
                          errors?.photp4 ||
                          errors?.photp5 ||
                          errors?.photp6) && (
                          <Row>
                            <Col className="mt-1">
                              <span className="otp-verify-error">
                                Please Enter 6 Digit OTP
                              </span>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-end  mt-2">
                                <div className="custom-resend-otp">
                                  <span
                                    onClick={handleResendOTP}
                                    className={`btn-resent-otp ${
                                      seconds === 0 ? "active" : "disabled"
                                    }`}
                                  >
                                    Resend OTP
                                  </span>{" "}
                                  in {timerDisplay}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}
                    </Form.Group>
                    {!(
                      errors &&
                      (errors?.photp1 ||
                        errors?.photp2 ||
                        errors?.photp3 ||
                        errors?.photp4 ||
                        errors?.photp5 ||
                        errors?.photp6)
                    ) && (
                      <div className="d-flex justify-content-end  mt-2">
                        <div className="custom-resend-otp">
                          <span
                            onClick={handleResendOTP}
                            className={`btn-resent-otp ${
                              seconds === 0 ? "active" : "disabled"
                            }`}
                          >
                            Resend OTP
                          </span>{" "}
                          in {timerDisplay}
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-end  mt-4">
                      <Button
                        className="custom-button btn-standard btn-submit"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isLoading && <Loader />}
      <div className="background-mvs-image type-1"></div>
    </>
  );
}

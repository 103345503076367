import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "rgb(33, 37, 41)",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function UploadCertificateAi({
  type,
  onFileUploaded,
  userId,
  clientId,
  onAiUploadCompleted,
}) {
  const userInfo = useSelector((state) => state.auth.userInfo);

  const onDropAccepted = async (files) => {
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!allowedTypes.includes(files[0]?.type)) {
      toast.error(
        "This format is not valid! Please upload PDF, DOC, or DOCX files only."
      );
      return;
    }

    if (files[0]?.size > 10 * 1024 * 1024) {
      toast.error("File more than 10 MB is not allowed");
      return;
    }

    const formData = new FormData();
    formData.append("file", files[0]);
    onFileUploaded(files[0]);
    if (type === "ai-doc-upload") {
      try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v2/ai/upload?clientId=${clientId}&userId=${userId}`;
        const token = userInfo?.accessToken;
        const response = await axios({
          url: baseUrl,
          method: "POST",
          responseType: "json",
          headers: { Authorization: "Bearer " + token },
          data: formData,
        });

        if (response.status) {
          onAiUploadCompleted(response);
        }
      } catch (err) {
        toast.error(
          err?.response?.data?.message ||
            `Error: Unable to upload your document. Please try again.`
        );
        onAiUploadCompleted(err);
      }
    }
  };

  const onDropRejected = (params) => {
    // console.log(params);
    if (params[0]?.errors[0]?.code === "file-invalid-type") {
      toast.error(
        `This format is not valid! Please upload PDF, DOC and DOCX format only`
      );
    }
    if (params[0]?.errors[0]?.code === "file-too-large") {
      toast.error(`File more than 10 MB is not allowed`);
    }

    if (params[0]?.errors[0]?.code === "too-many-files") {
      toast.error(`More than one file is not allowed`);
    }
  };
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    // accept: {
    //   "application/pdf": [".pdf"],
    //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    //     [".docx",".doc"],
    // },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div className="drag-container" style={{ fontWeight: 400 }}>
        <div {...getRootProps({ style })} data-testid="upload-certificate">
          <input {...getInputProps()} />
          <div className="w-100 text-center p-4">
            <div
              className="mt-4"
              style={{ fontSize: "20px", color: "#333333" }}
            >
              Drag and Drop File Here
            </div>
            <div
              className="mt-4"
              style={{ fontSize: "18px", color: "#333333" }}
            >
              Only one file can be uploaded at a time
            </div>
            <div className="d-flex align-items-center mt-4">
              <div
                style={{
                  flex: "1",
                  borderBottom: "2px dashed rgb(33, 37, 41, 0.5)",
                }}
              />
              <div style={{ margin: "0 20px", fontWeight: "400" }}>Or</div>
              <div
                style={{
                  flex: "1",
                  borderBottom: "2px dashed rgb(33, 37, 41, 0.5)",
                }}
              />
            </div>
            <Button
              className="custom-button btn-standard mt-4"
              style={{ fontSize: "18px" }}
              onClick={open}
            >
              Browse Files
            </Button>
            <div className="pt-4" style={{ fontSize: "18px" }}>
              Only PDF and DOC/DOCX files are allowed, with a size limit of 10
              MB
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

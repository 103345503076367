import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { clientPlaceholder } from "../../../constants/attorney/lables";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { useGetGeoCodesZipCodeQuery } from "../../../slices/ssoManagementSlice";
import { validation } from "../../../constants/attorney/validationMessages";
import {
  phoneNumberRules,
  emailRules,
  zipRules,
  alphabetRules,
  indphonNumberRules,
  usphonNumberRules,
  idNumberRules,
} from "../../../constants/regExp";
import {
  formType,
  executorDefaultValue,
  executorDefaultValue2,
} from "../../../constants/attorney/mockData";
import { optionalExeValidationMessage } from "../../../constants/client/labels";
import { useAddPeopleMutation } from "../../../slices/stackHolderApiManagmentSlice";
import {
  setStackHolderDetails,
  setExecutors,
  setExecutorlen,
  setExecutorRemove,
} from "../../../slices/stackHolderSlice";
import { ReactComponent as DeleteIcon } from "../../../assets/images/DeleteAction.svg";
import ModalPopupType2 from "../../modal/ModalPopupType2";
import PhoneInput from "react-phone-input-2";
import utc from "dayjs/plugin/utc";
import { countryCodeList } from "../../../constants/labels";

dayjs.extend(utc);

function ExecutorInformation({ onNext, getAPICall }) {
  const location = useLocation();
  const dispatch = useDispatch();
  //const navigate = useNavigate();
  const today = dayjs();
  const yesterday = dayjs()
    .year(today.year() - 100)
    .startOf("year");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [cities, setCities] = useState([]);
  const [cities2, setCities2] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [zipCode2, setZipCode2] = useState("");
  const [existDate, setExistdata] = useState([]);
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [submittedData, setSumbittedData] = useState(null);
  const buttonRef = useRef(null);
  const { stackHolders, executors, executorlen } = useSelector(
    (state) => state.stackholder
  );
  const { userInfo } = useSelector((state) => state.auth);
  const [countryCode, setCountryCode] = useState("");
  const [countryCode2, setCountryCode2] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const stackCount = JSON.parse(sessionStorage.getItem("stackcount"));
  const [defaulvalue] = useState(
    (stackCount && stackCount?.DefaulExecutor && stackCount?.DefaulExecutor > 1) || executorlen > 1
      ? executorDefaultValue2
      : executorDefaultValue
  );
  const executorFormSchema = {
    firstName: yup
      .string()
      .trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string()
      .trim()
      .required(`${validation.lastName.required}`)
      .matches(alphabetRules, `${validation.lastName.rule}`),
    addressLine1: yup
      .string()
      .trim()
      .required(`${validation.addressLine1.required}`),
    addressLine2: yup.string().trim(),
    dob: yup
      .string()
      .notRequired()
      .test("valid-date", "Invalid date of birth", (value, index) => {
        if (index.path === "executor[0].dob") {
          if (selectedDate === null) {
            return true;
          }
        }
        if (index.path === "executor[1].dob") {
          if (selectedDate2 === null) {
            return true;
          }
        }
        if (!value) return true;
        return !isNaN(Date.parse(value));
      })
      .test(
        "max-date",
        "Date of birth cannot be in the future",
        (value, index) => {
          if (index.path === "executor[0].dob") {
            if (selectedDate === null) {
              return true;
            }
          }
          if (index.path === "executor[1].dob") {
            if (selectedDate2 === null) {
              return true;
            }
          }
          if (!value) return true;
          const newtoday = dayjs().utc();
          const dob = dayjs(value).utc();
          return dob <= newtoday;
          //  let newtoday = new Intl.DateTimeFormat('en-US').format(today);
          //   // console.log(today.toLocaleDateStrinig())
          //   newtoday = dayjs(newtoday).utc()
          //   console.log(dayjs(newtoday).utc())

          // let newdob = new Intl.DateTimeFormat('en-US').format(dob);
          // newdob = dayjs(newdob).utc()
        }
      )
      .test(
        "min-date",
        "Date of birth should be after January 1, 1924",
        (value, index) => {
          if (index.path === "executor[0].dob") {
            if (selectedDate === null) {
              return true;
            }
          }
          if (index.path === "executor[1].dob") {
            if (selectedDate2 === null) {
              return true;
            }
          }
          if (!value) return true;
          // const today = dayjs().utc();
          const minDate = dayjs().year(1924).startOf("year");
          const dob = dayjs(value).utc();
          return dob >= minDate;
        }
      ),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value, i) {
          let formatValue = value;
          if (value.includes("+")) {
            formatValue = value.substring(1);
          }
          if (value.includes("++")) {
            formatValue = value.substring(2);
          }
          let tempvaliidate;
          if (stackHolders?.executorList?.[i?.options?.index]) {
            tempvaliidate =
              stackHolders?.executorList?.[i?.options?.index]?.[
                "phoneNumber"
              ] &&
              stackHolders?.executorList?.[i?.options?.index]?.[
                "phoneNumber"
              ] !== null
                ? stackHolders?.executorList?.[i?.options?.index]?.[
                    "phoneNumber"
                  ]?.substring(0, 3)
                : "";
          }
          if (i?.options?.index === 0) {
            if (countryCode) {
              if (countryCode === "+91") {
                return indphonNumberRules.test(formatValue);
              } else if (countryCode === "+1") {
                return usphonNumberRules.test(formatValue);
              } else {
                return phoneNumberRules.test(formatValue);
              }
            } else {
              if (
                tempvaliidate &&
                tempvaliidate !== null &&
                tempvaliidate !== ""
              ) {
                if (tempvaliidate.includes("+91")) {
                  setCountryCode(tempvaliidate);
                  return indphonNumberRules.test(
                    stackHolders?.executorList?.[i?.options?.index]?.[
                      "phoneNumber"
                    ]?.substring(1)
                  );
                } else {
                  setCountryCode(tempvaliidate.substr(0, 2));
                  return usphonNumberRules.test(
                    stackHolders?.executorList?.[i?.options?.index]?.[
                      "phoneNumber"
                    ]?.substring(1)
                  );
                  //console.log("tempvalidat===>else", tempvaliidate)
                }
              } else {
                return phoneNumberRules.test(formatValue);
              }
            }
          } else {
            if (countryCode2) {
              if (countryCode2 === "+91") {
                return indphonNumberRules.test(formatValue);
              } else if (countryCode2 === "+1") {
                return usphonNumberRules.test(formatValue);
              } else {
                // if(tempvaliidate && tempvaliidate!==null && tempvaliidate!==""){
                //   if(tempvaliidate.includes("+91")){
                //     console.log("tempvalidat===>if", usphonNumberRules.test(stackHolders?.executorList?.[i?.options?.index]?.["phoneNumber"]?.substring(1)))
                //     return indphonNumberRules.test(stackHolders?.executorList?.[i?.options?.index]?.["phoneNumber"]?.substring(1));
                //   }else{
                //     console.log("tempvalidat===>else", usphonNumberRules.test(stackHolders?.executorList?.[i?.options?.index]?.["phoneNumber"]?.substring(1)))
                //     return usphonNumberRules.test(stackHolders?.executorList?.[i?.options?.index]?.["phoneNumber"]?.substring(1));

                //   }
                // }else{
                //   return phoneNumberRules.test(value);
                // }
                return phoneNumberRules.test(formatValue);
              }
            } else {
              if (
                tempvaliidate &&
                tempvaliidate !== null &&
                tempvaliidate !== ""
              ) {
                if (tempvaliidate.includes("+91")) {
                  setCountryCode2(tempvaliidate);
                  return indphonNumberRules.test(
                    stackHolders?.executorList?.[i?.options?.index]?.[
                      "phoneNumber"
                    ]?.substring(1)
                  );
                } else {
                  setCountryCode2(tempvaliidate.substr(0, 2));
                  return usphonNumberRules.test(
                    stackHolders?.executorList?.[i?.options?.index]?.[
                      "phoneNumber"
                    ]?.substring(1)
                  );
                }
              } else {
                return phoneNumberRules.test(formatValue);
              }
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
    email: yup
      .string()
      .trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    zip: yup
      .string()
      .trim()
      .matches(zipRules, { message: `${validation.zip.zipRules}` }),
    cityList: yup.mixed().when("city", {
      is: null,
      then: () =>
        yup
          .string()
          .notOneOf([""], `${validation.cityList.required}`)
          .required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    //city: yup.string().required(`${validation.city.required}`).nullable(),
    city:yup.string().test("city-validation",`${validation.city.required}`, (value, index) => {
      if(index.path === "executor[0].city"){
        if((value === null || value === "") && (executors?.[`executor.0.cityList`] && executors?.[`executor.0.cityList`]!==null)){
          
          return true;
        }
        if(value !=="" && value !== null){
          return true;
        }
        return false;
      }
      if(index.path === "executor[1].city"){
        if((value === null || value === "") && (executors?.[`executor.1.cityList`] && executors?.[`executor.1.cityList`]!==null)){
          return true;
        }
        if(value !=="" && value !== null){
          return true;
        }
        return false;
      }

    }).nullable(),
    driversLicense: yup
      .string()
      .notRequired()
      .test("drivers_license", validation.driversLicense.rule, (value, ctx) => {
        if (!value) return true;
        if (!idNumberRules.test(value)) {
          return ctx.createError({
            message: validation.driversLicense.rule,
          });
        }
        return true;
      }),
    state: yup.string().required(`${validation.state.required}`),
    country: yup.string().required(`${validation.country.required}`),
  };
  const schema = yup.object().shape({
    executor: yup
      .array()
      .of(
        yup.lazy(() => {
          return yup.object().shape(executorFormSchema);
        })
      )
      .test({
        message: "The error message if length === 1",
        test: (arr) => arr.length < 3,
      }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      executor: defaulvalue,
    },
  });
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "executor",
  });
  const { data: geoCodeData, isLoading: geoCodeLoading } =
    useGetGeoCodesZipCodeQuery(zipCode, {
      skip: zipCode?.length !== 5,
    });
  const { data: geoCodeData2, isLoading: geoCodeLoading2 } =
    useGetGeoCodesZipCodeQuery(zipCode2, {
      skip: zipCode2?.length !== 5,
    });
  const [addPeople] = useAddPeopleMutation();
  const formatDate = (date) => {
    let dt2 = new Date(date);
    dt2.getMinutes(new Date().getTimezoneOffset());
    return dayjs(dt2).utc();
  };
  // useEffect(() => {
  //   if (stackHolders?.executorList) {

  //     stackHolders?.executorList?.forEach((ele, i) => {
  //       //let phone = ele?.phoneNumber && ele?.phoneNumber !== ""? ele?.phoneNumber: executors[`executor.${i}.phone`];
  //       let phone = ele?.phoneNumber && ele?.phoneNumber;
  //       if (ele) {
  //         if (ele && phone) {
  //           if (phone?.length === 10) {
  //             i === 0 ? setCountryCode("+1") : setCountryCode2("+1");
  //             i === 0
  //               ? setPhoneNumber(`1${phone}`)
  //               : setPhoneNumber2(`1${phone}`);
  //           } else {
  //             const firstDigit = phone.charAt(0);
  //             const lasttendigit = phone.slice(-10);
  //             if (firstDigit === "1") {
  //               i === 0 ? setCountryCode("+1") : setCountryCode2("+1");
  //               i === 0
  //                 ? setPhoneNumber(`1${lasttendigit}`)
  //                 : setPhoneNumber2(`1${lasttendigit}`);
  //             } else {
  //                 i === 0 ? setCountryCode("+91") : setCountryCode2("+91");
  //                 i === 0
  //                 ? setPhoneNumber(`${lasttendigit}`)
  //                 : setPhoneNumber2(`${lasttendigit}`);
  //             }
  //           }
  //         }
  //       }
  //     });
  //     if((stackHolders?.executorList?.length ===1) && Object.keys(executors).length>0){
  //       Object.keys(executors).forEach((e, i) => {
  //         if(e === "executor.1.countrycode"){
  //           setCountryCode2(executors[`executor.1.countrycode`])
  //         }
  //       })
  //     }
  //   }
  // }, [executors, stackHolders]);
  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue(`executor.0.phone`, phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
    if (
      stackHolders?.executorList &&
      stackHolders?.executorList?.length === 0 &&
      (countryCode === "" || countryCode === undefined)
    ) {
      setCountryCode(executors["executor.0.countrycode"]);
    }
    if (phoneNumber2 && countryCode2) {
      setValue(`executor.1.phone`, phoneNumber2, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
    if (
      stackHolders?.executorList &&
      stackHolders?.executorList?.length === 0 &&
      (countryCode2 === "" || countryCode2 === undefined)
    ) {
      setCountryCode2(executors["executor.1.countrycode"]);
    }
  }, [
    phoneNumber,
    phoneNumber2,
    countryCode,
    countryCode2,
    setValue,
    stackHolders?.executorList,
    executors,
  ]);

  useEffect(() => {
    const data = geoCodeData;
    if (data && data?.status === "OK") {
      let usResult = data?.results.find((result) => {
        let country = result.address_components.find((ac) =>
          ac.types.includes("country")
        )?.long_name;
        return (
          country === "United States" ||
          country === "Puerto Rico" ||
          country === "U.S. Virgin Islands" ||
          country === "American Samoa" ||
          country === "Northern Mariana Islands" ||
          country === "Guam"
        );
      });

      let multipleCities = usResult?.postcode_localities;

      let city = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("locality")
      )[0]?.long_name;

      let city1 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("sublocality")
      )[0]?.long_name;

      let city2 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("neighborhood")
      )[0]?.long_name;

      let city3 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_3")
      )[0]?.long_name;

      let city4 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_2")
      )[0]?.long_name;

      let state = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_1")
      )[0]?.long_name;

      let country = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("country")
      )[0]?.long_name;

      if (country === "United States") {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, state, country, 0);
        } else if (allCities && allCities?.length > 0) {
          setCities(allCities);
          setValueZip(null, state, country, 0);
        } else {
          setCities([]);
          setValueZip("", "", "", 0);
          toast("Please provide the valid zip code");
        }
      }

      if (
        country === "Puerto Rico" ||
        country === "U.S. Virgin Islands" ||
        country === "American Samoa" ||
        country === "Northern Mariana Islands" ||
        country === "Guam"
      ) {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          state,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, country, "United States", 0);
        } else if (allCities && allCities?.length > 0) {
          setCities(allCities);
          setValueZip(null, country, "United States", 0);
        } else {
          setCities([]);
          setValueZip("", "", "", 0);
          toast("Please provide the valid zip code");
        }
      }

      if (
        country !== "United States" &&
        country !== "Puerto Rico" &&
        country !== "U.S. Virgin Islands" &&
        country !== "Northern Mariana Islands" &&
        country !== "Guam" &&
        country !== "American Samoa"
      ) {
        toast("Please provide the US zip code");
        setCities([]);
        setValueZip("", "", "", 0);
      }
    } else {
      if (data !== undefined) {
        if (data && data?.status === "ZERO_RESULTS") {
          toast("Please provide the valid zip code");
          setCities([]);
          setValueZip("", "", "", 0);
        } else {
          data?.error_message
            ? toast(`${data?.error_message}`)
            : toast("somthing went wrong");
          setCities([]);
          setValueZip("", "", "", 0);
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoCodeData]);

  useEffect(() => {
    const data = geoCodeData2;
    if (data && data?.status === "OK") {
      let usResult = data?.results.find((result) => {
        let country = result.address_components.find((ac) =>
          ac.types.includes("country")
        )?.long_name;
        return (
          country === "United States" ||
          country === "Puerto Rico" ||
          country === "U.S. Virgin Islands" ||
          country === "American Samoa" ||
          country === "Northern Mariana Islands" ||
          country === "Guam"
        );
      });

      let multipleCities = usResult?.postcode_localities;

      let city = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("locality")
      )[0]?.long_name;

      let city1 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("sublocality")
      )[0]?.long_name;

      let city2 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("neighborhood")
      )[0]?.long_name;

      let city3 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_3")
      )[0]?.long_name;

      let city4 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_2")
      )[0]?.long_name;

      let state = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_1")
      )[0]?.long_name;

      let country = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("country")
      )[0]?.long_name;

      if (country === "United States") {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities2([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, state, country, 1);
        } else if (allCities && allCities?.length > 0) {
          setCities2(allCities);
          setValueZip(null, state, country, 1);
        } else {
          setCities2([]);
          setValueZip("", "", "", 1);
          toast("Please provide the valid zip code");
        }
      }

      if (
        country === "Puerto Rico" ||
        country === "U.S. Virgin Islands" ||
        country === "American Samoa" ||
        country === "Northern Mariana Islands" ||
        country === "Guam"
      ) {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          state,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities2([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, country, "United States", 1);
        } else if (allCities && allCities?.length > 0) {
          setCities2(allCities);
          setValueZip(null, country, "United States", 1);
        } else {
          setCities2([]);
          setValueZip("", "", "", 1);
          toast("Please provide the valid zip code");
        }
      }

      if (
        country !== "United States" &&
        country !== "Puerto Rico" &&
        country !== "U.S. Virgin Islands" &&
        country !== "Northern Mariana Islands" &&
        country !== "Guam" &&
        country !== "American Samoa"
      ) {
        toast("Please provide the US zip code");
        setCities2([]);
        setValueZip("", "", "", 1);
      }
    } else {
      if (data !== undefined) {
        if (data && data?.status === "ZERO_RESULTS") {
          toast("Please provide the valid zip code");
          setCities2([]);
          setValueZip("", "", "", 1);
        } else {
          data?.error_message
            ? toast(`${data?.error_message}`)
            : toast("somthing went wrong");
          setCities2([]);
          setValueZip("", "", "", 1);
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoCodeData2]);
  useEffect(() => {
    assignFieldValues("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stackHolders?.executorList]);
  const assignFieldValues = (rem) => {
    if (stackHolders?.executorList && stackHolders?.executorList?.length > 0) {
      let temoExistExecutors = [];
      if (rem === "") {
        stackHolders?.executorList?.forEach((ele, i) => {
          let fname =
            ele?.firstName && ele?.firstName !== ""
              ? ele?.firstName
              : executors?.[`executor.${i}.firstName`];
          let lname =
            ele?.lastName && ele?.lastName !== ""
              ? ele?.lastName
              : executors?.[`executor.${i}.lastName`];
          let email =
            ele?.email && ele?.email !== ""
              ? ele?.email
              : executors?.[`executor.${i}.email`];
          let dob =
            ele?.dob && ele?.dob !== ""
              ? ele?.dob
              : executors?.[`executor.${i}.dob`];
          if(!ele?.dob && ele?.dateOfBirth){
            dob= ele?.dateOfBirth !==""?ele?.dateOfBirth:executors?.[`executor.${i}.dob`];
          }    
          let phone =
            executors?.[`executor.${i}.phone`] &&
            executors?.[`executor.${i}.phone`] !== ""
              ? executors?.[`executor.${i}.phone`]
              : ele?.phoneNumber;
          let countryCode =
            executors?.[`executor.${i}.countrycode`] &&
            executors?.[`executor.${i}.countrycode`] !== ""
              ? executors[`executor.${i}.countrycode`]
              : ele?.countrycode;
          let driversLicense =
            ele?.drivingLicense && ele?.drivingLicense !== ""
              ? ele?.drivingLicense
              : executors?.[`executor.${i}.driversLicense`];

          let addressLine1 =
            ele?.address?.addressLine1 && ele?.address?.addressLine1 !== ""
              ? ele.address.addressLine1
              : executors?.[`executor.${i}.addressLine1`];
          let addressLine2 =
            ele?.address?.addressLine2 && ele?.address?.addressLine2 !== ""
              ? ele.address.addressLine2
              : executors?.[`executor.${i}.addressLine2`];
          let zip =
            executors?.[`executor.${i}.zip`] &&
            executors?.[`executor.${i}.zip`] !== ""
              ? executors[`executor.${i}.zip`]
              : ele?.address?.zip;
          let city =
            executors?.[`executor.${i}.city`] &&
            executors?.[`executor.${i}.city`] !== ""
              ? executors[`executor.${i}.city`]
              : ele?.address?.city;
          let cityList =
            executors?.[`executor.${i}.cityList`] &&
            executors?.[`executor.${i}.cityList`] !== ""
              ? executors[`executor.${i}.cityList`]
              : ele?.address?.cityList;
          let state =
            executors?.[`executor.${i}.state`] &&
            executors?.[`executor.${i}.state`] !== ""
              ? executors[`executor.${i}.state`]
              : ele?.address?.state;
          let country =
            executors?.[`executor.${i}.country`] &&
            executors?.[`executor.${i}.country`] !== ""
              ? executors[`executor.${i}.country`]
              : ele?.address?.country;
          if (!email && ele?.emailId && ele?.emailId !== null) {
            email = ele?.emailId;
          }
          fname &&setValue(`executor.${i}.firstName`, fname, {
            shouldValidate:false,
            shouldTouch: true,
            shouldDirty: true,
          });
          lname && setValue(`executor.${i}.lastName`, lname, {
            shouldValidate: false,
            shouldTouch: true,
            shouldDirty: true,
          });
           setValue(`executor.${i}.email`, email, {
            shouldValidate: false,
            shouldTouch: true,
            shouldDirty: true,
          });
          if (phone && phone !== "") {
            let tempPhone = phone;
            if (phone.includes("+")) {
              let count = phone.split("").filter((ele) => ele === "+").length;

              tempPhone = phone.substring(count);
            }
            if(tempPhone.length===10){
              tempPhone="1"+tempPhone;
              if(!(countryCode && countryCode !== "")){
                i === 0 ? setCountryCode("+1") : setCountryCode2("+1");
              }
            }
            if(!(countryCode && countryCode !== "") && phone.startsWith("+1")){
              i === 0 ? setCountryCode("+1") : setCountryCode2("+1");
             }
            i === 0 ? setPhoneNumber(tempPhone) : setPhoneNumber2(tempPhone);
          } else {
            setValue(`executor.${i}.phone`, "", {
              shouldValidate: false,
              shouldTouch: true,
              shouldDirty: false,
            });
          }
          if (countryCode && countryCode !== "") {
            i === 0
              ? setCountryCode(countryCode)
              : setCountryCode2(countryCode);
          }
          if (dob && dob !== null) {
            // i === 0
            //   ? setSelectedDate(dayjs(dob).utc()
            //   .format("YYYY-MM-DD"))
            //   : setSelectedDate2(dayjs(dob).utc()
            //   .format("YYYY-MM-DD"));
            // setValue(`executor.${i}.dob`, dayjs(dob).utc()
            // .format("YYYY-MM-DD"));
            i === 0
              ? setSelectedDate(dayjs(dob).format("YYYY-MM-DD"))
              : setSelectedDate2(dayjs(dob).format("YYYY-MM-DD"));
            setValue(`executor.${i}.dob`, dayjs(dob).format("YYYY-MM-DD"));
          }
          setValue(`executor.${i}.driversLicense`, driversLicense, {
            shouldValidate: false,
            shouldTouch: true,
            shouldDirty: true,
          });
          setValue(`executor.${i}.addressLine1`, addressLine1, {
            shouldValidate: false,
            shouldTouch: true,
            shouldDirty: false,
          });
          setValue(`executor.${i}.addressLine2`, addressLine2, {
            shouldValidate: false,
            shouldTouch: true,
            shouldDirty: true,
          });
          setValue(`executor.${i}.driversLicense`, driversLicense, {
            shouldValidate: false,
            shouldTouch: true,
            shouldDirty: true,
          });
          zip && setValue(`executor.${i}.zip`, zip, {
            shouldValidate: false,
            shouldTouch: true,
            shouldDirty: false,
          });

          if (city && city !== null && city !== "") {
            setValue(`executor.${i}.city`, city, {
              shouldValidate: false,
              shouldTouch: true,
              shouldDirty: false,
            });
          }
          if (
            !(city && city === null && city !== "") &&
            cityList &&
            cityList !== null &&
            cityList !== ""
          ) {
            setValue(`executor.${i}.city`, cityList, {
              shouldValidate: false,
              shouldTouch: true,
              shouldDirty: false,
            });
          }
          setValue(`executor.${i}.state`, state, {
            shouldValidate: false,
            shouldTouch: true,
            shouldDirty: false,
          });
          setValue(`executor.${i}.country`, country, {
            shouldValidate: false,
            shouldTouch: true,
            shouldDirty: false,
          });
          let tempEx = {
            firstName: fname,
            lastName: lname,
            name: fname + " " + lname,
            emailId: email,
            phoneNumber: phoneNumber,
            dateOfBirth: dayjs(dob).format("YYYY-MM-DD"),
            drivingLicense: driversLicense,
            address: {
              addressLine1: addressLine1,
              addressLine2: addressLine2,
              zip: zip,
              city: city,
              state: state,
              country: country,
            },
          };
          temoExistExecutors.push(tempEx);
        });
      } else {
        stackHolders?.executorList
          ?.filter((e, i) => i === 0)
          .forEach((ele, i) => {
            let fname =
            executors?.[`executor.${i}.firstName`] && executors?.[`executor.${i}.firstName`] !== ""
            ? executors[`executor.${i}.firstName`]
            :ele?.firstName && ele?.firstName !== ""
                ? ele.firstName 
                : "";
            let lname =
            executors?.[`executor.${i}.lastName`] && executors?.[`executor.${i}.lastName`] !== ""
            ?executors?.[`executor.${i}.lastName`]
            :ele?.lastName && ele?.lastName !== ""
                ? ele.lastName
                : "";
            let email =
            executors?.[`executor.${i}.email`] && executors?.[`executor.${i}.email`] !== "" ? executors?.[`executor.${i}.email`] : ele?.email;
            let dob = ele?.dob && ele?.dob !== "" ? ele.dob : executors?.[`executor.${i}.dob`];
            if(!ele?.dob && ele?.dateOfBirth && ele?.dateOfBirth!==""){
              dob= executors?.[`executor.${i}.dob`] && executors?.[`executor.${i}.dob`] !== "" ? executors?.[`executor.${i}.dob`] : ele?.dateOfBirth;
            }  
            let driversLicense =
            ele?.drivingLicense && ele?.drivingLicense !== ""
                ? ele?.drivingLicense
                : executors[`executor.${i}.driversLicense`];
            let addressLine1 =
            executors?.[`executor.${i}.addressLine1`] && executors?.[`executor.${i}.addressLine1`] !== ""
                ? executors?.[`executor.${i}.addressLine1`]
                : ele?.address?.addressLine1;
            let addressLine2 =
            executors?.[`executor.${i}.addressLine2`] !== ""
                ? executors[`executor.${i}.addressLine2`]
                : ele.address.addressLine2;
            let zip =
              executors?.[`executor.${i}.zip`] &&
             executors?.[`executor.${i}.zip`] !== ""
                ? executors?.[`executor.${i}.zip`]
                : ele?.address?.zip;
            let city =
              executors?.[`executor.${i}.city`] &&
              executors?.[`executor.${i}.city`] !== ""
                ? executors?.[`executor.${i}.city`]
                : ele?.address?.city;
            let cityList =
              executors?.[`executor.${i}.cityList`] &&
              executors?.[`executor.${i}.cityList`] !== ""
                ? executors?.[`executor.${i}.cityList`]
                : ele?.address?.cityList;
            let state =
            executors?.[`executor.${i}.state`] &&
              executors?.[`executor.${i}.state`] !== ""
                ? executors?.[`executor.${i}.state`]
                : ele?.address?.state;
            let country =
            executors?.[`executor.${i}.country`] &&
              executors?.[`executor.${i}.country`] !== ""
                ? executors[`executor.${i}.country`]
                : ele?.address?.country;
              if (!ele.email && ele?.emailId && ele?.emailId !== null) {
                email = ele?.emailId;
              }    
            setValue(`executor.${i}.firstName`, fname, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
            setValue(`executor.${i}.lastName`, lname, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
            setValue(`executor.${i}.email`, email, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
            if (dob && dob !== null) {
              i === 0
                ? setSelectedDate(dayjs(dob).format("YYYY-MM-DD"))
                : setSelectedDate2(dayjs(dob).format("YYYY-MM-DD"));
              setValue(`executor.${i}.dob`, dayjs(dob).format("YYYY-MM-DD"));
            }
            //setValue(`executor.${i}.dob`, formatDate(ele.dob));
            setValue(`executor.${i}.driversLicense`, driversLicense, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
            setValue(`executor.${i}.addressLine1`, addressLine1, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
            setValue(`executor.${i}.addressLine2`, addressLine2, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
            setValue(`executor.${i}.zip`, zip, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
            setValue(`executor.${i}.city`, city !== null ? city : cityList, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
            setValue(`executor.${i}.state`, state, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
            setValue(`executor.${i}.country`, country, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
            let tempEx = {
              firstName: fname,
              lastName: lname,
              name: fname + " " + lname,
              emailId: email,
              phoneNumber: phoneNumber,
              dateOfBirth: dayjs(dob).format("YYYY-MM-DD"),
              drivingLicense: driversLicense,
              address: {
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                zip: zip,
                city: city !== null ? city : cityList,
                state: state,
                country: country,
              },
            };
            temoExistExecutors.push(tempEx);
          });
      }

      setExistdata(temoExistExecutors);
    }
    if (
      ((stackHolders?.executorList &&
        stackHolders?.executorList.length === 0) ||
        !stackHolders?.executorList) &&
      Object.keys(executors).length > 0
    ) {
      fields
        .filter((e, i) => ((rem !== "" && i === 0) || (rem === "")))
        .forEach((ele, i) => {
          if (
            !stackHolders?.executorList[i]?.firstName ||
            stackHolders?.executorList[i]?.firstName === ""
          ) {
            setValue(
              `executor.${i}.firstName`,
              executors?.[`executor.${i}.firstName`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }
          if (
            !stackHolders?.executorList[i]?.lastName ||
            stackHolders?.executorList[i]?.lastName === ""
          ) {
            setValue(
              `executor.${i}.lastName`,
              executors?.[`executor.${i}.lastName`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }
          if (
            !stackHolders?.executorList[i]?.email ||
            stackHolders?.executorList[i]?.email === ""
          ) {
            setValue(
              `executor.${i}.email`,
              executors?.[`executor.${i}.email`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }
          if (
            !stackHolders?.executorList[i]?.phoneNumber ||
            stackHolders?.executorList[i]?.phoneNumber === ""
          ) {
            setValue(
              `executor.${i}.phone`,
              executors?.[`executor.${i}.phone`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }

          i === 0
            ? setPhoneNumber(executors?.[`executor.${i}.phone`])
            : setPhoneNumber2(executors?.[`executor.${i}.phone`]);
          if (
            (!stackHolders?.executorList[i]?.dob ||
              stackHolders?.executorList[i]?.dob === "") &&
            executors?.[`executor.${i}.dob`] !== undefined &&
            executors?.[`executor.${i}.dob`] !== null
          ) {
            i === 0
              ? setSelectedDate(formatDate(executors?.[`executor.${i}.dob`]))
              : setSelectedDate2(formatDate(executors?.[`executor.${i}.dob`]));
          }
          if (
            !stackHolders?.executorList[i]?.driversLicense ||
            stackHolders?.executorList[i]?.driversLicense === ""
          ) {
            setValue(
              `executor.${i}.driversLicense`,
              executors?.[`executor.${i}.driversLicense`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }
          if (
            !stackHolders?.executorList[i]?.drivingLicense ||
            stackHolders?.executorList[i]?.drivingLicense === ""
          ) {
            setValue(
              `executor.${i}.driversLicense`,
              executors?.[`executor.${i}.driversLicense`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }
          if (
            !stackHolders?.executorList[i]?.address?.addressLine1 ||
            stackHolders?.executorList[i]?.address?.addressLine1 === ""
          ) {
            setValue(
              `executor.${i}.addressLine1`,
              executors?.[`executor.${i}.addressLine1`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }
          if (
            !stackHolders?.executorList[i]?.address?.addressLine2 ||
            stackHolders?.executorList[i]?.address?.addressLine2 === ""
          ) {
            setValue(
              `executor.${i}.addressLine2`,
              executors?.[`executor.${i}.addressLine2`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }
          if (
            !stackHolders?.executorList[i]?.address?.zip ||
            stackHolders?.executorList[i]?.address?.zip === ""
          ) {
            setValue(`executor.${i}.zip`, executors?.[`executor.${i}.zip`], {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
          }
          if (
            !stackHolders?.executorList[i]?.address?.city ||
            stackHolders?.executorList[i]?.address?.city === ""
          ) {
            setValue(
              `executor.${i}.city`,
              executors?.[`executor.${i}.city`] === null
                ? executors?.[`executor.${i}.cityList`]
                : executors?.[`executor.${i}.city`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }
          if (
            !stackHolders?.executorList[i]?.address?.state ||
            stackHolders?.executorList[i]?.address?.state === ""
          ) {
            setValue(
              `executor.${i}.state`,
              executors?.[`executor.${i}.state`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }
          if (
            !(stackHolders?.executorList[i]?.address?.coutry === "") ||
            stackHolders?.executorList[i]?.address?.coutry === ""
          ) {
            setValue(
              `executor.${i}.country`,
              executors?.[`executor.${i}.country`],
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          }
          if (
            executors?.[`executor.${i}.dob`] !== undefined &&
            executors?.[`executor.${i}.dob`] !== null
          ) {
            handleDateChange(executors?.[`executor.${i}.dob`], i);
          }
        });
    }
  };
  const removeMoreExecutor = async (i) => {
    //setFieldEmpty(true);
    const { ...rest } = Object.fromEntries(
      Object.entries(executors).filter(([key]) => !key.includes(".1."))
    );
    remove(2);
    remove(1);
    assignFieldValues("remove");
    dispatch(setExecutorRemove({ lent: 1, executor: rest }));
    setSelectedDate2(null);
    setCountryCode2("");
    setPhoneNumber2("");
  };
  const setValueZip = (city, state, country, index) => {
    setValue(`executor.${index}.city`, city, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue(`executor.${index}.state`, state, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue(`executor.${index}.country`, country, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
    let temp =
      index === 0
        ? {
            "executor.0.city": city,
            "executor.0.state": state,
            "executor.0.country": country,
          }
        : {
            "executor.1.city": city,
            "executor.1.state": state,
            "executor.1.country": country,
          };
    dispatch(setExecutors({ ...executors, ...temp }));
  };
  const onConfirm = async ({ data, executorList, edited }) => {
    try {
      if (edited === true) {
        const res = await addPeople(data).unwrap();
        if (res.isSuccessful) {
          toast.success(res?.message);
          dispatch(
            setStackHolderDetails({
              ...stackHolders,
              executorList: [...executorList],
            })
          );

          onNext();
        } else {
          toast.error(res?.message);
        }
      } else {
        onNext();
      }
    } catch (err) {
      toast.error(err?.data?.errorMessage || err.errorMessage);
    }
  };
  const onSubmit = async (formData) => {
    let executorList = [];
    let dataChangeList = [];
    let nonMandatoryFields = false;
    formData?.executor?.forEach((ele, i) => {
      // let dob = new Date(ele.dob).toISOString().split("T")[0];
      let temp = {
        firstName: ele.firstName,
        lastName: ele.lastName,
        name: ele.firstName + " " + ele.lastName,
        emailId: ele.email,
        phoneNumber: `+${ele.phone}`,
        dateOfBirth: i === 0 ? selectedDate : selectedDate2,
        drivingLicense: ele.driversLicense ? ele.driversLicense : "",
        address: {
          addressLine1: ele.addressLine1,
          addressLine2: ele.addressLine2 || "",
          zip: ele.zip,
          city: ele?.city && ele?.city !== null &&  ele?.city !== ""? ele?.city : ele?.cityList,
          state: ele.state,
          country: ele.country,
        },
      };
      let existEx = {
        ...temp,
        dateOfBirth:
          i === 0
            ? dayjs(selectedDate).format("YYYY-MM-DD")
            : dayjs(selectedDate2).format("YYYY-MM-DD"),
      };
      
      executorList.push(temp);
      dataChangeList.push(existEx);
      if (i === 0) {
        nonMandatoryFields =
          (selectedDate === undefined ||
            selectedDate === null ||
            ele.driversLicense === "") &&
          true;
      }
      if (i === 1) {
        nonMandatoryFields =
          (selectedDate2 === undefined ||
            selectedDate2 === null ||
            ele.driversLicense === "") &&
          true;
      }
    });
    const transformData = {
      clientId: location?.state?.user?.clientId,
      userId: userInfo?.userId,
      type: formType.executor,
      executorList: executorList,
    };
    if (executorList.length > 1) {
      let errorMessage = "Executor 1 and Executor 2 cannot have the same";
      let isFieldSame = false;
      let emailMessage = "";
      let phoneMessage = "";
      let drivincePP = "";

      if (executorList[0]?.emailId === executorList[1]?.emailId) {
        emailMessage = " Email";
        isFieldSame = true;
      }
      if (executorList[0]?.phoneNumber === executorList[1]?.phoneNumber) {
        isFieldSame = true;
        phoneMessage =
          emailMessage === "" ? " Mobile phone" : " and Mobile phone";
      }
      if (
        executorList[0]?.drivingLicense &&
        executorList[1]?.drivingLicense &&
        executorList[0]?.drivingLicense === executorList[1]?.drivingLicense
      ) {
        isFieldSame = true;
        drivincePP =
          phoneMessage === "" && emailMessage === ""
            ? " Driver’s License / Passport"
            : " and Driver’s License / Passport";
      }
      if (isFieldSame) {
        toast.error(errorMessage + emailMessage + phoneMessage + drivincePP);
        return;
      }
    }
    const data = JSON.stringify(transformData);
    const submitDataJson = JSON.stringify(dataChangeList);
    const existDataJson = JSON.stringify(existDate);
    let edited = submitDataJson !== existDataJson ? true : false;
    if (stackHolders?.executorList?.length > 1) {
      if (stackHolders?.executorList?.length !== executorList?.length) {
        edited = true;
      }
    }

    if (nonMandatoryFields) {
      setSumbittedData({
        data,
        executorList,
        edited,
      });
      setConfirmModal(true);
    } else {
      onConfirm({
        data,
        executorList,
        edited,
      });
    }
  };
  const handleDateChange = (date, i) => {
    if (date === null) {
      i === 0 ? setSelectedDate(null) : setSelectedDate2(null);
      setValue(`executor.${i}.dob`, null, { shouldValidate: true });
      let dobField =
        i === 0 ? { "executor.0.dob": date } : { "executor.1.dob": date };
      dispatch(setExecutors({ ...executors, ...dobField }));
    } else {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      i === 0
        ? setSelectedDate(formattedDate)
        : setSelectedDate2(formattedDate);
      setValue(`executor.${i}.dob`, formattedDate, { shouldValidate: true });
      // eslint-disable-next-line no-unused-vars
      let dobField =
        i === 0 ? { "executor.0.dob": date } : { "executor.1.dob": date };
      dispatch(setExecutors({ ...executors, ...dobField }));
    }
    // const adjustedDate = new Date(date);
    // adjustedDate.setHours(0, 0, 0, 0);
    // const onSelectDate = adjustedDate.toDateString();
  };
  const handleKeyDown = (event, i) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      setTimeout(() => {
        i === 0 ? setSelectedDate(null) : setSelectedDate2(null);
        setValue(`executor.${i}.dob`, null, { shouldValidate: true });
        let dobField =
          i === 0 ? { "executor.0.dob": null } : { "executor.1.dob": null };
        dispatch(setExecutors({ ...executors, ...dobField }));
      }, 0);
    }
  };
  const handleMaxNumber = (event, i) => {
    let { value, name } = event.target;
    if (name === `executor.${i}.phone`) {
      event.target.value = value.slice(0, 10);
    }
    if (name === `executor.${i}.zip`) {
      event.target.value = value.slice(0, 5);
      i === 0
        ? setZipCode(event.target.value)
        : setZipCode2(event.target.value);
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };
  const addMoreExecutor = () => {
    if (stackHolders?.executorList?.length > 0) {
      insert(1, {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        dob: "",
        phone: "",
        email: "",
        zip: "",
        city: "",
        driversLicense: "",
        state: "",
        country: "",
      });
    } else {
      append({
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        dob: "",
        phone: "",
        email: "",
        zip: "",
        city: "",
        driversLicense: "",
        state: "",
        country: "",
      });
    }
    dispatch(setExecutorlen(2));
    remove(2);
  };
  const handleChangeField = (e) => {
    
    dispatch(setExecutors({ ...executors, [e.target.name]: e.target.value }));
    if((e.target.name === "executor.1.zip") || (e.target.name === "executor.0.zip") ){
      if(e.target.value.length===5){
        setError(e.target.name, null)
      }
    }
  };
  const handlePhone = (value, phone, i) => {
    let countrycode = "";
    if (i === 0) {
      setPhoneNumber(value);
      if (phone.dialCode === "1" && phone.countryCode === "us") {
        setCountryCode("+1");
        countrycode = "+1";
      }
      if (phone.dialCode === "91" && phone.countryCode === "in") {
        setCountryCode("+91");
        countrycode = "+91";
      }
    } else {
      setPhoneNumber2(value);
      if (phone.dialCode === "1" && phone.countryCode === "us") {
        setCountryCode2("+1");
        countrycode = "+1";
      }
      if (phone.dialCode === "91" && phone.countryCode === "in") {
        setCountryCode2("+91");
        countrycode = "+91";
      }
    }
    let obj =
      i === 0
        ? {
            "executor.0.phone": value,
            "executor.0.countrycode": countrycode,
          }
        : {
            "executor.1.phone": value,
            "executor.1.countrycode": countrycode,
          };
    dispatch(
      setExecutors({
        ...executors,
        ...obj,
      })
    );
  };
  return (
    <>
      <Form
        className="custom-form px-2 pb-3 type-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        {fields.map((item, i) => {
          let citiesArray = i === 0 ? cities : cities2;
          let selectedDates =
            i === 0
              ? dayjs(selectedDate).isValid()
                ? dayjs(selectedDate)
                : null
              : dayjs(selectedDate2).isValid()
              ? dayjs(selectedDate2)
              : null;
          return (
            <div className="executor-form position-relative" key={item.id}>
              <div className="">
                <Row className={i === 0 ? "" : "mt-4"}>
                  <Col lg={12} md={12} className="mt-4">
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor={`executor.${i}.firstName`}
                            className="required mb-0"
                          >
                            {clientPlaceholder.firstName}
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            name={`executor.${i}.firstName`}
                            autoComplete="off"
                            maxLength={30}
                            {...register(`executor.${i}.firstName`)}
                            isInvalid={!!errors?.executor?.[i]?.firstName}
                            data-testid={`executor.${i}.firstName`}
                            id={`executor.${i}.firstName`}
                            onChange={handleChangeField}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors?.executor?.[i]?.firstName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor={`executor.${i}.lastName`}
                            className="required mb-0"
                          >
                            {clientPlaceholder.lastName}
                          </Form.Label>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="text"
                            name={`executor.${i}.lastName`}
                            maxLength={30}
                            autoComplete="off"
                            {...register(`executor.${i}.lastName`)}
                            isInvalid={!!errors?.executor?.[i]?.lastName}
                            data-testid={`executor.${i}.lastName`}
                            id={`executor.${i}.lastName`}
                            onChange={handleChangeField}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors?.executor?.[i]?.lastName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor={`executor.${i}.email`}
                            className="required mb-0"
                          >
                            {clientPlaceholder.email}
                          </Form.Label>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="email"
                            name="email"
                            autoComplete="off"
                            {...register(`executor.${i}.email`)}
                            isInvalid={!!errors?.executor?.[i]?.email}
                            data-testid={`executor.${i}.email`}
                            onBlur={(e) => {
                              const trimmedValue = e.target.value.trim();
                              e.target.value = trimmedValue;
                              // Manually trigger a change event to update the form state
                              handleChangeField(e);
                            }}
                            id={`executor.${i}.email`}
                            //onChange={handleChangeField}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors?.executor?.[i]?.email?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor={`executor.${i}.phone`}
                            className="required mb-0"
                          >
                            {clientPlaceholder.phone}
                          </Form.Label>
                          <Controller
                            control={control}
                            name={`executor.${i}.phone`}
                            render={({ field }) => (
                              <div
                                className={
                                  errors?.executor?.[i]?.phone
                                    ? "phone-input-invalid-container"
                                    : ""
                                }
                              >
                                <PhoneInput
                                  {...field}
                                  defaultCountry="us"
                                  onlyCountries={countryCodeList}
                                  placeholder=""
                                  title=""
                                  country={"us"}
                                  inputProps={{
                                    required: true,
                                    autoFocus: false,
                                    title: "",
                                  }}
                                  value={i === 0 ? phoneNumber : phoneNumber2}
                                  countryCodeEditable={false}
                                  onChangeCapture={handleMaxNumber}
                                  onChange={(e, phone) =>
                                    handlePhone(e, phone, i)
                                  }
                                />
                              </div>
                            )}
                          />
                          <Form.Control
                            className="form-input input-border-radius-unset input_number"
                            type="hidden"
                            name={`executor.${i}.phone`}
                            onKeyDown={handleKeyPress}
                            onChangeCapture={(e) => handleMaxNumber(e, i)}
                            autoComplete="off"
                            {...register(`executor.${i}.phone`)}
                            isInvalid={!!errors?.executor?.[i]?.phone}
                            data-testid={`executor.${i}.phone`}
                            id={`executor.${i}.phone`}
                            onChange={handleChangeField}
                            value={i === 0 ? phoneNumber : phoneNumber2}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors?.executor?.[i]?.phone?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="" className="mb-0">
                        {clientPlaceholder.dob}
                      </Form.Label>
                      <Controller
                        name={`executor.${i}.dob`}
                        control={control}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div
                              className={
                                errors?.executor?.[i]?.dob
                                  ? "mui-date-picker-invalid-container"
                                  : "mui-date-picker-container"
                              }
                            >
                              <DesktopDatePicker
                                {...field}
                                name={`executor.${i}.dob`}
                                type="date"
                                slotProps={{
                                  field: { clearable: true },
                                  textField: {
                                    onKeyDown: (e) => handleKeyDown(e, i),
                                  },
                                }}
                                value={selectedDates}
                                onChange={(e) => handleDateChange(e, i)}
                                maxDate={today}
                                minDate={yesterday}
                                data-test={`executor.${i}.dob`}
                              />
                            </div>
                          </LocalizationProvider>
                        )}
                      />
                      <Form.Control
                        type="hidden"
                        value={selectedDates || ""}
                        autoComplete="off"
                        {...register(`executor.${i}.dob`, {
                          required: true,
                        })}
                        isInvalid={!!errors?.executor?.[i]?.dob}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.executor?.[i]?.dob?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor={`executor.${i}.driversLicense`}
                        className="mb-0"
                      >
                        {clientPlaceholder.driversLicense}
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        name={`executor.${i}.driversLicense`}
                        autoComplete="off"
                        maxLength={20}
                        {...register(`executor.${i}.driversLicense`)}
                        isInvalid={!!errors?.executor?.[i]?.driversLicense}
                        data-testid={`executor.${i}.driversLicense`}
                        id={`executor.${i}.driversLicense`}
                        onChange={handleChangeField}
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors?.executor?.[i]?.driversLicense?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor={`executor.${i}.addressLine1`}
                        className="required mb-0"
                      >
                        {clientPlaceholder.addressLine1}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name={`executor.${i}.addressLine1`}
                        maxLength={255}
                        autoComplete="off"
                        {...register(`executor.${i}.addressLine1`)}
                        isInvalid={!!errors?.executor?.[i]?.addressLine1}
                        data-testid={`executor.${i}.addressLine1`}
                        id={`executor.${i}.addressLine1`}
                        onChange={handleChangeField}
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors?.executor?.[i]?.addressLine1?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor={`executor.${i}.addressLine2`}
                        className="form-label mb-0"
                      >
                        {clientPlaceholder.addressLine2}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name={`executor.${i}.addressLine2`}
                        maxLength={255}
                        autoComplete="off"
                        {...register(`executor.${i}.addressLine2`)}
                        isInvalid={!!errors?.executor?.[i]?.addressLine2}
                        data-testid={`executor.${i}.addressLine2`}
                        id={`executor.${i}.addressLine2`}
                        onChange={handleChangeField}
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors?.executor?.[i]?.addressLine2?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor={`executor.${i}.zip`}
                        className="required mb-0"
                      >
                        {clientPlaceholder.zip}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset input_number"
                        type="number"
                        name={`executor.${i}.zip`}
                        autoComplete="off"
                        onKeyDown={handleKeyPress}
                        onChangeCapture={(e) => handleMaxNumber(e, i)}
                        {...register(`executor.${i}.zip`)}
                        isInvalid={executors?.[`executor.${i}.zip`] ?  
                        executors?.[`executor.${i}.zip`].length<5 ? 
                          true:false
                        :
                        !!errors?.executor?.[i]?.zip
                       }
                        data-testid={`executor.${i}.zip`}
                        id={`executor.${i}.zip`}
                        onChange={handleChangeField}
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors?.executor?.[i]?.zip?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor={`executor.${i}.city`}
                        className="required mb-0"
                      >
                        {clientPlaceholder.cityList}
                      </Form.Label>
                      {citiesArray?.length > 0 && (
                        <>
                          <Form.Select
                            aria-label="Default select example"
                            className="form-input input-border-radius-unset select-placeholder"
                            name={`executor.${i}.city`}
                            isInvalid={!!errors?.executor?.[i]?.cityList}
                            {...register(`executor.${i}.cityList`)}
                            id={`executor.${i}.city`}
                            data-testid={`executor.${i}.city`}
                            onChange={handleChangeField}
                          >
                            <option value="" hidden></option>
                            {citiesArray?.length > 0 &&
                              citiesArray.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors?.executor?.[i]?.cityList?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                      {citiesArray?.length === 0 && (
                        <>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="text"
                            // name="city"
                            autoComplete="off"
                            {...register(`executor.${i}.city`)}
                            isInvalid={!!errors?.executor?.[i]?.city}
                            disabled
                            data-testid={`executor.${i}.city`}
                            id={`executor.${i}.city`}
                            onChange={handleChangeField}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors?.executor?.[i]?.city?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor={`executor.${i}.state`}
                        className="required mb-0"
                      >
                        {clientPlaceholder.state}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name={`executor.${i}.state`}
                        autoComplete="off"
                        {...register(`executor.${i}.state`)}
                        isInvalid={!!errors?.executor?.[i]?.state}
                        data-testid={`executor.${i}.state`}
                        id={`executor.${i}.state`}
                        onChange={handleChangeField}
                        disabled
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors?.executor?.[i]?.state?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor={`executor.${i}.country`}
                        className="required mb-0"
                      >
                        {clientPlaceholder.country}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name={`executor.${i}.country`}
                        autoComplete="off"
                        {...register(`executor.${i}.country`)}
                        isInvalid={!!errors?.executor?.[i]?.country}
                        data-testid={`executor.${i}.country`}
                        id={`executor.${i}.country`}
                        onChange={handleChangeField}
                        disabled
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors?.executor?.[i]?.country?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              {i === 0 && fields.length > 1 && (
                <>
                  <div className="divider  position-absolute d-flex justify-content-between pb-2">
                    <span className="label-text ps-0 ps-lg-4 ps-md-4 pd-left">
                      Co-Executor
                    </span>
                    {((stackCount && stackCount?.DefaulExecutor && stackCount?.DefaulExecutor!==null && stackCount?.DefaulExecutor!==null && stackCount?.DefaulExecutor === 1) || 
                    (stackHolders && stackHolders?.executorList &&  (stackHolders?.executorList?.length<2))) ? (
                      <span
                        className="delete-icon pe-0 pe-lg-5 pe-md-5 "
                        onClick={() => removeMoreExecutor(i)}
                        data-testid="remove-executor-icon"
                      >
                        <DeleteIcon width={24} height={24} />
                      </span>
                    ):""}
                  </div>
                  <div className=""></div>
                </>
              )}
            </div>
          );
        })}
        {fields.length === 1 && (
          // ((stackHolders?.executorList && stackHolders?.executorList.length===0) || (!stackHolders?.executorList))  && fields.length === 1 && (
          <Row className="text-align-center">
            <Col lg={4} md={4} sm={6} className="mt-2">
              <Button
                type="button"
                className="custom-button btn-standard btn-update add-executor-button"
                data-testid="addmore-executor-button"
                ref={buttonRef}
                onClick={addMoreExecutor}
              >
                Add Co-Executor
              </Button>
            </Col>
            <Col lg={8} md={8} sm={6} className="mt-4"></Col>
          </Row>
        )}

        <div className="d-flex justify-content-center mt-lg-2">
          <Button
            type="submit"
            className="custom-button btn-standard btn-update mx-2 px-5"
            data-testid="next-executor-info-button"
            // onClick={nextStep}
          >
            Next
          </Button>
        </div>
      </Form>
      {(geoCodeLoading || geoCodeLoading2) && <Loader />}
      <ModalPopupType2
        setShowCallback={() => setConfirmModal(false)}
        content={optionalExeValidationMessage}
        showModal={showConfirmModal}
        setYesCallback={() => onConfirm(submittedData)}
      />
    </>
  );
}

export default ExecutorInformation;

import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
function NotificationWillUploadMsg({
  showModal,
  setShowCallback,
  content,
  headerTitle,
  setHideCallback,
  aiAPIStatus,
}) {
  const handleClose = () => setShowCallback(false);
  return (
    <>
      <Modal
        id="modal-popup-ai-will-upload-msg"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        data-testid="modal-popup-ai-will-upload-msg"
      >
        {aiAPIStatus === "success" || aiAPIStatus ===  "review"? 
        <Modal.Header className="modal-custom-header">
          <Modal.Title className="modal-custom-title">
            {headerTitle}
          </Modal.Title>
          </Modal.Header>
          :
          <Modal.Header className="modal-custom-header" closeButton>
            <Modal.Title className="modal-custom-title">
              {headerTitle}
            </Modal.Title>
          </Modal.Header>
          }
        <Modal.Body>
          <div className="modal-content-container text-center p-2">
            <div className="modal-content-section">{content}</div>
            <div className="modal-button-section mt-4">
              <Button
                onClick={handleClose}
                className="custom-popup-button btn-yes left-right-radius me-2"
              >
                {aiAPIStatus === "reject" ? "Re-Upload Will" : "Ok"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
NotificationWillUploadMsg.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
  headerTitle: PropTypes.string,
  content: PropTypes.node.isRequired,
  setHideCallback: PropTypes.func,
  aiAPIStatus:PropTypes.string
};
export default NotificationWillUploadMsg;

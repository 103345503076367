import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import UploadShortCertificate from "../bulkUpload/UploadShortCertificate";

function DragandDropShortCertificate({
  show,
  setShow,
  modalSize,
  role,
  type,
  onFileUploaded,
  onUploadSuccess
}) {
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal centered size={modalSize} show={show} onHide={handleClose}>
        <UploadShortCertificate
          role={role}
          type={type}
          onFileUploaded={onFileUploaded}
          onUploadSuccess={onUploadSuccess}
        />
      </Modal>
    </>
  );
}

export default DragandDropShortCertificate;

DragandDropShortCertificate.propTypes = {
  show: PropTypes.bool.isRequired,
  role: PropTypes.string,
  modalSize: PropTypes.string,
  type: PropTypes.string,
  onFileUploaded: PropTypes.func,
  onUploadSuccess: PropTypes.func,
};
import React from "react";
// import MemberForm from "../components/form/signup/MemberForm";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as WebLogo } from "../assets/images/MVS-logo-web-svg.svg";
// import { ReactComponent as ChangeAccount } from "../assets/images/left-arrow-change-account.svg";
import Header from "../components/headers/Header";
import VeteranForm from "../components/form/signup/VeteranForm";
// import { useNavigate } from "react-router-dom";
// import { signup } from "../constants/routes";

export default function SignupMember() {
  // const navigate = useNavigate();

  // const handleNavigate = () => {
  //   navigate(signup, {
  //     state: {
  //       from: "member",
  //     },
  //   });
  // };
  return (
    <>
      <Container
        className="signup-container-form"
        data-testid="signup-test-container"
        fluid
        style={{ padding: "0px" }}
      >
        <div>
          <div className="w-100 d-lg-none">
            <Header />
          </div>
          <Row>
            <Col lg={6} className="d-none d-lg-block p-0">
              <div className="login-web-image">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <WebLogo />
                </div>
              </div>
            </Col>
            <Col
              lg={6}
              xs={12}
              className="d-flex align-items-center justify-content-center p-0"
            >
              <div className="form-container mt-5 pt-3 mt-lg-0 pt-lg-0">
                {/* <div className="change-title mt-4" onClick={handleNavigate}>
                  <ChangeAccount />
                  <span className="ps-2"> Change account type</span>
                </div>
                <div className="choose-title mt-5">
                  <span className="pt-5">
                    {" "}
                    You have selected to sign up as a{" "}
                    <span className="highlight">Member</span>
                  </span>
                </div> */}
                <VeteranForm />
              </div>
            </Col>
          </Row>
          <div className="tick-login"></div>
        </div>
      </Container>
    </>
  );
}

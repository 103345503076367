import React, { useState, useRef, useEffect, useCallback } from "react";
import { Badge, Nav, Navbar, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as HeaderLogo } from "../../assets/images/mvs-logo.svg";
import {
  attorney_Changepassword,
  attorney_EditProfile,
  attorney_LandingPage,
  attorney_request_bank_details,
  attorney_uploadClientDocuments,
  attorney_view_share_bank_details,
  login,
} from "../../constants/routes";
import { ChevronDown, X } from "react-bootstrap-icons";
import usersIcon from "../../assets/images/users-icon.png";
import { ReactComponent as PasswordIcon } from "../../assets/images/change_pwd_icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout_icon.svg";
import { ReactComponent as UserProfile } from "../../assets/images/user-profile.svg";
import { ReactComponent as EditProfile } from "../../assets/images/edit-profile.svg";
import { ReactComponent as Notification } from "../../assets/images/BellSimple.svg";
import { ReactComponent as Notify } from "../../assets/images/notify-icon.svg";
import { ReactComponent as NotifyCross } from "../../assets/images/notify-cross-icon.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  myProfile,
  changePassword,
  logout,
  clientManagementLabel,
  editProfile,
  notification,
} from "../../constants/labels";
import {} from "../../constants/routes";
import { useSignoutsuperuserMutation } from "../../slices/loginAuthManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { signOutApiCall } from "../../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { ROLE } from "../../constants/role";
import AttorneyOffCanvasEditProfile from "../editProfileOffCanvas/AttorneyOffCanvasEditProfile";
import AttorneyNotificationCanvas from "../editProfileOffCanvas/NotificationCanvas";
import {
  useLazyGetNotificationStatusQuery,
  useUpdateNotificationStatusMutation,
} from "../../slices/userAuthManagementSlice";
import ReactGA from "react-ga4";
import { format } from "date-fns";
import {
  useDeleteNotificationsMutation,
  useGetNotificationsQuery,
  usePutNotificationsMutation,
} from "../../slices/notificationApiManagementSlice";
import { useLazyGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import {
  useLazyGetEmployeeQuery 
} from "../../slices/superAdminApiManagementSlice";
import ModalPopupType7 from "../modal/ModalPopupType7";
import { useLazyDownloadShareBankDetailsQuery } from "../../slices/wireTransferUploadApiManagementSlice";

import { shareDownloadMessage } from "../../constants/attorney/lables";
import { useLazyGetShareBankDetailsQuery } from "../../slices/wireTransferApiManagementSlice";
export default function HeaderProfileAttorney() {
  const dispatch = useDispatch();
  const [signoutsuperuser, { isLoading }] = useSignoutsuperuserMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const [collapse, setCollapese] = useState(false);
  const [show, setShow] = useState(false);
  const [activeNav, setActiveName] = useState("");
  const [showNotificationCanvas, setNotificationCanvas] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(true);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  let media = "lg";
  const location = useLocation();
  const [visible, showVisible] = useState(false);
  const [trigger] = useLazyGetNotificationStatusQuery();
  const [updateNotification, { isLoading: updateNotificationLoading }] =
    useUpdateNotificationStatusMutation();
  const notificationDropdownRef = useRef(null);
  const [notificationCollapse, setNotificationCollapse] = useState(false);
  const { data: notifications }= useGetNotificationsQuery(userInfo?.userId, {
    pollingInterval: 60000
  })
  const [updateNotifications, { isLoading: isUpdateNotificationsLoading }] =
    usePutNotificationsMutation();
  const [deleteNotifications, { isLoading: isDeleteNotificationsLoading }] =
    useDeleteNotificationsMutation();
  const [getClientData] = useLazyGetClientQuery();
  const [getEmployeeData]= useLazyGetEmployeeQuery();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [identifier, setIdentifier] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [getShareData, {isFetching: checkGetShareDataLoading , }] = useLazyGetShareBankDetailsQuery();
  const getNotification = useCallback(async () => {
    if (userInfo?.userId && userInfo?.userId !== undefined) {
      try {
        const res = await trigger(userInfo?.userId).unwrap();
        if (res?.isSuccessful) {
          setNotificationStatus(res?.bell);
        } else {
          toast.error(res?.message);
        }
      } catch (error) {
        toast.error(error?.errorMessage);
      }
    }
  }, [userInfo?.userId, trigger]);
  useEffect(() => {
    if (userInfo?.userId) {
      getNotification();
    }
  }, [
    userInfo?.userId,
    getNotification
  ]);
  const handleClose = () => showVisible(false);
  const handleNotificationClose = () => setNotificationCanvas(false);

  const ssoResponse =
    sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
  const isSSOLogin = ssoResponse
    ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
    : false;

  useEffect(() => {
      setActiveName("clientmanagement");
    // if (location?.pathname.includes("keymanagement")) {
    //   setActiveName("keymanagement");
    // } else if (location?.pathname.includes("accesscontrol")) {
    //   setActiveName("accesscontrol");
    // } else {
    //   setActiveName("clientmanagement");
    // }
  }, [location]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  });
  const handleClickListener = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCollapese(false);
    }
    if (
      notificationDropdownRef.current &&
      !notificationDropdownRef.current.contains(event.target)
    ) {
      setNotificationCollapse(false);
    }
  };
  const handleLogout = async () => {
    setCollapese(true);
    try {
      const res = await signoutsuperuser(userInfo?.accessToken).unwrap();
      dispatch(signOutApiCall());
      toast.success(res?.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleChangePassword = () => {
    setCollapese((collapse) => !collapse);
    navigate(attorney_Changepassword);
    ReactGA.event({
      category: `${ROLE.attorney} - 3 Level`,
      action: `Change Password - ${ROLE.attorney} - 3 Level`,
      label: `Change Password - ${ROLE.attorney} - 3 Level`,
    });
  };

  const handleEditProfile = (notification) => {
    ReactGA.event({
      category: `${ROLE.attorney} - 3 Level`,
      action: `Edit Profile - ${ROLE.attorney} - 3 Level`,
      label: `Edit Profile - ${ROLE.attorney} - 3 Level`,
    });
    if(notification){
      setCollapese(false);
    }
    if (window.innerWidth >= 768) {
      showVisible(true);
    } else {
      navigate(attorney_EditProfile, {
        state: {
          page: "attorneyPage",
          id: userInfo?.userId,
          pathname: location?.pathname,
          state: location?.state,
        },
      });
    }
  };

  const handleLogoNavigation = () => {
    const user = sessionStorage.getItem("userInfo");
    const userInfo = JSON.parse(user);
    if (userInfo?.role === ROLE.attorney) {
      navigate(attorney_LandingPage);
    }else{
      navigate(login);
    }
  };

  const handleNotification = () => {
    setCollapese((collapse) => !collapse);
    setNotificationCanvas(true);
    ReactGA.event({
      category: `${ROLE.attorney} - 3 Level`,
      action: `Change Notification - ${ROLE.attorney} - 3 Level`,
      label: `Change Notification - ${ROLE.attorney} - 3 Level`,
    });
  };

  const handleNotificationStatus = async (status) => {
    const transformData = {
      userId: userInfo?.userId,
      bell: status,
    };
    const data = JSON.stringify(transformData);
    try {
      if (notification !== status) {
        const res = await updateNotification(data).unwrap();
        if (res?.isSuccessful) {
          toast.success(
            status ? "Notification turned on" : "Notification turned off"
          );
          setNotificationCanvas(false);
          setNotificationStatus(status);
        } else {
          toast.error(res?.message);
        }
      }
    } catch (error) {
      toast.error(error?.errorMessage || error?.data?.message);
    }
  };
  const imageProfile = (
    <div
      className="custom-navbar-profile"
      data-testid="profile-image"
      onClick={() => setCollapese(!collapse)}
    >
      <UserProfile className="custom-navbar-img" />
    </div>
  );
  
  const [triggerDownload, { isLoading: sharebankDetailsDownload }] =
    useLazyDownloadShareBankDetailsQuery();

  const handleShareFileDownload = async () => {
    setShowDownloadModal(false);
    try {
      if (!identifier) throw new Error("Identifier is missing");
      const result = await triggerDownload({
        identifier: identifier,
        requestType,
      }).unwrap();
      const url = window.URL.createObjectURL(new Blob([result]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "Bank_details.csv";
      a.setAttribute("id", "download-sample");
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("File downloaded successfully");
    } catch (err) {
      toast.error(
        err?.data?.message ||
          err?.result?.message ||
          "Parameter identifier is invalid! expected type UUID"
      );
    }
  };

  const handleShareBankDetailsNoti = async (notification) => {
    if(notification){
      setCollapese(false);
    }  
    const apiUrl = notification[0]?.apiUrl;
    const urlObj = new URL(apiUrl);
    const queryParameters = new URLSearchParams(urlObj.search);
    const transactionId = queryParameters.get("transactionId");
    try {
      const result = await getShareData({transactionId: transactionId, userId: userInfo?.userId}).unwrap();
      if(result?.data !== null) {
        navigate(attorney_view_share_bank_details, {
          state: {
            page: "viewBankDetailNoti",
            id: {
              transactionId : transactionId,
            },
            userId: userInfo.userId
          },
        });
      } else {
        toast.error(result?.message);
      }
    } catch (error) {
      toast.error(error?.errorMessage || error?.data?.message);
    }
  };

  const handleNotificationClick = async (id) => {
    setNotificationCollapse(false)
    let notification = notifications.filter(
      (notification) => notification.notificationId === id
    )
    let type = notification[0].notificationType;
    let isRead = notification[0].seen;
    if (type === "WILL_UPLOAD") {
    //setReadDoc(isRead);
     try {
        const result = await getClientData(notification[0].createdBy).unwrap();
        if (result?.clientId) {
          navigate(attorney_uploadClientDocuments, {
            state: {
              doc: {
                userId: result?.clientId,
                rootDocId: "RD_001",
                rootDocName: "Wills & Estate Documents",
              },
              user: result,
              docType: notification[0].notification_message
            },
          });
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    }else if(type === "ATTORNEY_EDIT_BY_FIRM_ADMIN" || type === "ATTORNEY_ONBOARD_BY_FIRM_ADMIN"){
      try {
        const result = await getEmployeeData(userInfo?.userId).unwrap();
        if (result?.attorneyId) {
          handleEditProfile("notification");
        } else {
          toast.error(result?.message)
        }
      } catch (err) {
        toast.error(err?.message || err?.errorMessage)
      }
      
    } else if (type === "SHARE_BANK_DETAIL_BELL") {
      const apiUrl = notification[0]?.apiUrl;
      const urlObj = new URL(apiUrl);
      const queryParameters = new URLSearchParams(urlObj.search);
      const identifier = queryParameters.get("identifier");
      const requestType = queryParameters.get("requestType");
      setShowDownloadModal(true);
      setIdentifier(identifier);
      setRequestType(requestType);
    } else if ( type === "BANK_ACCOUNT_SHARE_BELL") {
      handleShareBankDetailsNoti(notification);
    } else if (type === "RECEIVE_BANK_DETAILS_BELL") {
      const apiUrl = notification[0]?.apiUrl;
      const urlObj = new URL(apiUrl);
      const queryParameters = new URLSearchParams(urlObj.search);
      const transactionId = queryParameters.get("transactionId");
      try {
        const shareResult = await getShareData({transactionId: transactionId, userId: userInfo?.userId}).unwrap();        
        if(shareResult?.data === null) {
          navigate(attorney_request_bank_details, {
            state: {
              id : notification,
              page: "requestBankDetails"
            }
          });
        } else {
          toast.error("Bank details already submitted.")
          navigate(attorney_request_bank_details, {
            state: {
              id : notification,
              page: "requestBankDetailsAddNoti",
              data: shareResult,
            }
          });
        }
      } catch (error) {
        toast.error(error?.errorMessage || error?.data?.message);
      }
    }
    if (!isRead) {
      try {
        await updateNotifications(id).unwrap();
      } catch (err) {
        toast.error(err?.errorMessage || err?.message);
      }
    }
  };
  const handleClearAll = async () => {
    let notificationids = [];
    notificationids = notifications.map(
      (notification) => notification.notificationId
    );
    try {
      const res = await deleteNotifications(notificationids).unwrap();
      if (res?.isSuccessful) {
        toast.success("Notification have been cleared");
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.errorMessage || err?.message);
    }
  };

  const activeNotifications =
    notifications?.length > 0 &&
    notifications.filter((notification) => notification.active);
  const unreadNotifications =
    notifications?.length > 0 &&
    notifications.filter(
      (notification) => !notification.seen && notification.active
    );
    return (
    <>
      <Navbar
        key={media}
        expand={media}
        data-bs-theme="dark"
        className={`custom-navbar profile align-items-center`}
        data-testid="header-profile"
      >
        <div className={"container-fluid px-lg-4"}>
          <div className="d-flex align-items-center">
            <Navbar.Toggle
              onClick={() => setShow(true)}
              aria-controls={`offcanvasNavbar-expand-${media}`}
              className="navbar-toggle-bg-icon p-0 me-2"
              data-testid="nav-bar-toggle"
            />
            <div
              onClick={() => handleLogoNavigation()}
              data-testid="header-profile-logo"
              className="cursor-point"
            >
              <HeaderLogo />
            </div>
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${media}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${media}`}
            placement="start"
            className="sidebar-mb"
            show={show}
            data-testid="nav-bar-offcanvas"
            onHide={() => setShow(false)}
          >
            <Offcanvas.Header className="header-line position-relative">
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${media}`}
                className="d-flex"
              >
                <HeaderLogo />
                <span
                  data-testid="offcanvas-close-icon"
                  onClick={() => setShow(false)}
                >
                  <X
                    size={28}
                    color="#fff"
                    className="position-absolute close-icon"
                  />
                </span>
              </Offcanvas.Title>
              <span className="offcanvas-header-pos"></span>
            </Offcanvas.Header>
            <Offcanvas.Body
              className="bg-color offcanvas-body-flex"
              data-testid="offcanvas-body-attorney"
            >
              <div>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link
                    as={Link}
                    to={attorney_LandingPage}
                    data-testid="firm-admin-emp-management-link"
                    onClick={() => {
                      setActiveName("clientmanagement");
                      setShow(false);
                    }}
                    className={`custom-nav-link pe-lg-3 ps-md-3 ${
                      activeNav === "clientmanagement" && "active"
                    }`}
                  >
                    <span className="txt-color">
                      <img src={usersIcon} height={24} width={24} alt="" />{" "}
                      {clientManagementLabel}
                    </span>
                  </Nav.Link>
                </Nav>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <div className="d-flex justify-content-center align-items-center">
            <div className="dropdown" ref={notificationDropdownRef}>
              {notificationStatus ? (
                <OverlayTrigger
                key="light"
                placement="bottom"
                trigger={["click", "focus", "hover"]}
                overlay={
                  <Tooltip className="tooltip custom-tooltip-notification" id="tooltip-bottom">
                    Check your notifications
                  </Tooltip>
                }
              >
                <div
                  className="pe-4 position-relative notification-container"
                  onClick={() =>
                    setNotificationCollapse(() => !notificationCollapse)
                  }
                  data-testid="notfication-openclose"
                >
                  <Notify className="bell-icon" />
                  <Badge
                    className="position-absolute custom-badge"
                    pill
                    bg="success badge-success-position"
                  >
                    {unreadNotifications?.length !== 0 ? 
                      unreadNotifications?.length > 9 ? `9+` : unreadNotifications.length
                    : null}
                  </Badge>
                </div>
                </OverlayTrigger>
              ) : (
                <div className="pe-4 position-relative notification-container">
                  <NotifyCross />
                </div>
              )}
              {/* <div className="dropdown" ref={notificationDropdownRef}> */}
              <div
                className={`dropdown-content custom-content dropdown-scroll`}
              >
                <ul
                  className={`${
                    !notificationCollapse ? "ul-hidden" : "ul-show"
                  }`}
                >
                  <li className="title-container sticky-header">
                    <div className="d-flex justify-content-between mx-2">
                      <span className="title">
                        Notifications{" "}
                        {unreadNotifications?.length > 0 &&
                          `(${unreadNotifications.length})`}
                      </span>
                      <span
                        className="clear-all"
                        onClick={handleClearAll}
                        hidden={!activeNotifications}
                        data-testid="clear-all"
                      >
                        Clear All
                      </span>
                    </div>
                  </li>
                  {activeNotifications &&
                    activeNotifications?.length > 0 ?
                    activeNotifications.map((notification,i) => {
                      return (<li
                        className="pd"
                        key={notification.notificationId}
                        onClick={() =>
                          handleNotificationClick(notification.notificationId)
                        }
                        data-testid={`notification-${i}`}
                      >
                        <div className="document-details d-flex flex-column mx-2">
                          <span
                            className={`document-type ${
                              notification.seen ? "" : "notification-unread"
                            }`}
                          >
                            
                            {notification.notification_message}
                          </span>
                          <span
                            className={`updated-time ${
                              notification.seen ? "" : "notification-unread"
                            }`}
                          >
                            on{" "}
                            {format(
                              notification.createdAt,
                              "do 'of' MMM h:mm a"
                            )}
                          </span>
                        </div>
                      </li>)
}):(
                      <div className="no-notifications">No notifications available</div>
                    )}
                </ul>
              </div>
            </div>
            <div className="dropdown" ref={dropdownRef}>
              <div>
                <span></span>
                <span></span>
              </div>
              <span className="cursor-point">
                {imageProfile}
                <ChevronDown
                  size={13}
                  className="dropbtn"
                  color="#fff"
                  data-testid="chevronDown-icon"
                  onClick={() => setCollapese(() => !collapse)}
                />
              </span>
              {
                <div className={`dropdown-content`}>
                  <ul className={`${!collapse ? "ul-hidden" : "ul-show"}`}>
                    <li className="disabled">
                      <span className="mx-2">{myProfile}</span>
                    </li>
                    {!isSSOLogin && (
                      <li
                        onClick={handleEditProfile}
                        data-testid="edit-profile"
                      >
                        <EditProfile />
                        <span className="mx-2">{editProfile}</span>
                      </li>
                    )}
                    {visible && !isSSOLogin && (
                      <AttorneyOffCanvasEditProfile
                        visible={visible}
                        onClose={handleClose}
                        state={{
                          page: "attorneyPage",
                          id: userInfo?.userId,
                        }}
                      />
                    )}
                    {!isSSOLogin && (
                      <li
                        onClick={handleChangePassword}
                        data-testid="change-password"
                      >
                        <PasswordIcon />
                        <span className="mx-2">{changePassword}</span>
                      </li>
                    )}
                    <li data-testid="notification" onClick={handleNotification}>
                      <Notification />
                      <span className="mx-2">{notification}</span>
                    </li>
                    <li onClick={handleLogout} data-testid="logout">
                      <LogoutIcon />
                      <span className="mx-2">{logout}</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
      </Navbar>
      <div id="attorney-notification-container">
        {showNotificationCanvas && (
          <AttorneyNotificationCanvas
            visible={showNotificationCanvas}
            onClose={handleNotificationClose}
            notification={notificationStatus}
            setUserNotification={handleNotificationStatus}
          />
        )}
      </div>
      {(isLoading || updateNotificationLoading || updateNotificationLoading || isUpdateNotificationsLoading || isDeleteNotificationsLoading || sharebankDetailsDownload || checkGetShareDataLoading) && <Loader />}
      <ModalPopupType7
        setShowCallback={handleShareFileDownload}
        setHideCallback={() => setShowDownloadModal(false)}
        content={shareDownloadMessage}
        showModal={showDownloadModal}
      />
    </>
  );
}

import { documentAiApiSlice } from "./documentAiApiSlice";
export const documentAiApiManagement = documentAiApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminApproval: builder.query({
      query: (userid) => ({
        url: `/admin-approval/status/${userid}`,
        method: "GET",
      }),
      //providesTags: ["getfoldersByid"],
    }),
  }),
});

export const { useLazyGetAdminApprovalQuery } = documentAiApiManagement;

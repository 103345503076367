import { Container } from "react-bootstrap";
import { ReactComponent as InfoIcon } from "../../assets/images/VectorInfo-blue.svg";
import { useNavigate } from "react-router-dom";
import { defaultRoute } from "../../constants/routes";
import { useAdminApprovalAiMutation } from "../../slices/aiDocumentManagementSlice";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";

export default function AdminVerifyPage() {
  const queryParameters = new URLSearchParams(window.location.search);
  const approvalId = queryParameters.get("approvalId");
  const action = queryParameters.get("type");
  const [adminVerify, { isLoading }] = useAdminApprovalAiMutation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const handleAdminVerify = async () => {
      if (approvalId) {
        try {
          const response = await adminVerify({
            data: { approvalId: approvalId, action: action },
          }).unwrap();
          setMessage(response?.message);
        } catch (err) {
          setMessage(err?.data?.message);
        }
      } else {
        navigate(defaultRoute);
      }
    };

    handleAdminVerify();
  }, [approvalId, action, adminVerify, navigate]);

  if (!approvalId || !action) {
    navigate(defaultRoute);
    return null;
  }

  return (
    <>
      <div
        className="attorney-client-title-page-container px-lg-4 px-xl-4"
        data-testid="death-claim-title"
      ></div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container px-3 px-lg-4 px-md-4 px-xl-4 py-4">
            <div className="deathclaim-message-container px-lg-2 px-md-2 px-sm-2 py-4">
              <span className="info-blue mx-3">
                <InfoIcon />
              </span>
              <div
                className="question-container"
                data-testid="deathclaim-view-page"
              >
                {message ? message : " "}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isLoading && <Loader />}
    </>
  );
}

import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL_USERSERVICE}/api/v1/login`,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set(
      "Access-Control-Allow-Methods",
      "GET, POST, OPTIONS, PUT, PATCH, DELETE"
    );
    headers.set(
      "Access-Control-Allow-Headers",
      "Origin, X-Requested-With, Content-Type, Accept"
    );
    headers.set("Access-Control-Allow-Credentials", "true");
    // const key = "67vh6h87-47a2-7568-c6gf-hg3vjh4rev7a";
    // if (key) {
    //   headers.set("key", key);
    // }
    return headers;
  },
});

export const userRegistrationApiSlice = createApi({
  reducerPath: "userRegistrationApiSlice",
  baseQuery: baseQuery,
  tagTypes: ["Auth"],
  endpoints: (builder) => ({}),
});

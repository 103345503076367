import React, { useState, useMemo, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import AgGrid from "../../components/table/AgGrid";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import { ReactComponent as EditIcon } from "../../assets/images/PencilSimple_1.svg";
import { client_bankinformation, client_LandingPage } from "../../constants/routes";
import { bankinformation } from "../../constants/attorney/lables";
import { ReactComponent as DownloadIcon } from "../../assets/images/DownloadSimple.svg";
import { clientTitle } from "../../constants/client/labels";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import BankInformationModal from "../../components/modal/BankInformationModal";
import { useCsvValidationMutation, useGetBankTransferDetailsQuery, useGetOwnBankDetailsQuery, useUpdateAmountMutation } from "../../slices/wireTransferApiManagementSlice";
import { useSelector } from "react-redux";
import { dateDMYSpaced } from "../../utils/dateFormat";
import Loader from "../../components/Loader/Loader";
import axios from "axios";


export default function ClientBankInformation() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [errorMessage, setErrorMessage] =  useState("")
  const [showModal, setShowModal] = useState(false)
  const { userInfo } = useSelector((state) => state.auth);
  const [bankDetails, setBankDetails] = useState([])
  const [rowData, setRowData] = useState([]);
  const [selectedBank, setSelectedBank] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [progressMap, setProgressMap] = useState({});

  const [items] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: clientTitle,
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: client_bankinformation,
      content: bankinformation,
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);

  const { data, isTableDataLoading, refetch } = useGetBankTransferDetailsQuery(userInfo?.userId, {
    refetchOnMountOrArgChange: true,
  });
  const { data: bankdata, isLoading: isBankDataloading } = useGetOwnBankDetailsQuery(userInfo?.userId, {
    refetchOnMountOrArgChange: true,
  });

  const [updateAmount, {isLoading: isUpdateLoading}] = useUpdateAmountMutation();
  const [validateCsv, {isLoading: isValidationPending}] =  useCsvValidationMutation();
  useEffect(() => {
    if (data) {
      const updatedRowData = data.data.map((item, index) => ({
        ...item,
        Id:index+1,
        name: `${item.firstName} ${item.lastName}`,
        lastUpdated: dateDMYSpaced(item.updatedAt),
        bankName: item.bankName !== null ? item.bankName : "NA"
      }));
      setRowData(updatedRowData);
    }
    if (bankdata?.data && bankdata?.data?.length > 0) {
      setBankDetails(bankdata?.data);
    }
  }, [data,bankdata]);

  const AmountCellRenderer = ({ value, data, api }) => {
    const onEditClick = () => {
      api.startEditingCell({
        rowIndex: data.Id - 1,
        colKey: "amountIn",
      });
    };
    const formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
    return (
      <div className="d-flex align-items-center">
        <span>{value === "NA" ? "N/A" : `${formattedAmount}`}</span>
        <div
          className="ms-3 pb-2 action-button ps-3"
          style={{ cursor: "pointer" }}
          onClick={onEditClick}
        >
          <EditIcon />
        </div>
      </div>
    );
  };

  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: false,
    resizable: false,
    wrapText: true,
    autoHeight: true,
    lockPosition: true,
    editable: false,
  }), []);

  const [columnDefs] = useState([
    {
      headerCheckboxSelection: true,
      checkboxSelection:true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      suppressSizeToFit: true,
    },
    { headerName: "S.No", field: "Id", width: 100, minWidth: 100, maxWidth: 100 },
    { headerName: "Name", field: "name", minWidth: 250 },
    { headerName: "Email", field: "email", minWidth: 250 },
    { headerName: "Amount in $", field: "amountIn", editable: true, cellRenderer: AmountCellRenderer, minWidth: 200 },
    { headerName: "Bank Name", field: "bankName", minWidth: 250 },
    {
      headerName: "Status", 
      field: "status", 
      minWidth: 200,
    },
    { headerName: "Last Updated", field: "lastUpdated", minWidth: 150 },
  ]);

  const paginationSizeLimit = 10;

  const { register, handleSubmit } = useForm();

  const onBankSelect = (e) => {
    const selectedBankName = e.target.value; 
    const selectedBank = bankDetails.find(bank => bank.bankName === selectedBankName);
    selectedBank?.bankDetailId ? setSelectedBank(selectedBank.bankDetailId) : setSelectedBank(null)
  };

  const onSelectionChanged = (event) => {
    let selectedRows = event.api.getSelectedRows();
    selectedRows =  selectedRows.map((item) => {
      return item.bankTransactionId
    })
    setErrorMessage("")
    setSelectedRows(selectedRows);
  };

  const handleCSVValidation = async() => {
    if(!selectedBank){
      setErrorMessage("Select a 'Bank Account' from the dropdown to continue")
      setShowModal(true)
    }
    else if(!selectedRows.length > 0){
      setErrorMessage("Please select at least one record to generate the CSV file")
      setShowModal(true)
    }
    else{
      try {
        const res = await validateCsv({
          user: {
            bankdetailId: selectedBank,
            userId: userInfo?.userId,
          },
          data: selectedRows,
        }).unwrap();
        if (res?.successful) {
          handleCSVGeneration()
        } 
      else{
        toast.error(res?.message)
      }
    } catch (err) {
      if(err.status === 500){
        if(err?.data?.message){
          setErrorMessage(err?.data?.message)
          setShowModal(true)
        }
        else{
          toast.error(err?.data?.message || err?.message || err?.error?.message || err?.error || err)        }
    }
    else{
      toast.error(err?.message || err?.error?.message || err?.error || err)
    }
    }
    }
  };
  
  const handleCSVGeneration = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/banking/csv/generation?fromBankDetailId=${selectedBank}&userId=${userInfo.userId}`;
  
    try {
      const token = userInfo?.accessToken;
      const response = await axios({
        url: baseUrl,
        method: "POST",
        responseType: "blob",
        headers: { 
          Authorization: "Bearer " + token,
          "Content-Type": "application/json" 
        },
        data: JSON.stringify(selectedRows), 
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;
          const docName = "File";
          if (totalLength !== null) {
            setIsLoading(true);
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
  
            setProgressMap((prevProgressMap) => ({
              ...prevProgressMap,
              [docName]: { progress, docName },
            }));
          }
        },
      });
  
      if (response.status === 200) {
        setIsLoading(true);
        const contentDisposition = decodeURIComponent(response?.headers['content-disposition']);
        let fileName = "Requested_bank_details.csv"; // Default filename
  
        // Extract filename from content-disposition if available
        if (contentDisposition && contentDisposition.includes('filename=')) {
          const matches = contentDisposition.match(/filename\*?=(?:UTF-8'')?"?([^"]+)"?/);
          if (matches && matches[1]) {
            fileName = matches[1];
          }
        }
  
        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: response.headers["content-type"], // Set the correct content type (CSV)
        });
  
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);
  
        // Create a download link and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
  
        window.URL.revokeObjectURL(url);
        setIsLoading(false);
        setProgressMap({});
        toast.success(`File has been downloaded successfully!`);
        refetch()
      }
    } catch (error) {
      setIsLoading(false);
      setProgressMap({});
      if (error?.response?.status === 401) {
        toast.error(`Error: Unauthorized access`);
      } else {
        console.error("Error downloading file:", error);
        toast.error(`File downloading failed. Try again!`);
      }
    }
  };
  const gridOptions = {
    suppressRowClickSelection: true,
    isRowSelectable: (node) => (node.data.status !== "CSV Generated" && node.data.status !== "Bank details requested"),
    
    onCellEditingStopped: (event) => {
      const updatedRow = event.data;
      const previousAmount = event.oldValue;      
      let amountIn = updatedRow.amountIn;
  
      if (isNaN(amountIn) || amountIn < 0 ) {
        updatedRow.amountIn = previousAmount; 
        event.api.refreshCells({ rowNodes: [event.node] }); 
        toast.error("Negative numbers are not allowed. Please enter a positive number");
      } else if(amountIn > 100000000) {
        updatedRow.amountIn = previousAmount; 
        event.api.refreshCells({ rowNodes: [event.node] });
        toast.error("Your amount must not exceed 100,000,000")
      }else {
        if (updatedRow.amountIn !== previousAmount) {
          updatedRow.amountIn = Math.floor(amountIn * 100) / 100;
          event.api.refreshCells({ rowNodes: [event.node] });
          onEditAmount(updatedRow, previousAmount); 
        }
      }
    },
  
    getRowStyle: (params) => {
      if (params.data.status === "CSV Generated" || params.data.status === "Bank details requested") {
        return {
          backgroundColor: "#f0f0f0", 
          opacity: "0.6", 
          pointerEvents: "none", 
        };
      } else {
        return {
          backgroundColor: "#fff",
          color: "black",
        };
      }
    }
  };
   const onEditAmount = async (row) => {
    let rowdata = {
        userId:userInfo.userId,
        transactionId: row.bankTransactionId,
        amount: row.amountIn,
      }
      try {
          const res = await updateAmount(rowdata).unwrap();
          if (res?.successful) {
            toast.success(res?.message)
          } 
        else{
          toast.error(res?.message)
        }
      } catch (err) {
        toast.error(err?.data?.message);
      }
    };

  return (
    <>
      <div className="superadmin-container view-people-info-container">
        <Container fluid className="px-lg-4">
          <div className="employee-title-page-container px-3 px-lg-4">
            <EmployeeTitleBar
              items={items}
              title={"Bank Information"}
              hasBackButton={false}
              hasButton={true}
              width={`172px`}
              buttonText={
                <div>
                  Download CSV
                  <DownloadIcon className="ms-2" style={{ height: "20px", width: "20px" }} />
                </div>
              }
              navigateTo={handleCSVValidation}
            />
          </div>
        </Container>
      </div>

      <div className="firm-page-outer-container">
        <Container fluid>
          <div className="firm-page-container px-2 px-lg-4">
            <div className="personal-use-doc-list px-2 px-lg-1 px-md-2 px-xl-2 pb-2 pt-3">
              <Row>
                <Col lg={12} xs={12}>
                  <Form onSubmit={handleSubmit(onBankSelect)} className="mb-3">
                  <Form.Group controlId="bankSelect">
                    <Row>
                      <Col xs={12} sm={8} md={6} lg={5} xl={5}>
                        <Form.Label className="ps-1"><b>Select Bank</b></Form.Label>
                        <Form.Select {...register("selectedBank")} defaultValue="" onChange={onBankSelect} className="custom-select-dropdown">
                          <option value="" disabled>Select Bank Account</option>
                          {bankDetails?.length !== 0 && bankDetails.map((bank, index) => (
                            <option key={bank.bankDetailId} value={bank.bankName}>
                              {bank.bankName} {bank.accountNumber}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                  </Form>
                  <AgGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    paginationSizeLimit={paginationSizeLimit}
                    rowSelection="multiple"
                    gridOptions={gridOptions}
                    onSelectionChanged={onSelectionChanged}
                    isBankInformation={true}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <div className="background-mvs-image type-1"></div>
      {showModal && (
        <BankInformationModal
          setShowCallback={() => setShowModal(false)}
          setOkCallback={() => setShowModal(false)}
          headerTitle={"Error"}
          singleOkButton={true}
          content={errorMessage}
          showModal={showModal}
        />
      )}
      {(isLoading || isTableDataLoading || isUpdateLoading || isBankDataloading || isValidationPending) &&  <Loader />}

      {Object.entries(progressMap).length > 0 &&  (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(([docId= "docId", { progress }]) => (
            <div key={docId} className="progress-bar-document">
              <div className="d-flex flex-column">
                <div>File downloading</div>
                <div className="d-flex align-items-center">
                  <progress value={progress} max={100} />{" "}
                  <div className="ps-2">{progress}%</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import {
  client_LandingPage,
  client_request_bank_details,
} from "../../constants/routes";
import { validation } from "../../constants/firm/validationsMessages";
import {
  emailRules,
  alphabetRules,
  usphonNumberRules,
  indphonNumberRules,
  phoneNumberRules,
} from "../../constants/regExp";
import { formPlaceholder } from "../../constants/firm/labels";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ModalPopupType6 from "../../components/modal/ModalPopupType6";
import { clientTitle, requestBankDetails } from "../../constants/client/labels";
import { countryCodeList } from "../../constants/labels";
import { useRequestBankDetailsMutation } from "../../slices/wireTransferApiManagementSlice";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";

export default function ClientRequestBankDetails() {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [requestData, setRequestData] = useState(null);

  const [items, setItems] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: clientTitle,
      active: false,
      state: { id: "", activeTab: "" },
    },
  ]);

  useEffect(() => {
    setItems([
      {
        id: "item-1",
        link: client_LandingPage,
        content: clientTitle,
        active: false,
      },
      {
        id: "item-2",
        link: client_request_bank_details,
        content: requestBankDetails,
        active: true,
      },
    ]);
  }, []);

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string()
      .trim()
      .matches(alphabetRules, `${validation.lastName.rule}`),
    email: yup
      .string()
      .trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [requestBank, { isLoading: requestBankLoading }] =
    useRequestBankDetailsMutation();

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
  };

  const onSubmit = async (formData) => {
    const transformData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailId: formData.email,
      phoneNumber: `+${formData.phone}`,
      userId: userInfo?.userId,
    };
    const data = JSON.stringify(transformData);
    setRequestData(data);
    setShowModal(true);
  };

  const handleCancelNavigation = () => {
    navigate(client_LandingPage);
  };

  const handleCancel = () => {
    setShowModal(false);
    navigate(client_LandingPage);
  };

  const handleBackNavigation = () => {
    navigate(client_LandingPage);
  };

  const handleShare = async (data) => {
    setShowModal(false);
    if (data) {
      const res = await requestBank(requestData).unwrap();
      if (res?.successful) {
        toast(
          <div>
            <div className="upload mt-2">{res?.message}</div>
          </div>,
          {
            autoClose: true,
            closeOnClick: false,
            theme: "light",
            closeButton: true,
            hideProgressBar: true,
            className: "custom-toast-video-sigining",
          }
        );
        navigate(client_LandingPage);
      }
    }
  };

  return (
    <>
      <div className="superadmin-container client-share-bank-details h-100">
        <Container fluid className="px-lg-4 pb-2 h-100">
          <div
            className="employee-title-page-container px-0 px-lg-4 px-md-4"
            data-testid="client-share-bank-details"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={requestBankDetails}
              hasBackButton={true}
              navigateToPrev={handleBackNavigation}
            />
          </div>
          <div className="firm-page-outer-container firm-page-add-outer-container">
            <div className="firm-page-container unset-height employee-form-container">
              <Form
                className="custom-form employee-form px-2 px-lg-4 px-md-4 type-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col className="custom-sub-title pt-2">
                    Please provide the details of the person to whose bank
                    details are being requested
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} className="mt-4">
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="ext-firstname"
                            className="required mb-0"
                          >
                            {formPlaceholder.firstName}
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            name="firstName"
                            autoComplete="off"
                            maxLength={30}
                            {...register("firstName")}
                            isInvalid={!!errors.firstName}
                            data-testid="request-firstname"
                            id="ext-firstname"
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.firstName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="ext-lastname"
                            className="mb-0"
                          >
                            {formPlaceholder.lastName}
                          </Form.Label>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="text"
                            name="lastName"
                            maxLength={30}
                            autoComplete="off"
                            {...register("lastName")}
                            isInvalid={!!errors.lastName}
                            data-testid="ext-lastname"
                            id="ext-lastname"
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.lastName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="ext-email" className="required mb-0">
                        {formPlaceholder.email}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="email"
                        name="email"
                        autoComplete="off"
                        {...register("email")}
                        isInvalid={!!errors.email}
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        data-testid="ext-email"
                        id="ext-email"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="" className="required mb-0">
                        {formPlaceholder.phone}
                      </Form.Label>
                      <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                          <div
                            className={
                              errors.phone
                                ? "phone-input-invalid-container"
                                : ""
                            }
                          >
                            <PhoneInput
                              {...field}
                              defaultCountry="us"
                              onlyCountries={countryCodeList}
                              placeholder=""
                              title=""
                              country={"us"}
                              inputProps={{
                                required: true,
                                autoFocus: false,
                                title: "",
                              }}
                              countryCodeEditable={false}
                              onChangeCapture={handleMaxNumber}
                              onChange={(value, phone) => {
                                setPhoneNumber(value);
                                if (
                                  phone.dialCode === "1" &&
                                  phone.countryCode === "us"
                                ) {
                                  setCountryCode("+1");
                                }
                                if (
                                  phone.dialCode === "91" &&
                                  phone.countryCode === "in"
                                ) {
                                  setCountryCode("+91");
                                }
                              }}
                            />
                          </div>
                        )}
                      />
                      <Form.Control
                        type="hidden"
                        autoComplete="off"
                        {...register("phone", {
                          required: true,
                        })}
                        isInvalid={!!errors.phone}
                        data-testid="request-phone"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.phone?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center mt-lg-2 mt-md-2">
                  <Button
                    className="custom-button btn-nobg mx-2"
                    data-testid="cancel-button"
                    onClick={handleCancelNavigation}
                  >
                    {formPlaceholder.cancelBtnText}
                  </Button>
                  <Button
                    type="submit"
                    className="custom-button btn-standard mx-2"
                    data-testid="submit-button"
                  >
                    {formPlaceholder.submitBtnText}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Container>
        <ModalPopupType6
          setShowCallback={handleCancel}
          setHideCallback={() => setShowModal(false)}
          // setYesCallback={handleConfirm}
          setYesCallback={handleShare}
          content={
            <div>
              You are about to request the bank details of{" "}
              {getValues("firstName")}{getValues("lastName") ? " " + getValues("lastName") : ""}. An email will be
              sent to the person on {getValues("email")} requesting the same.
              <br />
              <br />
              Do you wish to continue?
            </div>
          }
          showModal={showModal}
          buttonName={"Ok"}
        />
      </div>
      {requestBankLoading && <Loader />}
    </>
  );
}

export const dummyFolders = [
    {
      rootDocId: "RD_001",
      rootDocName: "Folder 1",
      rootDocDescription: "Folder 1",
      folderColor: "color-template-1",
      totalDocsByFolder: 0
    },
    {
      rootDocId: "RD_002",
      rootDocName: "Folder 1",
      rootDocDescription: "Folder 1",
      folderColor: "color-template-2",
      totalDocsByFolder: 0
    },
    {
      rootDocId: "RD_003",
      rootDocName: "Folder 2",
      rootDocDescription: "Folder 2",
      folderColor: "color-template-3",
      totalDocsByFolder: 0
    },
    {
      rootDocId: "RD_004",
      rootDocName: "Folder 3",
      rootDocDescription: "Folder 3",
      folderColor: "color-template-4",
      totalDocsByFolder: 0
    },
    {
      rootDocId: "RD_005",
      rootDocName: "Folder 4",
      rootDocDescription: "Folder 4",
      folderColor: "color-template-5",
      totalDocsByFolder: 0
    },
    {
      rootDocId: "RD_006",
      rootDocName: "Folder 5",
      rootDocDescription: "Folder 5",
      folderColor: "color-template-6",
      totalDocsByFolder: 0
    },
    {
      rootDocId: "RD_007",
      rootDocName: "Folder 6",
      rootDocDescription: "Folder 6",
      folderColor: "color-template-7",
      totalDocsByFolder: 0
    },
    {
      rootDocId: "RD_008",
      rootDocName: "Folder 7",
      rootDocDescription: "Folder 7",
      folderColor: "color-template-8",
      totalDocsByFolder: 0
    },
    {
      rootDocId: "RD_009",
      rootDocName: "Folder 8",
      rootDocDescription: "Folder 8",
      folderColor: "color-template-9",
      totalDocsByFolder: 0
    },
    {
      rootDocId: "RD_010",
      rootDocName: "Folder 10",
      rootDocDescription: "Folder 10",
      folderColor: "color-template-10",
      totalDocsByFolder: 0
    },
  ];
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import {
  addClient,
  attorneyAddClientTitle,
  attorneyDetails,
  cliManagement,
} from "../../constants/attorney/lables";
import {
  attorney_AddClient,
  attorney_LandingPage,
  attorney_client_addFamily,
} from "../../constants/routes";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { validation } from "../../constants/attorney/validationMessages";
import {
  phoneNumberRules,
  emailRules,
  zipRules,
  alphabetRules,
  indphonNumberRules,
  usphonNumberRules,
} from "../../constants/regExp";
import { clientPlaceholder } from "../../constants/attorney/lables";
import { useGetGeoCodesZipCodeQuery } from "../../slices/ssoManagementSlice";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";
import { setClientDetails } from "../../slices/attorneyAddClientSlice";
import utc from 'dayjs/plugin/utc';
import { countryCodeList } from "../../constants/labels";

dayjs.extend(utc);

export default function AttorneyAddClientPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.addClient);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [items] = useState([
    {
      id: "item-1",
      link: attorney_LandingPage,
      content: cliManagement,
      active: false,
      state: { id: userInfo?.firmTypeId },
    },
    {
      id: "item-2",
      link: attorney_AddClient,
      content: addClient,
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);

  const [cities, setCities] = useState([]);
  const today = dayjs();
  const yesterday = dayjs().year(today.year() - 100).startOf('year');
  const [selectedDate, setSelectedDate] = useState(null);

  const schema = yup.object().shape({
    firstName: yup
      .string().trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string().trim()
      .required(`${validation.lastName.required}`)
      .matches(alphabetRules, `${validation.lastName.rule}`),
    maidenName: yup
      .string().trim()
      .required(`${validation.maidenName.required}`),
    email: yup
      .string().trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
    dob: yup
      .string()
      .required(`${validation.dob.required}`)
      .test("valid-date", "Invalid date of birth", (value) => {
        if (!value) return true;
        return !isNaN(Date.parse(value));
      })
      .test("max-date", "Date of birth cannot be in the future", (value) => {
        if (!value) return true;
        const newtoday = dayjs().utc();
        const dob = dayjs(value).utc();
        return dob <= newtoday;
      //  let newtoday = new Intl.DateTimeFormat('en-US').format(today);
      //   // console.log(today.toLocaleDateString())
      //   newtoday = dayjs(newtoday).utc()
      //   console.log(dayjs(newtoday).utc())   

        // let newdob = new Intl.DateTimeFormat('en-US').format(dob);
        // newdob = dayjs(newdob).utc()
      })
      .test(
        "min-date",
        "Date of birth should be after January 1, 1924",
        (value) => {
          if (!value) return true;
          // const today = dayjs().utc();
          const minDate = dayjs().year(1924).startOf('year');
          const dob = dayjs(value).utc();
          return dob >= minDate;
        }
      ),
    addressLine1: yup.string().trim().required(`${validation.addressLine1.required}`),
    addressLine2: yup.string().trim(),
    zip: yup
      .string().trim()
      .matches(zipRules, { message: `${validation.zip.zipRules}` }),
    state: yup.string().required(`${validation.state.required}`),
    country: yup.string().required(`${validation.country.required}`),
    cityList: yup.mixed().when("city", {
      is: null,
      then: () =>
        yup
          .string()
          .notOneOf([""], `${validation.cityList.required}`)
          .required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    city: yup.string().required(`${validation.city.required}`).nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  useEffect(() => {
    if (Object.keys(client).length > 0) {
      if (client && client?.phone) {
        if (client?.phone?.length === 10) {
          setCountryCode("+1");
          setPhoneNumber(`1${client?.phone}`);
        } else {
          const firstDigit = client?.phone.charAt(1);
          const lasttendigit = client?.phone.slice(-10);
          if (firstDigit === "1") {
            setCountryCode("+1");
            setPhoneNumber(`1${lasttendigit}`);
          } else {
            setCountryCode("+91");
            setPhoneNumber(`91${lasttendigit}`);
          }
        }
      }
    }
  }, [client]);

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  useEffect(() => {
    if (Object.keys(client).length > 0) {
      setValue("firstName", client?.firstName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("lastName", client?.lastName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("maidenName", client?.maidenName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("email", client?.email, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setSelectedDate(dayjs(client?.dateOfBirth));
      setValue("dob", dayjs(client?.dateOfBirth));
      setValue("addressLine1", client?.address?.addressLine1, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("addressLine2", client?.address?.addressLine2, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("zip", client?.address?.zip, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("city", client?.address?.city, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("state", client?.address?.state, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("country", client?.address?.country, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [client, setValue]);

  const handleDateChange = (date) => {
    // const adjustedDate = new Date(date);
    // adjustedDate.setHours(0, 0, 0, 0);
    // const onSelectDate = adjustedDate.toUTCString();
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    setValue("dob", formattedDate, { shouldValidate: true });
  };


  const { data: geoCodeData, isLoading: geoCodeLoading } =
    useGetGeoCodesZipCodeQuery(zipCode, {
      skip: zipCode?.length !== 5,
    });

    useEffect(() => {
      const data = geoCodeData;
      if (data && data?.status === "OK") {
        let usResult = data?.results.find((result) => {
          let country = result.address_components.find((ac) =>
            ac.types.includes("country")
          )?.long_name;
          return (
            country === "United States" ||
            country === "Puerto Rico" ||
            country === "U.S. Virgin Islands" ||
            country === "American Samoa" ||
            country === "Northern Mariana Islands" ||
            country === "Guam"
          );
        });
  
        let multipleCities = usResult?.postcode_localities;
  
        let city = usResult?.address_components.filter(
          (ac) => ~ac.types.indexOf("locality")
        )[0]?.long_name;

        let city1 = usResult?.address_components.filter(
          (ac) => ~ac.types.indexOf("sublocality")
        )[0]?.long_name;
  
        let city2 = usResult?.address_components.filter(
          (ac) => ~ac.types.indexOf("neighborhood")
        )[0]?.long_name;
  
        let city3 = usResult?.address_components.filter(
          (ac) => ~ac.types.indexOf("administrative_area_level_3")
        )[0]?.long_name;
  
        let city4 = usResult?.address_components.filter(
          (ac) => ~ac.types.indexOf("administrative_area_level_2")
        )[0]?.long_name;
  
        let state = usResult?.address_components.filter(
          (ac) => ~ac.types.indexOf("administrative_area_level_1")
        )[0]?.long_name;
  
        let country = usResult?.address_components.filter(
          (ac) => ~ac.types.indexOf("country")
        )[0]?.long_name;
  
        if (country === "United States") {
          let allCities = [
            city,
            city1,
            city2,
            city3,
            city4,
            ...(multipleCities || []).flatMap((city) =>
              city.split(",").map((c) => c.trim())
            ),
          ];
  
          allCities = allCities.filter(Boolean);
  
          allCities = [...new Set(allCities)];
  
          allCities.sort((a, b) => a.localeCompare(b));
  
          if (allCities && allCities?.length === 1) {
            setCities([]);
            const filteredCity = allCities[0];
            setValueZip(filteredCity, state, country);
          } else if (allCities && allCities?.length > 0) {
            setCities(allCities);
            setValueZip(null, state, country);
          } else {
            setCities([]);
            setValueZip("", "", "");
            toast("Please provide the valid zip code");
          }
        }
  
        if (
          country === "Puerto Rico" ||
          country === "U.S. Virgin Islands" ||
          country === "American Samoa" ||
          country === "Northern Mariana Islands" ||
          country === "Guam"
        ) {
          let allCities = [
            city,
            city1,
            city2,
            city3,
            city4,
            state,
            ...(multipleCities || []).flatMap((city) =>
              city.split(",").map((c) => c.trim())
            ),
          ];
  
          allCities = allCities.filter(Boolean);
  
          allCities = [...new Set(allCities)];
  
          allCities.sort((a, b) => a.localeCompare(b));
  
          if (allCities && allCities?.length === 1) {
            setCities([]);
            const filteredCity = allCities[0];
            setValueZip(filteredCity, country, "United States");
          } else if (allCities && allCities?.length > 0) {
            setCities(allCities);
            setValueZip(null, country, "United States");
          } else {
            setCities([]);
            setValueZip("", "", "");
            toast("Please provide the valid zip code");
          }
        }
  
        if (
          country !== "United States" &&
          country !== "Puerto Rico" &&
          country !== "U.S. Virgin Islands" &&
          country !== "Northern Mariana Islands" &&
          country !== "Guam" &&
          country !== "American Samoa"
        ) {
          toast("Please provide the US zip code");
          setCities([]);
          setValueZip("", "", "");
        }
      } else {
        if (data !== undefined) {
          if (data && data?.status === "ZERO_RESULTS") {
            toast("Please provide the valid zip code");
            setCities([]);
            setValueZip("", "", "");
          } else {
            data?.error_message
              ? toast(`${data?.error_message}`)
              : toast("somthing went wrong");
            setCities([]);
            setValueZip("", "", "");
          }
        }
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoCodeData]);
  const setValueZip = (city, state, country) => {
    setValue("city", city, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue("state", state, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue("country", country, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };
  const onSubmit = async (formData) => {
    const transformData = {
      firmId: userInfo?.firmId,
      firmTypeId: userInfo?.firmTypeId,
      attorneyId: userInfo?.userId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: `+${formData.phone}`,
      dateOfBirth: selectedDate,
      maidenName: formData.maidenName,
      address: {
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2 || "",
        zip: formData.zip,
        city: formData.city === null ? formData.cityList : formData.city,
        state: formData.state,
        country: formData.country,
      },
    };
    dispatch(setClientDetails({ ...client, ...transformData }));
    navigate(attorney_client_addFamily, {
      state: {
        prevPage: "addClient",
      },
    });
  };
  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
    if (name === "zip") {
      event.target.value = value.slice(0, 5);
      setZipCode(event.target.value);
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };

  const handleCancelNavigation = () => {
    navigate(attorney_LandingPage);
  };

  return (
    <>
      <div className="superadmin-container attorney-edit-client-page">
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-0 px-lg-4 px-md-4"
            data-testid="sa-addemployee-page"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={attorneyAddClientTitle}
            />
          </div>
          <div className="firm-page-outer-container firm-page-add-outer-container">
            <div className="firm-page-container employee-form-container">
              <Form
                className="custom-form employee-form px-2 pb-3 px-lg-4 px-md-4 type-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col lg={12} md={12} className="mt-4">
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="cli-firstname"
                            className="required mb-0"
                          >
                            {clientPlaceholder.firstName}
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            name="firstName"
                            autoComplete="off"
                            maxLength={30}
                            {...register("firstName")}
                            isInvalid={!!errors.firstName}
                            data-testid="cli-firstname"
                            id="cli-firstname"
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.firstName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="cli-lastname"
                            className="required mb-0"
                          >
                            {clientPlaceholder.lastName}
                          </Form.Label>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="text"
                            name="lastName"
                            maxLength={30}
                            autoComplete="off"
                            {...register("lastName")}
                            isInvalid={!!errors.lastName}
                            data-testid="cli-lastname"
                            id="cli-lastname"
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.lastName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="cli-maidenName"
                      className="required mb-0"
                    >
                      {clientPlaceholder.mothersMaidenName}
                    </Form.Label>
                    <Form.Control
                      className="form-input input-border-radius-unset"
                      type="text"
                      name="maidenName"
                      maxLength={30}
                      autoComplete="off"
                      {...register("maidenName")}
                      isInvalid={!!errors.maidenName}
                      data-testid="cli-maidenName"
                      id="cli-maidenName"
                    />
                    <Form.Control.Feedback
                      data-testid="invalid-feedback"
                      type="invalid"
                    >
                      {errors.maidenName?.message}
                    </Form.Control.Feedback>
                  </Form.Group>                    
                  </Col>
                  <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                      <Form.Label htmlFor="" className="required mb-0">
                        {clientPlaceholder.dob}
                      </Form.Label>
                      <Controller
                        name="dob"
                        control={control}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div
                              className={
                                errors.dob
                                  ? "mui-date-picker-invalid-container"
                                  : "mui-date-picker-container"
                              }
                            >
                              <DatePicker
                                name="dob"
                                type="date"
                                value={
                                  dayjs(selectedDate).isValid()
                                    ? dayjs(selectedDate)
                                    : null
                                }
                                onChange={handleDateChange}
                                maxDate={today}
                                minDate={yesterday}
                              />
                            </div>
                          </LocalizationProvider>
                        )}
                      />
                      <Form.Control
                        type="hidden"
                        value={selectedDate || ""}
                        autoComplete="off"
                        {...register("dob", {
                          required: true,
                        })}
                        isInvalid={!!errors.dob}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.dob?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                      <Form.Label htmlFor="cli-email" className="required mb-0">
                        {clientPlaceholder.email}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="email"
                        name="email"
                        autoComplete="off"
                        {...register("email")}
                        isInvalid={!!errors.email}
                        onBlur = {(e) => e.target.value = e.target.value.trim()}
                        data-testid="cli-email"
                        id="cli-email"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                  </Col>
                  <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                      <Form.Label htmlFor="cli-phone" className="required mb-0">
                        {clientPlaceholder.phone}
                      </Form.Label>
                      <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                          <div
                            className={
                              errors.phone
                                ? "phone-input-invalid-container"
                                : ""
                            }
                          >
                            <PhoneInput
                              {...field}
                              defaultCountry="us"
                              onlyCountries={countryCodeList}
                              placeholder=""
                              title=""
                              country={"us"}
                              inputProps={{
                                required: true,
                                autoFocus: false,
                                title: "",
                              }}
                              countryCodeEditable={false}
                              onChangeCapture={handleMaxNumber}
                              onChange={(value, phone) => {
                                setPhoneNumber(value);
                                if (
                                  phone.dialCode === "1" &&
                                  phone.countryCode === "us"
                                ) {
                                  setCountryCode("+1");
                                }
                                if (
                                  phone.dialCode === "91" &&
                                  phone.countryCode === "in"
                                ) {
                                  setCountryCode("+91");
                                }
                              }}
                            />
                          </div>
                        )}
                      />
                      <Form.Control
                        className="form-input input-border-radius-unset input_number"
                        type="hidden"
                        name="phone"
                        onKeyDown={handleKeyPress}
                        onChangeCapture={handleMaxNumber}
                        autoComplete="off"
                        {...register("phone")}
                        isInvalid={!!errors.phone}
                        data-testid="cli-phone"
                        id="cli-phone"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.phone?.message}
                      </Form.Control.Feedback>
                    </Form.Group>                    
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="cli-addressLine1"
                        className="required mb-0"
                      >
                        {clientPlaceholder.addressLine1}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="addressLine1"
                        maxLength={255}
                        autoComplete="off"
                        {...register("addressLine1")}
                        isInvalid={!!errors.addressLine1}
                        data-testid="cli-addressLine1"
                        id="cli-addressLine1"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.addressLine1?.message}
                      </Form.Control.Feedback>
                    </Form.Group>                   
                  </Col>
                  <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="cli-addressLine2"
                        className="form-label mb-0"
                      >
                        {clientPlaceholder.addressLine2}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="addressLine2"
                        maxLength={255}
                        autoComplete="off"
                        {...register("addressLine2")}
                        isInvalid={!!errors.addressLine2}
                        data-testid="cli-addressLine2"
                        id="cli-addressLine2"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.addressLine2?.message}
                      </Form.Control.Feedback>
                    </Form.Group>                    
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                      <Form.Label htmlFor="cli-zip" className="required mb-0">
                        {clientPlaceholder.zip}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset input_number"
                        type="number"
                        name="zip"
                        autoComplete="off"
                        onKeyDown={handleKeyPress}
                        onChangeCapture={handleMaxNumber}
                        {...register("zip")}
                        isInvalid={!!errors.zip}
                        data-testid="cli-zip"
                        id="cli-zip"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.zip?.message}
                      </Form.Control.Feedback>
                    </Form.Group>                   
                  </Col>
                  <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                      <Form.Label htmlFor="cli-city" className="required mb-0">
                        {clientPlaceholder.cityList}
                      </Form.Label>
                      {cities?.length > 0 && (
                        <>
                          <Form.Select
                            aria-label="Default select example"
                            className="form-input input-border-radius-unset select-placeholder"
                            name="city"
                            isInvalid={!!errors.cityList}
                            {...register("cityList")}
                            id="cli-city"
                            data-testid="cli-cityList"
                          >
                            <option value="" hidden></option>
                            {cities?.length > 0 &&
                              cities.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.cityList?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                      {cities?.length === 0 && (
                        <>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="text"
                            // name="city"
                            autoComplete="off"
                            {...register("city")}
                            isInvalid={!!errors.city}
                            disabled
                            data-testid="cli-city"
                            id="cli-city"
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.city?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                    </Form.Group>                    
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                      <Form.Label htmlFor="cli-state" className="required mb-0">
                        {clientPlaceholder.state}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="state"
                        autoComplete="off"
                        {...register("state")}
                        isInvalid={!!errors.state}
                        data-testid="cli-state"
                        id="cli-state"
                        disabled
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.state?.message}
                      </Form.Control.Feedback>
                    </Form.Group>                    
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="cli-country"
                        className="required mb-0"
                      >
                        {clientPlaceholder.country}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="country"
                        autoComplete="off"
                        {...register("country")}
                        isInvalid={!!errors.country}
                        data-testid="cli-country"
                        id="cli-country"
                        disabled
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.country?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center mt-lg-2 mt-md-2">
                  <Button
                    className="custom-button btn-nobg mx-2 px-5"
                    data-testid="back-button"
                    onClick={handleCancelNavigation}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="custom-button btn-standard btn-update mx-2 px-5"
                    data-testid="submit-button"
                  >
                    {attorneyDetails.nextBtn}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Container>
      </div>
      {geoCodeLoading && <Loader />}
    </>
  );
}

import {  wireTransferAuthSlice} from "./wireTransferAuthSlice";

export const wireTransferAuthManagemementSlice =
wireTransferAuthSlice.injectEndpoints({
    endpoints: (builder) => ({
      bankOtpSend: builder.mutation({
        query: (data) => ({
          url: `/otp/send?identifier=${data.identifierID}&transactionId=${data.transactionId}&type=${data.requestType}`,
          method: "POST",
        }),
      }),
      bankSendOtpVerify: builder.mutation({
        query: (data) => ({
          url: `/otp/${data.identifierID}/verify?transactionId=${data.transactionId}&otp=${data.otp}`,
          method: "POST",
        }), 
      }),
      bankReceiveOtpVerify: builder.mutation({
        query: (data) => ({
          url: `/otp/verify?identifier=${data.identifierID}&transactionId=${data.transactionId}&otp=${data.otp}&type=RECEIVE`,
          method: "POST",
        }), 
      }),
      shareBankDetailsEmail: builder.mutation({
        query: ({ data, user }) => ({
          url: `/submit-bank-detail?transactionId=${user.transactionId}&key=${user.key}&identifier=${user.identifierID}`,
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }),
        invalidatesTags: ["getBankDetails"],
      }),
      checkBankDetails: builder.query({
        query: (data) => ({
          url: `/bank-detail?transactionId=${data.transactionId}&key=${data.key}&identifier=${data.identifierID}`,
          method: "GET",
        }),
        providesTags: ["getBankDetails"],
      }),
      checkEmailBankDetails: builder.query({
        query: (data) => ({
          url: `/accounts?transactionId=${data.transactionId}&key=${data.key}&identifier=${data.identifierID}`,
          method: "GET",
        }),
        providesTags: ["getBankDetails"],
      }),
    }),
  });

export const {
  useBankOtpSendMutation,
  useBankSendOtpVerifyMutation,
  useBankReceiveOtpVerifyMutation,
  useShareBankDetailsEmailMutation,
  useCheckBankDetailsQuery,
  useCheckEmailBankDetailsQuery,
  useLazyCheckEmailBankDetailsQuery,
} = wireTransferAuthManagemementSlice;

import { userRegistrationApiSlice } from "./userRegistrationApiSlice";

export const userRegistrationApiManagemnetSlice =
  userRegistrationApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getGeoCodesZipCode: builder.query({
        query: (zip) => ({
          url: "/geo-code",
          method: "GET",
          headers: {
            zipCode: `${zip}`,
            key: "1fg625dh-7d13-4h5d-7db8-65326a135284"
          },
        }),
      }),
      individualRegistarion: builder.mutation({
        query: (data) => ({
          url: "/individual",
          method: "POST",
          body: data,
        }),
      }),
    }),
  });

export const { useGetGeoCodesZipCodeQuery, useIndividualRegistarionMutation } =
  userRegistrationApiManagemnetSlice;

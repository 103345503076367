import { Button, Modal, ButtonGroup } from "react-bootstrap";
import PropTypes from "prop-types";

function ClientSearchSuccessModal({
  show,
  setShow,
  setYesCallback,
  modalContentElement,
  isSuccess,
  animation,
  onClose,
  hasCloseButton,
  text
}) {

  const handleClose = () => {
    setShow(false);
    onClose()
  };

  const handleClickYes = () => setYesCallback(true);

  return (
    <div className="px-2">
      <Modal
        centered
        show={show}
        onHide={handleClose}
        animation={animation}
        backdrop="static"
        className="client-search-result-modal"
      >
        {hasCloseButton ? 
          <Modal.Header className="border-0" closeButton>
          <Modal.Title className="modal-custom-title px-2">
            Search Results
          </Modal.Title>
        </Modal.Header>
        :
        <Modal.Header className="border-0">
          <Modal.Title className="modal-custom-title px-2">
            Search Results
          </Modal.Title>
        </Modal.Header>
      }
      {isSuccess && <div className="divider-firm ms-2"></div>}
      <Modal.Body className={isSuccess ? "mt-4" : "mt-4 mb-2"}>
          <div>{modalContentElement}</div>
        </Modal.Body>
      {isSuccess ? (
          <Modal.Footer  className="border-0 d-block">
                <h6 className="mb-3 result-subtext">Are you here to report {text}'s passing?</h6>
                <div className="d-flex justify-content-center mt-4 mb-5">
                  <ButtonGroup>
                  <Button className="custom-button btn-standard" onClick={handleClickYes}>
                    Yes
                  </Button>
                  <Button variant="secondary" className="custom-button btn-nobg mb-2" onClick={handleClose}>
                    No
                  </Button>
                  </ButtonGroup>
                </div>
          </Modal.Footer>
        ):
        <div className="modal-footer border-0 d-flex justify-content-center mb-4">
                <div>
                  <Button className="custom-button btn-standard ok-button mb-3" onClick={handleClose}>
                    OK
                  </Button>
                </div>
          </div>}
      </Modal>
    </div>
  );
}

export default ClientSearchSuccessModal;

ClientSearchSuccessModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func,
  setYesCallback: PropTypes.func.isRequired,
  modalContentElement: PropTypes.object.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  animation: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  text: PropTypes.string.isRequired
};

import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { ReactComponent as HeaderLogo } from "../../assets/images/mvs-logo.svg";
import {
  famVault_wesbite,
} from "../../constants/routes";
import { ChevronDown } from "react-bootstrap-icons";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout_icon.svg";
import { ReactComponent as UserProfile } from "../../assets/images/user-profile.svg";
import { logout } from "../../constants/labels";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setEmptyDetails } from "../../slices/shareReceiveBankDetailsSlice";
import axios from "axios";


export default function HeaderProfileWireTransfer() {
  const dispatch = useDispatch();
  const [collapse, setCollapese] = useState(false);
  const dropdownRef = useRef(null);
  let media = "lg";
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );
  const transactionId = useSelector(
    (state) => state.shareReceiveBankDetails.transactionId
  );
  useEffect(() => {
    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  });
  const handleClickListener = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCollapese(false);
    }
  };
  const handleLogout = async () => {
    setCollapese(true);
      if(transactionId && identifierID){
        try {
          const url = `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/transactions/logout?identifier=${identifierID}` 
          await axios({
            url: url,
            method: "GET",
          });
          toast.success("Signed out successfully...");
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
      }
      dispatch(setEmptyDetails())
      sessionStorage.clear();
      window.location.replace(famVault_wesbite);
  };

  const imageProfile = (
    <div
      className="custom-navbar-profile"
      data-testid="profile-image"
      onClick={() => setCollapese(!collapse)}
    >
      <UserProfile className="custom-navbar-img" />
    </div>
  );
  return (
    <>
      <Navbar
        key={media}
        expand={media}
        data-bs-theme="dark"
        className={`custom-navbar profile align-items-center`}
        data-testid="header-profile"
      >
        <div className={"container-fluid px-lg-4"}>
          <div className="d-flex align-items-center">
            <div
              className="cursor-point"
              data-testid="header-profile-logo"
            >
              <HeaderLogo />
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <div className="dropdown" ref={dropdownRef}>
              <span className="cursor-point">
                {imageProfile}
                <ChevronDown
                  size={13}
                  className="dropbtn"
                  color="#fff"
                  data-testid="chevronDown-icon"
                  onClick={() => setCollapese(() => !collapse)}
                />
              </span>
              {
                <div className={`dropdown-content`}>
                  <ul className={`${!collapse ? "ul-hidden" : "ul-show"}`}>
                    <li onClick={handleLogout} data-testid="logout">
                      <LogoutIcon />
                      <span className="mx-2">{logout}</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
}

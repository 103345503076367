export const clientTitle = "Command Center";
export const shareBkDetails = "Share Bank Details";
export const receivBkDetails = "Receive Bank Details";
export const shareFiles = "Share Files";
export const receiveFiles = "Receive Files";
export const shareReceiveBtn = "Exchange Hub";
export const shareBankDetails = "Share Bank Details";
export const clientModalMessage =
  "Please verify the person’s details before proceeding further to ensure complete security";
export const requestBankDetails = "Request Bank Details";
export const uploadAiDocs = "The document provided can be uploaded into one or the below document types"

export const personaluse =  "Personal Use";
export const bulkfileshare = "Bulk File Transfer"
export const dashboard = "Dashboard";
export const changeAttorneyLabels = {
  currentProfessional: "Current Professional",
  mobileNo: "Mobile phone",
  emailId: "Email",
  newAttorneyDetails: "New Professional Details",
  attorneyName: "Professional Name",
};
export const receivefilesNote = "A key feature of our platform allows members to grant temporary access to trusted professionals, like insurance agents, enabling them to upload relevant documents directly to your secure folder. This eliminates the hassle of collecting documents from multiple sources and adding them individually, ensuring all your important information is up-to-date and easily accessible when needed";
export const optionalExeValidationMessage = "Please note that the information required will be used to validate the identity of your executor. Without complete information, it may be difficult to validate the identity of your executor and delay their access to your information after you pass. You are able to update executor information at any time."
export const optionalWitValidationMessage = "Please note securing the contact information for the witnesses of your will may be crucial for your executor at the time of your passing. Please make an effort to secure that information and add it to your Command Center as soon as you can.";
export const optionalSuccValidationMessage = "Please note that the information required will be used to validate the identity of your sucessor. Without complete information, it may be difficult to validate the identity of your successor and delay their access to your information after you pass. You are able to update successor information at any time."
export const addPeopleCompleteMessage = "Kindly complete the process of uploading the Will and providing the required information for the individuals involved";
export const viewpageLabel = {
  name:"Name",
  dob:"DOB",
  email:"Email",
  mobilePhone:"Mobile Phone",  
  driversLicPassport: "Driver’s License / Passport",
  address:"Address",
  zip:"ZIP",
  city:"City",
  state:"State",
  country:"Country",
  executorfieldsError:"Executors Email, Mobile Phone or Driver’s License / Passport must not be the same"
}
export const editEmailMessage = "After changing your email address, you will be signed out of your account for security reasons. Please log in again using your new email address. ";
export const confirmationMessage = (mailid, name) => 
  `All the selected files/folders will be shared to ${mailid}. ${name} will need to enter an OTP sent to their mobile device to be able to access the shared documents.`;

export const bankDetailsLabel = {
  addBankDetails: "Add Bank Details",
  firstName: "First Name",
  lastName: "Last Name",
  save: "Save",
  update: "Update",
  cancel: "Cancel",
  routingNumber: "Routing Number",
  reRoutingNumber: "Re-enter Routing Number",
  bankName: "Bank Name",
  branchName: "Branch Name",
  accountType: "Account Type",
  accountNumber: "Account Number",
  reAccNumber: "Re-enter Account Number",
  swiftCode: "Swift Code",
  accountName: "Account Name",
  allAccounts: "All Accounts",
  addBankAccount: "Add Bank Account",
  bankDetails: "Bank Details",
  editBankDetails: "Edit Bank Details",
  deletePopup: "Are you sure you want to delete this bank account?",
  share: "Share",
  sendBankDetails: "Send Bank Details",
};
export const AILabel = {
  notificationLabel:"Notification",
  docUploadingMsg:"Analyzing the file, please wait!",
  uploadSuccessMsg:"The WILL has been uploaded successfully. Please review the details and update any missing information on the screen",
  adminApprovalReviewMsg:"The WILL has been sent for admin approval. Please allow 1-3 days for the review process. You will be notified via email once the review is complete, and you can proceed to the next step once it's verified by the admin"
}


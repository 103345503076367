import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setEmptyDetails } from "../../slices/shareReceiveBankDetailsSlice";
import { famVault_wesbite } from "../../constants/routes";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { useLazyCheckEmailBankDetailsQuery } from "../../slices/wireTransferAuthManagemementSlice";
import AddBankDetails from "../../components/WireTransfer/AddBankDetails";
import ClientRequestBankDetailsEmailTwoLevelTablePage from "./ClientRequestBankDetailsEmailTwoLevelTablePage";

export default function ClientTwoLevelEmailScenarioPage() {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isEmailPage = location?.state?.page === "requestBankDetailsEmail";
  const [componentToRender, setComponentToRender] = useState(null);

  const [getEmailBankData, { isFetching: checkGetShareDataLoading }] =
    useLazyCheckEmailBankDetailsQuery();

  let transactionId = isEmailPage && location?.state?.transactionId;
  const fromOtpPage = sessionStorage.getItem("fromOtpPage");

  const securityKey = location?.state?.securityKey;
  const identifierID = location?.state?.identifierID;

  const handleLogout = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/transactions/logout?identifier=${location?.state?.identifier}`;
      await axios.get(url);
      toast.success("Signed out successfully...");
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
    dispatch(setEmptyDetails());
    sessionStorage.clear();
    window.location.replace(famVault_wesbite);
  }, [dispatch, location?.state?.identifier]);

  useEffect(() => {
    if (userInfo === null && !location?.state) {
      window.location.href = famVault_wesbite;
    }
    if (
      location?.state?.page === "requestBankDetailsEmail" ||
      location?.state?.page === "viewBankDetailEmail"
    ) {
      if (fromOtpPage !== "Yes") {
        handleLogout();
      }
    }
  }, [location, navigate, userInfo, fromOtpPage, handleLogout]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const shareResult = await getEmailBankData({
          transactionId: transactionId,
          key: securityKey,
          identifierID: identifierID,
        }).unwrap();
        if (Array.isArray(shareResult?.data) && shareResult.data.length > 0) {
          // console.log("coming inside=======================");
          setComponentToRender("requestBankList");
        } else {
          // console.log("add coming inside=====================");
          setComponentToRender("addBankDetails");
        }
        // console.log(shareResult);
      } catch (error) {
        setComponentToRender("addBankDetails");
      }
    };

    if (transactionId) {
      fetchData();
    }
  }, [
    transactionId,
    userInfo,
    location?.state?.notification,
    identifierID,
    securityKey,
    navigate,
    getEmailBankData,
  ]);

  if (checkGetShareDataLoading) {
    return <Loader />;
  }

  return (
    <div
      className="view-people-info-container"
      data-testid="super-admin-render-page"
    >
      {componentToRender === "requestBankList" && (
        <ClientRequestBankDetailsEmailTwoLevelTablePage />
      )}
      {componentToRender === "addBankDetails" && <AddBankDetails />}
    </div>
  );
}

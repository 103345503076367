import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import {
  addClient,
  addFamily,
  attorneyAddFamilyTitle,
  cliManagement,
} from "../../constants/attorney/lables";
import {
  attoney_foldersPage,
  attorney_AddClient,
  attorney_LandingPage,
  attorney_client_addFamily,
} from "../../constants/routes";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { validation } from "../../constants/attorney/validationMessages";
import {
  phoneNumberRules,
  emailRules,
  alphabetRules,
  indphonNumberRules,
  usphonNumberRules,
} from "../../constants/regExp";
import { clientPlaceholder } from "../../constants/attorney/lables";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ModalPopupType3 from "../../components/modal/ModalPopupType3";
import PhoneInput from "react-phone-input-2";
import { clientRelationship } from "../../constants/attorney/mockData";
import { useAddPeopleMutation } from "../../slices/stackHolderApiManagmentSlice";
import { setFamilyDetails } from "../../slices/attorneyAddClientSlice";
import { useCreateClientMutation } from "../../slices/attorneyApiManagementSlice";
import utc from 'dayjs/plugin/utc';
import { countryCodeList } from "../../constants/labels";

dayjs.extend(utc);

export default function AttorneyClientAddFamily() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { family, client } = useSelector((state) => state.addClient);
  const [showModal, setShowModal] = useState(false);
  const [clientFormData, setClientFormData] = useState(null);
  const [familyFormData, setFamilyFormData] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: attorney_LandingPage,
      content: cliManagement,
      active: false,
      state: { id: userInfo?.firmTypeId },
    },
    {
      id: "item-2",
      link: attorney_client_addFamily,
      content: addFamily,
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);

  useEffect(() => {
    if (location?.state?.prevPage === "landingPage") {
      setItems([
        {
          id: "item-1",
          link: attorney_LandingPage,
          content: cliManagement,
          active: false,
          state: { id: userInfo?.firmTypeId },
        },
        {
          id: "item-2",
          link: attorney_client_addFamily,
          content: addFamily,
          active: true,
          state: { id: "", activeTab: "" },
        },
      ]);
    } else {
      setItems([
        {
          id: "item-1",
          link: attorney_LandingPage,
          content: cliManagement,
          active: false,
          state: { id: userInfo?.firmTypeId },
        },
        {
          id: "item-2",
          link: attorney_AddClient,
          content: addClient,
          active: false,
          state: { id: location?.state?.id },
        },
        {
          id: "item-3",
          link: attorney_client_addFamily,
          content: addFamily,
          active: true,
          state: { id: "", activeTab: "" },
        },
      ]);
    }
  }, [userInfo?.firmTypeId, location.state]);

  const today = dayjs();
  const yesterday = dayjs().year(today.year() - 100).startOf('year');
  const [selectedDate, setSelectedDate] = useState(null);

  const schema = yup.object().shape({
    firstName: yup
      .string().trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string().trim()
      .required(`${validation.lastName.required}`)
      .matches(alphabetRules, `${validation.lastName.rule}`),
    email: yup
      .string().trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
    dob: yup
      .string()
      .required(`${validation.dob.required}`)
      .test("valid-date", "Invalid date of birth", (value) => {
        if (!value) return true;
        return !isNaN(Date.parse(value));
      })
      .test("max-date", "Date of birth cannot be in the future", (value) => {
        if (!value) return true;
        const newtoday = dayjs().utc();
        const dob = dayjs(value).utc();
        return dob <= newtoday;
      //  let newtoday = new Intl.DateTimeFormat('en-US').format(today);
      //   // console.log(today.toLocaleDateString())
      //   newtoday = dayjs(newtoday).utc()
      //   console.log(dayjs(newtoday).utc())   

        // let newdob = new Intl.DateTimeFormat('en-US').format(dob);
        // newdob = dayjs(newdob).utc()
      })
      .test(
        "min-date",
        "Date of birth should be after January 1, 1924",
        (value) => {
          if (!value) return true;
          // const today = dayjs().utc();
          const minDate = dayjs().year(1924).startOf('year');
          const dob = dayjs(value).utc();
          return dob >= minDate;
        }
      ),
    relationship: yup
      .string()
      .notOneOf([""], `${validation.relationship.required}`)
      .required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const familyData = getValues();

  useEffect(() => {
    if (Object.keys(family).length > 0) {
      if (family && family?.phone) {
        if (family?.phone?.length === 10) {
          setCountryCode("+1");
          setPhoneNumber(`1${family?.phone}`);
        } else {
          const firstDigit = family?.phone.charAt(0);
          const lasttendigit = family?.phone.slice(-10);
          if (firstDigit === "1") {
            setCountryCode("+1");
            setPhoneNumber(`1${lasttendigit}`);
          } else {
            setCountryCode("+91");
            setPhoneNumber(`91${lasttendigit}`);
          }
        }
      }
    }
  }, [family]);

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  useEffect(() => {
    if (Object.keys(family).length > 0) {
      setValue("firstName", family?.firstName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("lastName", family?.lastName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("email", family?.email, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("relationship", family?.relationship);
      setSelectedDate(dayjs(family?.dob));
      setValue("dob", dayjs(family?.dob));
    }
  }, [family, setValue]);

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    setValue("dob", formattedDate, { shouldValidate: true });
  };

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  const clientTransformData = useMemo(
    () => ({
      firmId: client.firmId,
      firmTypeId: client?.firmTypeId,
      attorneyId: client?.attorneyId,
      firstName: client?.firstName,
      lastName: client?.lastName,
      email: client?.email,
      phone: client?.phone,
      dateOfBirth: client?.dateOfBirth,
      maidenName:client?.maidenName,
      address: {
        addressLine1: client?.address?.addressLine1,
        addressLine2: client?.address?.addressLine2 || "",
        zip: client?.address?.zip,
        city:
          client?.address?.city === null
            ? client?.address?.cityList
            : client?.address?.city,
        state: client?.address?.state,
        country: client?.address?.country,
      },
    }),
    [client]
  );

  useEffect(() => {
    setClientFormData(JSON.stringify(clientTransformData));
  }, [clientTransformData]);

  const [addPeople, { isLoading: addFamilyLoading }] = useAddPeopleMutation();

  const [createClient, { isLoading: createClientLoading }] =
    useCreateClientMutation();

  const onSubmit = async (formData) => {
    const familyTransformData = {
      familyMemberModel: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        emailId: formData.email,
        phoneNumber: `+${formData.phone}`,
        dateOfBirth: selectedDate,
        relationship: parseInt(formData.relationship),
      },
    };
    const data = JSON.stringify(familyTransformData);
    setFamilyFormData(data);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    if (location?.state?.prevPage === "landingPage") {
      setShowModal(false);
      if (familyFormData) {
        const confirmFamilyTransformData = {
          clientId: location?.state?.id,
          userId: userInfo?.userId,
          familyMember: {
            ...JSON.parse(familyFormData).familyMemberModel,
          },
        };
        const data = JSON.stringify(confirmFamilyTransformData);
        try {
          const res = await addPeople(data).unwrap();
          if (res?.isSuccessful) {
            toast.success(res?.message);
            navigate(attorney_LandingPage);
          } else {
            toast.error(res?.message);
          }
        } catch (err) {
          toast.error(err?.data?.message || err?.message);
          navigate(attorney_LandingPage);
        }
      }
    } else {
      setShowModal(false);

      if (clientFormData) {
        try {
          const confirmClientFamilyData = {
            ...JSON.parse(clientFormData),
            ...JSON.parse(familyFormData),
          };
          const data = JSON.stringify(confirmClientFamilyData);
          const res = await createClient(data).unwrap();
          if (res?.isSuccessful) {
            toast.success(res?.message);
            navigate(attoney_foldersPage, {
              state: {
                page: "addFamily",
                user: {
                  clientId: res?.userId,
                },
              },
            });
          } else {
            toast.error(res?.message);
          }
        } catch (err) {
          toast.error(err?.data?.message || err?.message);
          navigate(attorney_LandingPage);
        }
      }
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    navigate(attorney_LandingPage);
  };

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };

  const handleBackButton = () => {
    if (location?.state?.prevPage === "landingPage") {
      navigate(attorney_LandingPage);
    } else {
      dispatch(
        setFamilyDetails({
          ...family,
          ...familyData,
          firstName: getValues("firstName"),
          lastName: getValues("lastName"),
          email: getValues("email"),
          relationship: isNaN(parseInt(getValues("relationship")))
            ? ""
            : parseInt(getValues("relationship")),
          phone: getValues("phone"),
        })
      );
      navigate(attorney_AddClient);
    }
  };

  const skipButtonNavigation = async () => {
    let res;
    if (clientFormData) {
      try {
        res = await createClient(clientFormData).unwrap();
        if (res?.isSuccessful) {
          toast.success(res?.message);
          navigate(attoney_foldersPage, {
            state: {
              page: "addFamily",
              user: {
                clientId: res?.userId,
              },
            },
          });
        }else{
          toast.error(res?.message)
          navigate(attorney_LandingPage)
        }
      } catch (err) {
        toast.error(err?.data?.message || err?.message);
        navigate(attorney_LandingPage);
      }
    }
  };

  return (
    <>
      <div className="superadmin-container attorney-edit-client-page">
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-0 px-lg-4 px-md-4"
            data-testid="attorney-add-family-page"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={attorneyAddFamilyTitle}
            />
          </div>
          <div className="firm-page-outer-container firm-page-add-outer-container">
            <div className="firm-page-container employee-form-container">
              <Form
                className="custom-form employee-form px-2 pb-3 px-lg-4 px-md-4 pb-3 type-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col lg={12} md={12} className="mt-4">
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="family-firstname"
                            className="required mb-0"
                          >
                            {clientPlaceholder.firstName}
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            name="firstName"
                            autoComplete="off"
                            maxLength={30}
                            {...register("firstName")}
                            isInvalid={!!errors.firstName}
                            data-testid="family-firstname"
                            id="family-firstname"
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.firstName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="family-lastname"
                            className="required mb-0"
                          >
                            {clientPlaceholder.lastName}
                          </Form.Label>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="text"
                            name="lastName"
                            maxLength={30}
                            autoComplete="off"
                            {...register("lastName")}
                            isInvalid={!!errors.lastName}
                            data-testid="family-lastname"
                            id="family-lastname"
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.lastName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="" className="required mb-0">
                        {clientPlaceholder.dob}
                      </Form.Label>
                      <Controller
                        name="dob"
                        control={control}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div
                              className={
                                errors.dob
                                  ? "mui-date-picker-invalid-container"
                                  : "mui-date-picker-container"
                              }
                            >
                              <DatePicker
                                name="dob"
                                type="date"
                                value={
                                  dayjs(selectedDate).isValid()
                                    ? dayjs(selectedDate)
                                    : null
                                }
                                onChange={handleDateChange}
                                maxDate={today}
                                minDate={yesterday}
                                data-test="family-dob"
                              />
                            </div>
                          </LocalizationProvider>
                        )}
                      />
                      <Form.Control
                        type="hidden"
                        value={selectedDate || ""}
                        autoComplete="off"
                        {...register("dob", {
                          required: true,
                        })}
                        isInvalid={!!errors.dob}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.dob?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="family-relationship"
                        className="required mb-0"
                      >
                        {clientPlaceholder.relationship}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="form-input input-border-radius-unset select-placeholder"
                        name="city"
                        isInvalid={!!errors.relationship}
                        {...register("relationship")}
                        id="family-relationship"
                        data-testid="family-relationship"
                      >
                        <option value="" hidden></option>
                        {clientRelationship &&
                          clientRelationship.length > 0 &&
                          clientRelationship.map((relation, index) => {
                            return (
                              <option
                                key={"relation" + index}
                                value={relation.id}
                              >
                                {relation.label}
                              </option>
                            );
                          })}
                      </Form.Select>
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.relationship?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="family-email"
                        className="required mb-0"
                      >
                        {clientPlaceholder.email}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="email"
                        name="email"
                        autoComplete="off"
                        {...register("email")}
                        isInvalid={!!errors.email}
                        onBlur = {(e) => e.target.value = e.target.value.trim()}
                        data-testid="family-email"
                        id="family-email"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="family-phone"
                        className="required mb-0"
                      >
                        {clientPlaceholder.phone}
                      </Form.Label>
                      <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                          <div
                            className={
                              errors.phone
                                ? "phone-input-invalid-container"
                                : ""
                            }
                          >
                            <PhoneInput
                              {...field}
                              defaultCountry="us"
                              onlyCountries={countryCodeList}
                              placeholder=""
                              title=""
                              country={"us"}
                              inputProps={{
                                required: true,
                                autoFocus: false,
                                title: "",
                                name: "phone",
                              }}
                              value={phoneNumber}
                              countryCodeEditable={false}
                              onChangeCapture={handleMaxNumber}
                              onChange={(value, phone) => {
                                setPhoneNumber(value);
                                if (
                                  phone.dialCode === "1" &&
                                  phone.countryCode === "us"
                                ) {
                                  setCountryCode("+1");
                                }
                                if (
                                  phone.dialCode === "91" &&
                                  phone.countryCode === "in"
                                ) {
                                  setCountryCode("+91");
                                }
                              }}
                            />
                          </div>
                        )}
                      />
                      <Form.Control
                        className="form-input input-border-radius-unset input_number"
                        type="hidden"
                        name="phone"
                        autoComplete="off"
                        onKeyDown={handleKeyPress}
                        onChangeCapture={handleMaxNumber}
                        {...register("phone")}
                        isInvalid={!!errors.phone}
                        data-testid="family-phone"
                        id="family-phone"
                        value={phoneNumber}
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.phone?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-lg-none d-md-none d-sm-block d-md-block ">
                  <div className="d-flex justify-content-center pb-2 mt-lg-2">
                    {location?.state?.prevPage === "landingPage" ? null : (
                      <Button
                        className="custom-button btn-nobg mx-2 px-5"
                        data-testid="skip-button-dt"
                        onClick={skipButtonNavigation}
                      >
                        Skip
                      </Button>
                    )}
                  </div>
                  <div className="d-flex justify-content-center mt-lg-2">
                    <Button
                      className="custom-button btn-nobg mx-2 px-5"
                      data-testid="back-button"
                      onClick={handleBackButton}
                    >
                      Back
                    </Button>

                    <Button
                      type="submit"
                      className="custom-button btn-standard btn-update mx-2 px-5"
                      data-testid="next-button"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
                <div className="d-none d-sm-none d-md-block d-lg-block">
                  <div className="d-flex justify-content-center mt-lg-2">
                    <Button
                      className="custom-button btn-nobg mx-2 px-5"
                      data-testid="back-button-dt"
                      onClick={handleBackButton}
                    >
                      Back
                    </Button>
                    {location?.state?.prevPage === "landingPage" ? null : (
                      <Button
                        className="custom-button btn-nobg mx-2 px-5"
                        data-testid="skip-button-dt"
                        onClick={skipButtonNavigation}
                      >
                        Skip
                      </Button>
                    )}
                    <Button
                      type="submit"
                      className="custom-button btn-standard btn-update mx-2 px-5"
                      data-testid="next-button-dt"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Container>
        <ModalPopupType3
          setShowCallback={handleCancel}
          setHideCallback={() => setShowModal(false)}
          setYesCallback={handleConfirm}
          content={`Are you sure you want to add this Emergency Contact?`}
          showModal={showModal}
        />
      </div>
      {(addFamilyLoading || createClientLoading) && <Loader />}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import { addPeopleTitle } from "../../constants/attorney/lables";
import { optionalSuccValidationMessage } from "../../constants/client/labels";
import {
  veteran_LandingPage,
  veteran_uploadDocuments,
  veteran_addPeople,
} from "../../constants/routes";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { setStackHolderDetails } from "../../slices/stackHolderSlice";
import {
  phoneNumberRules,
  emailRules,
  zipRules,
  alphabetRules,
  indphonNumberRules,
  usphonNumberRules,
  idNumberRules,
} from "../../constants/regExp";
import { formType } from "../../constants/attorney/mockData";
import { validation } from "../../constants/attorney/validationMessages";
import { useGetGeoCodesZipCodeQuery } from "../../slices/ssoManagementSlice";
import { useAddPeopleMutation } from "../../slices/stackHolderApiManagmentSlice";
import { clientPlaceholder } from "../../constants/attorney/lables";
import PhoneInput from "react-phone-input-2";
import utc from "dayjs/plugin/utc";
import { clientTitle } from "../../constants/client/labels";
import { countryCodeList } from "../../constants/labels";
import ModalPopupType2 from "../../components/modal/ModalPopupType2";
dayjs.extend(utc);
export default function VeteranAddSuccessorPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const today = dayjs();
  const yesterday = dayjs()
    .year(today.year() - 100)
    .startOf("year");
  const [selectedDate, setSelectedDate] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [cities, setCities] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [submittedData, setSumbittedData] = useState(null);
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: veteran_LandingPage,
      content: "--",
      active: false,
      state: { user: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: veteran_uploadDocuments,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-3",
      link: veteran_addPeople,
      content: "--",
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);
  const { userInfo } = useSelector((state) => state.auth);
  const { stackHolders } = useSelector((state) => state.stackholder);
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string()
      .trim()
      .required(`${validation.lastName.required}`)
      .matches(alphabetRules, `${validation.lastName.rule}`),
    email: yup
      .string()
      .trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
    dob: yup
      .string()
      .notRequired()
      .test("valid-date", "Invalid date of birth", (value) => {
        if (!value) return true;
        return !isNaN(Date.parse(value));
      })
      .test("max-date", "Date of birth cannot be in the future", (value) => {
        if (!value) return true;
        const newtoday = dayjs().utc();
        const dob = dayjs(value).utc();
        return dob <= newtoday;
        //  let newtoday = new Intl.DateTimeFormat('en-US').format(today);
        //   // console.log(today.toLocaleDateString())
        //   newtoday = dayjs(newtoday).utc()
        //   console.log(dayjs(newtoday).utc())

        // let newdob = new Intl.DateTimeFormat('en-US').format(dob);
        // newdob = dayjs(newdob).utc()
      })
      .test(
        "min-date",
        "Date of birth should be after January 1, 1924",
        (value) => {
          if (!value) return true;
          // const today = dayjs().utc();
          const minDate = dayjs().year(1924).startOf("year");
          const dob = dayjs(value).utc();
          return dob >= minDate;
        }
      ),
    addressLine1: yup
      .string()
      .trim()
      .required(`${validation.addressLine1.required}`),
    addressLine2: yup.string().trim(),
    zip: yup
      .string()
      .trim()
      .matches(zipRules, { message: `${validation.zip.zipRules}` }),
    driversLicense: yup
      .string()
      .notRequired()
      .test("drivers_license", validation.driversLicense.rule, (value, ctx) => {
        if (!value) return true;
        if (!idNumberRules.test(value)) {
          return ctx.createError({
            message: validation.driversLicense.rule,
          });
        }
        return true;
      }),
    state: yup.string().required(`${validation.state.required}`),
    country: yup.string().required(`${validation.country.required}`),
    cityList: yup.mixed().when("city", {
      is: null,
      then: () =>
        yup
          .string()
          .notOneOf([""], `${validation.cityList.required}`)
          .required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    city: yup.string().required(`${validation.city.required}`).nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });
  const { data: geoCodeData, isLoading: geoCodeLoading } =
    useGetGeoCodesZipCodeQuery(zipCode, {
      skip: zipCode?.length !== 5,
    });
  const [addPeople] = useAddPeopleMutation();
  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);
  useEffect(() => {
    const data = geoCodeData;
    if (data && data?.status === "OK") {
      let usResult = data?.results.find((result) => {
        let country = result.address_components.find((ac) =>
          ac.types.includes("country")
        )?.long_name;
        return (
          country === "United States" ||
          country === "Puerto Rico" ||
          country === "U.S. Virgin Islands" ||
          country === "American Samoa" ||
          country === "Northern Mariana Islands" ||
          country === "Guam"
        );
      });

      let multipleCities = usResult?.postcode_localities;

      let city = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("locality")
      )[0]?.long_name;

      let city1 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("sublocality")
      )[0]?.long_name;

      let city2 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("neighborhood")
      )[0]?.long_name;

      let city3 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_3")
      )[0]?.long_name;

      let city4 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_2")
      )[0]?.long_name;

      let state = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_1")
      )[0]?.long_name;

      let country = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("country")
      )[0]?.long_name;

      if (country === "United States") {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, state, country);
        } else if (allCities && allCities?.length > 0) {
          setCities(allCities);
          setValueZip(null, state, country);
        } else {
          setCities([]);
          setValueZip("", "", "");
          toast("Please provide the valid zip code");
        }
      }

      if (
        country === "Puerto Rico" ||
        country === "U.S. Virgin Islands" ||
        country === "American Samoa" ||
        country === "Northern Mariana Islands" ||
        country === "Guam"
      ) {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          state,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, country, "United States");
        } else if (allCities && allCities?.length > 0) {
          setCities(allCities);
          setValueZip(null, country, "United States");
        } else {
          setCities([]);
          setValueZip("", "", "");
          toast("Please provide the valid zip code");
        }
      }

      if (
        country !== "United States" &&
        country !== "Puerto Rico" &&
        country !== "U.S. Virgin Islands" &&
        country !== "Northern Mariana Islands" &&
        country !== "Guam" &&
        country !== "American Samoa"
      ) {
        toast("Please provide the US zip code");
        setCities([]);
        setValueZip("", "", "");
      }
    } else {
      if (data !== undefined) {
        if (data && data?.status === "ZERO_RESULTS") {
          toast("Please provide the valid zip code");
          setCities([]);
          setValueZip("", "", "");
        } else {
          data?.error_message
            ? toast(`${data?.error_message}`)
            : toast("somthing went wrong");
          setCities([]);
          setValueZip("", "", "");
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoCodeData]);
  useEffect(() => {
    if (location?.state?.user) {
      setItems([
        {
          id: "item-1",
          link: veteran_LandingPage,
          content: clientTitle,
          active: false,
          state: { user: location?.state?.user },
        },
        {
          id: "item-2",
          link: veteran_uploadDocuments,
          content: location?.state?.doc?.rootDocName,
          active: false,
          state: { doc: location?.state?.doc, user: location?.state?.user },
        },
        {
          id: "item-3",
          link: veteran_addPeople,
          content: addPeopleTitle[1],
          active: true,
          state: { doc: location?.state?.doc, user: location?.state?.user },
        },
      ]);
    }
  }, [location?.state?.doc, location?.state?.user]);
  const setValueZip = (city, state, country) => {
    setValue("city", city, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue("state", state, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue("country", country, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };
  const handleDateChange = (date) => {
    if (date === null) {
      setSelectedDate(null);
      setValue("dob", null, { shouldValidate: true });
    } else {
      let changedDate = dayjs(date).format("YYYY-MM-DD");
      setSelectedDate(changedDate);
      setValue("dob", changedDate, { shouldValidate: true });
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      setTimeout(() => {
        setSelectedDate(null);
        setValue("dob", null, { shouldValidate: true });
      }, 0);
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };
  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
    if (name === "zip") {
      event.target.value = value.slice(0, 5);
      setZipCode(event.target.value);
    }
  };
  const onConfirm = async ({ data, transformData }) => {
    try {
      const res = await addPeople(data).unwrap();
      if (res?.isSuccessful) {
        toast.success(res?.message);
        dispatch(
          setStackHolderDetails({
            ...stackHolders,
            successor: { ...transformData.successor },
          })
        );
        navigate(veteran_uploadDocuments, {
          state: {
            doc: location?.state?.doc,
            user: location?.state?.user,
          },
        });
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(
        err?.data?.errorMessage || err?.errorMessage || err?.data?.message
      );
    }
  };
  const onSubmit = async (formData) => {
    let nonMandatoryFields = false;
    const transformData = {
      clientId: location?.state?.user?.clientId,
      userId: userInfo?.userId,
      type: formType.successor,
      successor: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        name: formData.firstName + " " + formData.lastName,
        emailId: formData.email,
        phoneNumber: `+${formData.phone}`,
        dateOfBirth: selectedDate,
        drivingLicense: formData.driversLicense ? formData.driversLicense : "",
        address: {
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2 || "",
          zip: formData.zip,
          city: formData.city !== null ? formData.city : formData.cityList,
          state: formData.state,
          country: formData.country,
        },
      },
    };
    const data = JSON.stringify(transformData);
    //nonMandatoryFields = formData.driversLicense === "" && true;
    if (
      selectedDate === undefined ||
      selectedDate === null ||
      formData.driversLicense === ""
    ) {
      nonMandatoryFields = true;
    }
    if (nonMandatoryFields) {
      setSumbittedData({
        data,
        transformData,
      });
      setConfirmModal(true);
    } else {
      onConfirm({
        data,
        transformData,
      });
    }
  };
  return (
    <>
      <div
        className="attorney-client-title-page-container px-1  px-lg-4 px-xl-4"
        data-testid="attonery-client-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={addPeopleTitle[1]}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container  px-2 px-lg-4 px-xl-4">
            <Form
              className="custom-form add-successor pb-3 type-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col lg={12} md={12} className="mt-4">
                  <Row>
                    <Col lg={6} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="successor-firstname"
                          className="required mb-0"
                        >
                          {clientPlaceholder.firstName}
                        </Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="firstName"
                          autoComplete="off"
                          maxLength={30}
                          {...register("firstName")}
                          isInvalid={!!errors.firstName}
                          data-testid="family-firstname"
                          id="successor-firstname"
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors.firstName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="successor-lastname"
                          className="required mb-0"
                        >
                          {clientPlaceholder.lastName}
                        </Form.Label>
                        <Form.Control
                          className="form-input input-border-radius-unset"
                          type="text"
                          name="lastName"
                          maxLength={30}
                          autoComplete="off"
                          {...register("lastName")}
                          isInvalid={!!errors.lastName}
                          data-testid="successor-lastname"
                          id="successor-lastname"
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors.lastName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="successor-email"
                      className="required mb-0"
                    >
                      {clientPlaceholder.email}
                    </Form.Label>
                    <Form.Control
                      className="form-input input-border-radius-unset"
                      type="email"
                      name="email"
                      autoComplete="off"
                      {...register("email")}
                      isInvalid={!!errors.email}
                      onBlur={(e) => (e.target.value = e.target.value.trim())}
                      data-testid="successor-email"
                      id="successor-email"
                    />
                    <Form.Control.Feedback
                      data-testid="invalid-feedback"
                      type="invalid"
                    >
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="successor-phone"
                      className="required mb-0"
                    >
                      {clientPlaceholder.phone}
                    </Form.Label>
                    <Controller
                      control={control}
                      name="phone"
                      render={({ field }) => (
                        <div
                          className={
                            errors.phone ? "phone-input-invalid-container" : ""
                          }
                        >
                          <PhoneInput
                            {...field}
                            defaultCountry="us"
                            onlyCountries={countryCodeList}
                            placeholder=""
                            title=""
                            country={"us"}
                            inputProps={{
                              required: true,
                              autoFocus: false,
                              title: "",
                            }}
                            countryCodeEditable={false}
                            onChangeCapture={handleMaxNumber}
                            onChange={(value, phone) => {
                              setPhoneNumber(value);
                              if (
                                phone.dialCode === "1" &&
                                phone.countryCode === "us"
                              ) {
                                setCountryCode("+1");
                              }
                              if (
                                phone.dialCode === "91" &&
                                phone.countryCode === "in"
                              ) {
                                setCountryCode("+91");
                              }
                            }}
                          />
                        </div>
                      )}
                    />
                    <Form.Control
                      className="form-input input-border-radius-unset input_number"
                      type="hidden"
                      name="phone"
                      autoComplete="off"
                      onKeyDown={handleKeyPress}
                      onChangeCapture={handleMaxNumber}
                      {...register("phone")}
                      isInvalid={!!errors.phone}
                      data-testid="successor-phone"
                      id="successor-phone"
                    />
                    <Form.Control.Feedback
                      data-testid="invalid-feedback"
                      type="invalid"
                    >
                      {errors.phone?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="" className="mb-0">
                      {clientPlaceholder.dob}
                    </Form.Label>
                    <Controller
                      name="dob"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div
                            className={
                              errors.dob
                                ? "mui-date-picker-invalid-container"
                                : "mui-date-picker-container"
                            }
                          >
                            <DatePicker
                              name="dob"
                              type="date"
                              value={
                                dayjs(selectedDate).isValid()
                                  ? dayjs(selectedDate)
                                  : null
                              }
                              slotProps={{
                                field: { clearable: true },
                                textField: {
                                  onKeyDown: (e) => handleKeyDown(e),
                                },
                              }}
                              onChange={handleDateChange}
                              maxDate={today}
                              minDate={yesterday}
                              data-test="successor-dob"
                            />
                          </div>
                        </LocalizationProvider>
                      )}
                    />
                    <Form.Control
                      type="hidden"
                      value={selectedDate || ""}
                      autoComplete="off"
                      {...register("dob", {
                        required: true,
                      })}
                      isInvalid={!!errors.dob}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dob?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor={`successor-driversLicense`}
                      className="mb-0"
                    >
                      {clientPlaceholder.driversLicense}
                    </Form.Label>
                    <Form.Control
                      className="form-input"
                      type="text"
                      name={`driversLicense`}
                      autoComplete="off"
                      maxLength={20}
                      {...register(`driversLicense`)}
                      isInvalid={!!errors?.driversLicense}
                      data-testid={`successor-driversLicense`}
                      id={`successor-driversLicense`}
                      //onChange={handleChangeField}
                    />
                    <Form.Control.Feedback
                      data-testid="invalid-feedback"
                      type="invalid"
                    >
                      {errors?.driversLicense?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="successor-addressLine1"
                      className="required mb-0"
                    >
                      {clientPlaceholder.addressLine1}
                    </Form.Label>
                    <Form.Control
                      className="form-input input-border-radius-unset"
                      type="text"
                      name="addressLine1"
                      maxLength={255}
                      autoComplete="off"
                      {...register("addressLine1")}
                      isInvalid={!!errors?.addressLine1}
                      data-testid="successor-addressLine1"
                      id="successor-addressLine1"
                    />
                    <Form.Control.Feedback
                      data-testid="invalid-feedback"
                      type="invalid"
                    >
                      {errors?.addressLine1?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="successor-addressLine2"
                      className="form-label mb-0"
                    >
                      {clientPlaceholder.addressLine2}
                    </Form.Label>
                    <Form.Control
                      className="form-input input-border-radius-unset"
                      type="text"
                      name="successor-addressLine2"
                      maxLength={255}
                      autoComplete="off"
                      {...register("addressLine2")}
                      isInvalid={!!errors?.addressLine2}
                      data-testid="successor-addressLine2"
                      id="successor-addressLine2"
                    />
                    <Form.Control.Feedback
                      data-testid="invalid-feedback"
                      type="invalid"
                    >
                      {errors?.addressLine2?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="successor-zip"
                      className="required mb-0"
                    >
                      {clientPlaceholder.zip}
                    </Form.Label>
                    <Form.Control
                      className="form-input input-border-radius-unset input_number"
                      type="number"
                      name="zip"
                      autoComplete="off"
                      onKeyDown={handleKeyPress}
                      onChangeCapture={handleMaxNumber}
                      {...register("zip")}
                      isInvalid={!!errors?.zip}
                      data-testid="successor-zip"
                      id="successor-zip"
                    />
                    <Form.Control.Feedback
                      data-testid="invalid-feedback"
                      type="invalid"
                    >
                      {errors?.zip?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="successor-city"
                      className="required mb-0"
                    >
                      {clientPlaceholder.cityList}
                    </Form.Label>
                    {cities?.length > 0 && (
                      <>
                        <Form.Select
                          aria-label="Default select example"
                          className="form-input input-border-radius-unset select-placeholder"
                          name="city"
                          isInvalid={!!errors?.cityList}
                          {...register("cityList")}
                          id="successor-city"
                          data-testid="successor-city"
                        >
                          <option value="" hidden></option>
                          {cities?.length > 0 &&
                            cities.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors?.cityList?.message}
                        </Form.Control.Feedback>
                      </>
                    )}
                    {cities?.length === 0 && (
                      <>
                        <Form.Control
                          className="form-input input-border-radius-unset"
                          type="text"
                          // name="city"
                          autoComplete="off"
                          {...register("city")}
                          isInvalid={!!errors?.city}
                          disabled
                          data-testid="successor-city"
                          id="successor-city"
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors?.city?.message}
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="successor-state"
                      className="required mb-0"
                    >
                      {clientPlaceholder.state}
                    </Form.Label>
                    <Form.Control
                      className="form-input input-border-radius-unset"
                      type="text"
                      name="successor-state"
                      autoComplete="off"
                      {...register("state")}
                      isInvalid={!!errors?.state}
                      data-testid="successor-state"
                      id="successor-state"
                      disabled
                    />
                    <Form.Control.Feedback
                      data-testid="invalid-feedback"
                      type="invalid"
                    >
                      {errors?.state?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="successor-country"
                      className="required mb-0"
                    >
                      {clientPlaceholder.country}
                    </Form.Label>
                    <Form.Control
                      className="form-input input-border-radius-unset"
                      type="text"
                      name="country"
                      autoComplete="off"
                      {...register("country")}
                      isInvalid={!!errors?.country}
                      data-testid="successor-country"
                      id="successor-country"
                      disabled
                    />
                    <Form.Control.Feedback
                      data-testid="invalid-feedback"
                      type="invalid"
                    >
                      {errors?.country?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-lg-2">
                <Button
                  type="submit"
                  className="custom-button btn-standard btn-update mx-2 px-5"
                  data-testid="next-button"
                  //onClick={nextStep}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Container>
      </div>
      {geoCodeLoading && <Loader />}
      <ModalPopupType2
        setShowCallback={() => setConfirmModal(false)}
        content={optionalSuccValidationMessage}
        showModal={showConfirmModal}
        setYesCallback={() => onConfirm(submittedData)}
      />
    </>
  );
}

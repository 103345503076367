import React, { useState, useRef, useEffect } from "react";
import { Navbar, Offcanvas } from "react-bootstrap";
import { ReactComponent as HeaderLogo } from "../../assets/images/mvs-logo.svg";
import {
  attorney_LandingPage,
  client_LandingPage,
  firmsuperadmin_LandingPage,
  login,
  veteran_Changepassword,
  veteran_EditComponent,
  veteran_LandingPage,
} from "../../constants/routes";
import { ChevronDown, X } from "react-bootstrap-icons";
import { ReactComponent as PasswordIcon } from "../../assets/images/change_pwd_icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout_icon.svg";
import { ReactComponent as UserProfile } from "../../assets/images/user-profile.svg";
import { ReactComponent as EditProfile } from "../../assets/images/edit-profile.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  myProfile,
  changePassword,
  logout,
  editProfile,
} from "../../constants/labels";
import { useSignoutsuperuserMutation } from "../../slices/loginAuthManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { signOutApiCall } from "../../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { ROLE } from "../../constants/role";
import ClientOffCanvasEditProfile from "../editProfileOffCanvas/ClientOffCanvasEditProfile";
import ClientChangeAttorney from "../editProfileOffCanvas/ClientChangeAttorneyCanvas";
import ModalPopup from "../../components/modal/ModalPopup";
import SuccessModalContent from "../../components/widgets/SuccessModalContent";
import { firmAttorneylabel } from "../../constants/firm/labels";
import { useLazyGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import ClientOffCanvasEditComponent from "../editProfileOffCanvas/ClientOffCanvasEditComponent";
import FamilyOffCanvasEditProfile from "../editProfileOffCanvas/FamilyOffCanvasEditProfile";
import FamilyOffCanvasAddProfile from "../editProfileOffCanvas/FamilyOffCanvasAddProfile";

export default function HeaderProfileVeteran() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [signoutsuperuser, { isLoading }] = useSignoutsuperuserMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const [collapse, setCollapese] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [visible, showVisible] = useState(false);
  const handleClose = () => showVisible(false);
  const [showChangeAttorney, setChangeAttorney] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const handleChangeAttorneyClose = () => setChangeAttorney(false);
  const [editClient, setEditClient] = useState(false);
  const [editFamily, setEditFamily] = useState(false);
  const [addFamily, setAddFamily] = useState(false);

  let media = "lg";
  const ssoResponse =
    sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
  const isSSOLogin = ssoResponse
    ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
    : false;

  const [getClientData] = useLazyGetClientQuery();

  useEffect(() => {
    getClientData(userInfo?.userId);
  }, [addFamily, editFamily, editClient, getClientData, userInfo]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  });

  const handleClickListener = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCollapese(false);
    }
  };
  const handleLogout = async () => {
    setCollapese(true);
    try {
      const res = await signoutsuperuser(userInfo?.accessToken).unwrap();
      dispatch(signOutApiCall());
      toast.success(res?.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleChangePassword = () => {
    setCollapese((collapse) => !collapse);
    navigate(veteran_Changepassword);
  };
  const handleEditProfile = (notification) => {
    if (notification) {
      setCollapese(false);
    }
    if (window.innerWidth >= 768) {
      showVisible(true);
    } else {
      navigate(veteran_EditComponent, {
        state: {
          page: "clientPage",
          id: userInfo?.userId,
          pathname: location?.pathname,
          state: location?.state,
        },
      });
    }
  };

  const handleLogoNavigation = () => {
    const user = sessionStorage.getItem("userInfo");
    const userInfo = JSON.parse(user);
    if (userInfo?.role === ROLE.firmsuperadmin) {
      navigate(firmsuperadmin_LandingPage);
    } else if (userInfo?.role === ROLE.attorney) {
      navigate(attorney_LandingPage);
    } else if (userInfo?.role === ROLE.client) {
      navigate(client_LandingPage);
    } else if (userInfo?.role === ROLE.individualClient) {
      navigate(veteran_LandingPage);
    } else {
      navigate(login);
    }
  };

  const handlePrevPage = () => {
    setSuccessModal(false);
  };
  const imageProfile = (
    <div
      className="custom-navbar-profile"
      data-testid="profile-image"
      onClick={() => setCollapese(!collapse)}
    >
      <UserProfile className="custom-navbar-img" />
    </div>
  );

  return (
    <>
      <Navbar
        key={media}
        expand={media}
        data-bs-theme="dark"
        className={`custom-navbar profile align-items-center`}
        data-testid="header-profile"
      >
        <div className={"container-fluid px-lg-4"}>
          <div className="d-flex align-items-center">
            <Navbar.Toggle
              onClick={() => setShow(true)}
              aria-controls={`offcanvasNavbar-expand-${media}`}
              className="navbar-toggle-bg-icon p-0 me-2"
              data-testid="nav-bar-toggle"
            />
            <div
              onClick={() => handleLogoNavigation()}
              data-testid="header-profile-logo"
              className="cursor-point"
            >
              <HeaderLogo />
            </div>
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${media}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${media}`}
            placement="start"
            className="sidebar-mb"
            show={show}
            data-testid="nav-bar-offcanvas"
            onHide={() => setShow(false)}
          >
            <Offcanvas.Header className="header-line position-relative">
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${media}`}
                className="d-flex"
              >
                <HeaderLogo />
                <span
                  data-testid="offcanvas-close-icon"
                  onClick={() => setShow(false)}
                >
                  <X
                    size={28}
                    color="#fff"
                    className="position-absolute close-icon"
                  />
                </span>
              </Offcanvas.Title>
              <span className="offcanvas-header-pos"></span>
            </Offcanvas.Header>
            <Offcanvas.Body
              className="bg-color offcanvas-body-flex"
              data-testid="offcanvas-body-attorney"
            ></Offcanvas.Body>
          </Navbar.Offcanvas>

          <div className="d-flex justify-content-center align-items-center">
            <div
              className="dropdown"
              data-testid="profile-ref"
              ref={dropdownRef}
            >
              <div>
                <span></span>
                <span></span>
              </div>
              <span className="cursor-point">
                {imageProfile}
                <ChevronDown
                  size={13}
                  className="dropbtn"
                  color="#fff"
                  data-testid="chevronDown-icon"
                  onClick={() => setCollapese(() => !collapse)}
                />
              </span>
              {
                <div className={`dropdown-content`}>
                  <ul className={`${!collapse ? "ul-hidden" : "ul-show"}`}>
                    <li className="disabled">
                      <span className="mx-2">{myProfile}</span>
                    </li>
                    {!isSSOLogin && (
                      <li
                        onClick={handleEditProfile}
                        data-testid="edit-profile"
                      >
                        <EditProfile />
                        <span className="mx-2">{editProfile}</span>
                      </li>
                    )}
                    {!isSSOLogin && (
                      <li
                        onClick={handleChangePassword}
                        data-testid="change-password"
                      >
                        <PasswordIcon />
                        <span className="mx-2">{changePassword}</span>
                      </li>
                    )}

                    <li onClick={handleLogout} data-testid="logout">
                      <LogoutIcon />
                      <span className="mx-2">{logout}</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
      </Navbar>
      {isLoading && <Loader />}
      {visible && (
        <ClientOffCanvasEditComponent
          visible={visible}
          onClose={handleClose}
          state={{
            page: "attorneyPage",
            id: userInfo?.userId,
          }}
          handleEditClient={() => setEditClient(true)}
          handleEditFamily={() => setEditFamily(true)}
          handleAddFamily={() => setAddFamily(true)}
        />
      )}

      {editClient && (
        <ClientOffCanvasEditProfile
          visible={editClient}
          onClose={() => setEditClient(false)}
        />
      )}
      {editFamily && (
        <FamilyOffCanvasEditProfile
          visible={editFamily}
          onClose={() => setEditFamily(false)}
        />
      )}
      {addFamily && (
        <FamilyOffCanvasAddProfile
          visible={addFamily}
          onClose={() => setAddFamily(false)}
        />
      )}

      <div id="client-change-attorney-container">
        {showChangeAttorney && (
          <ClientChangeAttorney
            visible={showChangeAttorney}
            onClose={handleChangeAttorneyClose}
            setSuccessModal={() => {
              setChangeAttorney(false);
              setSuccessModal(true);
            }}
          />
        )}
      </div>
      <ModalPopup
        show={showSuccessModal}
        setShow={() => setSuccessModal(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        modalClass="modal-container success-alert"
        titleClass="header-title"
        headerClass="no-border"
        modalContentElement={
          <SuccessModalContent text={firmAttorneylabel.changeRequest} />
        }
        hasFooter={false}
        footerClass="no-border pt-0"
        footerButtonText="Add"
        footerButtonClass="custom-button btn-standard btn-add-modal"
        onClickCloseNavigation={handlePrevPage}
        animation={false}
      />
    </>
  );
}

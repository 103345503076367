import React from "react";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import { ReactComponent as KeyboardLeftArrow } from "../../assets/images/keyboard_arrow_left.svg";

export default function ExecutorTitleBar({
  items,
  title,
  navigateToPrev,
  hasBackButton,
}) {

  return (
    <div className="d-flex justify-content-between align-items-center pb-2 flex-column flex-md-row flex-lg-row">
      <div className="pt-1 pb-1">
        {items !== false && <BreadCrumb items={items} />}
        {hasBackButton && (
          <span className="keyboard-left-arrow pe-2" onClick={navigateToPrev}>
            <KeyboardLeftArrow />
          </span>
        )}
        <span className="page-title">{title}</span>
      </div>
    </div>
  );
}

import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import axios from "axios";
import Header from "../../components/headers/Header";
import { ReactComponent as InfoIcon } from "../../assets/images/VectorInfo-blue.svg";

export default function AutoDownload() {
  const [isLoading, setIsLoading] = useState(false);
  const [progressMap, setProgressMap] = useState({});
  const [isDownloaded, setIsDownloaded] = useState(false)
  const [message, setMessage] = useState(false);
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );
    const effectRan = useRef(false);
  
  useEffect(() => {
    if (!effectRan.current) {
      downloadFile();
      effectRan.current = true;
    }
    return () => (effectRan.current = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const downloadFile = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v2/share/admin/${identifierID}/download`;

    try {
      const response = await axios({
        url: baseUrl,
        method: "GET",
        responseType: "blob",
        headers: { "Access-Control-Allow-Credentials": true },
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;
          const docName = "File";
          if (totalLength !== null && totalLength !== undefined && totalLength !== 1) {
            setIsLoading(true);
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
            if (progress === 100) {
                setTimeout(() => {
                    setIsDownloaded(true);
                }, 5000);
            }
            
            setProgressMap((prevProgressMap) => ({
              ...prevProgressMap,
              [docName]: { progress, docName },
            }));
          }
        },
      });
      if (response.status === 200) {
        setIsLoading(false);
        const contentDisposition = decodeURIComponent(response?.headers['content-disposition']);
        let fileName = "downloaded-file"; // Default filename
  
        // Extract filename from content-disposition if available
        if (contentDisposition && contentDisposition.includes('filename=')) {
          const matches = contentDisposition.match(/filename="(.+)"/);
          if (matches && matches[1]) {
            fileName = matches[1];
          }
        }

        const blob = new Blob([response.data], {
          type: response?.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        
        setMessage(`File has been downloaded successfully!`);
      }
    } catch (error) {
       setIsLoading(false);
    let errorMessage = "You don't have permission to view this page";

    if (error.response) {
        try {
            // Try to parse JSON error response
            const errorData = await error.response.data.text();
            const errorJson = JSON.parse(errorData);
            errorMessage = errorJson.message || errorMessage;
        } catch (jsonError) {
            // If parsing fails, fallback to plain text
            errorMessage = error.response.statusText || errorMessage;
        }
    }
    setMessage(errorMessage);
    }
  };

  return (
    <>
    <Header />
       <div
              className="attorney-client-title-page-container px-lg-4 px-xl-4"
              data-testid="death-claim-title"
            >
            </div>
            <div className="firm-page-outer-container px-lg-4 px-xl-4">
              <Container fluid className="deathclaim-page-container">
                <div className="firm-page-container px-3 px-lg-4 px-md-4 px-xl-4 py-4">
              <div
              hidden = {!message}
              className="deathclaim-message-container px-lg-3 px-md-3 px-sm-3 py-4"
                >
                <span className="info-blue mx-3 pe-4 pe-lg-1 pe-md-1">
                  <InfoIcon />
                </span>
              <div
                className="question-container"
                data-testid="deathclaim-view-page"
              >
                {message}
              </div>
              </div>
                {isLoading && <Loader />}
                {!isDownloaded && Object.entries(progressMap).length > 0 && progressMap.File.progress !== null && (
                  <div className="progress-doc-container">
                    {Object.entries(progressMap).map(([docId, { progress }]) => (
                      <div key={docId} className="progress-bar-document">
                        <div className="d-flex flex-column">
                          <div>File downloading</div>
                          <div className="d-flex align-items-center">
                            <progress value={progress} max={100} />
                            <div className="ps-2">{progress}%</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                </div>
                </ Container>
              </div>
    </>
  );
}

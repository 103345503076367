import React, { useCallback, useEffect, useState } from "react";
import { Container, Alert, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { clientTitle } from "../../constants/client/labels";
import { dateDMY } from "../../utils/dateFormat";
import { useLazyGetClientfoldersByidQuery } from "../../slices/documentApiManagement";
import Loader from "../../components/Loader/Loader";
import {
  executor_clientdocumentsDetails,
  executor_LandingPage,
} from "../../constants/routes";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import { setStackHolderEmpty } from "../../slices/stackHolderSlice";
import { dummyFolders } from "../../constants/Executor/folders";
import { ReactComponent as WarningIcon } from "../../assets/images/WarningCircle.svg";
import { setDetails } from "../../slices/shareReceiveBankDetailsSlice";
import ExecutorTitleBar from "../../components/executor/ExecutorTitleBar";
import DragandDropShortCertificate from "../../components/modal/DragandDropShortCertificate";
import AnalysingFile from "../../components/modal/AnalysingFile";
import ShortCertificateModal from "../../components/modal/ShortCertificateModal";

export default function ExecutorLandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [foldersData, setFolderData] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);

  const [showModal, setShowModal] = useState(false);
  const [showModalAnalyser, setShowModalAnalyser] = useState(false);
  const [showModalAISuccess, setShowModalAISuccess] = useState(false);
  const [icon, setIcon] = useState(null);
  const [message, setMessage] = useState("");
  const [btnText, setbtnText] = useState("");

  const {
    data,
    error: getApiError,
    isLoading: isGetLoading,
  } = useGetClientQuery(userInfo?.clientId, {
    skip: userInfo?.clientId === undefined,
  });

  const [trigger, { isLoading, error }] = useLazyGetClientfoldersByidQuery();
  const queryParameters = new URLSearchParams(window.location.search);
  const identifierID = queryParameters.get("identifier");
  const urlType = queryParameters.get("type");

  const getDocumentTypes = useCallback(async () => {
    if (data?.clientId) {
      const result = await trigger({
        userid: data?.clientId,
        role: "exe",
      }).unwrap();
      if (result && result.length > 0) {
        setFolderData(result);
      } else {
        toast.error(result?.message);
      }
    }
  }, [data?.clientId, trigger]);

  useEffect(() => {
    if (identifierID && urlType && urlType === "addProf") {
      dispatch(setDetails({ identifierID, requestType: urlType }));
      navigate(
        `${executor_LandingPage}/additionproflanding?identifier=${identifierID}&type=${urlType}`
      );
    }
    if (identifierID && urlType && urlType === "deathClaim") {
      dispatch(setDetails({ identifierID, requestType: urlType }));
      navigate(
        `${executor_LandingPage}/reporterlanding?identifier=${identifierID}&type=${urlType}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.fullName) {
      getDocumentTypes();
    }
  }, [data?.fullName, getDocumentTypes]);

  useEffect(() => {
    dispatch(setStackHolderEmpty());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const uploadShortCertificate = () => {
    setShowModal(true);
  };

  const handleFileUploaded = () => {
    setShowModal((show) => !show);
    setShowModalAnalyser(true);
  };

  const handleUploadSuccess = (responseType, icon, message) => {
    if (responseType === "Green") {
      setIcon(icon);
      setMessage(message);
      setShowModalAnalyser(false);
      setShowModalAISuccess(true);
      setbtnText("Ok");
    } else if (responseType === "Yellow" || responseType === "Red") {
      setIcon(icon);
      setMessage(message);
      setShowModalAnalyser(false);
      setShowModalAISuccess(true);
      responseType === "Red" && setbtnText("Re-Upload");
      responseType === "Yellow" && setbtnText("Ok");
    } else {
      setShowModalAnalyser(false);
      setShowModalAISuccess(false);
      toast.error(message);
    }
  };

  const handleAnalysisClose = () => {
    setShowModalAnalyser(false);
  };

  const handleModalAIClose = () => {
    setShowModalAISuccess(false);
    getDocumentTypes();
  };

  const getFilteredData =
    foldersData?.length > 0 &&
    foldersData.filter((data) => data.rootDocId === "RD_001");

  const isShortCertificateUploaded =
    getFilteredData?.length > 0 && getFilteredData[0]?.shortCertUploaded;
  const isShortCerticatedApproved =
    getFilteredData?.length > 0 && getFilteredData[0]?.shortCertApproved;

  if (error || getApiError) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      {!isShortCerticatedApproved && (
        <div>
          <div
            className="attorney-client-title-page-container px-lg-4 px-xl-4"
            data-testid="attonery-client-title"
          >
            <Container fluid>
              <div className="attoner-client-title px-3 pt-2 px-lg-4 px-md-4 px-xl-4">
                <ExecutorTitleBar
                  items={false}
                  hasButton={false}
                  title={
                    data?.fullName
                      ? `${clientTitle} - ${data?.fullName}`
                      : clientTitle
                  }
                />
              </div>
            </Container>
          </div>
          <div className="firm-page-outer-container px-lg-4 px-xl-4">
            <Container fluid>
              <div className="firm-page-container attorney-folders ps-3 px-lg-4 px-md-4 px-xl-4">
                <div className="noof-document-uploaded pt-3">
                  Total number of Documents uploaded:{" "}
                  <span className="no-uploaded">
                    {foldersData && foldersData.length > 0
                      ? foldersData[foldersData.length - 1].totalDocsByUserId
                      : 0}
                  </span>
                </div>
                <div className="mt-3 folder-container-new d-flex flex-wrap justify-content-lg-start justify-content-md-center">
                  <div className="d-none d-lg-block d-md-block">
                    <div className="w-100 d-flex flex-lg-row flex-md-column align-items-lg-start align-items-md-center">
                      {foldersData && foldersData.length > 0 && (
                        <div key={`dt-0`}>
                          <LinkContainer
                            to={executor_clientdocumentsDetails}
                            state={{
                              doc: foldersData[0],
                              user: data,
                            }}
                          >
                            <div
                              className={`big-folder position-relative ${foldersData[0]?.folderColor}`}
                            >
                              <div className="three-dots position-absolute">
                                {/* <DotsBig /> */}
                              </div>
                              <div className="folder-name ps-3 pt-5">
                                {foldersData[0].rootDocName}
                              </div>
                              <div className="document-count text-center">
                                {foldersData[0].totalDocsByFolder}
                              </div>
                              <div className="edited-date ps-3 position-absolute">
                                {foldersData[0]?.lastModifiedAt !== null && (
                                  <div>
                                    Edited:{" "}
                                    {dateDMY(foldersData[0].lastModifiedAt)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </LinkContainer>
                          <div className="short-certificate-upload-btn">
                            <div className="d-flex">
                              {!isShortCerticatedApproved && (
                                <div>
                                  <WarningIcon />
                                </div>
                              )}
                              {!isShortCertificateUploaded &&
                                !isShortCerticatedApproved && (
                                  <div className="ps-3">
                                    Please{" "}
                                    <span
                                      className="upload-link"
                                      onClick={uploadShortCertificate}
                                    >
                                      upload
                                    </span>{" "}
                                    your short certificate to unlock these
                                    folders. Once uploaded, all the Files/Folder
                                    will become accessible.
                                  </div>
                                )}
                              {isShortCertificateUploaded &&
                                !isShortCerticatedApproved && (
                                  <div className="ps-3">
                                    Your short certificate has been sent for
                                    admin verification. Please wait up to 7 days
                                    before uploading a new version.
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="other-common-folder-new short-ceritificate-disabled ms-xl-5 ms-lg-5 d-flex flex-wrap align-items-lg-start align-items-md-center justify-content-lg-start justify-content-md-center mt-lg-0 mt-md-5">
                        {dummyFolders &&
                          foldersData?.length > 0 &&
                          dummyFolders.length > 0 &&
                          dummyFolders.map((folder, index) => {
                            if (index === 0) return null;
                            return (
                              <div
                                className={`common-folders-new ${folder?.folderColor} me-4 mb-4 position-relative`}
                                key={0 + index}
                              >
                                <div className="three-dots position-absolute">
                                  {/* <DotsSmall /> */}
                                </div>
                                <div className="folder-name ps-3">
                                  {folder.rootDocName}
                                </div>
                                <div className="document-count text-center">
                                  {folder.totalDocsByFolder}
                                </div>
                                {/* <div className="edited-date ps-3 position-absolute">
                                  {folder?.lastModifiedAt !== null && (
                                    <div>
                                      Edited: {dateDMY(folder.lastModifiedAt)}
                                    </div>
                                  )}
                                </div> */}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-md-none d-lg-none d-xl-none d-xxl-none w-100">
                    <div className="short-certificate-upload-btn-small">
                      <div className="d-flex">
                        {!isShortCerticatedApproved && (
                          <div>
                            <WarningIcon />
                          </div>
                        )}
                        {!isShortCertificateUploaded &&
                          !isShortCerticatedApproved && (
                            <div className="ps-3">
                              Please{" "}
                              <span
                                className="upload-link"
                                onClick={uploadShortCertificate}
                              >
                                upload
                              </span>{" "}
                              your short certificate to unlock these folders.
                              Once uploaded, all the Files/Folder will become
                              accessible.
                            </div>
                          )}
                        {isShortCertificateUploaded &&
                          !isShortCerticatedApproved && (
                            <div className="ps-3">
                              Your short certificate has been sent for admin
                              verification. Please wait up to 7 days before
                              uploading a new version.
                            </div>
                          )}
                      </div>
                    </div>
                    <Row className="w-100">
                      <Col xs={12} sm={12}>
                        {foldersData && foldersData.length > 0 && (
                          <div key={`dt-0`}>
                            <LinkContainer
                              to={executor_clientdocumentsDetails}
                              state={{
                                doc: foldersData[0],
                                user: data,
                              }}
                            >
                              <div
                                className={`big-folder-small-devices ${foldersData[0]?.folderColor} position-relative`}
                              >
                                <div className="three-dots position-absolute">
                                  {/* <DotsBig /> */}
                                </div>
                                <div className="folder-name ps-3 pt-5">
                                  {foldersData[0].rootDocName}
                                </div>
                                <div className="document-count text-center">
                                  {foldersData[0].totalDocsByFolder}
                                </div>
                                <div className="edited-date ps-3 position-absolute">
                                  {foldersData[0]?.lastModifiedAt !== null && (
                                    <div>
                                      Edited:{" "}
                                      {dateDMY(foldersData[0].lastModifiedAt)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </LinkContainer>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div className="w-100 mt-3 short-ceritificate-disabled">
                      <Row className="w-100 other-small-folders">
                        {dummyFolders &&
                          foldersData?.length > 0 &&
                          dummyFolders.length > 0 &&
                          dummyFolders.map((folder, index) => {
                            if (index === 0) return null;
                            // let pdt =
                            //   folder.rootDocName.length > 20 ? "longstring" : "";
                            return (
                              <Col xs={6} sm={6} className="mb-3" key={index}>
                                <div
                                  className={`common-folders-new-small-devices ${folder?.folderColor} position-relative`}
                                >
                                  <div className="three-dots position-absolute">
                                    {/* <DotsSmall /> */}
                                  </div>
                                  <div className="folder-name ps-3">
                                    {folder.rootDocName}
                                  </div>

                                  <div className="edited-date ps-3 position-absolute">
                                    <div className="document-count">
                                      {folder.totalDocsByFolder} Files
                                    </div>
                                    {/* {folder?.lastModifiedAt !== null && (
                                      <div>
                                        Edited: {dateDMY(folder.lastModifiedAt)}
                                      </div>
                                    )} */}
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
      {isShortCerticatedApproved && (
        <div>
          <div
            className="attorney-client-title-page-container px-lg-4 px-xl-4"
            data-testid="attonery-client-title"
          >
            <Container fluid>
              <div className="attoner-client-title px-3 pt-2 px-lg-4 px-md-4 px-xl-4">
                <ExecutorTitleBar
                  items={false}
                  hasButton={false}
                  title={
                    data?.fullName
                      ? `${clientTitle} - ${data?.fullName}`
                      : clientTitle
                  }
                />
              </div>
            </Container>
          </div>
          <div className="firm-page-outer-container px-lg-4 px-xl-4">
            <Container fluid>
              <div className="firm-page-container attorney-folders ps-3 px-lg-4 px-md-4 px-xl-4">
                <div className="noof-document-uploaded pt-3">
                  Total number of Documents uploaded:{" "}
                  <span className="no-uploaded">
                    {foldersData && foldersData.length > 0
                      ? foldersData[foldersData.length - 1].totalDocsByUserId
                      : 0}
                  </span>
                </div>
                <div className="mt-3 folder-container-new d-flex flex-wrap justify-content-lg-start justify-content-md-center">
                  <div className="d-none d-lg-block d-md-block">
                    <div className="w-100 d-flex flex-lg-row flex-md-column align-items-lg-start align-items-md-center">
                      {foldersData && foldersData.length > 0 && (
                        <div key={`dt-0`}>
                          <LinkContainer
                            to={executor_clientdocumentsDetails}
                            state={{
                              doc: foldersData[0],
                              user: data,
                            }}
                          >
                            <div
                              className={`big-folder position-relative ${foldersData[0]?.folderColor}`}
                            >
                              <div className="three-dots position-absolute">
                                {/* <DotsBig /> */}
                              </div>
                              <div className="folder-name ps-3 pt-5">
                                {foldersData[0].rootDocName}
                              </div>
                              <div className="document-count text-center">
                                {foldersData[0].totalDocsByFolder}
                              </div>
                              <div className="edited-date ps-3 position-absolute">
                                {foldersData[0]?.lastModifiedAt !== null && (
                                  <div>
                                    Edited:{" "}
                                    {dateDMY(foldersData[0].lastModifiedAt)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </LinkContainer>
                        </div>
                      )}
                      <div className="other-common-folder-new ms-xl-5 ms-lg-5 d-flex flex-wrap align-items-lg-start align-items-md-center justify-content-lg-start justify-content-md-center mt-lg-0 mt-md-5">
                        {foldersData &&
                          foldersData.length > 0 &&
                          foldersData.map((folder, index) => {
                            if (index === 0) return null;
                            // let pdt =
                            //   folder.rootDocName.length > 20 ? "longstring" : "";
                            return (
                              <LinkContainer
                                to={executor_clientdocumentsDetails}
                                key={`dt-${index}`}
                                state={{
                                  doc: folder,
                                  user: data,
                                }}
                              >
                                <div
                                  className={`common-folders-new ${folder?.folderColor} me-4 mb-4 position-relative`}
                                >
                                  <div className="three-dots position-absolute">
                                    {/* <DotsSmall /> */}
                                  </div>
                                  <div className="folder-name ps-3">
                                    {folder.rootDocName}
                                  </div>
                                  <div className="document-count text-center">
                                    {folder.totalDocsByFolder}
                                  </div>
                                  <div className="edited-date ps-3 position-absolute">
                                    {folder?.lastModifiedAt !== null && (
                                      <div>
                                        Edited: {dateDMY(folder.lastModifiedAt)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </LinkContainer>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-md-none d-lg-none d-xl-none d-xxl-none w-100">
                    <Row className="w-100">
                      <Col xs={12} sm={12}>
                        {foldersData && foldersData.length > 0 && (
                          <div key={`dt-0`}>
                            <LinkContainer
                              to={executor_clientdocumentsDetails}
                              state={{
                                doc: foldersData[0],
                                user: data,
                              }}
                            >
                              <div
                                className={`big-folder-small-devices ${foldersData[0]?.folderColor} position-relative`}
                              >
                                <div className="three-dots position-absolute">
                                  {/* <DotsBig /> */}
                                </div>
                                <div className="folder-name ps-3 pt-5">
                                  {foldersData[0].rootDocName}
                                </div>
                                <div className="document-count text-center">
                                  {foldersData[0].totalDocsByFolder}
                                </div>
                                <div className="edited-date ps-3 position-absolute">
                                  {foldersData[0]?.lastModifiedAt !== null && (
                                    <div>
                                      Edited:{" "}
                                      {dateDMY(foldersData[0].lastModifiedAt)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </LinkContainer>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div className="w-100 mt-3">
                      <Row className="w-100 other-small-folders">
                        {foldersData &&
                          foldersData.length > 0 &&
                          foldersData.map((folder, index) => {
                            if (index === 0) return null;
                            // let pdt =
                            //   folder.rootDocName.length > 20 ? "longstring" : "";
                            return (
                              <LinkContainer
                                to={executor_clientdocumentsDetails}
                                key={`dt-${index}`}
                                state={{
                                  doc: folder,
                                  user: data,
                                }}
                              >
                                <Col xs={6} sm={6} className="mb-3">
                                  <div
                                    className={`common-folders-new-small-devices ${folder?.folderColor} position-relative`}
                                  >
                                    <div className="three-dots position-absolute">
                                      {/* <DotsSmall /> */}
                                    </div>
                                    <div className="folder-name ps-3">
                                      {folder.rootDocName}
                                    </div>

                                    <div className="edited-date ps-3 position-absolute">
                                      <div className="document-count">
                                        {folder.totalDocsByFolder} Files
                                      </div>
                                      {folder?.lastModifiedAt !== null && (
                                        <div>
                                          Edited:{" "}
                                          {dateDMY(folder.lastModifiedAt)}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </LinkContainer>
                            );
                          })}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
      <div className="background-mvs-image type-1"></div>
      {(isLoading || isGetLoading) && <Loader data-testid="loader" />}
      <DragandDropShortCertificate
        show={showModal}
        setShow={() => setShowModal(false)}
        modalSize="md"
        role="executor"
        type="short-certificate"
        onFileUploaded={handleFileUploaded}
        onUploadSuccess={handleUploadSuccess}
      />

      <AnalysingFile
        show={showModalAnalyser}
        size="md"
        setShowAnalyse={handleAnalysisClose}
        text="Analyzing the file, please wait!"
      />

      <ShortCertificateModal
        show={showModalAISuccess}
        size="md"
        setShowAISuccess={handleModalAIClose}
        Icon={icon}
        message={message}
        btnText={btnText}
        showDragandDrop={() => {setShowModal(true)}}
      />
    </>
  );
}

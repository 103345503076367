import React from "react";
import { Modal, Button} from "react-bootstrap";

export default function AddMaidenNamePrompt({
  showModal,
  setShowCallback,
  editProfile
}) {

  const handleClose = () => {
    sessionStorage.setItem("isMaidenChecked", true)
    setShowCallback(false);
  };
  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        data-testid="personal-use-modal-container"
      >
        <Modal.Header
          className="border-0"
          closeButton
        >
        </Modal.Header>
        <div className="d-flex justify-content-center px-3" style={{textAlignLast: "center", textAlign: "-webkit-center"}}>
        <div className="px-3 mb-3">
           <b>For enhanced account security and a seamless experience, please update your mother's maiden name</b>              
        </div>
        </div>
        <Modal.Footer className="share-doc-modal-footer justify-content-center pt-0 pb-4 mb-2 border-0">
          <Button
            className="custom-button btn-standard btn-maiden-prompt"
            type="button"
            onClick={editProfile}
            data-testid="personal-use-submit-btn"
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Row, Col, Alert, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  client_LandingPage,
  client_uploadDocuments,
  client_addPeople,
  attoeney_client_addPeople,
} from "../../constants/routes";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import {
  useLazyGetDocumentTypeByDocTypeidQuery,
  useUploadwilldocumentMutation,
  useUploadotherdocumentMutation,
  useGetAllDocsByRootIdQuery,
  documentApiManagement,
} from "../../slices/documentApiManagement";
import { useLazyGetAdminApprovalQuery } from "../../slices/documentAiApiManagement";
import Loader from "../../components/Loader/Loader";
import { useDropzone } from "react-dropzone";
import {
  defaultDocType,
  videosigning,
  wills,
} from "../../constants/documentFileType";
import DocumentPreview from "../../components/modal/DocumentPreview";
import DocumentDetails from "../../components/Documents/DocumentDetails";
import NotificationWillUploadMsg from "../../components/modal/NotificationWillUploadMsg";
import { useDispatch, useSelector } from "react-redux";
import { useGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import { setStackHolderEmpty } from "../../slices/stackHolderSlice";
import axios from "axios";
import { ROLE } from "../../constants/role";
import ReactGA from "react-ga4";
import { clientTitle, AILabel } from "../../constants/client/labels";
import AnalysingFile from "../../components/modal/AnalysingFile";
export default function UploadClientDocuments() {
  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: "--",
      active: true,
      state: { user: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: client_uploadDocuments,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
  ]);
  const [documentTypes, setDocumentTypes] = useState(defaultDocType);
  const [selectedDropdown, setDropdown] = useState("");
  const [acceptedFiles, setAcceptedFiles] = useState({});
  const [maxUploadFiles, setMaxUploadFiles] = useState("");
  const [isMultiple, setMultiple] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [content, setFileContent] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isSubmitted, setSubmitClicked] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [radioSelect, setRadioSelect] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [isUploadLoading, setLoading] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [aiResponseModal, setAIResponse] = useState(false);
  const [aiResponseMsg, setAIResponseMsg] = useState(AILabel.uploadSuccessMsg);
  const [aiAPIStatus, setAIStatus] = useState("success");
  const [isWillApprovalPending, setWillAprrovalPending] = useState(false);
  const [disableBrowseBtn, setDisableBrowseBtn] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const rootDocId = location?.state?.doc?.rootDocId;
  const rootDocName = location?.state?.doc?.rootDocName;
  const userId = location?.state?.doc?.userId;
  const isAiDocPage = location?.state?.fromAiDocPage;
  const [
    trigger,
    { isLoading: documentTypeLoading, error: documentTypeError },
  ] = useLazyGetDocumentTypeByDocTypeidQuery();
  const { data: allDocumentsData, isLoading: getAllDocsLoading } =
    useGetAllDocsByRootIdQuery(
      { rootDocId, userId },
      {
        skip: rootDocId && userId === undefined,
        refetchOnMountOrArgChange: true,
      }
    );
  const [adminApproval] = useLazyGetAdminApprovalQuery();
  const dispatch = useDispatch();
  async function blobUrlToFile(blobUrl, fileName, fileType) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], fileName, { type: fileType });
}
const AiDocUpload = useCallback(async() => {
  const fileData = sessionStorage.getItem("uploadedFile") || null
  setRadioSelect(true);
  setShowModal(true);
  setFileName(location?.state?.fileName);
  const file = await blobUrlToFile(fileData, location?.state?.fileName, "application/pdf")
  setSelectedDocs(file);
  setFileContent(fileData);    
  setDropdown("DT_001")
},[location?.state])
  useEffect(() => {
    if(isAiDocPage){
      AiDocUpload()
    }
  },[isAiDocPage, AiDocUpload])

  const onDropAccepted = async (files) => {
    if (selectedDropdown === "DT_001") {
      const acceptedFiles = files[0];
      setShowModal(true);
      setFileName(acceptedFiles?.name);
      setSelectedDocs(acceptedFiles);
      setRadioSelect(true);
      const reader = new FileReader();

      reader.onload = (e) => {
        const blob = new Blob([new Uint8Array(e.target.result)]);
        const blobUrl = URL.createObjectURL(blob);
        setFileContent(blobUrl);
      };

      reader.readAsArrayBuffer(acceptedFiles);
    } else {
      setShowModal(false);
      const data = {
        rootDocId: location?.state?.doc?.rootDocId,
        docTypeId: selectedDropdown,
        clientId: location?.state?.doc?.userId,
        userId: userInfo?.userId,
      };
      const fileData = new FormData();
      for (const file of files) {
        fileData.append("documents", file);
      }
      uploaddocumentotherFiles({ fileData, data });
    }
  };
  const onDropRejected = (params) => {
    if (params[0]?.errors[0]?.code === "file-invalid-type") {
      if (selectedDropdown === "DT_001") {
        toast.error("This format is not valid! Please upload PDF format only");
      } else if (selectedDropdown === "DT_002") {
        toast.error("This format is not valid! Please upload MP4 format only");
      } else {
        toast.error(
          "This format is not valid! Please upload PDF and DOCX format only"
        );
      }
    }

    if (params[0]?.errors[0]?.code === "too-many-files") {
      toast.error("Maximum 10 files are allowed");
    }
  };
  const onFileDialogOpen = () => {
    setFileContent(null);
  };
  const [uploadwilldocument, { isLoading: uploadLoading }] =
    useUploadwilldocumentMutation();
  const [uploadotherdocument, { isLoading: uploadOthersLoading }] =
    useUploadotherdocumentMutation();
  const { data, isLoading } = useGetClientQuery(userInfo?.userId, {
    skip: userInfo?.userId === undefined,
  });
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: acceptedFiles,
    noDrag: true,
    noClick: true,
    maxFiles: maxUploadFiles,
    noKeyboard: true,
    multiple: isMultiple,
    onDropAccepted,
    onDropRejected,
    onFileDialogOpen,
  });

  const getDocumentTypes = useCallback(async () => {
    if (location?.state?.doc && location?.state?.doc?.rootDocId) {
      const result = await trigger(location?.state?.doc?.rootDocId).unwrap();
      if (result && result.length > 0) {
        setDocumentTypes(result);
      } else {
        toast.error(result?.message);
      }
    }
  }, [location?.state, trigger]);
  const getAdminApproval = useCallback(async () => {
    if (userInfo?.userId && !isWillApprovalPending) {
      const response = await adminApproval(userInfo?.userId).unwrap();
      if (response?.successful) {
        sessionStorage.removeItem("stackcount");
        const stackHoldersData = response?.aiResponse
          ? response?.aiResponse
          : null;
        sessionStorage.setItem(
          "aistackholders",
          JSON.stringify(stackHoldersData)
        );
        sessionStorage.setItem(
          "stackcount",
          JSON.stringify({
            DefaulExecutor:
              stackHoldersData?.extracted_information?.executor_details.length >
              1
                ? 2
                : 1,
            DefaulWitness:
              stackHoldersData?.extracted_information?.witness_information
                .length > 1
                ? 2
                : 1,
          })
        );
        dispatch(setStackHolderEmpty())
        setAIStatus("success");
        setAIResponseMsg(response?.message);
        setTimeout(() => {
          setAIResponse(true);
        });
      }
    }
  }, [adminApproval, dispatch, isWillApprovalPending, userInfo?.userId]);
  const uploaddocumentwillFiles = useCallback(
    async ({ fileData, data }) => {
      setShowModal(false);
      const fileNames = fileData.getAll("file").map((file) => file.name);
      const fileSizes = fileData.getAll("file").map((file) => {
        return file.size;
      });
      const totalFileSize = fileSizes.reduce(
        (acc, fileSize) => acc + fileSize,
        0
      );
      const isorgWillDocRetain =
        sessionStorage.getItem("orgDoc") === "Yes" ? true : false;
      try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v2/ai/will-documents?rootDocId=${data.rootDocId}&docTypeId=${data.docTypeId}&clientId=${data.clientId}&userId=${data.userId}&orgDocsFlag=${isorgWillDocRetain}`;
        const token = userInfo?.accessToken;
        const response = await axios({
          url: baseUrl,
          method: "POST",
          responseType: "json",
          headers: { Authorization: "Bearer " + token },
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.total;
            if (totalLength !== null) {
              setNotificationModal(true);
            }
          },
          data: fileData,
        });

        if (response.status === 200) {
          sessionStorage.removeItem("stackcount");
          const stackHoldersData = response?.data?.aiResponse
            ? response?.data?.aiResponse
            : null;
          sessionStorage.setItem(
            "aistackholders",
            JSON.stringify(stackHoldersData)
          );
          sessionStorage.setItem(
            "stackcount",
            JSON.stringify({
              DefaulExecutor:
                stackHoldersData?.extracted_information?.executor_details.length > 1
                  ? 2
                  : 1,
              DefaulWitness:
                stackHoldersData?.extracted_information?.witness_information
                  .length > 1
                  ? 2
                  : 1,
            })
          );
          setNotificationModal(false);
          if (stackHoldersData?.confidence_score < 80) {
            setAIStatus(
              stackHoldersData?.confidence_score < 60 ? "reject" : "review"
            );
            setAIResponseMsg(response?.data?.message);
          } else {
            setAIStatus("success");
            setAIResponseMsg(response?.data?.message);
          }
          setTimeout(() => {
            setAIResponse(true);
          }, 1000);
          sessionStorage.removeItem("orgDoc");
          const firmType = userInfo?.firmTypeId;
          firmType === 1 &&
            ReactGA.event(`upload_will_document_confirmation`, {
              category: `${ROLE.client} - 3 Level`,
              action: `Upload Will Document Confirmation - ${ROLE.client} - 3 Level`,
              label: `Upload Will Document Confirmation - ${ROLE.client} - 3 Level`,
              wills_uploaded: fileNames.length,
              wills_filesize: totalFileSize,
            });

          firmType === 2 &&
            ReactGA.event(`upload_will_document_confirmation`, {
              category: `${ROLE.client} - 2 Level`,
              action: `Upload Will Document Confirmation - ${ROLE.client} - 2 Level`,
              label: `Upload Will Document Confirmation - ${ROLE.client} - 2 Level`,
              wills_uploaded: fileNames.length,
              wills_filesize: totalFileSize,
            });
          dispatch(setStackHolderEmpty())
          dispatch(
            documentApiManagement.util.invalidateTags(["getAllDocsByRootId"])
          );
          dispatch(
            documentApiManagement.util.invalidateTags(["getfoldersByid"])
          );
        }
      } catch (err) {
        setNotificationModal(false);
        toast.error(
          err?.response?.data?.message || `File uploading failed. Try again!`
        );
      }
      sessionStorage.removeItem("uploadedFile")
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadwilldocument]
  );

  const uploaddocumentotherFiles = useCallback(
    async ({ fileData, data }) => {
      setShowModal(false);
      const fileNames = fileData.getAll("documents").map((file) => file.name);
      const fileSizes = fileData.getAll("documents").map((file) => {
        return file.size;
      });
      const totalFileSize = fileSizes.reduce(
        (acc, fileSize) => acc + fileSize,
        0
      );
      try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents/?rootDocId=${data.rootDocId}&docTypeId=${data.docTypeId}&clientId=${data.clientId}&userId=${data.userId}`;
        const token = userInfo?.accessToken;
        const response = await axios({
          url: baseUrl,
          method: "POST",
          responseType: "json",
          headers: { Authorization: "Bearer " + token },
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.total;
            if (totalLength !== null) {
              setLoading(true);
            }
          },
          data: fileData,
        });

        if (response.status === 200) {
          setLoading(false);
          const firmType = userInfo?.firmTypeId;
          firmType === 1 &&
            ReactGA.event(`upload_other_documents_confirmation`, {
              category: `${ROLE.client} - 3 Level`,
              action: `Upload other Documents Confirmation - ${ROLE.client} - 3 Level`,
              label: `Upload other Documents Confirmation - ${ROLE.client} - 3 Level`,
              other_docs: fileNames.length,
              other_filesize: totalFileSize,
            });

          firmType === 2 &&
            ReactGA.event(`upload_other_documents_confirmation`, {
              category: `${ROLE.client} - 2 Level`,
              action: `Upload other Documents Confirmation - ${ROLE.client} - 2 Level`,
              label: `Upload other Documents Confirmation - ${ROLE.client} - 2 Level`,
              other_docs: fileNames.length,
              other_filesize: totalFileSize,
            });
          dispatch(
            documentApiManagement.util.invalidateTags(["getAllDocsByRootId"])
          );
          dispatch(
            documentApiManagement.util.invalidateTags(["getfoldersByid"])
          );
          toast.success(response?.data?.message);
        }
      } catch (err) {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || `File uploading failed. Try again!`
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadotherdocument]
  );
  useEffect(() => {
    if (
      allDocumentsData &&
      allDocumentsData?.length > 0 &&
      allDocumentsData?.filter((e, i) => e?.rootDocTypeId === "DT_001")
        ?.length > 0
    ) {
      let willdoc = allDocumentsData?.filter(
        (e, i) => e?.rootDocTypeId === "DT_001"
      )[0];
      if (willdoc?.isWillApprovalPending) {
        setWillAprrovalPending(true);
      }
      if(willdoc?.addPersonInfoModel){
        sessionStorage.setItem(
          "stackcount",
          JSON.stringify({
            DefaulExecutor:
            willdoc?.addPersonInfoModel?.executorList && willdoc?.addPersonInfoModel?.executorList?.length > 1
                ? 2
                : 1,
            DefaulWitness:
              willdoc?.addPersonInfoModel?.witness && willdoc?.addPersonInfoModel?.witness?.length > 1
                ? 2
                : 1,
          })
        );
      }
    }
  }, [allDocumentsData]);
  useEffect(() => {
    sessionStorage.removeItem("aistackholders");
    //sessionStorage.removeItem("stackcount");
    dispatch(setStackHolderEmpty());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userInfo?.userId && !isWillApprovalPending) {
      getAdminApproval();
    }
  }, [getAdminApproval, isWillApprovalPending, userInfo?.userId]);
  useEffect(() => {
    if (isSubmitted) {
      if (selectedDropdown === "DT_001") {
        const data = {
          rootDocId: location?.state?.doc?.rootDocId,
          docTypeId: selectedDropdown,
          clientId: location?.state?.doc?.userId,
          userId: userInfo?.userId,
          orgDocsFlag: radioValue === "Yes" ? true : false,
        };
        const fileData = new FormData();
        fileData.append("file", selectedDocs);
        uploaddocumentwillFiles({ fileData, data });
        setSubmitClicked(false);
        setRadioSelect(false);
      }
    }
  }, [
    isSubmitted,
    selectedDocs,
    location?.state?.doc,
    selectedDropdown,
    radioValue,
    uploaddocumentwillFiles,
    uploaddocumentotherFiles,
    userInfo?.userId,
  ]);
  useEffect(() => {
    if (location?.state?.user) {
      getDocumentTypes();
      setUserDetails(location?.state?.user);
      setItems([
        {
          id: "item-1",
          link: client_LandingPage,
          content: clientTitle,
          active: false,
          state: { user: location?.state?.user, refetch: true },
        },
        {
          id: "item-2",
          link: client_uploadDocuments,
          content: rootDocName,
          active: true,
          state: { doc: location?.state?.doc, user: location?.state?.user },
        },
      ]);
    }
  }, [location?.state, getDocumentTypes, data?.fullName, rootDocName]);
  useEffect(() => {
    if (selectedDropdown === "DT_001") {
      if (isWillApprovalPending) {
        setDisableBrowseBtn(true);
        return;
      } else {
        setAcceptedFiles(wills);
        setMultiple(false);
        setMaxUploadFiles(1);
      }
    } else if (selectedDropdown === "DT_002") {
      setAcceptedFiles(videosigning);
      setMultiple(false);
      setMaxUploadFiles(1);
    } else {
      setAcceptedFiles(defaultDocType);
      setMultiple(true);
      setMaxUploadFiles(10);
    }
    setDisableBrowseBtn(false);
  }, [disableBrowseBtn, isWillApprovalPending, selectedDropdown]);
  const handleUploadDocumentSelect = (selValue) => {
    setDropdown(selValue);
  };
  if (documentTypeError) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status -{" "}
          {documentTypeError?.status}. {documentTypeError?.data}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      <div
        className="attorney-client-title-page-container  px-lg-4 px-xl-4"
        data-testid="attonery-client-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={
                allDocumentsData && allDocumentsData?.rootDocName
                  ? allDocumentsData?.rootDocName
                  : rootDocName
              }
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container upload-client-container px-1 px-lg-4 px-xl-4">
            <div className="document-type-container px-2 px-lg-4 px-md-4 px-xl-1 pb-2">
              <Form className="custom-form employee-form type-2 mt-3">
                <Row>
                  <Col lg={8} md={8} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="select-documentType"
                        className="required mb-0"
                      >
                        Select document type
                      </Form.Label>
                      <Form.Select
                        name="documentType"
                        className="form-input input-border-radius-unset select-placeholder"
                        data-testid="select-documentType"
                        id="select-documentType"
                        onChange={(e) => {
                          handleUploadDocumentSelect(e.target.value);
                        }}
                      >
                        <option value="" hidden></option>
                        {documentTypes &&
                          documentTypes.length > 0 &&
                          documentTypes.map((group, index) => (
                            <option
                              value={group.rootDocTypeId}
                              key={"docType" + index}
                            >
                              {group.rootDocTypeName}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={12} className="browse-upload-button">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} data-testid="dropzone" />
                      <Button
                        className={`custom-button btn-standard custom-file-upload ${
                          selectedDropdown === "" || disableBrowseBtn
                            ? "btn-disable"
                            : ""
                        } mt-0 mt-lg-4 mt-md-4`}
                        type="button"
                        onClick={open}
                      >
                        Browse & Upload
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="document-list-container px-2 px-lg-4 px-md-4 px-xl-1 pb-2">
              <DocumentDetails
                allDocumentsData={allDocumentsData}
                userDetails={userDetails}
                locationState={{
                  doc: location?.state?.doc,
                  user: location?.state?.user,
                  docType: location?.state?.docType,
                }}
              />
            </div>
          </div>
        </Container>
      </div>
      {(isLoading || documentTypeLoading || getAllDocsLoading) && (
        <Loader data-testid="loader" />
      )}

      {isUploadLoading && <Loader />}

      <DocumentPreview
        showModal={showModal}
        setShowCallback={() => setShowModal(false)}
        fileContent={content}
        fileName={fileName}
        setSubmitCallback={() => {
          setShowModal(false);
          setSubmitClicked(true);
        }}
        setRadioCallback={(val) => {
          setRadioValue(val);
          sessionStorage.setItem("orgDoc", val);
        }}
        initialRadioSelect={radioSelect}
        isLoading={uploadLoading}
        isOthersLoading={uploadOthersLoading}
        selectedDropdown={selectedDropdown}
        selectedDocs={selectedDocs}
      />
      <AnalysingFile
        show={notificationModal}
        size="md"
        setShowAnalyse={() => setNotificationModal(false)}
        text={AILabel.docUploadingMsg}
      />
      <NotificationWillUploadMsg
        setHideCallback={() => setAIResponse(false)}
        setShowCallback={() => {
          setAIResponseMsg(false);
          if (aiAPIStatus === "success") {
            navigate(
              userInfo?.role === ROLE.client
                ? client_addPeople
                : attoeney_client_addPeople,
              {
                state: {
                  doc: location?.state?.doc,
                  user: location?.state?.user,
                  docType: location?.state?.docType,
                },
              }
            );
          } else {
            setAIResponse(false);
          }
        }}
        handleClickOk={() => {}}
        singleOkButton={true}
        content={aiResponseMsg}
        showModal={aiResponseModal}
        aiAPIStatus={aiAPIStatus}
      />
    </>
  );
}

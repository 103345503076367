import { aiDocumentSlice } from "./aiDocumentSlice";
import { documentApiManagement } from "./documentApiManagement";

export const aiDocumentManagementSlice = aiDocumentSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadAiDocument: builder.mutation({
      query: (data) => ({
        url: `/upload`,
        method: "PUT",
        body: data,
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
    }),
    uploadshortCertificate: builder.mutation({
      query: ({ formData, user }) => ({
        url: `/short-certificate?userId=${user.userId}&clientId=${user.clientId}`,
        method: "POST",
        body: formData,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          documentApiManagement.util.invalidateTags(["getClientfoldersByid"])
        );
      },
    }),
    deathAiClaim: builder.mutation({
      query: ({ fileData, data }) => ({
        url: `/death-claim/${data.clientId}?userId=${data.userId}`,
        method: "POST",
        body: fileData,
      }),
      prepareHeaders: (headers) => {
        headers.set("Content-Type", "multipart/form-data");
        return headers;
      },
    }),
    adminApprovalAi: builder.mutation({
      query: ({ data }) => ({
        url: `/admin-approval/${data.approvalId}?action=${data.action}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useUploadAiDocumentMutation,
  useUploadshortCertificateMutation,
  useDeathAiClaimMutation,
  useAdminApprovalAiMutation,
} = aiDocumentManagementSlice;

import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  sploginEmailReqValidation,
  sploginEmailValidation,
  enterdetails,
  sploginNameRegValidation,
  sploginNameValidation,
  sploginDurationValiation,
  sploginphoneNumberValidation,
  countryCodeList,
} from "../../constants/labels";
import {
  emailRules,
  alphabetRules,
  phoneNumberRules,
  indphonNumberRules,
  usphonNumberRules,
} from "../../constants/regExp";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import ReactGA from "react-ga4";
import { ROLE } from "../../constants/role";
import PhoneInput from "react-phone-input-2";
import { useShareDocumentV2Mutation } from "../../slices/documentApiManagementV2";
export default function ShareDocumentModalPopup({
  showModal,
  setShowCallback,
  docId,
  setSuccessCallback,
}) {
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const schema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(`${sploginNameRegValidation}`)
      .matches(alphabetRules, `${sploginNameValidation}`),
    email: yup
      .string()
      .trim()
      .required(`${sploginEmailReqValidation}`)
      .email(`${sploginEmailValidation}`)
      .matches(emailRules, `${sploginEmailValidation}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${sploginphoneNumberValidation}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${sploginphoneNumberValidation}`),
    duration: yup.string().required(`${sploginDurationValiation}`),
  });
  const { userInfo } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });
  const handleClose = () => {
    setShowCallback(false);
    reset();
    setPhoneNumber("");
    setCountryCode("");
    setValue("phone", "", {
      shouldValidate: false,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const [shareDocument, { isLoading }] = useShareDocumentV2Mutation();

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };

  const onSubmit = async (formData) => {
    const data = {
      userId: userInfo?.userId,
      toAddress: formData?.email,
      recipientName: formData?.name,
      duration: parseInt(formData?.duration),
      phoneNumber: `+${formData.phone}`,
    };
    try {
      const res = await shareDocument({ docId, data }).unwrap();
      if (res?.isSuccessful) {
        if (userInfo?.role === ROLE.attorney) {
          ReactGA.event({
            category: `${ROLE.attorney} - 3 Level`,
            action: `Share Will Document confimration - ${ROLE.attorney} - 3 Level`,
            label: `Share Will Document confimration - ${ROLE.attorney} - 3 Level`,
          });
        }

        if (userInfo?.role === ROLE.client) {
          const firmType = userInfo?.firmTypeId;
          firmType === 1 &&
            ReactGA.event({
              category: `${ROLE.client} - 3 Level`,
              action: `Share Will Document confimration - ${ROLE.client} - 3 Level`,
              label: `Share Will Document confimration - ${ROLE.client} - 3 Level`,
            });
          firmType === 2 &&
            ReactGA.event({
              category: `${ROLE.client} - 2 Level`,
              action: `Share Will Document confimration - ${ROLE.client} - 2 Level`,
              label: `Share Will Document confimration - ${ROLE.client} - 2 Level`,
            });
        }
        setSuccessCallback();
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err?.data?.error);
    }
  };
  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="share-document-modal"
      >
        <Form
          className="custom-form custom-form-doc-share w-100"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Header className="share-doc-modal-header" closeButton>
            <Modal.Title className="header-title">{enterdetails}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="share-doc-modal-body">
            <Form.Group className="mb-3">
              <Form.Control
                className="form-input"
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                {...register("name")}
                isInvalid={!!errors.name}
                placeholder="Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                className="form-input"
                type="email"
                id="email"
                name="email"
                autoComplete="off"
                {...register("email")}
                isInvalid={!!errors.email}
                onBlur={(e) => (e.target.value = e.target.value.trim())}
                placeholder="Email"
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <div
                    className={
                      errors.phone
                        ? "phone-input-invalid-container"
                        : "phone-input-valid-container"
                    }
                  >
                    <PhoneInput
                      {...field}
                      defaultCountry="us"
                      onlyCountries={countryCodeList}
                      placeholder="Mobile Phone"
                      title=""
                      country={"us"}
                      inputProps={{
                        required: true,
                        autoFocus: false,
                        title: "",
                      }}
                      countryCodeEditable={false}
                      onChangeCapture={handleMaxNumber}
                      onChange={(value, phone) => {
                        if (
                          phone.dialCode === "1" &&
                          phone.countryCode === "us"
                        ) {
                          setCountryCode("+1");
                          setPhoneNumber(value);
                        }
                        if (
                          phone.dialCode === "91" &&
                          phone.countryCode === "in"
                        ) {
                          setCountryCode("+91");
                          setPhoneNumber(value);
                        }
                      }}
                    />
                  </div>
                )}
              />
              <Form.Control
                className="form-input input-border-radius-unset input_number"
                type="hidden"
                name="phone"
                onKeyDown={handleKeyPress}
                onChangeCapture={handleMaxNumber}
                autoComplete="off"
                {...register("phone")}
                isInvalid={!!errors.phone}
                data-testid="emp-phone"
                id="emp-phone"
              />
              <Form.Control.Feedback
                data-testid="invalid-feedback"
                type="invalid"
              >
                {errors.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 radio-button-container">
              <Form.Label htmlFor="comments" className="radio-button-label">
                Duration of Access
              </Form.Label>
              <div className="radio-buttons-wrapper">
                <Form.Check
                  inline
                  label="1 Hour"
                  name="group1"
                  type="radio"
                  id={`inline-radio-1`}
                  value={1}
                  //checked={selRadio === 1 ? true:false}
                  checked={true}
                  data-testid="radio-button-3days"
                  {...register("duration")}
                />
                {/* <Form.Check
                  inline
                  label="5 days"
                  name="group1"
                  type="radio"
                  id={`inline-radio-2`}
                  value={5}
                  checked={selRadio === 5 ? true:false}
                  onClick={() => setRadio(5)}
                  data-testid="radio-button-5days"
                  {...register("duration")}
                />
                <Form.Check
                  inline
                  label="7 days"
                  name="group1"
                  type="radio"
                  id={`inline-radio-2`}
                  value={7}
                  checked={selRadio ===  7? true:false}
                  onClick={() => setRadio(7)}
                  data-testid="radio-button-7days"
                  {...register("duration")}
                /> */}
              </div>
              {errors?.duration && (
                <p className="error-message">{errors?.duration?.message}</p>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="share-doc-modal-footer pt-0">
            <Button
              className="custom-button btn-standard doc-share-submit-btn"
              type="submit"
              name="submit"
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
        {isLoading && <Loader />}
      </Modal>
    </>
  );
}

import { folderApiSlice } from "./folderApiSlice";
import { documentApiManagement } from "./documentApiManagement";
export const folderApiManagement = folderApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createFolder: builder.mutation({
      query: (data) => ({
        url: `/${data.clientId}/create?userId=${data.userId}&colorId=${data.color}&name=${data.name}`,
        method: "POST",
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            documentApiManagement.util.invalidateTags(["getfoldersByid"])
          );
        } catch (error) {
          console.log(error);
          return;
        }
      },
    }),
    updateFolder: builder.mutation({
      query: (data) => ({
        url: `/${data.clientId}/update?entityId=${data.entityId}&colorId=${data.color}&name=${data.name}`,
        method: "PUT",
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            documentApiManagement.util.invalidateTags(["getfoldersByid"])
          );
        } catch (error) {
          console.log(error);
          return;
        }
      },
    }),
    deleteFolder: builder.mutation({
      query: (data) => ({
        url: `/${data.clientId}/delete?entityId=${data.entityId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getfoldersByid"],
    }),
    notificationCheck: builder.query({
      query: (data) => ({
        url: `/${data.userId}/${data.rootDocId}`,
        method: "GET",
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(documentApiManagement.util.invalidateTags(["getfoldersByid"]));
      },
    }),
  }),
});

export const {
  useCreateFolderMutation,
  useUpdateFolderMutation,
  useDeleteFolderMutation,
  useLazyNotificationCheckQuery,
} = folderApiManagement;

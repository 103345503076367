import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  CloseButton,
  Alert,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { validation } from "../../constants/attorney/validationMessages";
import {
  phoneNumberRules,
  emailRules,
  alphabetRules,
  indphonNumberRules,
  usphonNumberRules,
} from "../../constants/regExp";
import { formPlaceholder } from "../../constants/firm/labels";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ModalPopupType2 from "../../components/modal/ModalPopupType2";
import { clientPlaceholder } from "../../constants/attorney/lables";
import {
  useGetStakeHolderDetailsQuery,
  useUpdateStakeHolderDetailsMutation,
} from "../../slices/stackHolderApiManagmentSlice";
import {
  clientRelationship,
  formType,
} from "../../constants/attorney/mockData";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import { countryCodeList } from "../../constants/labels";
import {
  veteran_addPeople,
  veteran_addSuccussor,
  veteran_Changepassword,
  veteran_EditComponent,
  veteran_editPeoplePage,
  veteran_LandingPage,
  veteran_uploadDocuments,
  veteran_viewPeoplePage,
} from "../../constants/routes";

dayjs.extend(utc);

export default function VeteranEditFamilyDetails({ onClose }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [peopleData, setPeopleData] = useState({});
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const today = dayjs();
  const yesterday = dayjs()
    .year(today.year() - 100)
    .startOf("year");
  const [selectedDate, setSelectedDate] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const { data, error, isLoading } = useGetStakeHolderDetailsQuery(
    userInfo?.userId,
    {
      skip: userInfo?.userId === undefined,
    }
  );
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string()
      .trim()
      .required(`${validation.lastName.required}`)
      .matches(alphabetRules, `${validation.lastName.rule}`),
    email: yup
      .string()
      .trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
    dob: yup
      .string()
      .required(`${validation.dob.required}`)
      .test("valid-date", "Invalid date of birth", (value) => {
        if (!value) return true;
        return !isNaN(Date.parse(value));
      })
      .test("max-date", "Date of birth cannot be in the future", (value) => {
        if (!value) return true;
        const newtoday = dayjs().utc();
        const dob = dayjs(value).utc();
        return dob <= newtoday;
        //  let newtoday = new Intl.DateTimeFormat('en-US').format(today);
        //   // console.log(today.toLocaleDateString())
        //   newtoday = dayjs(newtoday).utc()
        //   console.log(dayjs(newtoday).utc())

        // let newdob = new Intl.DateTimeFormat('en-US').format(dob);
        // newdob = dayjs(newdob).utc()
      })
      .test(
        "min-date",
        "Date of birth should be after January 1, 1924",
        (value) => {
          if (!value) return true;
          // const today = dayjs().utc();
          const minDate = dayjs().year(1924).startOf("year");
          const dob = dayjs(value).utc();
          return dob >= minDate;
        }
      ),
    relationship: yup
      .string()
      .notOneOf([""], `${validation.relationship.required}`)
      .required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const formatDate = (date) => {
    return dayjs(date).utc();
  };

  const [editPeopleDetails, { isLoading: editPeopleLoading }] =
    useUpdateStakeHolderDetailsMutation();

  const handleDateChange = (date) => {
    let changedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDate(changedDate);
    setValue("dob", changedDate, { shouldValidate: true });
  };
  const handleViewPageNavigation = () => {
    if (location?.state?.pathname === veteran_LandingPage) {
      navigate(veteran_LandingPage, {
        state: {
          page: "editProfile",
          id: location?.state?.id,
        },
      });
    } else if (location?.state?.pathname === veteran_uploadDocuments) {
      navigate(veteran_uploadDocuments, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === veteran_viewPeoplePage) {
      navigate(veteran_viewPeoplePage, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === veteran_editPeoplePage) {
      navigate(veteran_editPeoplePage, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === veteran_addPeople) {
      navigate(veteran_addPeople, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === veteran_addSuccussor) {
      navigate(veteran_addSuccussor, {
        state: location?.state?.state,
      });
    } else if (location?.state?.pathname === veteran_Changepassword) {
      navigate(veteran_Changepassword, {
        state: {
          page: "editProfile",
        },
      });
    } else if (location?.state?.pathname === veteran_EditComponent) {
      navigate(veteran_EditComponent, {
        state: {
          activeTab: "family-info",
        },
      });
    }
  };
  useEffect(() => {
    if (data?.familyMember) {
      if (data?.familyMember && data?.familyMember?.phoneNumber) {
        if (data?.familyMember?.phoneNumber?.length === 10) {
          setCountryCode("+1");
          setPhoneNumber(`1${data?.familyMember?.phoneNumber}`);
        } else {
          const firstDigit = data?.familyMember?.phoneNumber.charAt(1);
          const lasttendigit = data?.familyMember?.phoneNumber.slice(-10);

          if (firstDigit === "1") {
            setCountryCode("+1");
            setPhoneNumber(`1${lasttendigit}`);
          } else {
            setCountryCode("+91");
            setPhoneNumber(`91${lasttendigit}`);
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  useEffect(() => {
    if (data?.familyMember && Object.keys(data?.familyMember).length > 0) {
      setPeopleData({
        firstName: data?.familyMember?.firstName,
        lastName: data?.familyMember?.lastName,
        emailId: data?.familyMember?.emailId,
        phoneNumber: data?.familyMember?.phoneNumber,
        dateOfBirth: formatDate(data?.familyMember?.dateOfBirth),
        relationship: data?.familyMember?.relationship,
      });
      setValue("firstName", data?.familyMember?.firstName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("lastName", data?.familyMember?.lastName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("email", data?.familyMember?.emailId, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      // setSelectedDate(formatDate(data?.familyMember?.dateOfBirth));
      setSelectedDate(
        dayjs(data?.familyMember?.dateOfBirth).utc().format("YYYY-MM-DD")
      );
      setValue("dob", formatDate(data?.familyMember?.dateOfBirth));
      setValue("relationship", data?.familyMember?.relationship);
    }
  }, [setValue, data]);

  const onSubmit = async (formData) => {
    const transformData = {
      clientId: userInfo?.userId,
      docId: data?.docId,
      userId: userInfo?.userId,
      type: formType.family,
      familyMember: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        emailId: formData.email,
        phoneNumber: `+${formData.phone}`,
        dateOfBirth: selectedDate,
        relationship: parseInt(formData.relationship),
      },
    };

    const dataCheck = JSON.stringify(transformData?.familyMember);
    const dataPeople = JSON.stringify(transformData);
    const existData = JSON.stringify(peopleData);
    const clientId = userInfo?.userId;
    const formatData = {
      dataPeople,
      clientId,
    };
    if (dataCheck !== existData) {
      try {
        const res = await editPeopleDetails(formatData).unwrap();
        if (res?.isSuccessful) {
          toast.success(res?.message);
          onClose ? onClose() : handleViewPageNavigation();
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    } else {
      onClose ? onClose() : handleViewPageNavigation();
    }
  };
  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };
  const handleUpdateCheck = () => {
    if (
      peopleData.firstName === getValues("firstName") &&
      peopleData.lastName === getValues("lastName") &&
      peopleData.emailId === getValues("email") &&
      `${peopleData.phoneNumber}` === `+${getValues("phone")}` &&
      `${peopleData.dateOfBirth}` === `${getValues("dob")}` &&
      peopleData.relationship === getValues("relationship")
    ) {
      handleViewPageNavigation();
    } else {
      setShowModal(true);
    }
  };
  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      {location?.state?.header && (
        <div className="death-claim-title-page-container border-0">
          <CloseButton
            className="close-button-clientedit position-absolute"
            variant="black"
            onClick={handleUpdateCheck}
            data-testid="close-button"
          />
          <h5 className="my-2 ms-3 pe-5">
            <b>{location?.state?.header} </b>
          </h5>
          <div className="px-3 mb-2">
            <div className="divider-firm-tab"></div>
          </div>
        </div>
      )}
      <Form
        className="custom-form employee-form px-2 pb-3 type-2 mx-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col lg={12} md={12} className="mt-4">
            <Row>
              <Col lg={6} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="emp-firstname" className="required mb-0">
                    {formPlaceholder.firstName}
                  </Form.Label>
                  <Form.Control
                    className="form-input"
                    type="text"
                    name="firstName"
                    autoComplete="off"
                    maxLength={30}
                    {...register("firstName")}
                    isInvalid={!!errors.firstName}
                    data-testid="emp-firstname"
                    id="emp-firstname"
                  />
                  <Form.Control.Feedback
                    data-testid="invalid-feedback"
                    type="invalid"
                  >
                    {errors.firstName?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="emp-lastname" className="required mb-0">
                    {formPlaceholder.lastName}
                  </Form.Label>
                  <Form.Control
                    className="form-input input-border-radius-unset"
                    type="text"
                    name="lastName"
                    maxLength={30}
                    autoComplete="off"
                    {...register("lastName")}
                    isInvalid={!!errors.lastName}
                    data-testid="emp-lastname"
                    id="emp-lastname"
                  />
                  <Form.Control.Feedback
                    data-testid="invalid-feedback"
                    type="invalid"
                  >
                    {errors.lastName?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6}>
            <Form.Group className="mb-3">
              <Form.Label id="emp-email" className="required mb-0">
                {formPlaceholder.email}
              </Form.Label>
              <Form.Control
                className="form-input input-border-radius-unset"
                type="email"
                name="email"
                autoComplete="off"
                {...register("email")}
                isInvalid={!!errors.email}
                onBlur={(e) => (e.target.value = e.target.value.trim())}
                data-testid="emp-email"
                id="emp-email"
              />
              <Form.Control.Feedback
                data-testid="invalid-feedback"
                type="invalid"
              >
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6} md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="emp-phone" className="required mb-0">
                {formPlaceholder.phone}
              </Form.Label>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <div
                    className={
                      errors.phone ? "phone-input-invalid-container" : ""
                    }
                  >
                    <PhoneInput
                      {...field}
                      defaultCountry="us"
                      onlyCountries={countryCodeList}
                      placeholder=""
                      title=""
                      country={"us"}
                      inputProps={{
                        required: true,
                        autoFocus: false,
                        title: "",
                      }}
                      countryCodeEditable={false}
                      onChangeCapture={handleMaxNumber}
                      onChange={(value, phone) => {
                        if (
                          phone.dialCode === "1" &&
                          phone.countryCode === "us"
                        ) {
                          setCountryCode("+1");
                          setPhoneNumber(value);
                        }
                        if (
                          phone.dialCode === "91" &&
                          phone.countryCode === "in"
                        ) {
                          setCountryCode("+91");
                          setPhoneNumber(value);
                        }
                      }}
                    />
                  </div>
                )}
              />
              <Form.Control
                className="form-input input-border-radius-unset input_number"
                type="hidden"
                name="phone"
                onKeyDown={handleKeyPress}
                onChangeCapture={handleMaxNumber}
                autoComplete="off"
                {...register("phone")}
                isInvalid={!!errors.phone}
                data-testid="emp-phone"
                id="emp-phone"
              />
              <Form.Control.Feedback
                data-testid="invalid-feedback"
                type="invalid"
              >
                {errors.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="" className="required mb-0">
                {clientPlaceholder.dob}
              </Form.Label>
              <Controller
                name="dob"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div
                      className={
                        errors.dob
                          ? "mui-date-picker-invalid-container"
                          : "mui-date-picker-container"
                      }
                    >
                      <DatePicker
                        {...field}
                        name="dob"
                        type="date"
                        value={
                          dayjs(selectedDate).isValid()
                            ? dayjs(selectedDate)
                            : null
                        }
                        onChange={handleDateChange}
                        maxDate={today}
                        minDate={yesterday}
                      />
                    </div>
                  </LocalizationProvider>
                )}
              />
              <Form.Control
                type="hidden"
                value={selectedDate || ""}
                autoComplete="off"
                {...register("dob", {
                  required: true,
                })}
                isInvalid={!!errors.dob}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dob?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6} md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="relationship" className="required mb-0">
                {clientPlaceholder.relationship}
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="form-input input-border-radius-unset select-placeholder"
                name="city"
                isInvalid={!!errors.relationship}
                {...register("relationship")}
                id="relationship"
                data-testid="family-relationship"
              >
                <option value="" hidden></option>
                {clientRelationship &&
                  clientRelationship.length > 0 &&
                  clientRelationship.map((relation, index) => {
                    return (
                      <option key={"relation" + index} value={relation.id}>
                        {relation.label}
                      </option>
                    );
                  })}
              </Form.Select>
              <Form.Control.Feedback
                data-testid="invalid-feedback"
                type="invalid"
              >
                {errors.relationship?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-lg-2">
          <Button
            className="custom-button btn-nobg"
            data-testid="cancel-button"
            onClick={handleUpdateCheck}
          >
            {formPlaceholder.cancelBtnText}
          </Button>
          <Button
            type="submit"
            className="custom-button btn-standard btn-update ms-4"
            data-testid="update-button"
          >
            {formPlaceholder.updateBtnText}
          </Button>
        </div>
      </Form>
      <ModalPopupType2
        setShowCallback={() => setShowModal(false)}
        setYesCallback={onClose ? onClose : handleViewPageNavigation}
        content={"Are you sure you want to cancel the changes?"}
        showModal={showModal}
      />
      {(editPeopleLoading || isLoading) && <Loader />}
    </>
  );
}

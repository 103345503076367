import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Card, Form, Button } from "react-bootstrap";
import { useUploadAiDocumentMutation } from "../slices/aiDocumentManagementSlice";
import { toast } from "react-toastify";
import {
  attoney_foldersPage,
  attorney_ai_recommendations,
  attorney_LandingPage,
  attorney_new_folder_upload_documents,
  attorney_uploadClientDocuments,
  client_ai_recommendations,
  client_LandingPage,
  client_new_folder_upload_documents,
  client_uploadDocuments,
} from "../constants/routes";
import NewFolderModal from "../components/modal/NewFolderModal";
import EmployeeTitleBar from "../components/firmadmin/EmployeeTitleBar";
import { clientTitle, uploadAiDocs } from "../constants/client/labels";
import TooltipPopoverInfo from "../components/tooltip/TooltipPopoverInfo";
import AiDocUploadConfirmationModal from "../components/modal/AiDocUploadConfirmationModal";
import Loader from "../components/Loader/Loader";
import { ROLE } from "../constants/role";
import { cliManagement } from "../constants/attorney/lables";

export default function AiRecommendations() {
  const location = useLocation();
  const navigate = useNavigate();
  const response = location?.state?.response || [];
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [showNewFolderModal, setShowNewFolderModal] =  useState(false)
  const [showModal, setShowModal] = useState(false)
  const isClient =  location?.state?.role === ROLE.client
  const [items, setItems] = useState([])
  const isRightPage = sessionStorage.getItem("FromClientPage") ===  "Yes"
  useEffect(() => {
    if (isClient) {
      setItems([
        {
          id: "item-1",
          link: client_LandingPage,
          content: clientTitle,
          active: false,
          state: { id: "", activeTab: "" },
        },
        {
          id: "item-2",
          link: {client_ai_recommendations},
          content: "Upload Document",
          active: true,
          state: { id: "", activeTab: "" },
        },
      ]);
    }
    else{
      setItems([
        {
          id: "item-1",
          link: attorney_LandingPage,
          content: cliManagement,
          active: false,
          state: { id: "", activeTab: "" },
        },
        {
          id: "item-2",
          link: attoney_foldersPage,
          content: location?.state?.fullName,
          active: false,
          state: { id: "", activeTab: "", refetch: true, user: {clientId :  location?.state?.clientId} },
        },
        {
          id: "item-3",
          link: attorney_ai_recommendations,
          content: "Upload Document",
          active: true,
          state: { id: "", activeTab: "" },
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [uploadAiDoc, { isLoading }] = useUploadAiDocumentMutation();

  const handleBackNavigation = () => {
    if(isClient){
      navigate(client_LandingPage)
      sessionStorage.removeItem("FromClientPage")
    }
    else{
      navigate(attoney_foldersPage , {state: {refetch: true, user: {clientId: location?.state?.clientId}}})  
      sessionStorage.removeItem("FromClientPage")  
    }
  }
  const createFolder = () => {
    setShowNewFolderModal(true)
    setSelectedDoc({docName : response.recommendedFolders[0].docName})
  } 

  const handleSelection = (doc) => {
    if(doc["docTypeId"] === "DT_001"){
      const filename = response.tempS3Path.split('/').pop();
      const fileType = filename.split('.')
      if(fileType[fileType.length - 1] !== "pdf"){
        toast.error("Unsupported file type. Only pdf files are allowed in Wills folder.")
      }
      else{
        setSelectedDoc(doc);
      }
    }
    else{
      setSelectedDoc(doc);
    }    
  };

  const handleNewFolderTransfer = async (callbackData) => {
      setShowNewFolderModal(false)
      if(selectedDoc && callbackData !== false){
        const data = {
          userId: location?.state?.userId,
          clientId: location?.state?.clientId,
          identifier: null,
          orgDocsFlag: true,
          tempS3Path: response.tempS3Path,
          recommendedFolder: {
            rootDocId: callbackData.rootDocId,
            docTypeId: null,
            docTypeName: null,
            docName: selectedDoc.docName,
            Confidence_score: null,
          },
        };
        const doc = {
          rootDocId : callbackData.rootDocId,
          rootDocName: callbackData.name,
          userId: isClient? location?.state?.userId : location?.state?.clientId
        }
        const user = {
          userId: location?.state?.userId,
          clientId: location?.state?.clientId,
          fullName: location?.state?.fullName
        }
          try {
            const res = await uploadAiDoc(data).unwrap();
            if (res?.isSuccessful) {
              toast.success(res?.message);
              try {
                if(isClient){
                  navigate(client_new_folder_upload_documents, { state: { doc:  doc, user: user, refetch: true} });
                  sessionStorage.removeItem("FromClientPage")
                }
                else{
                  navigate(attorney_new_folder_upload_documents , { state: { doc:  doc, user: user, refetch: true} });
                  sessionStorage.removeItem("FromClientPage")
                }
              } catch (err) {
                console.log(err);
              }
            } else {
              toast.error(res?.message);
            }
          } catch (err) {
            toast.error(err?.data?.message || err?.message || err?.error?.message || err?.error || err);
          }
      }
  }
  const handleSubmit = async () => {
    setShowModal(false)
    const data = {
      userId: location?.state?.userId,
      clientId: location?.state?.clientId,
      identifier: null,
      orgDocsFlag: true,
      tempS3Path: response.tempS3Path,
      recommendedFolder: {
        rootDocId: selectedDoc.rootDocId,
        docTypeId: selectedDoc.docTypeId,
        docTypeName: selectedDoc.docTypeName,
        docName: selectedDoc.docName,
        Confidence_score: selectedDoc.Confidence_score,
      },
    };
    const doc = {
      rootDocId : selectedDoc.rootDocId,
      rootDocName: selectedDoc.rootDocName,
      userId: isClient? location?.state?.userId : location?.state?.clientId
    }
    const user = {
      userId: location?.state?.clientId,
      clientId: location?.state?.clientId,
      fullName: location?.state?.fullName 
    }
    if (selectedDoc) {
      try {
        const res = await uploadAiDoc(data).unwrap();
        if (res?.isSuccessful) {
          toast.success(res?.message);
          try {
            if(isClient){
              navigate(client_uploadDocuments, { state: { doc:  doc, user: user, refetch: true} });
              sessionStorage.removeItem("FromClientPage")
            }
            else{
              navigate(attorney_uploadClientDocuments , { state: { doc:  doc, user: user, refetch: true} });
              sessionStorage.removeItem("FromClientPage")
            }
          } catch (err) {
            console.log(err);
          }
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.data?.message || err?.message || err?.error?.message || err?.error || err);
      }
    }
  };
  
  const handleDocCheck = () => {

  if (selectedDoc.docTypeId === "DT_001" && response.tempS3Path) {
    const fileName = response.tempS3Path.split('/').pop();
    const doc = {
      rootDocId : selectedDoc.rootDocId,
      rootDocName: selectedDoc.rootDocName,
      userId: isClient? location?.state?.userId : location?.state?.clientId
    }
    const user = {
      userId: location?.state?.clientId,
      clientId: location?.state?.clientId,
      fullName: location?.state?.fullName 
    }
    if(isClient){
      sessionStorage.removeItem("FromClientPage")
      navigate(client_uploadDocuments, {state:{
        doc : doc,
        user : user,
        fileName: fileName,
        fromAiDocPage: true,
      }})
    }
    else{
      sessionStorage.removeItem("FromClientPage")
      navigate(attorney_uploadClientDocuments, {state:{
        doc : doc,
        user : user,
        fileName: fileName,
        fromAiDocPage: true,
      }})
    }
  } else {
    setShowModal(true); 
  }
};

  const [visibleItems, setVisibleItems] = useState(3);

  return (
    <div
      className="ai-recommendations-doc-list"
      data-testid="client-receive-files-page"
    >
      <div
        className="superadmin-container view-people-info-container"
        data-testid="client-receive-files-page"
      >
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-3 px-lg-4"
            data-testid="sa-addemployee-page"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={uploadAiDocs}
              hasBackButton={false}
              navigateToPrev={() => {}}
              isAiDoc = {true}
            />
          </div>
        </Container>
      </div>
      <Container fluid className="px-lg-4">
        { isRightPage && response?.recommendedFolders ?         
        <div className="firm-page-container attorney-view-page employee-form-container px-lg-4 px-3">
          <div>
            <div className="mt-4">
              <span className="page-title-ai">
                <b>Recommended Document Types</b>
              </span>
            </div>
            <div className="mt-4">
              {response?.recommendedFolders  &&
                response?.recommendedFolders
                  .slice(0, visibleItems)
                  .map((folder, index) => {
                    return (
                      <Card className="custom-card" key={folder.docTypeId}>
                        <Card.Header className="custom-card-header d-flex">
                          <Form.Check
                            name="group1"
                            type="radio"
                            className="custom-radio-button me-2"
                            checked={selectedDoc === folder}
                            data-testid={`folder-radio-${index}`}
                            onChange={() => handleSelection(folder)}
                          />
                          <div>
                            <span data-testid={`folder-name-${index}`} className="folder-name">
                              {(window.innerWidth < 576 &&
                                folder.docTypeName?.length < 25) ||
                              window.innerWidth > 576
                                ? folder.docTypeName
                                : folder.docTypeName.slice(0, 22) + "..."}
                              <span className="tooltip-ai ps-2">
                                <TooltipPopoverInfo
                                  content={<>{folder.documentDescription}</>}
                                  placement={window.innerWidth > 576 ? "right" : "top"}
                                />
                              </span>
                            </span>
                          </div>
                        </Card.Header>
                      </Card>
                    );
                  })}
            </div>
            {response?.recommendedFolders?.length > visibleItems ? (
              <div className="ms-3">
                <button className="button-link" onClick={(e) => {
                    e.preventDefault();
                    setVisibleItems(visibleItems + 2); // Show 2 more items on click
                  }} data-testid="load-more-link">More options</button>

              </div>
            ) : 
            (
              <div className="ms-3">
              <button className="button-link" onClick={createFolder}
                data-testid="new-folder">Add this file to a New Folder</button>
            </div> 
            )}
            {response && (
              <div className="d-flex justify-content-end mt-3 mb-2 pb-4">
                <Button
                  style={{ width: "8.5rem" }}
                  className={`custom-button btn-standard ${
                    !selectedDoc && "btn-disable"
                  }`}
                  type="button"
                  onClick={handleDocCheck}
                  disabled={!selectedDoc}
                  data-testid="submit-button"
                >
                  Submit
                </Button>
              </div>
            )}
            <div className="mb-5 pb-3">
            <span className="ms-3">
              <p>
                Not happy with the suggested document types? Please click
                <button className="button-link" onClick={handleBackNavigation} data-testid="navigate-back-link">here</button>
                 to navigate back to your folders.  
              </p>
            </span>
            </div>
          </div>
        </div>
        :
        <div className="m-4">
          No Data available
        </div>
        }

      </Container>
      {showNewFolderModal && 
      <NewFolderModal
        showModal={showNewFolderModal}
        setShowCallback={(callbackData) => handleNewFolderTransfer(callbackData)}
        folderData={null}
        clientId={location?.state?.clientId}
      />}
      {showModal && 
      <AiDocUploadConfirmationModal
        setShowCallback={() => setShowModal(false)}
        setSuccessCallback={handleSubmit}
        docName= {selectedDoc.docTypeName}
        showModal={showModal}
      />
      }
      {isLoading && <Loader/>}
    </div>
    
  );
}

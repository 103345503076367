import React, { useEffect, useState } from "react";
import { Container, Alert, Row, Col, CloseButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { clientTitle } from "../../constants/client/labels";
import { dateDMY } from "../../utils/dateFormat";
import { useGetfoldersByidQuery } from "../../slices/documentApiManagement";
import Loader from "../../components/Loader/Loader";
import {
  client_share_bank_details,
  client_receivefiles,
  client_new_folder_upload_documents,
  client_ai_recommendations,
  veteran_uploadDocuments,
  veteran_LandingPage,
  veteran_addFamily,
} from "../../constants/routes";
import { toast } from "react-toastify";
import { useGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import { setStackHolderEmpty } from "../../slices/stackHolderSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
//import { ReactComponent as DotsBig } from "../../assets/images/DotsBig.svg";
import { ReactComponent as DotsSmall } from "../../assets/images/DotsSmall.svg";
import { ReactComponent as EditAction } from "../../assets/images/EditAction.svg";
import { ReactComponent as DeleteAction } from "../../assets/images/DeleteAction.svg";
import FamilyOffCanvasAddProfile from "../../components/editProfileOffCanvas/FamilyOffCanvasAddProfile";
import { useGetStakeHolderDetailsQuery } from "../../slices/stackHolderApiManagmentSlice";
import { ReactComponent as Info } from "../../assets/images/Info.svg";
import { useGoogleAnalyticsKeyQuery } from "../../slices/gaApiSliceManagement";
import { setDetails } from "../../slices/shareReceiveBankDetailsSlice";
import NewFolderModal from "../../components/modal/NewFolderModal";
import ModalPopupType5 from "../../components/modal/ModalPopupType5";
import DeleteFolderContent from "../../components/client/DeleteFolderContent";
import { useDeleteFolderMutation } from "../../slices/folderApiManagementSlice";
import DragandDropFile from "../../components/modal/DragandDropFile";
import AnalysingFile from "../../components/modal/AnalysingFile";
import { ROLE } from "../../constants/role";
import VeteranTitleBar from "../../components/veteran/VeteranTitleBar";
import VeteranLoginPopup from "../../components/modal/VeteranLoginPopup";

export default function VeteranLandingPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [foldersData, setFolderData] = useState([]);
  const [showRipon, setShowRipon] = useState(false);
  const [addFamily, setAddFamily] = useState(false);
  const [showNewFolderModal, setNewFolder] = useState(false);
  //const [showSuccessModal, setSuccessModal] = useState(false);
  const [showDeleteMsg, setDeleteMsg] = useState(false);
  const [activeDots, setActiveDots] = useState(null);
  const [helpstate, setHelpState] = useState(false);
  const [selectedFolderData, setSelectedFolderData] = useState(null);
  const [isMobile] = useState(window.innerWidth >= 768 ? false : true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showModalAnalyser, setShowModalAnalyser] = useState(false);
  const [showModalVetLogin, setShowModalVetLogin] = useState(false);
  // const [addFamily, setAddFamily] = useState(false);

  const { userInfo } = useSelector((state) => state.auth);

  const {
    data,
    error: getApiError,
    isLoading: isGetLoading,
  } = useGetClientQuery(userInfo?.userId, {
    skip: userInfo?.userId === undefined,
  });

  //const [trigger, { isLoading, error}] = useLazyGetfoldersByidQuery();

  const USER_TYPE = "VET_ESSENTIALS";

  const {
    data: refetchData,
    error: refetchError,
    isLoading,
    refetch,
  } = useGetfoldersByidQuery(data?.clientId, {
    skip: data?.clientId === undefined,
  });
  const {
    data: familyData,
    error: familyError,
    isLoading: isFamilyLoading,
  } = useGetStakeHolderDetailsQuery(userInfo?.userId, {
    skip: userInfo?.userId === undefined,
  });

  const { data: googleAnalyticsKey } = useGoogleAnalyticsKeyQuery({
    skip: data?.length === 0,
  });
  const [deleteFolder, { isLoading: deleteFolderLoading }] =
    useDeleteFolderMutation();
  const queryParameters = new URLSearchParams(window.location.search);
  const identifierID = queryParameters.get("identifier");
  const urlType = queryParameters.get("type");
  useEffect(() => {
    if (data?.clientId && refetchData && refetchData.length > 0) {
      setFolderData(refetchData);
      const essentialPlan = userInfo?.firstLogin;
      const getSessionPlan = sessionStorage.getItem("essentialPlan");
      if (essentialPlan && getSessionPlan === "true") {
        setShowModalVetLogin(true);
      }
    }
  }, [data?.clientId, refetchData, userInfo]);

  const storeFileInSession = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const blob = new Blob([new Uint8Array(e.target.result)]);
      const blobUrl = URL.createObjectURL(blob);
      sessionStorage.setItem("uploadedFile", blobUrl);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileUploaded = async (file) => {
    setShowUploadModal((show) => !show);
    setShowModalAnalyser(true);
    storeFileInSession(file);
  };

  const handleUploadSuccess = (response) => {
    // console.log(response);
    setShowModalAnalyser(false);
    if (response.status === 200) {
      try {
        sessionStorage.setItem("FromClientPage", "Yes");
        navigate(client_ai_recommendations, {
          state: {
            role: ROLE.client,
            response: response.data.aiRecommendationResponse,
            clientId: userInfo.userId,
            userId: userInfo.userId,
          },
        });
      } catch (err) {
        toast.error(
          err?.data?.message ||
            err?.errorMessage ||
            err?.data?.errorMessage ||
            err.error
        );
      }
    } else {
      toast.error(
        response?.data?.message ||
          response?.errorMessage ||
          response?.data?.errorMessage ||
          response.error
      );
    }
    // setShowModalAISuccess(true);
  };

  // const getDocumentTypes = useCallback(async () => {
  //   if (data?.clientId) {
  //     const result = await trigger(data?.clientId).unwrap();
  //     if (result && result.length > 0) {
  //       console.log("get folders===>", result);
  //       setFolderData(prev => [...result]);
  //     } else {
  //       toast.error(result?.message);
  //     }
  //   }
  // }, [data?.clientId, trigger]);
  useEffect(() => {
    if (location?.state?.refetch && data?.clientId) {
      refetch();
    }
    if (identifierID && urlType && urlType === "addProf") {
      dispatch(setDetails({ identifierID, requestType: urlType }));
      navigate(
        `${veteran_LandingPage}/additionproflanding?identifier=${identifierID}&type=${urlType}`
      );
    }
    if (identifierID && urlType && urlType === "deathClaim") {
      dispatch(setDetails({ identifierID, requestType: urlType }));
      navigate(
        `${veteran_LandingPage}/reporterlanding?identifier=${identifierID}&type=${urlType}`
      );
    }
    sessionStorage.removeItem("FromClientPage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (data?.fullName) {
  //     getDocumentTypes();
  //   }
  // }, [data?.fullName, getDocumentTypes, helpstate]);

  useEffect(() => {
    if (familyData?.familyMember?.firstName === undefined) {
      setShowRipon(true);
    } else {
      setShowRipon(false);
    }
  }, [familyData]);

  const handleAddFamily = () => {
    if (window.innerWidth >= 768) {
      setAddFamily(true);
    } else {
      navigate(veteran_addFamily, {
        state: {
          page: "clientPage",
          id: userInfo?.userId,
          pathname: location?.pathname,
          state: location?.state,
          header: "My Information | Add Emergency Contact Profile",
        },
      });
    }
  };

  useEffect(() => {
    const googleTrackingId = googleAnalyticsKey?.REACT_APP_GOOGLE_ID;
    if (data?.clientId && googleTrackingId) {
      const page = location.pathname + location.search;
      const usersId = userInfo?.userId;
      const role = userInfo?.role;
      const firmType = userInfo?.firmTypeId;
      const firmId = userInfo?.firmId;

      ReactGA.initialize(googleTrackingId);

      ReactGA.gtag("set", "user_properties", {
        userRole: role,
        usersId: usersId,
        firmTypeId: firmType,
        firmId: firmId,
      });

      firmType === 1 &&
        ReactGA.send({
          hitType: "pageview",
          page: `${page}`,
          title: "Client Landing Page - 3 Level",
        });

      firmType === 2 &&
        ReactGA.send({
          hitType: "pageview",
          page: `${page}`,
          title: "Client Landing Page - 2 Level",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, googleAnalyticsKey]);

  useEffect(() => {
    dispatch(setStackHolderEmpty());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const shareReceiveHandler = (val) => {
    if (val === "receivefiles") {
      navigate(client_receivefiles, {
        state: {
          page: "clientPage",
          id: userInfo?.userId,
          pathname: location?.pathname,
          state: location?.state,
          header: "My Information | Add Emergency Contact Profile",
        },
      });
    }
  };

  useEffect(() => {
    if (activeDots) {
      const handleClickOutside = (event) => {
        const hasActionTextClass = Array.from(
          event.target.parentNode.children
        ).some((child) => child.classList.contains("action-text"));

        const hasActionclass = Array.from(event.target.children).some((child) =>
          child.classList.contains("action-text")
        );

        if (!hasActionTextClass && !hasActionclass) {
          setActiveDots(null);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [activeDots]);

  const handleShareBankDetails = () => {
    navigate(client_share_bank_details, {
      state: {
        user: userInfo?.userId,
      },
    });
  };
  const handleDots = (rootDocId) => {
    setActiveDots(rootDocId);
    setHelpState(!helpstate);
  };
  const handleNewFolderCreate = () => {
    setActiveDots(null);
    setSelectedFolderData(null);
    setNewFolder(true);
  };
  const handleFolderEdit = (folderData) => {
    setSelectedFolderData(folderData);
    setActiveDots(null);
    setNewFolder(true);
  };
  const handleFolderDelete = (folder) => {
    setActiveDots(null);
    setSelectedFolderData(folder);
    setDeleteMsg(true);
  };
  const deletCustomerFolder = async () => {
    const data = {
      clientId: userInfo?.userId,
      entityId: selectedFolderData?.rootDocId,
    };
    if (selectedFolderData?.rootDocId) {
      try {
        const res = await deleteFolder(data).unwrap();
        if (res?.isSuccessful) {
          toast.success(res?.message);
          setDeleteMsg(false);
          refetch();
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.data?.message);
      }
    }
  };

  if (refetchError || getApiError || familyError) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status -{" "}
          {refetchError?.status || getApiError?.status || familyError?.status}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      <div
        className="attorney-client-title-page-container px-lg-4 px-xl-4"
        data-testid="attonery-client-title"
      >
        <Container fluid>
          <div className="attoner-client-title p-2 px-lg-4 px-md-4 px-xl-4">
            <VeteranTitleBar
              items={false}
              title={
                data?.fullName
                  ? `${clientTitle} - ${data?.fullName}`
                  : clientTitle
              }
              isMobile={isMobile}
              hasButton={false}
              createFolderCallback={() => handleNewFolderCreate()}
              shareReceiveCallback={shareReceiveHandler}
              shareBankDetailsCallback={handleShareBankDetails}
              userType={USER_TYPE}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container attorney-folders ps-3 px-lg-4 px-md-4 px-xl-4">
            {!isFamilyLoading &&
              familyData?.familyMember?.firstName === undefined &&
              showRipon && (
                <div className="update-info-box">
                  <span className="info-icon-addfamily mx-2">
                    <Info />
                  </span>
                  <span className="update-info-text pt-2">
                    Please update your Emergency Contact information!
                    <button
                      onClick={handleAddFamily}
                      className="update-click-here mx-1"
                    >
                      {" "}
                      Click here{" "}
                    </button>
                    to update
                  </span>
                  <CloseButton
                    className="close-button-ripon ms-3"
                    // variant="black"
                    onClick={() => {
                      setShowRipon(false);
                    }}
                  />
                </div>
              )}
            <div className="noof-document-uploaded pt-3">
              Total number of Documents uploaded:{" "}
              <span className="no-uploaded">
                {foldersData && foldersData.length > 0
                  ? foldersData[foldersData.length - 1].totalDocsByUserId
                  : 0}
              </span>
            </div>
            <div className="mt-5 folder-container-new d-flex flex-wrap justify-content-lg-start justify-content-md-center">
              <div className="d-none d-lg-block d-md-block">
                <div className="w-100 d-flex flex-lg-row flex-md-column align-items-lg-start align-items-md-center">
                  {foldersData && foldersData.length > 0 && (
                    <div key={`dt-0`}>
                      <LinkContainer
                        to={veteran_uploadDocuments}
                        state={{
                          doc: foldersData[0],
                          user: data,
                        }}
                      >
                        <div
                          className={`big-folder position-relative ${foldersData[0]?.folderColor}`}
                        >
                          <div className="three-dots position-absolute">
                            {/* <DotsBig /> */}
                          </div>
                          <div className="folder-name ps-3 pt-5">
                            {foldersData[0].rootDocName}
                          </div>
                          <div className="document-count text-center">
                            {foldersData[0].totalDocsByFolder}
                          </div>
                          <div className="edited-date ps-3 position-absolute">
                            {foldersData[0]?.lastModifiedAt !== null && (
                              <div>
                                Edited: {dateDMY(foldersData[0].lastModifiedAt)}
                              </div>
                            )}
                          </div>
                        </div>
                      </LinkContainer>
                    </div>
                  )}
                  <div className="other-common-folder-new ms-xl-5 ms-lg-5 d-flex flex-wrap align-items-lg-start align-items-md-center justify-content-lg-start justify-content-md-center mt-lg-0 mt-md-5">
                    {foldersData &&
                      foldersData.length > 0 &&
                      foldersData.map((folder, index) => {
                        if (index === 0) return null;
                        if (folder?.isCustomFolder && folder?.isCustomFolder)
                          return null;
                        // let pdt =
                        //   folder.rootDocName.length > 20 ? "longstring" : "";
                        return (
                          <LinkContainer
                            to={veteran_uploadDocuments}
                            key={`dt-${index}`}
                            state={{
                              doc: folder,
                              user: data,
                            }}
                          >
                            <div
                              className={`common-folders-new ${folder?.folderColor} me-4 mb-4 position-relative`}
                            >
                              <div className="three-dots position-absolute">
                                {/* <DotsSmall /> */}
                              </div>
                              <div className="folder-name ps-3">
                                {folder.rootDocName}
                              </div>
                              <div className="document-count text-center">
                                {folder.totalDocsByFolder}
                              </div>
                              <div className="edited-date ps-3 position-absolute">
                                {folder?.lastModifiedAt !== null && (
                                  <div>
                                    Edited: {dateDMY(folder.lastModifiedAt)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </LinkContainer>
                        );
                      })}
                    {foldersData &&
                      foldersData.length > 0 &&
                      foldersData.map((folder, index) => {
                        if (index === 0) return null;
                        if (!folder?.isCustomFolder) return null;
                        // let pdt =
                        //   folder.rootDocName.length > 20 ? "longstring" : "";
                        return (
                          <div
                            className={`common-folders-new ${folder?.folderColor} me-4 mb-4 position-relative`}
                            key={`dt-${index}`}
                          >
                            <div
                              className="three-dots position-absolute"
                              onClick={() => handleDots(folder.rootDocId)}
                              data-testid={`desktop-three-dots-${index}`}
                            >
                              <DotsSmall />
                            </div>
                            <div>
                              <div
                                className={
                                  activeDots === folder.rootDocId
                                    ? "new-folder-actions d-flex flex-column"
                                    : "d-none"
                                }
                              >
                                <div
                                  onClick={() => handleFolderEdit(folder)}
                                  data-testid={`desktop-folder-edit-${index}`}
                                >
                                  <EditAction width={14} height={12} />{" "}
                                  <span className="action-text ps-2">Edit</span>
                                </div>
                                <div
                                  className="pt-1"
                                  onClick={() => handleFolderDelete(folder)}
                                  data-testid={`desktop-folder-delete-${index}`}
                                >
                                  <DeleteAction width={18} height={16} />
                                  <span className="action-text ps-2">
                                    Delete
                                  </span>{" "}
                                </div>
                              </div>
                            </div>
                            <LinkContainer
                              to={client_new_folder_upload_documents}
                              key={`dt-08`}
                              state={{
                                doc: folder,
                                user: data,
                              }}
                            >
                              <div>
                                <div className="folder-name ps-3">
                                  {folder.rootDocName}
                                </div>
                                <div className="document-count text-center">
                                  {folder.totalDocsByFolder}
                                </div>
                                <div className="edited-date ps-3 position-absolute">
                                  {folder?.lastModifiedAt !== null && (
                                    <div>
                                      Edited: {dateDMY(folder.lastModifiedAt)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </LinkContainer>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none d-lg-none d-xl-none d-xxl-none w-100">
                <Row className="w-100">
                  <Col xs={12} sm={12}>
                    {foldersData && foldersData.length > 0 && (
                      <div key={`md-0`}>
                        <LinkContainer
                          to={veteran_uploadDocuments}
                          state={{
                            doc: foldersData[0],
                            user: data,
                          }}
                        >
                          <div
                            className={`big-folder-small-devices ${foldersData[0]?.folderColor} position-relative`}
                          >
                            <div className="three-dots position-absolute">
                              {/* <DotsBig /> */}
                            </div>
                            <div className="folder-name ps-3 pt-5">
                              {foldersData[0].rootDocName}
                            </div>
                            <div className="document-count text-center">
                              {foldersData[0].totalDocsByFolder}
                            </div>
                            <div className="edited-date ps-3 position-absolute">
                              {foldersData[0]?.lastModifiedAt !== null && (
                                <div>
                                  Edited:{" "}
                                  {dateDMY(foldersData[0].lastModifiedAt)}
                                </div>
                              )}
                            </div>
                          </div>
                        </LinkContainer>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="w-100 mt-3">
                  <Row className="w-100 other-small-folders">
                    {foldersData &&
                      foldersData.length > 0 &&
                      foldersData.map((folder, index) => {
                        if (index === 0) return null;
                        if (folder?.isCustomFolder && folder?.isCustomFolder)
                          return null;
                        // let pdt =
                        //   folder.rootDocName.length > 20 ? "longstring" : "";
                        return (
                          <LinkContainer
                            to={veteran_uploadDocuments}
                            key={`md-${index}`}
                            state={{
                              doc: folder,
                              user: data,
                            }}
                          >
                            <Col xs={6} sm={6} className="mb-3">
                              <div
                                className={`common-folders-new-small-devices ${folder?.folderColor} position-relative`}
                              >
                                <div className="three-dots position-absolute">
                                  {/* <DotsSmall /> */}
                                </div>
                                <div className="folder-name ps-3">
                                  {folder.rootDocName}
                                </div>

                                <div className="edited-date ps-3 position-absolute">
                                  <div className="document-count">
                                    {folder.totalDocsByFolder} Files
                                  </div>
                                  {folder?.lastModifiedAt !== null && (
                                    <div>
                                      Edited: {dateDMY(folder.lastModifiedAt)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </LinkContainer>
                        );
                      })}
                    {foldersData &&
                      foldersData.length > 0 &&
                      foldersData.map((folder, index) => {
                        if (index === 0) return null;
                        if (!folder?.isCustomFolder) return null;
                        return (
                          <Col
                            xs={6}
                            sm={6}
                            className="mb-3"
                            key={`md-${index}`}
                          >
                            <div
                              className={`common-folders-new-small-devices ${folder?.folderColor} position-relative`}
                            >
                              <div
                                className="three-dots position-absolute"
                                onClick={() => handleDots(folder.rootDocId)}
                                data-testid={`mb-three-dots-${index}`}
                              >
                                <DotsSmall />
                              </div>
                              <div>
                                <div
                                  className={
                                    activeDots === folder.rootDocId
                                      ? "new-folder-actions d-flex flex-column ps-2 pt-1"
                                      : "d-none"
                                  }
                                >
                                  <div
                                    onClick={() => handleFolderEdit(folder)}
                                    data-testid={`mb-folder-edit-${index}`}
                                  >
                                    <EditAction width={14} height={12} />{" "}
                                    <span className="action-text ps-2">
                                      Edit
                                    </span>
                                  </div>
                                  <div
                                    className="pt-1"
                                    onClick={() => handleFolderDelete(folder)}
                                    data-testid={`mb-folder-delete-${index}`}
                                  >
                                    <DeleteAction width={18} height={16} />
                                    <span className="action-text ps-2">
                                      Delete
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              <div></div>
                              <LinkContainer
                                to={client_new_folder_upload_documents}
                                key={`dt-08`}
                                state={{
                                  doc: folder,
                                  user: data,
                                }}
                              >
                                <div>
                                  <div className="folder-name mb-height ps-3">
                                    {folder?.rootDocName}
                                  </div>

                                  <div className="edited-date ps-3 position-absolute">
                                    <div className="document-count">
                                      {folder?.totalDocsByFolder} Files
                                    </div>
                                    {folder?.lastModifiedAt !== null && (
                                      <div>
                                        Edited:{" "}
                                        {dateDMY(
                                          foldersData[7]?.lastModifiedAt
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </LinkContainer>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </div>
            </div>
            {USER_TYPE !== "VET_ESSENTIALS" && (
              <div className="py-2">
                <p>
                  Unable to find the right folder or document type? Try using
                  our
                  <span>
                    <button
                      className="button-link"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowUploadModal(true);
                      }}
                      data-testid="load-more-link"
                    >
                      {" "}
                      Suggested Upload{" "}
                    </button>
                  </span>
                  process.
                </p>
              </div>
            )}
          </div>
        </Container>
      </div>
      <div className="background-mvs-image type-1"></div>
      {addFamily && (
        <FamilyOffCanvasAddProfile
          visible={addFamily}
          onClose={() => setAddFamily(false)}
        />
      )}
      {(isLoading ||
        isGetLoading ||
        isFamilyLoading ||
        deleteFolderLoading) && <Loader data-testid="loader" />}
      <NewFolderModal
        showModal={showNewFolderModal}
        setShowCallback={(callbackData) => {
          setNewFolder(false);
          setSelectedFolderData(null);
          setHelpState(!helpstate);
          refetch();
        }}
        folderData={selectedFolderData}
      />
      <ModalPopupType5
        show={showDeleteMsg}
        setShow={() => setDeleteMsg(false)}
        hasFooter={false}
        modalClass={
          false
            ? "modal-container modal-text px-3"
            : "modal-container modal-text px-3 pb-4"
        }
        modalContentElement={
          <DeleteFolderContent
            setYesCallback={() => deletCustomerFolder()}
            setNoCallback={() => setDeleteMsg(false)}
            folderData={selectedFolderData}
          />
        }
        onClickCloseNavigation={() => setDeleteMsg(false)}
      />
      <DragandDropFile
        show={showUploadModal}
        setShow={() => setShowUploadModal(false)}
        modalSize="md"
        type="ai-doc-upload"
        onFileUploaded={(file) => {
          handleFileUploaded(file);
        }}
        onAiUploadCompleted={handleUploadSuccess}
        role="client"
        clientId={userInfo.userId}
        userId={userInfo.userId}
      />
      <AnalysingFile
        show={showModalAnalyser}
        size="md"
        setShowAnalyse={() => setShowModalAnalyser(false)}
        text="Analysing the file, please wait!"
      />

      <VeteranLoginPopup
        showModal={showModalVetLogin}
        setShowCallback={() => {
          setShowModalVetLogin(false);
          sessionStorage.removeItem("essentialPlan");
        }}
        headerTitle=""
      />
    </>
  );
}

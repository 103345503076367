import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Container, Form } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { deathClaimDocType } from "../../constants/documentFileType";
import { toast } from "react-toastify";
import { useDeathCertificateUploadedMutation } from "../../slices/documentApiManagement";
import { upload_question } from "../../constants/deathclaim/labels";
import DocumentPreview from "../modal/DocumentPreview";
import { famVault_wesbite } from "../../constants/routes";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import EmployeeTitleBar from "../firmadmin/EmployeeTitleBar";
import { ReactComponent as InfoIcon } from "../../assets/images/VectorInfo-blue.svg";
import AccessExpired from "../AccessDenied/AccessExpired";
import AccessDenied from "../AccessDenied/AccessDenied";
import WillUploadedModalContent from "../widgets/WillUploadedModalContent";
import ModalPopup from "../modal/ModalPopup";
import AnalysingFile from "../modal/AnalysingFile";
import { useDeathAiClaimMutation } from "../../slices/aiDocumentManagementSlice";
export default function ReporterLandingPage() {
  const [uploadDeathCertificateV2] = useDeathAiClaimMutation();
  const [question, setQuestion] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [showResModal, setShowResModal] = useState(false);
  const [modaltextMesage, setModaltextmessage] = useState(null);
  const [modalIcon, setModalIcon] = useState(null);
  const [showModalAnalyser, setShowModalAnalyser] = useState(false);
  const [footerButtonText, setFooterButtonText] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState("");
  const [fileName, setFileName] = useState("");
  const [content, setFileContent] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const [isNoSelected, setIsNoSelected] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [error, setError] = useState(false);
  const [happyPath, setHappyPath] = useState(null);
  const [
    deathCertificateUploaded,
    { isLoading: deathCertificateUploadedLoading },
  ] = useDeathCertificateUploadedMutation();
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );

  const ssoResponse =
    sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
  const isSSOLogin = ssoResponse
    ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
    : false;
  let identifierVal = null;
  if (isSSOLogin) {
    if (
      sessionStorage.ssoIdentifier &&
      JSON.parse(sessionStorage.ssoIdentifier) &&
      JSON.parse(sessionStorage.ssoIdentifier)?.identifierID &&
      JSON.parse(sessionStorage.ssoIdentifier)?.requestType
    ) {
      identifierVal = JSON.parse(sessionStorage.ssoIdentifier)?.identifierID;
    }
  } else {
    identifierVal = identifierID;
  }

  const isCertificateUploaded = useCallback(async () => {
    try {
      const res = await deathCertificateUploaded({
        clientId: identifierVal,
      }).unwrap();
      if (res && res?.message !== "") {
        setIsUploaded(true);
        setQuestion(res?.message);
      } else {
        if (res?.isSuccessful) {
          if (res?.isExpired) {
            setIsExpired(res?.isExpired);
          } else {
            const newText = upload_question.replace(/{}/g, res?.clientName);
            setQuestion(newText);
          }
        } else {
          if (res?.isExpired) {
            setIsExpired(res?.isExpired);
          }
        }
      }
    } catch (err) {
      if (err.status === 403) {
        setError(true);
      }
      setShowFile(false);
      toast.error(
        err?.data?.message ||
          err?.data?.errorMessage ||
          err.error ||
          err?.errorMessage
      );
    }
    // eslint-disable-next-line
  }, [deathCertificateUploaded, userInfo, identifierVal]);

  useEffect(() => {
    isCertificateUploaded();
    // eslint-disable-next-line
  }, [userInfo, isCertificateUploaded]);

  const uploadCertificate = useCallback(
    async ({ fileData, data }) => {
      setShowModalAnalyser(true);
      try {
        const res = await uploadDeathCertificateV2({
          fileData,
          data,
        }).unwrap();
        if (res?.isSuccessful) {
          setShowFile(false);
          setShowUpload(false);
          setShowModalAnalyser(false);
          setShowResModal(true);
          setModaltextmessage(res?.message);
          setModalIcon(false);
          setHappyPath(true);
        } else if (res?.key === "ERROR" && res?.isSuccessful === false) {
          setShowFile(false);
          setShowModalAnalyser(false);
          setShowResModal(true);
          setModaltextmessage(res?.message);
          setModalIcon(true);
          setFooterButtonText("Re-Upload");
        } else if (res?.key === "WARN" && res?.isSuccessful === false) {
          setShowFile(false);
          setShowModalAnalyser(false);
          setShowResModal(true);
          setModaltextmessage(res?.message);
          setModalIcon(true);
          setFooterButtonText("OK");
        }
      } catch (err) {
        setShowFile(false);
        setShowModalAnalyser(false);
        toast.error(
          err?.data?.message ||
            err?.data?.errorMessage ||
            err.error ||
            err?.errorMessage ||
            err?.data?.error
        );
      }
    },
    [uploadDeathCertificateV2]
  );

  const handleCloseModel = () => {
    if (footerButtonText === "Re-Upload") {
      setShowResModal(false);
    } else if (footerButtonText === "OK") {
      setShowResModal(false);
      window.location.href = `${famVault_wesbite}`;
    } else if (happyPath) {
      setShowResModal(false);
      window.location.href = `${famVault_wesbite}`;
    }
  };

  const handleSubmit = () => {
    const data = {
      clientId: identifierVal,
      userId: userInfo?.userId,
    };
    const fileData = new FormData();
    fileData.append("file", selectedDocs);
    uploadCertificate({ fileData, data });
  };
  const handleClick = (val) => {
    setShowUpload(val);
    setIsNoSelected(val);
  };
  const onDropAccepted = async (files) => {
    try {
      const acceptedFiles = files[0];
      setShowFile(true);
      setFileName(acceptedFiles?.name);
      setSelectedDocs(acceptedFiles);
      const reader = new FileReader();

      reader.onload = (e) => {
        const blob = new Blob([new Uint8Array(e.target.result)]);
        const blobUrl = URL.createObjectURL(blob);
        setFileContent(blobUrl);
      };

      reader.readAsArrayBuffer(acceptedFiles);
    } catch (err) {
      toast.error(err?.data?.message || err?.errorMessage);
    }
  };

  const onDropRejected = (params) => {
    if (params[0]?.errors[0]?.code === "file-invalid-type") {
      toast.error(`This format is not valid! Please upload PDF format only`);
    }
    if (params[0]?.errors[0]?.code === "file-too-large") {
      toast.error(`File more than 5 MB is not allowed`);
    }
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: deathClaimDocType,
    noDrag: true,
    noClick: true,
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024,
    noKeyboard: true,
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  if (isExpired) {
    return <AccessExpired />;
  }
  if (error) {
    return (
      <div className="pt-3">
        <AccessDenied />
      </div>
    );
  }

  return (
    <>
      <div
        className="attorney-client-title-page-container px-lg-4 px-xl-4"
        data-testid="death-claim-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-3 pt-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={false}
              hasButton={false}
              title="Disclosure"
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid className="deathclaim-page-container">
          <div className="firm-page-container px-3 px-lg-4 px-md-4 px-xl-4 py-4">
            <div
              hidden={deathCertificateUploadedLoading}
              className={
                isUploaded
                  ? "deathclaim-message-container px-lg-3 px-md-3 px-sm-3 py-4"
                  : "deathclaim-question-container border px-lg-3 px-md-3 px-sm-3 py-lg-2 py-md-2"
              }
            >
              {isUploaded && (
                <span className="info-blue mx-3 pe-4 pe-lg-1 pe-md-1">
                  <InfoIcon />
                </span>
              )}
              <div
                className="question-container"
                data-testid="deathclaim-view-page"
              >
                {question}
              </div>
              <div
                className="button-container px-lg-3 px-md-3 m-sm-3 m-xs-3"
                hidden={isUploaded}
              >
                <ButtonGroup hidden={isUploaded}>
                  <Button
                    className={
                      isNoSelected
                        ? "custom-button btn-nobg"
                        : "custom-button btn-standard-deathclaim"
                    }
                    data-testid="btn-isalive-yes"
                    onClick={() => handleClick(false)}
                  >
                    Yes
                  </Button>
                  <Button
                    className={
                      isNoSelected
                        ? "custom-button btn-standard-deathclaim"
                        : "custom-button btn-nobg"
                    }
                    data-testid="btn-isalive-no"
                    onClick={() => handleClick(true)}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <div className="row my-4">
              <Form.Group
                className="col-lg-3 col-md-5 col-sm-6 col-12"
                hidden={!showUpload}
              >
                <Form.Control
                  type="text"
                  name="death-certificate"
                  className="form-input death-certificate-input p-2"
                  value="Death Certificate"
                  data-testid="death-certificate"
                  id="death-certificate"
                  readOnly
                />
                <Form.Text>Allowed Files : PDF format only</Form.Text>
              </Form.Group>
              <div
                {...getRootProps()}
                className="col-lg-3 col-md-5 col-sm-4 col-xs-12 custom-file-upload-deathcertificate upload-block"
              >
                <input {...getInputProps()} data-testid="input-file" />
                <Button
                  className="custom-button btn-standard-upload mt-2 mt-lg-0 mt-md-0 ml-sm-2"
                  type="button"
                  onClick={open}
                  hidden={!showUpload}
                >
                  Browse & Upload
                </Button>
              </div>
            </div>
          </div>
        </Container>
        <DocumentPreview
          showModal={showFile}
          setShowCallback={() => setShowFile(false)}
          setSubmitCallback={handleSubmit}
          fileName={fileName}
          selectedDocs={selectedDocs}
          fileContent={content}
        />
        <div className="background-mvs-image type-2"></div>
      </div>
      {deathCertificateUploadedLoading && <Loader />}
      <ModalPopup
        show={showResModal}
        setShow={() => setShowResModal(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        titleClass="header-title"
        headerClass="no-border"
        modalClass={
          modalIcon
            ? "modal-container modal-text px-3"
            : "modal-container modal-text px-3 pb-4"
        }
        modalContentElement={
          <WillUploadedModalContent
            text={modaltextMesage}
            isError={modalIcon}
          />
        }
        hasFooter={modalIcon}
        footerClass="no-border pt-0 justify-content-center"
        footerButtonText={footerButtonText}
        footerButtonClass="custom-button btn-standard btn-add-modal px-4"
        animation={false}
        onClickCloseNavigation={handleCloseModel}
      />
      <AnalysingFile
        show={showModalAnalyser}
        size="md"
        setShowAnalyse={handleCloseModel}
        text="Analyzing the file, please wait!"
      />
    </>
  );
}
